import { useEffect, useMemo } from 'react';
import {
  NEATLEAF_ORGANIZATION_CODE,
  TOrganization,
} from 'shared/interfaces/organization';
import {
  EUserRole,
  PublicRole,
  UserWithMetadata,
} from 'shared/interfaces/user';
import { useAnalytics } from './useAnalytics';

export const useTrackUser = (
  user: Maybe<UserWithMetadata>,
  isNeatleafOrganizationMember: boolean,
  organization: Maybe<TOrganization>
): void => {
  const { identifyUser } = useAnalytics();

  const { organizationCode, publicRole, userId, excludeFromAnalytics } =
    useMemo(() => {
      let publicRole = PublicRole.NotSpecified;
      let organizationCode: string | undefined = undefined;
      let userId: string | undefined = undefined;
      let excludeFromAnalytics = false;

      if (!user) {
        return {
          publicRole,
          organizationCode,
          userId,
        };
      }

      organizationCode = isNeatleafOrganizationMember
        ? NEATLEAF_ORGANIZATION_CODE
        : organization?.code;

      const isKiosk = user.user_organization_roles.some(
        (r) => r.role.code === EUserRole.KIOSK
      );

      if (user.metadata && user.metadata.publicRole) {
        publicRole = user.metadata.publicRole;
      } else if (isKiosk) {
        publicRole = PublicRole.Kiosk;
      }

      if (user.metadata && user.metadata.excludeFromAnalytics) {
        excludeFromAnalytics = true;
      }

      userId = user.id.toString();

      return {
        organizationCode,
        publicRole,
        userId,
        excludeFromAnalytics,
      };
    }, [organization, user, isNeatleafOrganizationMember]);

  useEffect(() => {
    if (userId && organizationCode) {
      identifyUser(userId, organizationCode, publicRole, excludeFromAnalytics);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationCode, publicRole, userId]);
};
