import { useAvailableMentions } from 'api/user';
import { Datetime } from 'components/common/Datetime/Datetime';
import { Dropdown, Option } from 'components/common/Dropdown/Dropdown';
import { Textarea } from 'components/common/Textarea/Textarea';
import { max, min } from 'date-fns';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { ComponentPropsWithoutRef, forwardRef, useContext } from 'react';
import { TDiscussionCategory } from 'shared/interfaces/discussion';
import { cn } from 'shared/utils/cn';
import { formatDateyyyyMMddHHmm, isDateValid } from 'shared/utils/date';
import {
  formatCategory,
  getCategoriesFromAnnotationType,
} from 'shared/utils/discussion';
import { DiscussionBoxContext } from './DiscussionBoxContext';

export const EditComment = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'>
>(function EditComment({ className, ...props }, ref) {
  const { editState } = useContext(DiscussionBoxContext);
  const {
    discussionDraft: { annotationType, category, startTime, endTime },
    commentDraft,
    timeRange,
    discussionErrors,
    commentErrors,
    touchedFields,
    updateDiscussion,
    updateContent,
  } = editState!;
  const categories = getCategoriesFromAnnotationType(annotationType);
  const selectedCategory = category ?? categories[0]!;
  const categoryOptions: Option<TDiscussionCategory>[] = categories.map(
    (category) => ({
      label: formatCategory(category),
      value: category,
      selected: category === selectedCategory,
    })
  );
  const selectedCategoryOption = categoryOptions.find(
    ({ value }) => value === selectedCategory
  );

  const { currentZone } = useCurrentZone();
  const availableMentions = useAvailableMentions(currentZone?.uid);

  return (
    <div
      ref={ref}
      {...props}
      className={cn('w-full h-full flex flex-col gap-4', className)}
    >
      <Dropdown
        variant="secondary"
        value={selectedCategoryOption}
        options={categoryOptions}
        label="Category"
        labelPlacement="top"
        labelClassName="font-bold"
        required={touchedFields.category}
        errorText={(discussionErrors.category ?? [])[0]}
        onChange={(option) => {
          // narrow to a single option
          if (option && !Array.isArray(option)) {
            updateDiscussion({ category: option.value });
          }
        }}
      />
      {annotationType === 'event' && (
        <Datetime
          label="Time Stamp"
          labelPlacement="top"
          labelClassName="font-bold"
          value={formatDateyyyyMMddHHmm(startTime)}
          required={touchedFields.startTime}
          errorText={(discussionErrors.startTime ?? [])[0]}
          {...(timeRange && {
            min: formatDateyyyyMMddHHmm(timeRange.start),
            max: formatDateyyyyMMddHHmm(timeRange.end),
          })}
          onChange={(event) => {
            const time = new Date(event.target.value);
            updateDiscussion({ startTime: time, endTime: time });
          }}
        />
      )}
      {annotationType === 'time_range_annotation' && (
        <>
          <Datetime
            label="Start time"
            labelPlacement="top"
            labelClassName="font-bold"
            className="w-full"
            value={formatDateyyyyMMddHHmm(startTime)}
            required={touchedFields.startTime}
            errorText={(discussionErrors.startTime ?? [])[0]}
            {...(timeRange && {
              min: formatDateyyyyMMddHHmm(timeRange.start),
              max: formatDateyyyyMMddHHmm(min([timeRange.end, endTime])),
            })}
            onChange={(event) => {
              const time = new Date(event.target.value);
              if (isDateValid(time)) {
                updateDiscussion({ startTime: time });
              }
            }}
          />
          <Datetime
            label="End time"
            labelPlacement="top"
            labelClassName="font-bold"
            value={formatDateyyyyMMddHHmm(endTime)}
            required={touchedFields.endTime}
            errorText={(discussionErrors.endTime ?? [])[0]}
            {...(timeRange && {
              min: formatDateyyyyMMddHHmm(max([timeRange.start, startTime])),
              max: formatDateyyyyMMddHHmm(timeRange.end),
            })}
            onChange={(event) => {
              const time = new Date(event.target.value);
              if (isDateValid(time)) {
                updateDiscussion({ endTime: time });
              }
            }}
          />
        </>
      )}
      <div className="h-auto">
        <Textarea
          aria-label="Comment content"
          placeholder="Type your comment here"
          value={commentDraft.content.content}
          className="min-h-24 h-24 transition-all"
          minLength={1}
          required={touchedFields.content}
          errorText={(commentErrors.content ?? [])[0]}
          onChange={(content) => updateContent({ content })}
          isLexicalEditor={true}
          availableMentions={availableMentions}
        />
      </div>
    </div>
  );
});
