import { TPasswordRule } from 'shared/interfaces/general';

export const PASSWORD_RULES: TPasswordRule[] = [
  {
    label: 'Password must match',
    check: (password: string, confirmPassword: string): boolean =>
      password === confirmPassword && password?.length > 0,
  },
  {
    label: 'At least 8 characters',
    check: (password: string): boolean => password?.length >= 8,
  },
  {
    label: 'Must contain at least one upper case letter',
    check: (password: string): boolean => /[A-Z]/.test(password),
  },
  {
    label: 'Must contain at least one special character',
    check: (password: string): boolean => /[#?!@$%^&*-]/.test(password),
  },
  {
    label: 'Must contain at least one number',
    check: (password: string): boolean => /[0-9]/.test(password),
  },
];
