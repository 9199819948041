import { RouteObject } from 'react-router-dom';
import { TOrganization } from 'shared/interfaces/organization';
import { UserWithMetadata } from 'shared/interfaces/user';
import { useTrackEvents } from './useTrackEvents';
import { useTrackPageViews } from './useTrackPageViews';
import { useTrackSearchParams } from './useTrackSearchParams';
import { useTrackUser } from './useTrackUser';

export interface SetupAnalyticsProps {
  flattenedRoutes: RouteObject[];
  user: Maybe<UserWithMetadata>;
  isNeatleafOrganizationMember: boolean;
  organization: Maybe<TOrganization>;
  searchParamsKeysThatShouldBeTracked: string[];
}

export function SetupAnalytics(props: SetupAnalyticsProps) {
  useTrackUser(
    props.user,
    props.isNeatleafOrganizationMember,
    props.organization
  );
  useTrackPageViews(
    props.flattenedRoutes,
    props.searchParamsKeysThatShouldBeTracked
  );
  useTrackSearchParams(props.searchParamsKeysThatShouldBeTracked);
  useTrackEvents();
  return null;
}
