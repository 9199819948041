import { ComponentPropsWithRef, FC } from 'react';
import { cn } from 'shared/utils/cn';

interface StatusTextProps extends ComponentPropsWithRef<'span'> {}

/**
 * A status text usually displayed below a form field e.g.: input and dropdown
 */
export const StatusText: FC<StatusTextProps> = ({ children, className }) => {
  if (!children) {
    return null;
  }
  return (
    <span className={cn('hidden text-xs text-gray-500', className)}>
      {children}
    </span>
  );
};
