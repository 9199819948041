import { useCallback, useState } from 'react';

export interface UseDisclosureProps {
  isOpen?: boolean;
  defaultIsOpen?: boolean;
  onClose?(): void;
  onOpen?(): void;
}

export interface Disclosure {
  isOpen: boolean;
  close(): void;
  open(): void;
  toggle(): void;
}

/**
 * `useDisclosure` is a custom hook used to help handle common open, close, or toggle scenarios.
 * It can be used to control feedback component such as `Modal`.
 */
export function useDisclosure({
  onOpen: onOpenProp,
  onClose: onCloseProp,
  isOpen: isOpenProp,
  defaultIsOpen,
}: UseDisclosureProps = {}): Disclosure {
  const handleOpen = useCallback(() => onOpenProp?.(), [onOpenProp]);
  const handleClose = useCallback(() => onCloseProp?.(), [onCloseProp]);

  const [isOpenState, setIsOpen] = useState(defaultIsOpen || false);

  const isOpen = isOpenProp !== undefined ? isOpenProp : isOpenState;

  const isControlled = isOpenProp !== undefined;

  const close = useCallback(() => {
    if (!isControlled) {
      setIsOpen(false);
    }
    handleClose();
  }, [isControlled, handleClose]);

  const open = useCallback(() => {
    if (!isControlled) {
      setIsOpen(true);
    }
    handleOpen();
  }, [isControlled, handleOpen]);

  const toggle = useCallback(() => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  }, [isOpen, open, close]);

  return {
    isOpen,
    open,
    close,
    toggle,
  };
}
