/**
 * The zoom level limitations of the image feed.
 */
export const ZOOM_LEVELS = {
  MAX: 2,
  // NOTE: Value of 1 is too close for an optimal user experience
  // given a large grid it becomes cumbersome to always keep zooming out
  NORMAL: 0.5,
  DELTA: 0.5,
  MIN: 0.0625,
};

export const WIDTH_INDEX = 0;
export const HEIGHT_INDEX = 1;
export const CANVAS_INTERACTION_THROTTLE_MILLISECONDS = 100;
export const NOT_LOADED_IMAGE_SIZE_INDEX = -1;
