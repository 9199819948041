import AbnormalImage from 'assets/image/abnormal.jpg';
import NecrosisImage from 'assets/image/necrosis.jpg';
import OtherImage from 'assets/image/other.jpg';
import YellowingImage from 'assets/image/yellowing.jpg';
import {
  EImageLabelCategory,
  IMAGE_LABEL_CATEGORY_TITLES,
} from 'shared/interfaces/image';
import { cn } from 'shared/utils/cn';

const CATEGORY_IMAGES = {
  [EImageLabelCategory.YELLOWING]: YellowingImage,
  [EImageLabelCategory.NECROSIS]: NecrosisImage,
  [EImageLabelCategory.ABNORMAL_SHAPE]: AbnormalImage,
  [EImageLabelCategory.OTHER]: OtherImage,
} as const;

const MAX_COUNT_THRESHOLD = 1.1;
const BAR_HEIGHT = 40;

interface IGetAriaLabelInput {
  categoryTitle: string;
  displayCount: number;
  percentChanges: Nullable<number>;
}

const getAriaLabel = ({
  categoryTitle,
  displayCount,
  percentChanges,
}: IGetAriaLabelInput) => {
  if (percentChanges === null) {
    return `"${categoryTitle}" labels have not changed in the past ${displayCount} days`;
  }

  const trend = percentChanges > 0 ? 'increased' : 'decreased';
  const value = Math.abs(percentChanges * 100).toFixed(1);

  return `"${categoryTitle}" labels ${trend} ${value}% in the past ${displayCount} days`;
};

interface IPlantHealthCardProps {
  /** The image label category */
  category: EImageLabelCategory;
  /** The maximum number to be show for the health bar. */
  displayCount: number;
  /** The latest label counts of the category */
  labelCounts: number[];
  /** Called when the user clicks the card */
  onClick: () => void;
}

/** Displays a card with the plant health based on the label category */
export const PlantHealthCard = ({
  category,
  displayCount,
  labelCounts,
  onClick,
}: IPlantHealthCardProps) => {
  if (category === EImageLabelCategory.OBJECT) {
    return null;
  }

  const previousCount = labelCounts.at(-2);
  const currentCount = labelCounts.at(-1);

  const MAX_COUNT = Math.max(...labelCounts) * MAX_COUNT_THRESHOLD;
  const categoryImage = CATEGORY_IMAGES[category];
  const categoryTitle = IMAGE_LABEL_CATEGORY_TITLES[category];

  const percentChanges =
    previousCount && currentCount !== undefined
      ? (currentCount - previousCount) / previousCount
      : null;

  const ariaLabel = getAriaLabel({
    categoryTitle,
    displayCount,
    percentChanges,
  });

  return (
    <div
      aria-label={ariaLabel}
      className="max-w-[300px] max-h-[240px] min-w-[195px] p-3 flex flex-col gap-2 justify-between cursor-pointer flex-1 bg-neutral-200 rounded"
      role="listitem"
      onClick={onClick}
    >
      <img
        alt={categoryTitle}
        src={categoryImage}
        className="w-full object-cover rounded h-[110px] sm:h-[144px]"
      />

      <div className="flex justify-between px-1">
        <div className="flex flex-col gap-2">
          <p className="text-xs font-bold text-neutral-500">{categoryTitle}</p>

          {percentChanges !== null ? (
            <div
              data-testid="plant-health-card-percent"
              className="flex gap-1 items-center"
            >
              <div
                className={cn(
                  'w-2 h-2',
                  percentChanges > 0
                    ? 'bg-red-500 [clip-path:polygon(0%_100%,50%_0%,100%_100%)]'
                    : 'bg-green-300 [clip-path:polygon(0%_0%,50%_100%,100%_0%)]'
                )}
              />
              <p className="font-bold">
                {Math.abs(percentChanges * 100).toFixed(1)}%
              </p>
            </div>
          ) : (
            <p className="font-bold">-</p>
          )}
        </div>

        <div className="flex gap-1 self-end">
          {labelCounts.map((labelCount, index) => (
            <div
              key={index}
              data-testid="plant-health-bar"
              className="relative w-4 rounded-xs bg-green-300"
              style={{ height: BAR_HEIGHT }}
            >
              <div
                className="absolute left-0 right-0 bottom-0 bg-orange-600 rounded-xs"
                style={{
                  height: (BAR_HEIGHT * ((labelCount / MAX_COUNT) * 100)) / 100,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
