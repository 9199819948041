import { getNextImagePostition } from 'components/image_feed/utils';
import { EImageTypes, ISectionCapture } from 'shared/interfaces/image';
import { useGetSectionMeasurementsByZoneId } from './useGetSectionMeasurementsByZoneId';

export type TGetNextSectionProps = {
  gridSize: TGridSize;
  imageType: EImageTypes;
  position: TPosition;
  startTime: Optional<number>;
  endTime: Optional<number>;
  zoneId: string;
};

export type TGetNextSectionReturn = {
  captures: ISectionCapture[];
  nextSectionMeasurementList: ISectionCapture[];
};

export const useGetCurrentAndNextSectionCaptures = ({
  gridSize,
  imageType,
  position,
  startTime,
  endTime,
  zoneId,
}: TGetNextSectionProps): TGetNextSectionReturn => {
  const currentSectionOptions = {
    endTime,
    gridSize,
    imageType,
    position,
    startTime,
    zoneId,
  };
  const { captures } = useGetSectionMeasurementsByZoneId(currentSectionOptions);

  const nextSectionOptions = currentSectionOptions;
  nextSectionOptions.position = getNextImagePostition(position, gridSize);
  const { captures: nextSectionMeasurementList } =
    useGetSectionMeasurementsByZoneId(nextSectionOptions);

  return {
    captures,
    nextSectionMeasurementList,
  };
};
