export const noop = (value: any) => value;

/**
 * Convert fahrenheit degree to celsius degree.
 */
export function convertFahrenheitToCelsius(fahrenheit: number) {
  return ((fahrenheit - 32) * 5) / 9;
}

/**
 * Convert celsius degree to fahrenheit degree.
 *
 * @param {number} celsius - celsius degree
 * @returns {number} - fahrenheit degree
 */
export function convertCelsiusToFahrenheit(celsius: number) {
  return (celsius * 9) / 5 + 32;
}

/**
 * Convert difference of celsius degrees to fahrenheit degree.
 * if celsiusDelta = 10 then celsiusDelta = celsius01 - celsius02
 * input celsius01 = 10 and celius02 = 0,
 * diff  = (9/5 * celsius01 - 32) - (9/5 * celsius02 -32)
 *       = 9/5 * celsius01 - 9/5 * celius02
 *       = (9 * (celsius01 - celius02)) / 5
 *       = (9 * (celsiusDelta)) / 5
 *       = 9 * (10) / 5
 *       = 18 Fahrenheit
 * @param {number} celsiusDelta - celsius degree difference
 * @returns {number} - fahrenheit degree
 */
export function convertDifferenceCelsiusToFahrenheit(celsiusDelta: number) {
  return (celsiusDelta * 9) / 5;
}

/**
 * Convert meter to feet.
 *
 * @param {number} meter - meters
 * @returns {number} - feet
 */
export function convertMeterToFeet(meter: number) {
  return meter * 3.281;
}

/**
 * Convert meter to inches.
 *
 * @param {number} meter - meter
 * @returns {number} - inches
 */
export function convertMeterToInches(meter: number) {
  return meter * 39.26;
}

/**
 * Convert number to the Hexavigesimal letters
 * http://en.wikipedia.org/wiki/Hexavigesimal
 * @param  {number} num - The number to convert
 * @returns {string} - The converted letters
 */
export const convertNumberToLetters = (num: number): string => {
  const charCodeA = 'A'.charCodeAt(0);
  const charCodeZ = 'Z'.charCodeAt(0);
  const len = charCodeZ - charCodeA + 1;
  let result = '';

  while (num >= 0) {
    result = String.fromCharCode((num % len) + charCodeA) + result;
    num = Math.floor(num / len) - 1;
  }

  return result;
};

export function hashStringToIndex(str: string, max: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash) % max;
}
