import {
  GetLocationByIdDocument,
  GetLocationByIdQuery,
  GetLocationByIdQueryVariables,
  useGetLocationByIdQuery,
  useUpdateLocationMetadataMutation,
} from 'graphql/generated/react_apollo';
import isNil from 'lodash.isnil';
import { TLocation } from 'shared/interfaces/location';

export const useGetLocationById = (id: number) => {
  const { data, ...result } = useGetLocationByIdQuery({
    variables: { id },
    skip: isNil(id),
  });

  let location: Nullable<TLocation> = null;
  const [dbLocation] = data?.location ?? [];
  if (isNil(dbLocation)) {
    return { ...result, location };
  }
  location = {
    id: dbLocation.id,
    code: dbLocation.enumeration.code,
    name: dbLocation.enumeration.description,
    timeZone: dbLocation.time_zone_name,
    addressId: dbLocation.address_id,
    organizationId: dbLocation.organization_id,
    organizationCode: dbLocation.organization.enumeration.code,
    organizationName: dbLocation.organization.enumeration.description,
    metadata: {
      dark_environment_parameters: [],
      light_environment_parameters: [],
      ...dbLocation.metadata,
    },
  };
  return { ...result, location };
};

export const useUpdateLocationMetadata = (id: number) => {
  const [mutation, result] = useUpdateLocationMetadataMutation();
  const update = (metadata: TLocation['metadata']) =>
    mutation({
      variables: {
        id,
        metadata,
      },
      refetchQueries: [GetLocationByIdDocument],
      optimisticResponse: {
        update_location: {
          affected_rows: 1,
          __typename: 'location_mutation_response',
        },
      },
      update: (cache) => {
        const variables = { id };
        const cachedData = cache.readQuery<
          GetLocationByIdQuery,
          GetLocationByIdQueryVariables
        >({
          query: GetLocationByIdDocument,
          variables,
        });

        const [location] = cachedData?.location ?? [];

        cache.writeQuery({
          query: GetLocationByIdDocument,
          variables,
          data: { location: { ...location, metadata } },
          id: `location:${id}`,
        });
      },
    });

  return { ...result, update };
};
