import { useAvailableMentions } from 'api/user';
import { Chip } from 'components/common/Chip/Chip';
import { TextEditor } from 'components/common/TextEditor/TextEditor';
import { usePermissions } from 'hooks/usePermissions';
import { CalendarIcon, SparklesIcon, TriangleAlertIcon } from 'lucide-react';
import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';
import {
  InsightCommentContent,
  InsightSeverity,
  severityChip,
  TDiscussion,
} from 'shared/interfaces/discussion';
import { cn } from 'shared/utils/cn';
import { formatDateInMMMd } from 'shared/utils/date';
import { hasText } from 'shared/utils/textEditor';
import { CommentThread } from './CommentThread';

interface InsightContentProps extends ComponentPropsWithoutRef<'div'> {
  discussion: TDiscussion;
  showTime?: boolean;
  showStatus: boolean;
  preview?: ReactNode;
}

export const InsightContent = forwardRef<HTMLDivElement, InsightContentProps>(
  function InsightContent(
    { className, discussion, showTime = false, showStatus, preview, ...props },
    ref
  ) {
    const insight = discussion.firstComment.content as InsightCommentContent;
    const availableMentions = useAvailableMentions(discussion.zoneUid);
    const permissions = usePermissions();

    return (
      <div
        ref={ref}
        {...props}
        className={cn('flex flex-col gap-4 @container', className)}
      >
        <div className="flex gap-2 flex-wrap">
          {insight.severity !== InsightSeverity.None && (
            <Chip
              variant={severityChip[insight.severity]}
              size="xs"
              selected
              className="capitalize"
            >
              <TriangleAlertIcon className="stroke-[1.5px] size-4" />
              {insight.severity}
            </Chip>
          )}
          {showTime && (
            <Chip variant="contrast" size="xs">
              <CalendarIcon className="stroke-[1.5px] size-4" />
              {formatDateInMMMd(discussion.endTime)}
            </Chip>
          )}
          {insight.occurrence && (
            <Chip variant="contrast" size="xs">
              {insight.occurrence}
            </Chip>
          )}
          <Chip variant="contrast" size="xs" className="capitalize">
            {insight.type}
          </Chip>
          {showStatus && (
            <Chip variant="contrast" size="xs" className="capitalize">
              {discussion.status}
            </Chip>
          )}
        </div>

        {hasText(insight.content) && (
          <TextEditor
            key={insight.content}
            initialEditorState={insight.content}
            editable={false}
            availableMentions={availableMentions}
          />
        )}

        {preview}

        {hasText(insight.recommendation) && (
          <div className="flex flex-col gap-1 p-4 @sm:p-6 text-green-900 bg-green-50 rounded-sm whitespace-pre-wrap">
            <p className="flex gap-2 items-center font-bold">
              <SparklesIcon className="stroke-[1.5px]" />
              Recommendation
            </p>
            <TextEditor
              key={insight.recommendation}
              initialEditorState={insight.recommendation}
              editable={false}
              availableMentions={availableMentions}
            />
          </div>
        )}

        {permissions.comments.canView && (
          <CommentThread discussion={discussion} />
        )}
      </div>
    );
  }
);
