import { PlantHealthValue } from 'api/labels';
import fallbackImage from 'assets/image/plant-health-fallback-image.jpg';
import { BudIcon } from 'icons/BudIcon';
import { LeafFoldingIcon } from 'icons/LeafFoldingIcon';
import { LeafNecrosisIcon } from 'icons/LeafNecrosisIcon';
import { LeafPowderIcon } from 'icons/LeafPowderIcon';
import { LeafYellowingIcon } from 'icons/LeafYellowingIcon';
import { PlantHeightIcon } from 'icons/PlantHeightIcon';
import {
  ArrowDownRightIcon,
  ArrowUpRightIcon,
  LucideIcon,
  MinusIcon,
} from 'lucide-react';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import {
  EMeasurementStatisticsTypesV2,
  MeasurementTypeConfig,
} from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';
import { smartRound } from 'shared/utils/miscellaneous';

const {
  CALCULATED_DISTANCE,
  YELLOWING_GENERAL,
  NECROSIS_GENERAL,
  ABNORMAL_SHAPE_FOLDING,
  OTHER_POWDER,
  OBJECT_BUD,
} = EMeasurementStatisticsTypesV2;

const icons = {
  [YELLOWING_GENERAL]: LeafYellowingIcon,
  [ABNORMAL_SHAPE_FOLDING]: LeafFoldingIcon,
  [OTHER_POWDER]: LeafPowderIcon,
  [NECROSIS_GENERAL]: LeafNecrosisIcon,
  [CALCULATED_DISTANCE]: PlantHeightIcon,
  [OBJECT_BUD]: BudIcon,
} as Record<EMeasurementStatisticsTypesV2, LucideIcon>;

export interface PlantHealthCardProps extends ComponentPropsWithoutRef<'div'> {
  value: PlantHealthValue;
  onSelectSignal?: (signal: MeasurementTypeConfig) => void;
  variant: 'trend' | 'max';
  imgClassName?: string;
}

export const PlantHealthCard = forwardRef<HTMLDivElement, PlantHealthCardProps>(
  function PlantHealthCard(
    { className, imgClassName, variant, value, onSelectSignal },
    ref
  ) {
    const { trend, linear, colors, signal, title, image } = value;
    const isEmpty = !trend && !linear && !colors;
    const Icon = icons[signal.statisticsKeyV2];
    const handleOnClick =
      isEmpty || !onSelectSignal ? undefined : () => onSelectSignal(signal);

    return (
      <div
        ref={ref}
        className={cn('flex flex-col gap-2 lg:gap-3 w-full', className)}
      >
        <div
          className={cn(
            'relative rounded-xxs overflow-hidden w-full h-[100px]',
            !isEmpty && onSelectSignal && 'hover:cursor-pointer',
            imgClassName
          )}
          onClick={handleOnClick}
        >
          <img
            src={image ?? fallbackImage}
            alt={title}
            className="absolute inset-0 size-full object-cover"
          />
          {!image && (
            <Icon className="absolute inset-0 size-full stroke-[1.5px] py-2 lg:py-4 xl:py-5 text-ash-400" />
          )}
        </div>

        <div className="flex flex-col">
          <p className="text-sm xl:text-base text-nowrap truncate">{title}</p>
          {trend && <Trend trend={trend} variant={variant} />}
          {linear && <Linear linear={linear} variant={variant} />}
          {colors && <Colors colors={colors} />}
          {isEmpty && (
            <p className="text-lg lg:text-xl xl:text-2xl text-ash-500">–</p>
          )}
        </div>
      </div>
    );
  }
);

interface TrendProps {
  trend: NonNullable<PlantHealthValue['trend']>;
  variant: 'trend' | 'max';
}
const Trend = ({ trend, variant }: TrendProps) => {
  const { value, previousValue, unit, max } = trend;
  const isWorse = previousValue < value;
  const isBetter = previousValue > value;
  const Icon = isBetter
    ? ArrowDownRightIcon
    : isWorse
      ? ArrowUpRightIcon
      : MinusIcon;

  return (
    <div className="flex">
      {variant === 'trend' && (
        <>
          <div className="hidden xl:flex flex-col text-gray-500">
            <p className="text-xs">from</p>
            <p className="font-semibold truncate">{`${smartRound(previousValue)} ${unit}`}</p>
          </div>
          <Icon
            className={cn(
              'shrink-0 size-7 lg:size-9 xl:size-12 stroke-[1.5px] lg:stroke-[2px] xl:stroke-[3px]',
              'stroke-ash-500',
              isWorse && 'stroke-red-500',
              isBetter && 'stroke-green-500'
            )}
          />
        </>
      )}
      <p className="text-lg lg:text-xl xl:text-2xl font-semibold truncate">{`${smartRound(variant === 'trend' ? value : max)} ${unit}`}</p>
    </div>
  );
};

interface LinearProps {
  linear: NonNullable<PlantHealthValue['linear']>;
  variant: 'trend' | 'max';
}
export const Linear = ({ linear, variant }: LinearProps) => {
  const { suffix, unit, value, max } = linear;
  return (
    <p className="text-lg lg:text-xl xl:text-2xl font-semibold">{`${variant === 'trend' && suffix ? suffix : ''}${smartRound(variant === 'trend' ? value : max)} ${unit}`}</p>
  );
};

export const Colors = ({
  colors,
}: {
  colors: NonNullable<PlantHealthValue['colors']>;
}) => {
  return (
    <div className="w-full h-7 lg:h-9 xl:h-12 flex items-center">
      {colors.map((backgroundColor) => (
        <div
          key={backgroundColor}
          className="w-full h-5 lg:h-7 xl:h-8 first:rounded-s-full last:rounded-e-full"
          style={{ backgroundColor }}
        />
      ))}
    </div>
  );
};
