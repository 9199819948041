import { useUpdateGrowthCycleMetadata } from 'api/growth-cycle';
import { Button } from 'components/common/Button/Button';
import { Modal } from 'components/common/Modal/Modal';
import { Textarea } from 'components/common/Textarea/Textarea';
import * as toast from 'components/common/Toast/Toast';
import { useDisclosure } from 'hooks/useDisclosure';
import { useGrowthCycles } from 'hooks/useGrowthCycles';
import { PencilLineIcon } from 'lucide-react';
import {
  ChangeEventHandler,
  ComponentPropsWithoutRef,
  forwardRef,
  useState,
} from 'react';
import { TGrowthCycleEdit } from 'shared/interfaces/growthCycle';
import { cn } from 'shared/utils/cn';

export const GrowthCycleNotes = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'>
>(function GrowthCycleNotes({ className, ...props }, ref) {
  const editModal = useDisclosure();
  const { currentCycle, latestCycle } = useGrowthCycles();
  const ongoingCycle = currentCycle ?? latestCycle;
  const notes = ongoingCycle?.metadata?.notes;
  const { update, loading } = useUpdateGrowthCycleMetadata();
  const [localCycle, setLocalCycle] = useState<TGrowthCycleEdit>(
    ongoingCycle as TGrowthCycleEdit
  );
  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setLocalCycle((prev) => ({
      ...prev,
      metadata: { ...prev.metadata, notes: event.target.value },
    }));
  };
  const handleSave = async () => {
    try {
      await update(localCycle);
      editModal.close();
    } catch (_error) {
      toast.error({
        content:
          'Something went wrong while saving the notes. Please try again.',
      });
    }
  };

  if (!localCycle) {
    return null;
  }

  return (
    <>
      {!notes && (
        <Button
          variant="tertiary"
          className="w-full justify-between"
          trailingIcon={<PencilLineIcon className="icon" />}
          onClick={editModal.open}
        >
          Add notes
        </Button>
      )}

      {notes && (
        <div
          ref={ref}
          {...props}
          className={cn(
            'py-3 px-4 xl:py-4 xl:px-6 rounded bg-white flex flex-col gap-2 xl:gap-4 items-center justify-center',
            className
          )}
        >
          <h2
            className="w-full flex gap-3 items-center justify-between text-sm lg:text-base font-semibold hover:cursor-pointer"
            onClick={editModal.open}
          >
            Notes
            <PencilLineIcon className="icon size-5 xl:size-6" />
          </h2>
          <div className="w-full text-sm xl:text-base whitespace-pre-wrap">
            {notes}
          </div>
        </div>
      )}

      {editModal.isOpen && (
        <Modal
          mode="normal"
          open={editModal.isOpen}
          className="sm:min-w-[576px]"
          onClose={editModal.close}
        >
          <Modal.Header
            title="Edit notes"
            dialogTitleProps={{
              className: 'pl-0 justify-start',
            }}
            closeButtonProps={{ onClick: editModal.close }}
          />
          <Modal.Content>
            <Textarea
              rows={12}
              value={localCycle.metadata?.notes}
              aria-label="Growth cycle notes"
              placeholder="Type any notes here"
              className="[field-sizing:content]"
              onChange={handleChange}
            />
          </Modal.Content>
          <Modal.Footer>
            <Button variant="secondary" onClick={editModal.close}>
              Cancel
            </Button>
            <Button disabled={loading} onClick={handleSave}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
});
