import { useGetImagesByZoneIdAndSensor } from 'components/image_feed/hooks/useGetImagesByZoneIdAndSensor';
import { useImageFeedURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { useMemo } from 'react';
import { EImageTypes } from 'shared/interfaces/image';
import {
  IMeasurementRunFrame,
  TMeasurementRun,
} from 'shared/interfaces/measurementRun';
import { IGridView } from '../types';
import { useCurrentImageFeedMeasurements } from './useCurrentImageFeedMeasurements';

export interface IUseGetImageFeedDataReturn {
  /** Measurement capture frames. */
  captures: IMeasurementRunFrame[];
  /** Shows if the NDVI images are available or not. */
  isNDVIAvailable: boolean;
  /** Shows if the data is currently being loaded. */
  loading: boolean;
  /** Shows if the data has no measurements. */
  hasNoMeasurements: boolean;
  /** Shows if the RGB mode is enabled. */
  hasNoRGBImages: boolean;
  /**  measurement run information of current system. */
  measurementRun: Nullable<TMeasurementRun>;
  /** The array of measurement run. */
  measurementRuns: TMeasurementRun[];
  /** Measurement ndvi capture frames. */
  ndviCaptures: IMeasurementRunFrame[];
  /** The ndvi photo urls in grid */
  ndviMeasurementsGridView: IGridView;
  /** Measurement rgb capture frames. */
  rgbCaptures: IMeasurementRunFrame[];
  /** The rgb photo urls in grid */
  rgbMeasurementsGridView: IGridView;
}

/**
 * Get the  Measurements Data By SystemId
 *
 * @param {IUseGetImageFeedDataProps} props - The photo feed query
 * @returns {IUseGetImageFeedDataReturn} - The photo feed data
 */
export const useGetImageFeedDataByZoneId = (): IUseGetImageFeedDataReturn => {
  const { measurementRun, measurementRuns, measurementRunsLoading } =
    useCurrentImageFeedMeasurements();
  const { imageType } = useImageFeedURL();

  const {
    captures: rgbCaptures,
    gridViewLoading: rgbGridViewLoading,
    gridViewData: rgbGridViewData,
    measurementIds: rgbMeasurementIds,
    measurementsById: rgbMeasurementsById,
    measurementsGridView: rgbMeasurementsGridView,
    measurementsLoading: rgbMeasurementsLoading,
    measurementsCalled: rgbMeasurementsCalled,
  } = useGetImagesByZoneIdAndSensor({
    measurementRun,
    measurementRuns,
    imageType: EImageTypes.RGB,
  });

  const {
    captures: ndviCaptures,
    gridViewLoading: ndviGridViewLoading,
    measurementIds: ndviMeasurementIds,
    measurementsById: ndviMeasurementsById,
    measurementsGridView: ndviMeasurementsGridView,
    measurementsLoading: ndviMeasurementsLoading,
    measurementsCalled: ndviMeasurementsCalled,
  } = useGetImagesByZoneIdAndSensor({
    measurementRun,
    measurementRuns,
    imageType: EImageTypes.NDVI,
  });

  const captures = useMemo(() => {
    return imageType === EImageTypes.RGB ? rgbCaptures : ndviCaptures;
  }, [rgbCaptures, ndviCaptures, imageType]);

  return {
    loading:
      measurementRunsLoading ||
      rgbMeasurementsLoading ||
      ndviMeasurementsLoading ||
      rgbGridViewLoading ||
      ndviGridViewLoading,
    hasNoMeasurements:
      !Object.keys(ndviMeasurementsById).length &&
      !Object.keys(rgbMeasurementsById).length &&
      (ndviMeasurementsCalled || rgbMeasurementsCalled),
    hasNoRGBImages: !!rgbGridViewData && !rgbMeasurementIds?.length,
    captures,
    isNDVIAvailable: ndviMeasurementIds.length > 0,
    measurementRuns,
    measurementRun,
    ndviCaptures,
    ndviMeasurementsGridView,
    rgbCaptures,
    rgbMeasurementsGridView,
  };
};
