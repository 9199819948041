import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { DayPicker, type DayPickerProps } from 'react-day-picker';
import { cn } from 'shared/utils/cn';
import { formatShortWeekday } from 'shared/utils/date';

const classNames: DayPickerProps['classNames'] = {
  root: cn('flex flex-col items-center', 'rounded text-sm text-gray-900'),
  months: 'w-full p-4',
  month: 'flex flex-col gap-1',
  button: '',
  caption: 'flex items-center justify-between',
  caption_label: 'text-lg font-semibold',
  button_reset: '',
  caption_start: '',
  caption_end: '',
  nav: 'flex gap-2',
  nav_button: cn(
    'inline-flex h-9 min-w-9 xl:h-10 xl:min-w-10 items-center justify-center rounded-full px-1 text-gray-800',
    'hover:bg-sand-300 active:bg-sand-400 disabled:bg-sand-300 disabled:text-sand-600'
  ),
  nav_button_next: '',
  nav_button_previous: '',
  nav_icon: '',
  table: 'flex flex-col gap-1',
  head: '',
  head_row: 'flex gap-2 justify-between py-1',
  head_cell:
    'flex items-center justify-center uppercase aspect-square w-full min-w-8 p-0 font-semibold',
  tbody: 'flex flex-col gap-2',
  row: 'flex gap-2 justify-between',
  cell: 'aspect-square w-full min-w-8 p-0',
  tfoot: 'flex items-center justify-center pt-1 font-semibold',
  day: cn(
    'flex items-center justify-center rounded-xs',
    'h-full w-full',
    'bg-white hover:bg-gray-200 active:bg-gray-300'
  ),
  day_outside: '',
  day_today: 'border border-gray-900',
  day_disabled:
    'bg-transparent disabled:text-gray-900 disabled:bg-transparent hover:disabled:bg-transparent active:disabled:bg-transparent',
  day_selected: '!bg-orange-100 hover:bg-orange-300 active:bg-orange-500',
  day_hidden: '',
  day_range_end: '!bg-orange-300',
  day_range_middle: '!bg-orange-100',
  day_range_start: '!bg-orange-300',
};
export type CalendarProps = DayPickerProps;

/** */
export const Calendar = (props: CalendarProps) => {
  return (
    <DayPicker
      showOutsideDays
      fixedWeeks
      formatters={{
        formatWeekdayName: formatShortWeekday,
      }}
      {...props}
      classNames={{ ...classNames, ...props.classNames }}
      components={{
        IconLeft: () => (
          <ChevronLeftIcon className="stroke-[1.5px] size-4 xl:size-5" />
        ),
        IconRight: () => (
          <ChevronRightIcon className="stroke-[1.5px] size-4 xl:size-5" />
        ),
      }}
    />
  );
};
