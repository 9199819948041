import { startOfDay, startOfWeek, subWeeks } from 'date-fns';
import {
  TAnnotationType,
  TDiscussionCategory,
  TDiscussionStatus,
  TDiscussionType,
  eventCategories,
  heatMapAggregateAnnotationCategories,
  heatMapAnnotationCategories,
  singleImageAnnotationCategories,
  timeRangeAnnotationCategories,
} from 'shared/interfaces/discussion';
import { capitalizeWord } from 'shared/utils/string';

export function formatCategory(category: string) {
  return category && category.split('-').map(capitalizeWord).join(' ');
}

export function getDiscussionLabel(
  discussionType: TDiscussionType,
  discussionCategory: TDiscussionCategory,
  discussionStatus: TDiscussionStatus,
  title?: string
): string {
  if (discussionType === 'comment') {
    return formatCategory(discussionCategory);
  }

  if (discussionStatus === 'draft') {
    return `${title} [DRAFT]`;
  }

  return `${title}`;
}

export function getDefaultCategoryFromAnnotationType(
  annotationType: TAnnotationType
): TDiscussionCategory {
  const categories = getCategoriesFromAnnotationType(annotationType);
  return categories[0]!;
}

export function getCategoriesFromAnnotationType(
  annotationType: TAnnotationType
): readonly TDiscussionCategory[] {
  switch (annotationType) {
    case 'event':
      return eventCategories;
    case 'single_image_annotation':
      return singleImageAnnotationCategories;
    case 'time_range_annotation':
      return timeRangeAnnotationCategories;
    case 'heatmap_annotation':
      return heatMapAnnotationCategories;
    case 'heatmap_aggregate_annotation':
      return heatMapAggregateAnnotationCategories;
    default:
      throw new Error(`Unknown annotation type: ${annotationType}`);
  }
}

export function getDefaultFeedStartTime(currentTime: Date, lookupWeeks = 1) {
  return startOfDay(
    subWeeks(startOfWeek(currentTime, { weekStartsOn: 1 }), lookupWeeks)
  );
}
