import { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const ErrorOccuredImg = ({ className, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 499 499"
    aria-hidden="true"
    className={cn(className)}
    {...props}
  >
    <path
      d="M150.692 131.675C150.692 131.675 204.199 172.28 285.542 96.3568C357.783 28.9317 416.77 135.314 417.183 186.258C417.718 252.251 344.941 305.055 380.259 348.4C415.578 391.745 310.219 463.323 253.435 401.377C182.799 324.319 163.663 386.929 123.4 386.929C94.5037 386.929 35.1758 315.128 75.2393 261.71C108.952 216.76 90.5658 201.842 81.6619 186.258C68.8179 163.783 99.32 102.779 150.692 131.675Z"
      fill="#FFB94C"
      fillOpacity="0.3"
    />
    <path
      d="M365.503 221.404C368.379 222.473 371.523 222.544 374.513 221.858C387.042 218.982 430.121 211.368 422.635 244.75C421.005 251.374 395.774 288.466 431.706 302.006C434.338 303.01 437.123 303.559 439.94 303.627C451.003 303.855 472.926 301.243 482.101 291.903C493.185 280.621 481.076 285.971 481.076 285.971C481.076 285.971 438.939 304.359 424.055 284.851C422.398 282.68 421.344 279.527 421.042 276.813C420.518 272.108 422.607 265.89 425.352 260.476C429.067 253.15 446.902 220.193 408.18 210.593C399.26 208.468 386.304 206.56 369.598 213.594C359.854 217.696 362.012 220.107 365.503 221.404Z"
      fill="#F48E42"
    />
    <path
      d="M374.126 209.101L362.91 213.299C359.039 214.748 357.075 219.061 358.524 222.932L359.08 224.418C360.529 228.289 364.842 230.253 368.714 228.804L379.929 224.607C383.801 223.158 385.765 218.845 384.316 214.973L383.76 213.488C382.311 209.616 377.998 207.652 374.126 209.101Z"
      fill="#DD382F"
    />
    <path
      d="M305.282 221.247L268.294 235.09C266.358 235.815 265.376 237.971 266.101 239.907L266.247 240.296C266.971 242.232 269.128 243.214 271.063 242.489L308.052 228.646C309.987 227.922 310.969 225.765 310.245 223.829L310.099 223.44C309.375 221.504 307.218 220.522 305.282 221.247Z"
      fill="#E86339"
    />
    <path
      d="M315.607 248.829L278.618 262.673C276.683 263.397 275.701 265.554 276.425 267.489L276.571 267.879C277.295 269.815 279.452 270.796 281.388 270.072L318.376 256.229C320.312 255.504 321.294 253.348 320.569 251.412L320.423 251.022C319.699 249.087 317.542 248.105 315.607 248.829Z"
      fill="#E86339"
    />
    <path
      d="M349.756 202.424L319.778 213.643C315.906 215.092 313.942 219.406 315.391 223.277L325.626 250.624C327.075 254.496 331.388 256.46 335.26 255.011L365.238 243.791C369.11 242.342 371.074 238.029 369.625 234.157L359.39 206.81C357.941 202.939 353.627 200.975 349.756 202.424Z"
      fill="#F48E42"
    />
    <path
      d="M322.875 209.598L292.897 220.818C289.025 222.267 287.061 226.58 288.51 230.452L300.721 263.078C302.17 266.949 306.483 268.913 310.355 267.464L340.333 256.245C344.205 254.796 346.168 250.482 344.719 246.611L332.509 213.985C331.06 210.113 326.747 208.149 322.875 209.598Z"
      fill="#FFB94C"
    />
    <path
      d="M327.567 202.977L327.332 203.065C324.751 204.031 323.441 206.906 324.407 209.487L341.513 255.193C342.479 257.774 345.355 259.084 347.936 258.118L348.171 258.03C350.752 257.064 352.061 254.188 351.095 251.607L333.989 205.901C333.023 203.32 330.148 202.011 327.567 202.977Z"
      fill="#DD382F"
    />
    <path
      d="M21.8884 232.199C21.8884 232.199 82.0948 215.28 73.3538 254.261C71.8358 260.429 49.9849 292.187 76.0127 307.436C81.6015 310.71 88.0511 312.16 94.5279 312.126C106.14 312.066 124.55 309.831 132.82 301.413C143.904 290.13 131.795 295.481 131.795 295.481C131.795 295.481 97.2175 309.704 79.4286 298.366C73.3546 294.495 70.6013 286.999 72.2508 279.988C73.0938 276.505 74.3776 273.144 76.0711 269.986C79.9399 262.74 97.6209 229.703 58.8985 220.102C49.9784 217.978 37.0226 216.069 20.3173 223.103C3.61207 230.136 21.8884 232.199 21.8884 232.199Z"
      fill="#F48E42"
    />
    <path
      d="M140.915 286.758L129.7 290.955C125.828 292.404 123.864 296.718 125.313 300.589L125.869 302.075C127.318 305.946 131.631 307.91 135.503 306.461L146.718 302.264C150.59 300.815 152.554 296.501 151.105 292.63L150.549 291.144C149.1 287.273 144.787 285.309 140.915 286.758Z"
      fill="#DD382F"
    />
    <path
      d="M159.864 268.305L143.243 274.526C139.371 275.975 137.407 280.288 138.856 284.16L146.871 305.576C148.32 309.447 152.633 311.411 156.505 309.962L173.127 303.741C176.998 302.292 178.962 297.979 177.513 294.107L169.498 272.692C168.049 268.82 163.736 266.856 159.864 268.305Z"
      fill="#F48E42"
    />
    <path
      d="M187.536 251.817L162.748 261.095C158.877 262.544 156.913 266.857 158.362 270.728L170.573 303.355C172.022 307.226 176.335 309.19 180.206 307.741L204.994 298.464C208.866 297.015 210.83 292.702 209.381 288.83L197.17 256.204C195.721 252.332 191.408 250.368 187.536 251.817Z"
      fill="#FFB94C"
    />
    <path
      d="M192.787 245.06L192.552 245.148C189.971 246.114 188.662 248.99 189.628 251.571L206.734 297.277C207.7 299.858 210.575 301.167 213.157 300.201L213.392 300.113C215.973 299.147 217.282 296.272 216.316 293.691L199.21 247.985C198.244 245.404 195.368 244.094 192.787 245.06Z"
      fill="#DD382F"
    />
    <path
      d="M256.084 215.942C255.191 215.843 254.371 215.401 253.798 214.71C253.224 214.019 252.941 213.133 253.008 212.237L254.857 178.455C254.968 176.437 256.672 174.904 258.605 175.082L261.3 175.331C263.234 175.51 264.618 177.327 264.348 179.331L259.803 212.864C259.699 213.757 259.254 214.576 258.561 215.149C257.868 215.722 256.981 216.007 256.084 215.942Z"
      fill="#F48E42"
    />
    <path
      d="M240.114 218.855C239.836 218.937 239.545 218.963 239.258 218.932C238.97 218.901 238.691 218.814 238.437 218.675C238.183 218.536 237.959 218.349 237.777 218.124C237.596 217.898 237.46 217.64 237.379 217.362L230.774 196.347C230.582 195.75 230.635 195.1 230.921 194.542C231.207 193.983 231.703 193.561 232.3 193.367L234.01 192.851C234.304 192.766 234.612 192.742 234.915 192.781C235.218 192.821 235.51 192.922 235.771 193.08C236.033 193.238 236.259 193.448 236.436 193.698C236.612 193.947 236.735 194.231 236.796 194.53L241.691 216.061C241.828 216.64 241.741 217.249 241.449 217.766C241.157 218.284 240.68 218.673 240.114 218.855Z"
      fill="#F48E42"
    />
    <path
      d="M231.201 230.973C230.018 232.44 227.638 232.422 225.806 230.934L193.279 204.506C191.336 202.928 190.762 200.315 192.018 198.76L193.768 196.593C195.023 195.038 197.602 195.165 199.44 196.873L230.218 225.468C231.951 227.079 232.385 229.507 231.201 230.973Z"
      fill="#F48E42"
    />
    <path
      d="M246.237 299.233C247.075 299.556 247.755 300.193 248.133 301.007C248.511 301.822 248.558 302.751 248.265 303.6L237.849 335.789C237.227 337.712 235.188 338.758 233.364 338.093L230.821 337.164C228.997 336.497 228.121 334.386 228.896 332.518L241.854 301.259C242.183 300.421 242.822 299.743 243.639 299.366C244.456 298.988 245.386 298.941 246.237 299.233Z"
      fill="#F48E42"
    />
    <path
      d="M262.422 300.495C262.711 300.487 262.999 300.536 263.27 300.639C263.54 300.743 263.787 300.898 263.998 301.097C264.208 301.296 264.377 301.535 264.495 301.799C264.613 302.063 264.678 302.348 264.686 302.637L265.704 324.642C265.737 325.269 265.521 325.883 265.101 326.35C264.682 326.817 264.094 327.099 263.468 327.133L261.682 327.196C261.377 327.203 261.073 327.148 260.79 327.032C260.507 326.917 260.251 326.744 260.038 326.524C259.825 326.305 259.66 326.044 259.553 325.757C259.447 325.471 259.4 325.166 259.418 324.86L260.184 302.794C260.2 302.2 260.439 301.633 260.854 301.207C261.269 300.781 261.828 300.527 262.422 300.495Z"
      fill="#F48E42"
    />
    <path
      d="M274.134 291.055C275.653 289.939 277.949 290.564 279.34 292.471L304.04 326.328C305.515 328.351 305.402 331.023 303.79 332.206L301.545 333.854C299.934 335.038 297.473 334.256 296.132 332.135L273.677 296.627C272.414 294.629 272.615 292.17 274.134 291.055Z"
      fill="#F48E42"
    />
  </svg>
);

export { ErrorOccuredImg };
