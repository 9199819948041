import {
  areIntervalsOverlapping,
  differenceInWeeks,
  endOfDay,
  format,
  isAfter,
  max,
  min,
  startOfDay,
  subDays,
  subWeeks,
} from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import isNil from 'lodash.isnil';
import memoizee from 'memoizee';
import { TLabeledTimeRange, TTimeRange } from 'shared/interfaces/general';
import {
  LightCycle,
  PREDEFINED_TIME_RANGES,
  Setting,
  TGrowthCycle,
  TLightInfo,
  TPredefinedTimeRange,
} from 'shared/interfaces/growthCycle';
import {
  ELightCycleType,
  EMeasurementStatisticsTypes,
  EMeasurementStatisticsTypesV2,
  EMontioringParameterVersion,
  IDayRange,
  MeasurementTypeConfig,
  MeasurementUnit,
  TMontioringParameterType,
} from 'shared/interfaces/measurement';
import { TZone } from 'shared/interfaces/zone';
import { convertFahrenheitToCelsius } from 'shared/utils/converters';
import { v4 as uuidv4 } from 'uuid';
import { formatDateInMMMDD, isDateValid } from './date';
import { getClosestLightInfo, getGrowDay, getLightCycle } from './getters';

const { AIR_TEMPERATURE, INFRARED_MATRIX, RELATIVE_HUMIDITY } =
  EMeasurementStatisticsTypesV2;

const DATE_RANGE_FORMAT = 'PP';

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const userfromZonedTime = (date: Date): Date =>
  fromZonedTime(date, userTimeZone);

export const getMatchingGrowthCycleOrFallbackToLatest = (
  growthCycleDataSorted: TGrowthCycle[],
  rangeStartTime: Maybe<number>,
  rangeEndTime: Maybe<number>,
  currentTime: Date
): Nullable<TGrowthCycle> => {
  // Future growth cycles are not considered
  const validCycles = growthCycleDataSorted.filter((cycle) => {
    const cycleStartTime = startOfDay(cycle.start_time);
    return cycleStartTime <= currentTime;
  });

  // Lastest growth cycle
  const latestGrowthCycle = validCycles.at(-1) || null;

  if (!rangeStartTime || !rangeEndTime) {
    return latestGrowthCycle;
  }

  const matchingGrowthCycle =
    validCycles.find((cycle) => {
      const cycleStartTime = startOfDay(cycle.start_time);
      const cycleEndTime = endOfDay(cycle.end_time);

      return (
        cycleStartTime <= new Date(rangeStartTime) &&
        cycleEndTime >= new Date(rangeEndTime)
      );
    }) || null;

  return matchingGrowthCycle ?? latestGrowthCycle;
};

/**
 * Get a day label
 *
 * @param {Date} date - the date
 * @returns {string} the date label
 */
export const getDayLabel = (date: Date | number): string =>
  format(date, DATE_RANGE_FORMAT);

/**
 * Get the date range label
 *
 * @param {Date} startDate - the start date
 * @param {Date} endDate - the end date
 * @returns {string} the date range label
 */
export const getDateRangeLabel = (
  startDate: Date | number,
  endDate: Date | number
): string => `${getDayLabel(startDate)} - ${getDayLabel(endDate)}`;

type TGetGrowthCycleDefaultRangeInput = {
  currentTime: Date;
  predefinedRanges: TLabeledTimeRange[];
  rangeStartTime?: number;
  rangeEndTime?: number;
  selectedCycle?: Maybe<TGrowthCycle>;
};
export const getFullDaysRange = ({
  currentTime,
  predefinedRanges,
  rangeStartTime,
  rangeEndTime,
  selectedCycle,
}: TGetGrowthCycleDefaultRangeInput): Nullable<TLabeledTimeRange> => {
  const endTime = min([rangeEndTime ?? currentTime, currentTime]);
  if (rangeStartTime && endTime && !selectedCycle) {
    const start = startOfDay(new Date(rangeStartTime));
    const end = endOfDay(new Date(endTime));
    const label = getGrowthCycleLabel(start, end, predefinedRanges);

    return {
      start,
      end,
      label,
    };
  }

  if (isNil(selectedCycle) || isNil(currentTime) || !isDateValid(currentTime)) {
    return null;
  }

  const { start_time: selectedCycleStartTime, end_time: selectedCycleEndTime } =
    selectedCycle;

  let start = min([
    selectedCycleEndTime,
    max([
      selectedCycleStartTime,
      new Date(rangeStartTime ?? selectedCycleStartTime),
    ]),
  ]);

  let end = max([start, min([selectedCycleEndTime, new Date(endTime)])]);
  start = startOfDay(start);
  end = endOfDay(end);

  const label = getGrowthCycleLabel(start, end, predefinedRanges);

  return {
    start,
    end,
    label,
  };
};

type TGetGrowthCycleMenuOptionsInput = {
  currentCycle: Nullable<TGrowthCycle>;
  selectedCycle: Nullable<TGrowthCycle>;
  currentTime: Date;
};

export const getPredefinedTimeRanges = ({
  currentCycle,
  selectedCycle,
  currentTime,
}: TGetGrowthCycleMenuOptionsInput): TPredefinedTimeRange[] => {
  if (isNil(selectedCycle)) {
    return [];
  }

  if (selectedCycle.start_time > currentTime.valueOf()) return [];

  const isCurrentCycleSelected = selectedCycle?.id === currentCycle?.id;
  if (!isCurrentCycleSelected) {
    const start = startOfDay(selectedCycle.start_time);
    const end = endOfDay(selectedCycle.end_time);
    return [
      {
        range: 'CUSTOM',
        label: getDateRangeLabel(start, end),
        start,
        end,
      },
    ];
  }

  const endDateTime = min([selectedCycle.end_time, currentTime]);
  const fullCurrentCycle: TPredefinedTimeRange = {
    range: 'FULL_CYCLE',
    label: PREDEFINED_TIME_RANGES.FULL_CYCLE,
    start: new Date(currentCycle.start_time),
    end: endDateTime,
  };

  const ongoingCycleOptions: TPredefinedTimeRange[] = [
    {
      range: 'THREE_DAYS',
      label: PREDEFINED_TIME_RANGES.THREE_DAYS,
      start: subDays(endDateTime, 3),
      end: endDateTime,
    },
    {
      range: 'ONE_WEEK',
      label: PREDEFINED_TIME_RANGES.ONE_WEEK,
      start: subWeeks(endDateTime, 1),
      end: endDateTime,
    },
    {
      range: 'TWO_WEEKS',
      label: PREDEFINED_TIME_RANGES.TWO_WEEKS,
      start: subWeeks(endDateTime, 2),
      end: endDateTime,
    },
  ];

  return ongoingCycleOptions
    .filter(({ start: optionStart }) => {
      return isAfter(optionStart, new Date(currentCycle.start_time));
    })
    .concat(fullCurrentCycle)
    .map(({ range, label, start, end }) => ({
      range,
      label,
      start: startOfDay(start),
      end: endOfDay(end),
    }));
};

export const getDisabledDays = ({ start, end }: Partial<TTimeRange>) => {
  return [
    { before: start ? start : new Date(0) },
    { after: end ? end : new Date() },
  ];
};

/**
 * Get the current label based on the range and the current menu options.
 *
 * @param {Date} startDate the range start time
 * @param {Date} endDate the range end date
 * @param {TLabeledTimeRange[]} menuOptions the available menu options
 * @returns {string}  the label value
 */
export const getGrowthCycleLabel = (
  startDate: Date,
  endDate: Date,
  menuOptions: TLabeledTimeRange[]
) => {
  const menuOption = menuOptions.find((option) => {
    return (
      option.start.valueOf() === startDate.valueOf() &&
      option.end.valueOf() === endDate.valueOf()
    );
  });

  if (menuOption) {
    return menuOption.label;
  }

  return getDateRangeLabel(startDate, endDate);
};

/** Get the closest lower day */
const closestDay = (days: number[], targetDay: number) =>
  days.reduce((a, b) => {
    const aDiff = Math.abs(a - targetDay);
    const bDiff = Math.abs(b - targetDay);

    if (aDiff === bDiff) {
      // Choose largest vs smallest (> vs <)
      return a < b ? a : b;
    } else {
      return bDiff < aDiff ? b : a;
    }
  });

/**
 * Calculate saturation vapor pressure using the Tetens equation for temperatures above 0 °C.
 *
 * (Source: https://en.wikipedia.org/wiki/Tetens_equation)
 *
 * The coefficients in the Tetens equation represent empirical constants derived from fitting
 * the equation to observed data. Here's what each coefficient represents:
 *   -> 0.61078: This coefficient represents the reference saturation vapor pressure at a certain temperature.
 *   -> 17.269: This coefficient is derived from the Clausius–Clapeyron relation and represents the sensitivity of saturation vapor pressure to temperature changes.
 *   -> 237.3: This coefficient represents the reference temperature at which the saturation vapor pressure is defined.
 *
 * These coefficients are chosen to best fit the empirical data and provide a reasonably accurate representation
 * of the saturation vapor pressure over liquid water for temperatures above 0 °C.
 * */
function saturationVaporPressure(temperatureCelsius: number) {
  return (
    0.61078 *
    Math.exp((17.269 * temperatureCelsius) / (temperatureCelsius + 237.3))
  );
}

/** Calculate actual vapor pressure */
function actualVaporPressure(temperatureCelsius: number, humidity: number) {
  return (humidity / 100) * saturationVaporPressure(temperatureCelsius);
}

/** Calculate Air Vapour Pressure Deficit (VPD) */
function calculateAirVPD(
  convertTemperatureToCelsius: boolean,
  temperature: Nullable<number>,
  humidityPercentage: Nullable<number>
) {
  const tempCelsius = convertTemperatureToCelsius
    ? convertFahrenheitToCelsius(temperature ?? 0)
    : (temperature ?? 0);
  const airSVP = saturationVaporPressure(tempCelsius);

  return Number(
    (
      airSVP - actualVaporPressure(tempCelsius, humidityPercentage ?? 0)
    ).toFixed(2)
  );
}

/** Calculate Leaf Vapour Pressure Deficit (VPD) */
function calculateLeafVPD(
  convertTemperatureToCelsius: boolean,
  airTemperature: Nullable<number>,
  leafTemperature: Nullable<number>,
  humidityPercentage: Nullable<number>
) {
  const airTempCelsius = convertTemperatureToCelsius
    ? convertFahrenheitToCelsius(airTemperature ?? 0)
    : (airTemperature ?? 0);
  const leafTempCelsius = convertTemperatureToCelsius
    ? convertFahrenheitToCelsius(leafTemperature ?? 0)
    : (leafTemperature ?? 0);
  const leafSVP = saturationVaporPressure(leafTempCelsius);

  return Number(
    (
      leafSVP - actualVaporPressure(airTempCelsius, humidityPercentage ?? 0)
    ).toFixed(2)
  );
}

/**
 * Compute both Air and Leaf VPD settings from
 * Air Temperature, Leaf Temperature and Relative Humidity
 * */
function deriveVPDSettings(
  presetTypes: MeasurementTypeConfig[],
  settings: Setting[]
): Setting[] {
  const convertTemperatureToCelsius =
    presetTypes.find(
      ({ statisticsKeyV2 }) => statisticsKeyV2 === AIR_TEMPERATURE
    )!.unit === MeasurementUnit.FahrenheitDegree;

  const airTempSettings = settings.filter(
    ({ type }) => type === AIR_TEMPERATURE
  );
  const leafTempSettings = settings.filter(
    ({ type }) => type === INFRARED_MATRIX
  );
  const humiditySettings = settings.filter(
    ({ type }) => type === RELATIVE_HUMIDITY
  );
  const airTempDays = airTempSettings.map(({ day }) => day) as number[];
  const leafTempDays = leafTempSettings.map(({ day }) => day) as number[];
  const humidityDays = humiditySettings.map(({ day }) => day) as number[];

  const uniqueDays = Array.from(
    new Set([...airTempDays, ...leafTempDays, ...humidityDays])
  ).sort((a, b) => a - b);

  const vpdSettings: Setting[] = [];
  for (const day of uniqueDays) {
    const airTemp = airTempSettings.find(
      (setting) => setting.day === closestDay(airTempDays, day)
    );
    const leafTemp = leafTempSettings.find(
      (setting) => setting.day === closestDay(leafTempDays, day)
    );
    const humidity = humiditySettings.find(
      (setting) => setting.day === closestDay(humidityDays, day)
    );

    if (airTemp && humidity) {
      /**
       * AIR VPD
       */

      const light = {
        setPoint: calculateAirVPD(
          convertTemperatureToCelsius,
          airTemp.light.setPoint ?? 0,
          humidity.light.setPoint ?? 0
        ),
        idealLower: calculateAirVPD(
          convertTemperatureToCelsius,
          airTemp.light.idealLower ?? 0,
          humidity.light.idealUpper ?? 0
        ),
        idealUpper: calculateAirVPD(
          convertTemperatureToCelsius,
          airTemp.light.idealUpper ?? 0,
          humidity.light.idealLower ?? 0
        ),
        criticalLower: calculateAirVPD(
          convertTemperatureToCelsius,
          airTemp.light.criticalLower ?? 0,
          humidity.light.criticalUpper ?? 0
        ),
        criticalUpper: calculateAirVPD(
          convertTemperatureToCelsius,
          airTemp.light.criticalUpper ?? 0,
          humidity.light.criticalLower ?? 0
        ),
      };

      const dark = {
        setPoint: calculateAirVPD(
          convertTemperatureToCelsius,
          airTemp.dark.setPoint ?? 0,
          humidity.dark.setPoint ?? 0
        ),
        idealLower: calculateAirVPD(
          convertTemperatureToCelsius,
          airTemp.dark.idealLower ?? 0,
          humidity.dark.idealUpper ?? 0
        ),
        idealUpper: calculateAirVPD(
          convertTemperatureToCelsius,
          airTemp.dark.idealUpper ?? 0,
          humidity.dark.idealLower ?? 0
        ),
        criticalLower: calculateAirVPD(
          convertTemperatureToCelsius,
          airTemp.dark.criticalLower ?? 0,
          humidity.dark.criticalUpper ?? 0
        ),
        criticalUpper: calculateAirVPD(
          convertTemperatureToCelsius,
          airTemp.dark.criticalUpper ?? 0,
          humidity.dark.criticalLower ?? 0
        ),
      };

      vpdSettings.push({
        id: uuidv4(),
        type: 'AIR_VPD',
        day,
        light: {
          ...light,
          ideal: Number(
            Math.max(
              light.idealUpper - light.setPoint,
              light.setPoint - light.idealLower
            ).toFixed(2)
          ),
          critical: Number(
            Math.max(
              light.criticalUpper - light.setPoint,
              light.setPoint - light.criticalLower
            ).toFixed(2)
          ),
        },
        dark: {
          ...dark,
          ideal: Number(
            Math.max(
              dark.idealUpper - dark.setPoint,
              dark.setPoint - dark.idealLower
            ).toFixed(2)
          ),
          critical: Number(
            Math.max(
              dark.criticalUpper - dark.setPoint,
              dark.setPoint - dark.criticalLower
            ).toFixed(2)
          ),
        },
      });
    }

    if (airTemp && leafTemp && humidity) {
      /**
       * Leaf VPD
       */
      const light = {
        setPoint: calculateLeafVPD(
          convertTemperatureToCelsius,
          airTemp.light.setPoint ?? 0,
          leafTemp.light.setPoint ?? 0,
          humidity.light.setPoint ?? 0
        ),
        idealLower: calculateLeafVPD(
          convertTemperatureToCelsius,
          airTemp.light.idealLower ?? 0,
          leafTemp.light.idealLower ?? 0,
          humidity.light.idealUpper ?? 0
        ),
        idealUpper: calculateLeafVPD(
          convertTemperatureToCelsius,
          airTemp.light.idealUpper ?? 0,
          leafTemp.light.idealUpper ?? 0,
          humidity.light.idealLower ?? 0
        ),
        criticalLower: calculateLeafVPD(
          convertTemperatureToCelsius,
          airTemp.light.criticalLower ?? 0,
          leafTemp.light.criticalLower ?? 0,
          humidity.light.criticalUpper ?? 0
        ),
        criticalUpper: calculateLeafVPD(
          convertTemperatureToCelsius,
          airTemp.light.criticalUpper ?? 0,
          leafTemp.light.criticalUpper ?? 0,
          humidity.light.criticalLower ?? 0
        ),
      };

      const dark = {
        setPoint: calculateLeafVPD(
          convertTemperatureToCelsius,
          airTemp.dark.setPoint ?? 0,
          leafTemp.dark.setPoint ?? 0,
          humidity.dark.setPoint ?? 0
        ),
        idealLower: calculateLeafVPD(
          convertTemperatureToCelsius,
          airTemp.dark.idealUpper ?? 0,
          leafTemp.dark.idealLower ?? 0,
          humidity.dark.idealUpper ?? 0
        ),
        idealUpper: calculateLeafVPD(
          convertTemperatureToCelsius,
          airTemp.dark.idealLower ?? 0,
          leafTemp.dark.idealUpper ?? 0,
          humidity.dark.idealLower ?? 0
        ),
        criticalLower: calculateLeafVPD(
          convertTemperatureToCelsius,
          airTemp.dark.criticalUpper ?? 0,
          leafTemp.dark.criticalLower ?? 0,
          humidity.dark.criticalUpper ?? 0
        ),
        criticalUpper: calculateLeafVPD(
          convertTemperatureToCelsius,
          airTemp.dark.criticalLower ?? 0,
          leafTemp.dark.criticalUpper ?? 0,
          humidity.dark.criticalLower ?? 0
        ),
      };

      vpdSettings.push({
        id: uuidv4(),
        type: 'LEAF_VPD',
        day,
        light: {
          ...light,
          ideal: Number(
            Math.max(
              light.idealUpper - light.setPoint,
              light.setPoint - light.idealLower
            ).toFixed(2)
          ),
          critical: Number(
            Math.max(
              light.criticalUpper - light.setPoint,
              light.setPoint - light.criticalLower
            ).toFixed(2)
          ),
        },
        dark: {
          ...dark,
          ideal: Number(
            Math.max(
              dark.idealUpper - dark.setPoint,
              dark.setPoint - dark.idealLower
            ).toFixed(2)
          ),
          critical: Number(
            Math.max(
              dark.criticalUpper - dark.setPoint,
              dark.setPoint - dark.criticalLower
            ).toFixed(2)
          ),
        },
      });
    }
  }

  return vpdSettings;
}

/** Generates an empty setting */
export function getEmptySetting(type: Setting['type']): Setting {
  return {
    id: uuidv4(),
    type,
    day: 0,
    light: {
      setPoint: null,
      ideal: null,
      idealLower: null,
      idealUpper: null,
      critical: null,
      criticalLower: null,
      criticalUpper: null,
    },
    dark: {
      setPoint: null,
      ideal: null,
      idealLower: null,
      idealUpper: null,
      critical: null,
      criticalLower: null,
      criticalUpper: null,
    },
  };
}

/**
 * Generates any missing empty settings.
 * When type is provided it generates only for that particular type.
 */
function getEmptySettings(
  presetTypes: MeasurementTypeConfig[],
  type?: MeasurementTypeConfig
) {
  if (type) {
    return [getEmptySetting(type.statisticsKeyV2)];
  }

  const settings = [];
  for (const { statisticsKeyV2 } of presetTypes) {
    settings.push(getEmptySetting(statisticsKeyV2));
  }

  return settings;
}

export const getEmptyLightCycle = (): LightCycle => ({
  id: uuidv4(),
  start: 0,
  onAt: '08:00:00',
  duration: 720,
});

/**
 * Checks and adds empty settings where missing.
 * Makes sures every type has at least one empty setting.
 */
export function sanitize(
  presetTypes: MeasurementTypeConfig[],
  settings?: Setting[]
) {
  if (!settings || settings.length === 0) {
    return getEmptySettings(presetTypes);
  }

  // purge entries that are supposed to be derived
  const editableSettings = settings
    .filter(({ type }) =>
      presetTypes.some(
        ({ statisticsKeyV2, preset }) =>
          statisticsKeyV2 === type && !preset?.readOnly
      )
    )
    .map(({ light, dark, ...setting }) => {
      return {
        ...setting,
        light: {
          ...light,
          idealLower: (light.setPoint ?? 0) - (light.ideal ?? 0),
          idealUpper: (light.setPoint ?? 0) + (light.ideal ?? 0),
          criticalLower: (light.setPoint ?? 0) - (light.critical ?? 0),
          criticalUpper: (light.setPoint ?? 0) + (light.critical ?? 0),
        },
        dark: {
          ...dark,
          idealLower: (dark.setPoint ?? 0) - (dark.ideal ?? 0),
          idealUpper: (dark.setPoint ?? 0) + (dark.ideal ?? 0),
          criticalLower: (dark.setPoint ?? 0) - (dark.critical ?? 0),
          criticalUpper: (dark.setPoint ?? 0) + (dark.critical ?? 0),
        },
      };
    });

  const missingSettings = [];
  for (const { statisticsKeyV2, preset } of presetTypes) {
    if (
      !editableSettings.some((s) => s.type === statisticsKeyV2) &&
      !preset?.readOnly
    ) {
      missingSettings.push(getEmptySetting(statisticsKeyV2));
    }
  }

  const vpdSettings = deriveVPDSettings(presetTypes, editableSettings);

  return [...editableSettings, ...missingSettings, ...vpdSettings];
}

/** */
export function computePresetName(zone: TZone, date: Date | number) {
  return `${zone.label} - ${formatDateInMMMDD(date)}`;
}

/** Whether the provided cycle overlaps the given list of growth cycles  */
export function overlapsCycle(cycle: TGrowthCycle, cycles: TGrowthCycle[]) {
  return cycles.some((c) => {
    if (c.id === cycle.id) {
      return false;
    }

    return areIntervalsOverlapping(
      {
        start: c.start_time,
        end: c.end_time,
      },
      {
        start: cycle.start_time,
        end: cycle.end_time,
      },
      { inclusive: true }
    );
  });
}

/**
 * Creates key for optimal monitoring parameters mapping
 *
 * @param {EMeasurementStatisticsTypes} measurementType measurement type
 * @param {ELightCycleType} lightCycle light or dark
 * @param {number} day Whole number from 1..N
 * @returns {string}  key
 */
const createOptimalMonitoringParametersMapKey = (
  measurementType: EMeasurementStatisticsTypes,
  lightCycle: ELightCycleType,
  day: number
): string => {
  return `${measurementType}_${lightCycle}_${day}`
    .replace(/ /g, '_')
    .toLocaleLowerCase();
};

type TMonitoringParameterKey = {
  measurementType: EMeasurementStatisticsTypes;
  lightCycle: ELightCycleType;
  day: number;
};

/**
 * Takes in a string an gets the component parts.
 *
 * @param {string} key OptimalMonitoringParameterKey
 * @returns {*}  {TMonitoringParameterKey} Componenets of monitoring parameter key
 */
const getOptimalMonitoringParameterKeyParts = (
  key: string
): TMonitoringParameterKey => {
  const regex =
    /(?<measurementType>.*?)_(?<lightCycle>light|dark)_(?<day>\d+)/gm;
  const groups = regex.exec(key)?.groups;
  const measurementType =
    groups?.measurementType as keyof typeof EMeasurementStatisticsTypes;
  const lightCycle = groups?.lightCycle as keyof typeof ELightCycleType;
  const day = groups?.day ?? '1';
  return {
    measurementType: EMeasurementStatisticsTypes[measurementType],
    lightCycle: ELightCycleType[lightCycle],
    day: parseInt(day),
  };
};

// memoized version of getOptimalMonitoringParameterKeyParts
const getOptimalMonitoringParameterKeyPartsMemo = memoizee(
  getOptimalMonitoringParameterKeyParts,
  {
    primitive: true,
  }
);

/**
 * Return the closest monitoring parameter key. ex) if grow day
 * is 11 but the parameter only has information until day 2 then
 * your key will be <measurementType>_<lightCycle>_2
 *
 * @param {string[]} monitoringParameterKeyList list of monitoring parameters keys
 * @param {EMeasurementStatisticsTypes}  measurementType measurementType
 * @param {ELightCycleType} lightCycle lightCycle
 * @param {number} targetDay day you want the closest monitoring parameter
 * @returns {string}  {string} <measurementType>_<lightCycle>_<closest_week>
 *
 */
export const getClosestMonitoringParameterKey = (
  monitoringParameterKeyList: string[],
  measurementType: EMeasurementStatisticsTypes,
  lightCycle: ELightCycleType,
  targetDay: number
): string => {
  const monitoringParameterDaySortedDescending = Array.from(
    new Set(monitoringParameterKeyList)
  )
    .sort((a, b) => {
      const { day: dayA } = getOptimalMonitoringParameterKeyPartsMemo(a);
      const { day: dayB } = getOptimalMonitoringParameterKeyPartsMemo(b);
      // Sort DESCENDING.
      return dayB - dayA;
    })
    .map(
      (monitoringParameterKey) =>
        getOptimalMonitoringParameterKeyPartsMemo(monitoringParameterKey).day
    );
  // Search through available keys and return when day is less than or equal to target day.
  for (const day of monitoringParameterDaySortedDescending) {
    const key = createOptimalMonitoringParametersMapKey(
      measurementType,
      lightCycle,
      day
    );
    if (day <= targetDay) {
      return key;
    }
  }

  const key = createOptimalMonitoringParametersMapKey(
    measurementType,
    lightCycle,
    targetDay
  );
  return `key: "${key}" not found in growthCycle`;
};

/**
 * Get the grow week based on current date and growth cycle information.
 * Currently there is only information available from week 1 to N
 *
 * @param {Date} date current date
 * @param {Maybe<TGrowthCycle>} growthCycle growth cycle
 * @returns {number} result between 1 and N weeks
 */
const getGrowWeek = (
  date: Date | number,
  growthCycle: Maybe<TGrowthCycle>
): number => {
  if (!growthCycle) {
    return 0;
  }

  return Math.max(differenceInWeeks(date, growthCycle.start_time), 1);
};

// memoized version of getClosestMonitoringParameterKey
const getClosestMonitoringParameterKeyMemo = memoizee(
  getClosestMonitoringParameterKey,
  {
    normalizer: (args) => {
      const monitoringParameterKeys = args[0];
      const monitoringParameterKeysSorted = monitoringParameterKeys.sort();
      const monitoringParameterKeysString =
        monitoringParameterKeysSorted.join('|');
      const measurementType = args[1]
        ? args[1].toString()
        : 'NO_MEASURMENT_TYPE';
      const lightCycle = args[2].toString();
      const targetDay = args[3];

      return `${measurementType}_${lightCycle}_${targetDay}_${monitoringParameterKeysString}`;
    },
  }
);

const getMonitoringParameterVersion = (
  growthCycle: Maybe<TGrowthCycle>
): EMontioringParameterVersion => {
  const monitoringParameters = growthCycle?.metadata?.monitoring_parameters;
  let version: EMontioringParameterVersion =
    monitoringParameters?.version || EMontioringParameterVersion.NO_VERSION;
  if (version === EMontioringParameterVersion.NO_VERSION) {
    const mpKeySet = new Set(Object.keys(monitoringParameters));
    if (mpKeySet.has('par_dark_1')) {
      version = EMontioringParameterVersion.V_1_0_0;
      const paremeterKeySet = new Set(
        Object.keys(monitoringParameters['par_dark_1'])
      );
      if (paremeterKeySet.has('set_point')) {
        version = EMontioringParameterVersion.V_1_5_0;
      }
    } else if (mpKeySet.has('par')) {
      version = EMontioringParameterVersion.V_0_0_0;
    }
  }
  return version;
};

const getMonitoringParameterVersionMemo = memoizee(
  getMonitoringParameterVersion,
  {
    normalizer: function (args) {
      // args is arguments object as accessible in memoized function
      return args[0]?.id.toString() || '-1';
    },
  }
);

/**
 * Gets the optimal range for measurement given the current date,
 * light cycle based on the growth cycle data.
 *
 * @param {Date} date current date time
 * @param {EMeasurementTypes} measurementOption measurement type
 * @param {Maybe<IDayRange>} dayRange day range to determine light cycle
 * @param {"Maybe<TGrowthCycle[]>"} growthCycle growth data
 * @returns {TMontioringParameterType} optimal range
 */
export const getOptimalRange = (
  date: Date | number,
  measurementType: EMeasurementStatisticsTypes,
  dayRange: Maybe<IDayRange>,
  growthCycle: Maybe<TGrowthCycle>
): TMontioringParameterType | undefined => {
  if (!growthCycle) {
    throw new Error('growthCycle is null or undefined');
  }
  if (isNil(dayRange)) {
    // throw new Error('dayRange is null or undefined');
    return undefined;
  }

  const lightCycle = getLightCycle(date, dayRange);
  const version = getMonitoringParameterVersionMemo(growthCycle);
  const monitoringParametersMap: { [key: string]: TMontioringParameterType } =
    growthCycle.metadata?.monitoring_parameters;

  if (EMontioringParameterVersion.V_2_0_0 === version) {
    // Keyed by day.
    const day = getGrowDay(date, growthCycle.start_time);
    const key = getClosestMonitoringParameterKeyMemo(
      Object.keys(monitoringParametersMap).filter(
        (key) => key.includes(measurementType) && key.includes(lightCycle)
      ),
      measurementType,
      lightCycle,
      day
    );

    return monitoringParametersMap[key];
  } else if (EMontioringParameterVersion.V_1_0_0 === version) {
    // Keyed by week no set point.
    const week = getGrowWeek(date, growthCycle);
    const key = getClosestMonitoringParameterKeyMemo(
      Object.keys(monitoringParametersMap).filter(
        (key) => key.includes(measurementType) && key.includes(lightCycle)
      ),
      measurementType,
      lightCycle,
      week
    );

    const monitoringParameters = monitoringParametersMap[key]!;
    const { max, min } = monitoringParameters.optimal_range;
    const setPoint = (max + min) / 2;

    return {
      ...monitoringParameters,
      set_point: setPoint,
    };
  } else if (EMontioringParameterVersion.V_1_5_0 === version) {
    // Keyed by week and has set point.
    const week = getGrowWeek(date, growthCycle);
    const key = getClosestMonitoringParameterKeyMemo(
      Object.keys(monitoringParametersMap).filter(
        (key) => key.includes(measurementType) && key.includes(lightCycle)
      ),
      measurementType,
      lightCycle,
      week
    );

    return monitoringParametersMap[key];
  } else if (EMontioringParameterVersion.V_0_0_0 === version) {
    // version 0.0.0 with no keying by time.
    const mp = growthCycle?.metadata?.monitoring_parameters;
    const mp2 = mp[measurementType][lightCycle];
    return {
      optimal_range: {
        min: mp2.green.min,
        max: mp2.green.max,
      },
      warning_range: {
        min: mp2.yellow.min,
        max: mp2.yellow.max,
      },
      set_point: (mp2.green.min + mp2.green.max) / 2,
    };
  } else if (EMontioringParameterVersion.NO_VERSION === version) {
    console.warn(
      '[getOptimalRange] No version found for monitoring parameters. Cannot provide optimal ranges.'
    );
  }
};

const HOURS_A_DAY = 24;

export const getLightHours = (timestamp: Date, lightInfo: TLightInfo[]) => {
  const currentLightInfo = getClosestLightInfo(timestamp, lightInfo);

  if (!currentLightInfo) {
    return {
      lightHours: 0,
      currentTimeOffsetInHours: 0,
    };
  }
  const lightOnStartTime =
    currentLightInfo.light_on_start_time.hour +
    currentLightInfo.light_on_start_time.minute / 60.0;
  const lightHours = currentLightInfo.light_on_duration_minutes / 60.0;
  const currentTimeOffsetInHours =
    (timestamp.getHours() +
      timestamp.getMinutes() / 60.0 -
      lightOnStartTime +
      HOURS_A_DAY) %
    HOURS_A_DAY;

  return { lightHours, currentTimeOffsetInHours };
};
