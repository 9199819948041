import { ReactNode } from 'react';

interface WrapProps {
  /** Determines whether `with` is called or not. If not, the component returns `children`. */
  condition?: boolean;
  /** Called with the provided `children` as argument when `condition` evaluates true. */
  with: (children: ReactNode) => JSX.Element;
  children: ReactNode;
}

/** Executes function `with` with the provided `children` as argument based on the `condition` value */
export const Wrap = ({
  condition = true,
  with: wrapper,
  children,
}: WrapProps) => {
  return condition ? wrapper(children) : <>{children}</>;
};
