import { hash } from 'bcryptjs';
import identity from 'lodash.identity';
import uniqBy from 'lodash.uniqby';
import { TOrganization } from 'shared/interfaces/organization';
import { TUser } from 'shared/interfaces/user';
import { isDefined } from './is-defined';

export const getUserName = (user: {
  first_name?: Maybe<string>;
  last_name?: Maybe<string>;
}) => [user.first_name, user.last_name].filter(identity).join(' ');

export const getUserOrganizations = (user: Maybe<TUser>): TOrganization[] => {
  const organizationList =
    user?.user_organization_roles
      .map((role) => {
        if (role.organization_id && role.organization) {
          return {
            id: role.organization_id,
            code: role.organization.enumeration.code,
            label: role.organization.enumeration.description,
          };
        } else if (role.location_id && role.location) {
          return {
            id: role.location.organization_id,
            code: role.location.organization.enumeration.code,
            label: role.location.organization.enumeration.description,
          };
        } else if (role.zone_id && role.zone) {
          return {
            id: role.zone.location.organization_id,
            code: role.zone.location.organization.enumeration.code,
            label: role.zone.location.organization.enumeration.description,
          };
        }
      })
      .filter(isDefined) ?? [];
  return uniqBy(organizationList, (organization) => organization.id);
};

export const getUserFirstName = (user: Maybe<TUser>, userInfo: any) =>
  user?.first_name ??
  userInfo?.attributes?.['given_name'] ??
  userInfo?.firstName ??
  userInfo?.attributes?.['name'] ??
  '';

export const getUserLastName = (user: Maybe<TUser>, userInfo: any) =>
  user?.last_name ??
  userInfo?.attributes?.['family_name'] ??
  userInfo?.familyName ??
  '';

/**
 * This function creates a hashed password using the `hash` function and a salt value
 * defined in the `VITE_APP_SALT` environment variable. If the salt is not defined,
 * this function returns undefined. If the password is already hashed, it returns the
 * password without hashing it again.
 *
 * @param {string} password - the password to be hashed
 * @returns {Promise<string | undefined>} - the hashed password or undefined if the salt is missing
 */
export const createHashedPassword = async (password: string) => {
  if (!import.meta.env.VITE_APP_SALT) {
    console.log('Missing import.meta.env.VITE_APP_SALT');
    return undefined;
  }
  // Test if password is already hashed.
  if (password.includes(import.meta.env.VITE_APP_SALT)) {
    return password;
  }
  return await hash(password, import.meta.env.VITE_APP_SALT!!);
};
