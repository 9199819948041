import { ERoutePath } from 'shared/constants/url';
import { ESettingsTabs } from 'shared/interfaces/settings';
import { getQueryParams } from './URLQueryParams';
import { StoreArgs } from './URLStoreArgs';

export const getSettingsUsersStore = (
  args: StoreArgs<ERoutePath.SETTINGS_USERS>
) => {
  const { routePath, searchParams } = args;
  const queryParams = getQueryParams(searchParams, routePath);
  const actions = {
    setPage: (page: number) => {
      args.setSearchParams({ ...queryParams, page });
    },
  };
  const state = {
    organization: queryParams.organization,
    tab: ESettingsTabs.USERS,
    page: queryParams.page ?? 1,
  };
  return {
    ...actions,
    ...state,
  };
};
