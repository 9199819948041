import { Button, ButtonProps } from 'components/common/Button/Button';
import {
  Dropdown,
  DropdownProps,
  findSelected,
  Option,
} from 'components/common/Dropdown/Dropdown';
import { Menu, MenuProps } from 'components/common/Menu';
import { Modal } from 'components/common/Modal/Modal';
import { TabGroup } from 'components/common/TabGroup';
import { useDisclosure } from 'hooks/useDisclosure';
import { NeatleafLogo } from 'icons/NeatleafLogo';
import { NeatleafMark } from 'icons/NeatleafMark';
import groupBy from 'lodash.groupby';
import {
  ChevronLeftIcon,
  MenuIcon,
  MoveRightIcon,
  SettingsIcon,
} from 'lucide-react';
import {
  ComponentProps,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { TOrganization } from 'shared/interfaces/organization';
import { TZone } from 'shared/interfaces/zone';
import { cn } from 'shared/utils/cn';

export interface THeaderLink<HLINK>
  extends Omit<ButtonProps, 'id' | 'onClick' | 'children'> {
  id: HLINK;
  label: string;
  enabled?: boolean;
  location: 'zone-detail-tabs' | 'zone-overview-tabs' | 'settings-menu';
  onClick?: (headerLink: THeaderLink<HLINK>) => void;
}

export interface HeaderProps<HLINK> extends ComponentProps<'header'> {
  extraContent?: ReactNode;
  orgs: TOrganization[];
  zones?: TZone[];
  links: THeaderLink<HLINK>[];
  selectedOrg: Maybe<TOrganization>;
  selectedZone?: Maybe<TZone>;
  selectedLink: THeaderLink<HLINK>;
  onChangeOrg: (org: TOrganization) => void;
  onChangeZone?: (zone: TZone) => void;
  onClickHome: () => void;
}

export const Header = <HLINK extends string>({
  className,
  extraContent,
  orgs,
  zones,
  links,
  selectedOrg,
  selectedZone,
  selectedLink,
  onChangeOrg,
  onChangeZone,
  onClickHome,
  ...props
}: HeaderProps<HLINK>) => {
  const sideNav = useDisclosure();
  const submenu = useDisclosure();
  const [activeSubmenu, setActiveSubmenu] = useState<Maybe<'org' | 'zone'>>();
  const orgOptions = useMemo(() => {
    if (!orgs) {
      return [];
    }
    return orgs
      .sort((a, b) => a.label.localeCompare(b.label))
      .map<Option<TOrganization>>((org) => ({
        label: org.label,
        value: org,
        selected: selectedOrg?.id === org.id,
      }));
  }, [selectedOrg?.id, orgs]);
  const selectedOrgOption = useMemo(
    () => findSelected<TOrganization>(orgOptions),
    [orgOptions]
  );
  const zoneOptions = useMemo(() => {
    if (!zones) {
      return undefined;
    }
    const groupedZones = groupBy(zones, (z) => z.locationName);
    const keys = Object.keys(groupedZones);
    return keys
      .sort((a, b) => a.localeCompare(b))
      .reduce(
        (optionsOrGroups = [], key) => {
          const options = groupedZones[key]!.sort((a, b) =>
            a.label.localeCompare(b.label)
          ).map<Option<TZone>>((zone) => ({
            label: zone.label,
            value: zone,
            selected: selectedZone?.id === zone.id,
          }));

          if (key) {
            optionsOrGroups.push({
              label: key,
              options,
            });
          } else {
            optionsOrGroups.push(...options);
          }

          return optionsOrGroups;
        },
        [] as DropdownProps<TZone>['options']
      );
  }, [selectedZone?.id, zones]);
  const selectedZoneOption = useMemo(
    () => findSelected<TZone>(zoneOptions),
    [zoneOptions]
  );
  const zoneDetailTabLinks = useMemo(
    () =>
      links.filter(
        ({ enabled, location }) =>
          location === 'zone-detail-tabs' &&
          (enabled !== undefined ? enabled : true)
      ),
    [links]
  );

  const zoneOverviewTabLinks = useMemo(
    () =>
      links.filter(
        ({ enabled, location }) =>
          location === 'zone-overview-tabs' &&
          (enabled !== undefined ? enabled : true)
      ),
    [links]
  );
  const menuLinks = useMemo(
    () =>
      links.filter(
        ({ enabled, location }) =>
          location === 'settings-menu' &&
          (enabled !== undefined ? enabled : true)
      ),
    [links]
  );
  const menuOptions: MenuProps['items'] = useMemo(
    () =>
      menuLinks.map((link) => {
        const { id, label, onClick, leadingIcon } = link;
        return {
          children: label,
          variant: 'flat',
          'aria-pressed': id === selectedLink.id,
          onClick: () => onClick?.(link),
          leadingIcon,
        };
      }),
    [menuLinks, selectedLink]
  );
  const handleChangeOrg: DropdownProps<TOrganization>['onChange'] = useCallback(
    (option: Option<TOrganization> | Option<TOrganization>[] | null) => {
      if (option && !Array.isArray(option)) {
        submenu.close();
        sideNav.close();
        setActiveSubmenu(null);
        onChangeOrg(option.value);
      }
    },
    [sideNav, submenu, onChangeOrg]
  );
  const handleChangeZone: DropdownProps<TZone>['onChange'] = useCallback(
    (option: Option<TZone> | Option<TZone>[] | null) => {
      if (option && !Array.isArray(option)) {
        submenu.close();
        sideNav.close();
        setActiveSubmenu(null);
        onChangeZone?.(option.value);
      }
    },
    [sideNav, submenu, setActiveSubmenu, onChangeZone]
  );
  const handleClickLink = useCallback(
    (id: string) => {
      const headerLink = links.find((link) => link.id === id)!;
      headerLink.onClick?.(headerLink);
    },
    [links]
  );

  return (
    <header
      {...props}
      className={cn(
        'p-3 px-4 lg:px-8 flex items-center gap-4 xl:gap-9',
        className
      )}
    >
      {!sideNav.isOpen && (
        <>
          <NeatleafLogo
            className="shrink-0 h-8 hidden xl:block cursor-pointer"
            onClick={onClickHome}
          />

          <NeatleafMark
            className="shrink-0 h-8 hidden lg:block xl:hidden cursor-pointer"
            onClick={onClickHome}
          />

          <nav className="h-9 xl:h-10 hidden lg:flex gap-4 xl:gap-9">
            <div className="flex gap-px">
              {orgOptions.length === 1 && selectedOrgOption && (
                <div className="px-6 xl:px-7 flex items-center rounded-s-full rounded-e-none bg-white text-nowrap">
                  {selectedOrgOption.label}
                </div>
              )}

              {orgOptions.length > 1 && selectedOrgOption && (
                <Dropdown<TOrganization>
                  variant="flat"
                  value={selectedOrgOption}
                  options={orgOptions}
                  aria-label="selected organization"
                  buttonClassName={cn(
                    'pl-6 xl:pl-7 pr-5 bg-white',
                    zoneOptions && 'rounded-e-none'
                  )}
                  onChange={handleChangeOrg}
                />
              )}

              {zoneOptions && selectedZoneOption && (
                <Dropdown<TZone>
                  variant="flat"
                  value={selectedZoneOption}
                  options={zoneOptions}
                  aria-label="selected zone"
                  buttonClassName="pl-6 xl:pl-7 pr-5 rounded-s-none bg-white"
                  onChange={handleChangeZone}
                />
              )}
            </div>

            {zoneDetailTabLinks.length > 0 && (
              <TabGroup
                value={selectedLink.id}
                tabs={zoneDetailTabLinks}
                variant="nav"
                onChange={handleClickLink}
              />
            )}

            {zoneOverviewTabLinks.length > 0 && (
              <TabGroup
                value={selectedLink.id}
                tabs={zoneOverviewTabLinks}
                variant="nav"
                onChange={handleClickLink}
              />
            )}
          </nav>
        </>
      )}

      <div className="flex justify-between gap-2 w-full lg:w-auto lg:ml-auto">
        {!sideNav.isOpen && (
          <Menu
            className="hidden lg:block ml-auto"
            floatProps={{ placement: 'bottom-end' }}
            items={menuOptions}
            button={
              <Button
                variant="flat"
                size="icon"
                aria-label="Settings"
                className="bg-white"
              >
                <SettingsIcon className="stroke-[1.5px] size-4 xl:size-5" />
              </Button>
            }
          />
        )}

        <Button
          className="lg:hidden bg-white"
          variant="flat"
          size="icon"
          aria-label="Open navigation"
          onClick={sideNav.toggle}
        >
          <MenuIcon className="stroke-[1.5px] size-4 xl:size-5" />
        </Button>

        <div
          id="filters-slot"
          className="lg:hidden flex-1 overflow-x-auto no-scrollbar"
        />

        {extraContent}
      </div>

      <Modal
        open={sideNav.isOpen}
        dismissOnEscape
        mode="drawer-left"
        className="min-w-[320px] w-1/2"
        onClose={sideNav.close}
      >
        <Modal.Header
          leadingElement={
            submenu.isOpen ? (
              <Button
                variant="tertiary"
                size="icon"
                aria-label="Go back"
                onClick={submenu.toggle}
              >
                <ChevronLeftIcon className="stroke-[1.5px] size-4 xl:size-5" />
              </Button>
            ) : (
              <NeatleafMark
                className="w-8 flex-shrink-0"
                onClick={onClickHome}
              />
            )
          }
          closeButtonProps={{ onClick: sideNav.close }}
        />

        <Modal.Content className="h-full pb-4">
          <div
            className={cn(
              'w-full h-full flex flex-col gap-8 min-h-max',
              submenu.isOpen && 'hidden'
            )}
          >
            <div className="w-full flex flex-col gap-2">
              {orgs.length === 1 && selectedOrg && (
                <div className="px-4 py-2 flex items-center rounded-full bg-white font-semibold text-nowrap">
                  {selectedOrg.label}
                </div>
              )}

              {orgs.length > 1 && selectedOrg && (
                <Button
                  variant="tertiary"
                  className="w-full justify-between font-normal"
                  aria-label="selected organization"
                  trailingIcon={
                    <MoveRightIcon className="stroke-[1.5px] size-4" />
                  }
                  onClick={() => {
                    setActiveSubmenu('org');
                    submenu.open();
                  }}
                >
                  {selectedOrg.label}
                </Button>
              )}

              {selectedZone && (
                <Button
                  variant="tertiary"
                  className="w-full justify-between font-normal"
                  aria-label="selected zone"
                  trailingIcon={
                    <MoveRightIcon className="stroke-[1.5px] size-4" />
                  }
                  onClick={() => {
                    setActiveSubmenu('zone');
                    submenu.open();
                  }}
                >
                  {selectedZone?.label}
                </Button>
              )}
            </div>

            <nav className="flex flex-col flex-1 gap-4 justify-between">
              {[
                [...zoneDetailTabLinks, ...zoneOverviewTabLinks],
                menuLinks,
              ].map((linksGroup) => (
                <div
                  key={linksGroup.reduce(
                    (acc, group) => `${acc}-${group.id}`,
                    ''
                  )}
                  className="w-full flex flex-col gap-2"
                >
                  {linksGroup.map(({ id, label, leadingIcon }) => (
                    <Button
                      key={id}
                      variant="flat"
                      className="justify-start font-normal"
                      aria-pressed={id === selectedLink.id}
                      leadingIcon={leadingIcon}
                      onClick={() => handleClickLink(id)}
                    >
                      {label}
                    </Button>
                  ))}
                </div>
              ))}
            </nav>
          </div>

          <div
            className={cn(
              'hidden w-full overflow-y-auto',
              submenu.isOpen && 'flex flex-col gap-4'
            )}
          >
            <p className="text-lg font-bold">
              {activeSubmenu === 'org' ? 'Customers' : 'Zones'}
            </p>

            {activeSubmenu === 'org' && orgOptions && (
              <div className="flex flex-col gap-2">
                {orgOptions.map((option) => (
                  <Button
                    variant="tertiary"
                    key={option.value.code}
                    selected={
                      option.value.code === selectedOrgOption?.value.code
                    }
                    onClick={() => handleChangeOrg(option)}
                  >
                    {option.label}
                  </Button>
                ))}
              </div>
            )}

            {activeSubmenu === 'zone' && zoneOptions && (
              <div className="flex flex-col gap-4">
                {zoneOptions.map((optionOrGroup) => {
                  // narrow into a group
                  if ('options' in optionOrGroup) {
                    return (
                      <div key={optionOrGroup.label}>
                        <p className="font-semibold py-2">
                          {optionOrGroup.label}
                        </p>

                        <div className="flex flex-col gap-2">
                          {optionOrGroup.options.map((option) => (
                            <Button
                              variant="tertiary"
                              key={option.label}
                              selected={
                                option.value.uid ===
                                selectedZoneOption?.value.uid
                              }
                              onClick={() => handleChangeZone(option)}
                            >
                              {option.label}
                            </Button>
                          ))}
                        </div>
                      </div>
                    );
                  }

                  return (
                    <Button
                      variant="tertiary"
                      key={optionOrGroup.label}
                      selected={
                        optionOrGroup.value.uid ===
                        selectedZoneOption?.value.uid
                      }
                      onClick={() => handleChangeZone(optionOrGroup)}
                    >
                      {optionOrGroup.label}
                    </Button>
                  );
                })}
              </div>
            )}
          </div>
        </Modal.Content>
      </Modal>
    </header>
  );
};
