import {
  NotificationTarget,
  NotificationType,
  UserNotificationSettingDto,
} from 'api/user-notification-settings';
import { Checkbox } from 'components/common/Checkbox/Checkbox';
import { Tooltip } from 'components/common/Tooltip/Tooltip';
import { InfoIcon } from 'lucide-react';
import { useCallback, useState } from 'react';
import { cn } from 'shared/utils/cn';
import {
  NotificationTargets,
  NotificationTypes,
} from './UserNotificationSettingContainer';

const NotificationTypeLabels: Record<
  NotificationType,
  { label: string; tooltip?: string }
> = {
  [NotificationType.CriticalEnvironment]: {
    label: 'Environmental alerts',
    tooltip: 'Will only be sent for set critical environment settings',
  },
  [NotificationType.InsightReport]: { label: 'Insight reports' },
  [NotificationType.SpyderStatus]: { label: 'Spyder status alerts' },
};

const NotificationTargetLabels: Record<NotificationTarget, string> = {
  [NotificationTarget.Email]: 'E-mail',
  [NotificationTarget.Sms]: 'SMS',
};

export interface UserNotificationSettingPanelProps {
  label: string;
  settings: UserNotificationSettingDto[];
  onSettingAdded: (
    notificationType: NotificationType,
    notificationTarget: NotificationTarget
  ) => void;
  onSettingRemoved: (uid: string) => void;
}

export const UserNotificationSettingPanel = (
  props: UserNotificationSettingPanelProps
) => {
  return (
    <div className="grid grid-cols-2 gap-y-4">
      <div className="font-semibold ">{props.label}</div>
      <div
        className={cn(
          `grid grid-cols-${NotificationTargets.length} justify-self-stretch`
        )}
      >
        {NotificationTargets.map((target) => {
          return (
            <span className="text-center" key={target}>
              {NotificationTargetLabels[target]}
            </span>
          );
        })}
      </div>

      {NotificationTypes.map((type) => {
        const settings = props.settings.filter(
          (s) => s.notificationType === type
        );
        return (
          <UserNotificationSettingRow
            notificationType={type}
            id={`${props.label}-${type}`}
            key={`${props.label}-${type}`}
            settings={settings}
            onSettingAdded={props.onSettingAdded}
            onSettingRemoved={props.onSettingRemoved}
          />
        );
      })}
    </div>
  );
};

interface UserNotificationSettingRowProps {
  id: string;
  notificationType: NotificationType;
  settings: UserNotificationSettingDto[];
  onSettingAdded: (
    notificationType: NotificationType,
    notificationTarget: NotificationTarget
  ) => void;
  onSettingRemoved: (uid: string) => void;
}

const UserNotificationSettingRow = (props: UserNotificationSettingRowProps) => {
  const onSettingChanged = useCallback(
    (
      checked: boolean,
      notificationTarget: NotificationTarget,
      isCheckedSetting: UserNotificationSettingDto | undefined
    ) => {
      if (checked) {
        props.onSettingAdded(props.notificationType, notificationTarget);
      } else {
        if (isCheckedSetting) {
          props.onSettingRemoved(isCheckedSetting.uid);
        }
      }
    },
    [props]
  );

  const [checkedState, setCheckedState] = useState<
    Record<NotificationTarget, boolean>
  >({} as Record<NotificationTarget, boolean>);

  const label = NotificationTypeLabels[props.notificationType];

  return (
    <>
      <div className="self-center flex align-middle gap-2">
        {label.label}
        {label.tooltip ? (
          <Tooltip
            label={label.tooltip}
            size="lg"
            allowedPlacements={['top', 'right']}
          >
            <span>
              <InfoIcon className="stroke-[1.5px]" />
            </span>
          </Tooltip>
        ) : null}
      </div>
      <div
        className={cn(
          `grid grid-cols-${NotificationTargets.length} justify-self-stretch`
        )}
      >
        {NotificationTargets.map((notificationTarget) => {
          const isCheckedSetting = props.settings.find(
            (s) => s.target === notificationTarget
          );
          const isChecked =
            typeof checkedState[notificationTarget] === 'undefined'
              ? !!isCheckedSetting
              : checkedState[notificationTarget];
          return (
            <div
              key={`${props.id}-${notificationTarget}`}
              className="flex justify-center"
            >
              <Checkbox.Input
                checked={isChecked}
                onChange={(e) => {
                  setCheckedState({
                    ...checkedState,
                    [notificationTarget]: e.target.checked,
                  });
                  onSettingChanged(
                    e.target.checked,
                    notificationTarget,
                    isCheckedSetting
                  );
                }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
