import { TUserOrganizationRole, TUserPermission } from 'shared/interfaces/user';
import { Permission } from './permission';

export interface ZoneIdentifiers {
  zoneUid: string;
  locationId: number;
  organizationCode: string;
}

export interface LocationIdentifiers {
  locationId: number;
  organizationCode: string;
}

export class PermissionContainer {
  private byLocationId = new Map<number, TUserPermission[]>();
  private byOrganizationCode = new Map<string, TUserPermission[]>();
  private byZoneUid = new Map<string, TUserPermission[]>();

  constructor(roles: TUserOrganizationRole[], permissions: TUserPermission[]) {
    for (const role of roles) {
      if (role.organization) {
        upsertIntoPermissionMap<string>(
          this.byOrganizationCode,
          role.organization.enumeration.code,
          permissions.filter((p) => p.role_id === role.role_id)
        );
      } else if (role.location_id) {
        upsertIntoPermissionMap<number>(
          this.byLocationId,
          role.location_id,
          permissions.filter((p) => p.role_id === role.role_id)
        );
      } else if (role.zone) {
        upsertIntoPermissionMap<string>(
          this.byZoneUid,
          role.zone.uid,
          permissions.filter((p) => p.role_id === role.role_id)
        );
      }
    }
  }

  public hasZoneLocationOrOrganizationPermission(
    permission: Permission,
    zoneIdentifiers: ZoneIdentifiers
  ): boolean {
    return (
      this.hasOrganizationPermission(
        permission,
        zoneIdentifiers.organizationCode
      ) ||
      this.hasLocationPermission(permission, zoneIdentifiers.locationId) ||
      this.hasZonePermission(permission, zoneIdentifiers.zoneUid)
    );
  }

  public hasOrganizationPermission(
    permission: Permission,
    organizationCode: string
  ): boolean {
    const organizationPermissions =
      this.byOrganizationCode.get(organizationCode);

    return (
      !!organizationPermissions &&
      organizationPermissions.some((p) => p.enumeration.code === permission)
    );
  }

  public hasLocationPermission(
    permission: Permission,
    locationId: number
  ): boolean {
    const locationPermissions = this.byLocationId.get(locationId);

    return (
      !!locationPermissions &&
      locationPermissions.some((p) => p.enumeration.code === permission)
    );
  }

  public hasZonePermission(permission: Permission, zoneUid: string): boolean {
    const zonePermissions = this.byZoneUid.get(zoneUid);

    return (
      !!zonePermissions &&
      zonePermissions.some((p) => p.enumeration.code === permission)
    );
  }

  public getOrganizationPermissions(
    organizationCode: string
  ): TUserPermission[] | undefined {
    return this.byOrganizationCode.get(organizationCode);
  }
}

function upsertIntoPermissionMap<TId>(
  map: Map<TId, TUserPermission[]>,
  id: TId,
  permissions: TUserPermission[]
) {
  if (map.has(id)) {
    map.set(id, [...map.get(id)!, ...permissions]);
  } else {
    map.set(id, permissions);
  }
}
