import { FC } from 'react';

interface IUserAvatarProps {
  /** The user's name initials. */
  nameInitials: string;
}

/**
 * Represents user's profile picture.
 *
 * @param {IUserAvatarProps} props imgageUrl, size
 * @returns {JSX.Element} UserAvatar component
 */
export const UserAvatar: FC<IUserAvatarProps> = ({ nameInitials }) => {
  return (
    <>
      <div
        className={
          'flex text-xl bg-orange-400 text-center justify-center items-center uppercase w-[90px] h-[90px] rounded-[90px]'
        }
      >
        {nameInitials}
      </div>
    </>
  );
};
