import { useMediaQuery } from 'react-responsive';

interface IScreenSizeInterface {
  /** Shows if the current screen is mobile. */
  isMobile: boolean;
  /** Shows if the current screen is mobile no bigger than breakpoint xs. */
  isMobileXs: boolean;
  /** Tells if the screenis landscape or portrait */
  isPortrait: boolean;
}

/**
 * Custom hook to see if the screen is mobile/desktop based on the screen width.
 * @returns {IScreenSizeInterface} shows the current platform.
 */
export const useScreenSize = (): IScreenSizeInterface => {
  // Hard coded breakpoint values to break dependency to material-ui
  // Original values can be found in tailwind.config.ts
  const isMobile = useMediaQuery({
    query: `(max-width: 992px)`,
  });

  const isMobileXs = useMediaQuery({
    query: `(max-width: 576px)`,
  });

  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  return { isMobile, isMobileXs, isPortrait };
};
