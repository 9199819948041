import { error } from 'components/common/Toast/Toast';
import { useEffect, useRef, useState } from 'react';
import { TScaleAndPosition } from 'shared/interfaces/image';

export type TDrawnBoundingBox<T> = {
  x: number;
  y: number;
  width: number;
  height: number;
  drawing: boolean;
  visible: boolean;
  metadata?: T;
};
const initializeBoundingBox = <T>(
  x: number,
  y: number,
  visible = true,
  drawing = true
): TDrawnBoundingBox<T> => ({
  x,
  y,
  width: 0,
  height: 0,
  visible,
  drawing,
});

type BoundingBoxesState<T> = {
  drawnBoundingBox: TDrawnBoundingBox<T>;
  selectedBoundingBox: Nullable<TDrawnBoundingBox<T>>;
  disabled: boolean;
};

const sanitizeDrawnShape = <T>(shape: TDrawnBoundingBox<T>) => {
  return {
    ...shape,
    x: Math.min(shape.x, shape.x + shape.width),
    y: Math.min(shape.y, shape.y + shape.height),
    width: Math.abs(shape.width),
    height: Math.abs(shape.height),
  };
};

export const useDrawBoundingBoxController = <T>({
  scaleAndPosition,
  drawingMode = false,
  onDrawEnd,
}: {
  scaleAndPosition: TScaleAndPosition;
  drawingMode: boolean;
  onDrawEnd: () => void;
}) => {
  const boundingBoxesInitialState: BoundingBoxesState<T> = {
    drawnBoundingBox: initializeBoundingBox<T>(0, 0, false, false),
    selectedBoundingBox: null as Nullable<TDrawnBoundingBox<T>>,
    disabled: false,
  };

  const [boundingBoxesState, setBoundingBoxesState] = useState(
    boundingBoxesInitialState
  );
  const {
    drawnBoundingBox: notYetSanitizedDrawnBoundingBox,
    selectedBoundingBox,
    disabled,
  } = boundingBoxesState;

  const drawnBoundingBox = sanitizeDrawnShape<T>(
    notYetSanitizedDrawnBoundingBox
  );

  const getEventCoordinatesWithRespectToDrawingArea = (
    event:
      | TouchEvent
      | React.MouseEvent<HTMLDivElement>
      | React.TouchEvent<HTMLDivElement>,
    isTouch = false,
    scale: number
  ) => {
    if (!drawingDivRef.current) throw new Error('Drawing div ref is not set');
    const rect = drawingDivRef.current.getBoundingClientRect();
    if (
      isTouch &&
      !(event as React.TouchEvent<HTMLDivElement> | TouchEvent).targetTouches[0]
    )
      throw new Error('Touch event has no target touches');
    const { clientX, clientY } = isTouch
      ? (event as React.TouchEvent<HTMLDivElement> | TouchEvent)
          .targetTouches[0]!
      : (event as React.MouseEvent<HTMLDivElement>);
    const x = (clientX - rect.left) / scale;
    const y = (clientY - rect.top) / scale;
    return { x, y };
  };

  const handleDrawStart = (
    event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ) => {
    if (!drawingMode) return;
    const { x, y } = getEventCoordinatesWithRespectToDrawingArea(
      event,
      event.type.startsWith('touch'),
      scaleAndPosition.scale
    );
    setBoundingBoxesState((prev) => ({
      ...prev,
      drawnBoundingBox: initializeBoundingBox<T>(x, y),
      selectedBoundingBox: null,
    }));
  };
  const drawingDivRef = useRef<Nullable<HTMLDivElement>>(null);

  useEffect(() => {
    const drawingDiv = drawingDivRef.current;
    if (!drawingDiv) return;

    const handleTouchMove = (event: TouchEvent) => {
      if (!drawingMode || !drawnBoundingBox?.drawing) return;
      event.preventDefault();
      const { x, y } = getEventCoordinatesWithRespectToDrawingArea(
        event,
        true,
        scaleAndPosition.scale
      );
      setBoundingBoxesState((prev) => ({
        ...prev,
        drawnBoundingBox: {
          ...prev.drawnBoundingBox,
          width: x - prev.drawnBoundingBox.x,
          height: y - prev.drawnBoundingBox.y,
        },
      }));
    };

    drawingDiv.addEventListener('touchmove', handleTouchMove, {
      passive: false,
    });

    return () => {
      drawingDiv.removeEventListener('touchmove', handleTouchMove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawnBoundingBox?.drawing]);

  const handleDrawMove = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!drawingMode || !drawnBoundingBox.drawing) return;
    event.preventDefault();
    const { x, y } = getEventCoordinatesWithRespectToDrawingArea(
      event,
      event.type.startsWith('touch'),
      scaleAndPosition.scale
    );
    setBoundingBoxesState((prev) => ({
      ...prev,
      drawnBoundingBox: {
        ...prev.drawnBoundingBox,
        width: x - prev.drawnBoundingBox.x,
        height: y - prev.drawnBoundingBox.y,
      },
    }));
  };

  const handleDrawEnd = () => {
    if (!drawingMode || !drawnBoundingBox.drawing) return;
    const finalShape = { ...drawnBoundingBox, drawing: false };
    const sanitizedShape = sanitizeDrawnShape<T>(finalShape);

    setBoundingBoxesState((prev) => ({
      ...prev,
      selectedBoundingBox: sanitizedShape,
      drawnBoundingBox: { ...finalShape, visible: false },
    }));

    onDrawEnd();
  };

  const handleRemoveBoundingBox = (promise: Promise<any>) => {
    const previousBoundingBoxState = boundingBoxesState;
    setBoundingBoxesState((prev) => ({
      ...prev,
      selectedBoundingBox: null,
      disabled: true,
    }));
    promise
      .catch(() => {
        error({ content: 'Failed to remove boundingBox' });
        setBoundingBoxesState(previousBoundingBoxState);
      })
      .finally(() => {
        setBoundingBoxesState((prev) => ({
          ...prev,
          disabled: false,
        }));
      });
  };

  const handleSaveBoundingBox = async (promise: Promise<void>) => {
    const previousBoundingBoxState = boundingBoxesState;
    setBoundingBoxesState((prev) => ({
      ...prev,
      disabled: true,
    }));
    try {
      await promise;
      setBoundingBoxesState((prev) => ({
        ...prev,
        selectedBoundingBox: null,
      }));
    } catch (err) {
      setBoundingBoxesState(previousBoundingBoxState);
      throw err;
    } finally {
      setBoundingBoxesState((prev) => ({
        ...prev,
        disabled: false,
      }));
    }
  };

  const setSelectedBoundingBox = (
    selectedBoundingBox: Nullable<TDrawnBoundingBox<T>>
  ) => {
    setBoundingBoxesState((prev) => ({
      ...prev,
      selectedBoundingBox,
    }));
  };

  const clear = () => {
    setBoundingBoxesState(boundingBoxesInitialState);
  };

  useEffect(() => {
    if (drawingMode)
      setBoundingBoxesState((prev) => ({ ...prev, selectedBoundingBox: null }));
  }, [drawingMode]);

  return {
    drawingMode,
    disabled,
    drawnBoundingBox,
    selectedBoundingBox,
    drawingDivRef,
    handleDrawEnd,
    handleDrawMove,
    handleDrawStart,
    handleRemoveBoundingBox,
    handleSaveBoundingBox,
    setSelectedBoundingBox,
    clear,
  };
};
