import { Chip } from 'components/common/Chip/Chip';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useGrowthCycleReport } from 'hooks/useGrowthCycleReport';
import { CalendarRangeIcon } from 'lucide-react';
import { formatGrowthCycleDaterange } from './GrowthCycleReportUtils';

export const GrowthCycleReportSummary = () => {
  const { currentZone } = useCurrentZone();
  const { selectedGrowthCycle } = useGrowthCycleReport();

  if (!selectedGrowthCycle || !currentZone) {
    return null;
  }
  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-2 text-2xl">
        <span>{currentZone.locationName}</span>
        <span className="font-bold">{currentZone.label}</span>
      </div>
      <Chip variant="secondary" selected={true}>
        <CalendarRangeIcon className="stroke-[1.5px] size-4 xl:size-5" />
        {formatGrowthCycleDaterange(
          selectedGrowthCycle.start_time,
          selectedGrowthCycle.end_time
        )}
      </Chip>
    </div>
  );
};
