import { Auth } from 'aws-amplify';
import { NonAuthForm } from 'components/auth/NonAuthForm';
import { NonAuthLayout } from 'components/auth/NonAuthLayout';
import { PasswordRules } from 'components/auth/PasswordRules';
import { Alert } from 'components/common/Alert/Alert';
import { Button } from 'components/common/Button/Button';
import { Input } from 'components/common/Input/Input';
import { Link } from 'components/common/Link';
import { useResetPasswordURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PASSWORD_RULES } from 'shared/constants/password';
import { ERoutePath, PATH_PATTERNS } from 'shared/constants/url';

type TResetPasswordForm = {
  code: string;
  password: string;
  confirmPassword: string;
};

export const ResetPasswordPage: FC = () => {
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shouldShowRules, setShouldShowRules] = useState(false);
  const [showChangedDialog, setShowChangedDialog] = useState(false);

  const { handleSubmit, register, watch } = useForm<TResetPasswordForm>();

  const { navigateToLogin, username } = useResetPasswordURL();
  const values = watch();

  const checkPasswordRules = (password: string, confirmPassword: string) =>
    PASSWORD_RULES.every((rule) => rule.check(password, confirmPassword));

  const onSubmit = handleSubmit(async (values) => {
    const isValid = checkPasswordRules(values.password, values.confirmPassword);
    if (!isValid) {
      setShouldShowRules(true);
      return;
    }

    setIsSubmitting(true);

    try {
      await Auth.forgotPasswordSubmit(
        username || '',
        values.code,
        values.password
      );
      // show password changed dialog
      showPasswordChangedDialog();
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  });

  const showPasswordChangedDialog = () => {
    setShowChangedDialog(true);
  };

  const handleGoToLogIn = () => {
    setShowChangedDialog(false);
    navigateToLogin();
  };

  return (
    <NonAuthLayout formClassName="min-h-[600px]">
      <NonAuthForm
        instructionHeader={
          <>
            <h1 className="text-3xl leading-none">Reset</h1>
            <h1 className="text-3xl font-bold leading-none">password</h1>
          </>
        }
        instructions={
          <p className="text-gray-500 leading-7">
            Enter the reset code you received and the new password you would
            like to use.
          </p>
        }
        footer={
          <>
            <p>Remember password?</p>
            <Link
              to={PATH_PATTERNS[ERoutePath.LOGIN]}
              text="Login"
              className="text-base"
            />
          </>
        }
      >
        <form role="form" onSubmit={onSubmit}>
          <div className="flex flex-col gap-3">
            <Input
              placeholder="Verify code"
              data-testid="verify-code"
              {...register('code', { required: true })}
            />

            <Input
              placeholder="New Password"
              type="password"
              data-testid="new-password"
              {...register('password', { required: true })}
            />

            <Input
              placeholder="Confirm Password"
              type="password"
              data-testid="confirm-password"
              {...register('confirmPassword', { required: true })}
            />

            {shouldShowRules && (
              <PasswordRules
                confirmPassword={values.confirmPassword}
                password={values.password}
                rules={PASSWORD_RULES}
              />
            )}

            <Button
              role="button"
              disabled={isSubmitting}
              type="submit"
              className="w-full"
            >
              Change password
            </Button>

            {error && <p>{error}</p>}
          </div>
        </form>
      </NonAuthForm>

      {showChangedDialog ? (
        <Alert
          open
          confirmLabel="Continue"
          onConfirm={handleGoToLogIn}
          onCancel={handleGoToLogIn}
        >
          Password changed!
        </Alert>
      ) : null}
    </NonAuthLayout>
  );
};
