import isNil from 'lodash.isnil';
import { ChevronDownIcon } from 'lucide-react';
import {
  ComponentPropsWithoutRef,
  MouseEvent,
  ReactNode,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { cn } from 'shared/utils/cn';
import { Button } from '../Button/Button';

type DisclosureProps = ComponentPropsWithoutRef<'div'> & {
  /** The button label */
  summary: ReactNode;
} & Exclusive<
    {
      /** Keep the disclosure open regardless. When true its not possible to toggle it. */
      keepOpen: true;
      open?: never;
      defaultOpen?: never;

      onToggle?: never;
    },
    {
      keepOpen?: false;
      open?: boolean;
      defaultOpen?: boolean;
      onToggle?: (open: boolean) => void;
    }
  >;

/**
 * A disclosure is a widget that enables content to be either collapsed (hidden) or expanded (visible).
 * It has two elements: a disclosure button and a section of content whose visibility is controlled by the button.
 */
export const Disclosure = forwardRef<HTMLDivElement, DisclosureProps>(
  function Disclosure(
    {
      className,
      children,
      defaultOpen,
      open,
      keepOpen,
      summary,
      'aria-label': ariaLabel,
      onToggle,
      ...props
    },
    ref
  ) {
    if (!isNil(open) && isNil(onToggle)) {
      throw new Error(
        '`onToggle` handler should be provided when `open` is passed'
      );
    }

    const [isOpen, setIsOpen] = useState(keepOpen ?? defaultOpen);
    const localAriaLabel = ariaLabel
      ? ariaLabel
      : typeof summary === 'string'
        ? (summary as string)
        : '';
    const summaryAriaLabel = isOpen
      ? `Collapse ${localAriaLabel}`
      : `Expand ${localAriaLabel}`;
    const handleClick = (event: MouseEvent) => {
      if (!keepOpen) {
        event.preventDefault();
        event.stopPropagation();
        if (onToggle) {
          onToggle(!isOpen);
        } else {
          setIsOpen((prev) => !prev);
        }
      }
    };

    useEffect(() => {
      if (!keepOpen && typeof onToggle === 'function') {
        setIsOpen(open);
      }
    }, [keepOpen, onToggle, open]);

    return (
      <div
        ref={ref}
        {...props}
        className={cn(
          'py-6 flex flex-col gap-2 border-t-[1px] border-neutral-400',
          className
        )}
        aria-label={localAriaLabel}
      >
        <div
          ref={ref}
          className="font-semibold flex gap-2 items-start sm:items-center justify-between"
        >
          {summary}

          {!keepOpen && (
            <Button
              size="icon"
              variant="flat"
              aria-label={summaryAriaLabel}
              onClick={handleClick}
            >
              <ChevronDownIcon
                className={cn(
                  'stroke-[1.5px] size-4 xl:size-5 transform',
                  isOpen && '-rotate-180'
                )}
              />
            </Button>
          )}
        </div>

        {(keepOpen || isOpen) && children}
      </div>
    );
  }
);
