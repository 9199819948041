import { parseISO } from 'date-fns';
import Cookies from 'js-cookie';
import isNil from 'lodash.isnil';
import memoize from 'lodash.memoize';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FEATURE_ENABLED } from 'shared/constants/general';
import { TUserPermission } from 'shared/interfaces/user';
import { BetaFlag } from 'shared/models/beta-flag';
import { DebugFlag } from 'shared/models/debug-flag';
import { useUserPermissions } from './useUserPermissions';

export interface IUseFeatureFlagsReturn {
  getBetaFlagValue: (flag: BetaFlag) => boolean;
  getDebugFlagValue: (flag: DebugFlag) => boolean;
  debugDateTime: Date;
  regularlyUpdatedDebugDateTime: Date;
}

const UPDATE_CURRENT_TIME_INTERVAL_IN_MINUTES = 1;

export const useFeatureFlags = (): IUseFeatureFlagsReturn => {
  const { currentOrganizationUserPermissions } = useUserPermissions();

  const [time, setTime] = useState(new Date());

  // Update the time regularly
  useEffect(() => {
    const intervalId = setInterval(
      () => {
        setTime(new Date());
      },
      UPDATE_CURRENT_TIME_INTERVAL_IN_MINUTES * 60 * 1000
    );

    return () => clearInterval(intervalId);
  }, []);

  const regularlyUpdatedDebugDateTime = useMemo(
    () => getCurrentDateTime(currentOrganizationUserPermissions),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOrganizationUserPermissions, time]
  );

  const debugDateTime = useMemo(
    () => getCurrentDateTime(currentOrganizationUserPermissions),
    [currentOrganizationUserPermissions]
  );

  const getDebugFlagValue = useCallback((flag: DebugFlag) => {
    return Cookies.get(flag) === FEATURE_ENABLED;
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFlagFromMap = useCallback(
    memoize((flag: BetaFlag, isDebug: boolean = false): boolean => {
      if (isNil(currentOrganizationUserPermissions)) {
        return false;
      }

      const hasCookieFlag = !isNil(Cookies.get(flag));
      let cookieFlagValue = false;
      if (hasCookieFlag) {
        cookieFlagValue = Cookies.get(flag) === FEATURE_ENABLED;
      }

      const databaseFlagValue = currentOrganizationUserPermissions.some(
        (p) => p.enumeration.code === flag
      );

      let flagValue = false;
      let flagSource = 'nowhere';
      if (hasCookieFlag) {
        flagSource = 'cookie';
        flagValue = cookieFlagValue;
      } else if (databaseFlagValue) {
        flagSource = 'database';
        flagValue = databaseFlagValue;
      }
      if (isDebug) {
        console.log(
          `[getFlagFromMap] source: ${flagSource}\tvalue: ${flagValue}\tflag: ${flag}`
        );
      }
      return flagValue;
    }),
    [currentOrganizationUserPermissions]
  );

  const getBetaFlagValue = useCallback(
    (flag: BetaFlag): boolean => {
      // Is debug mode
      const isDebug = getDebugFlagValue(DebugFlag.SHOW_PERMISSIONS);
      // Is beta tester
      const isBetaTester = getFlagFromMap(BetaFlag.TESTER, isDebug);

      if (isBetaTester) {
        return true;
      }
      // Is feature flag enabled
      return getFlagFromMap(flag, isDebug);
    },
    [getDebugFlagValue, getFlagFromMap]
  );

  return {
    getDebugFlagValue: getDebugFlagValue,
    getBetaFlagValue: getBetaFlagValue,
    debugDateTime,
    regularlyUpdatedDebugDateTime,
  };
};

/**
 * Function for getting date time based on cookie or database.
 * If no config and no cookie then use current date time.
 *
 * Priority is as follows:
 *  Database < Cookie
 *
 * ### Example using cookie:
 * run in browser console dev tools:
 *  * `document.cookie='DEBUG:ANCHOR_DATE_TIME_VALUE=2022-12-30T18:30:30'`
 *
 *  or without time
 *
 *  * `document.cookie='DEBUG:ANCHOR_DATE_TIME_VALUE=2022-12-30'`
 *
 */
const getCurrentDateTime = (
  userPermissions: TUserPermission[] | undefined
): Date => {
  let resultDateTime = new Date();
  let dateTimeSource = 'system';
  let dateTime: Optional<Date> = undefined;
  try {
    // Check database
    if (!isNil(userPermissions)) {
      dateTime = getDatabaseAnchorDateValue(userPermissions);

      if (!isNil(dateTime)) {
        dateTimeSource = 'database';
        resultDateTime = dateTime;
      }
    }

    // Check cookie
    const dateTimeString: Optional<string> = Cookies.get(
      DebugFlag.ANCHOR_DATE_TIME_VALUE
    );
    if (!isNil(dateTimeString)) {
      dateTimeSource = 'cookie';
      resultDateTime = parseISO(dateTimeString);
    }

    if (resultDateTime instanceof Date && isNaN(resultDateTime.getTime())) {
      throw new Error(
        `[getCurrentDateTime] ${dateTimeSource}: Invalid date time - ${dateTime}`
      );
    }
  } catch (err: any) {
    console.error(
      `Error parsing ${dateTimeSource}: using current date time: ${resultDateTime}`
    );
    console.error(`err: ${JSON.stringify(err, null, 2)}`);
  }
  return resultDateTime;
};

/**
 * Get anchor date time value from enum metadata
 */
const getDatabaseAnchorDateValue = (
  userPermissions: TUserPermission[]
): Optional<Date> => {
  let datetimeValue = undefined;
  const debugAnchorDateTimeValueEnumList = userPermissions.filter(
    (rolePermission) =>
      rolePermission.enumeration.code === DebugFlag.ANCHOR_DATE_TIME_VALUE
  );

  const firstDebugAnchorDateTimeValueEnum = debugAnchorDateTimeValueEnumList[0];
  if (
    !isNil(firstDebugAnchorDateTimeValueEnum) &&
    !isNil(firstDebugAnchorDateTimeValueEnum.enumeration.metadata.value)
  ) {
    datetimeValue =
      firstDebugAnchorDateTimeValueEnum.enumeration.metadata.value;
  }
  return datetimeValue;
};
