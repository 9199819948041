import { QueueItem, useImageQueueProvider } from 'contexts/ImageQueueProvider';
import { useEffect, useRef, useState } from 'react';
import { EImageLoadStatus } from 'shared/interfaces/image';

type TUseImageArgs = {
  url: string | null;
  parentId: number;
  isVisible?: boolean;
  skipQueue?: boolean;
};

const useImage = ({
  url,
  parentId,
  isVisible,
  skipQueue,
}: TUseImageArgs): [
  HTMLImageElement | undefined,
  EImageLoadStatus | undefined,
] => {
  const [image, setImage] = useState<HTMLImageElement | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<EImageLoadStatus | undefined>(
    undefined
  );
  const { enqueue, remove } = useImageQueueProvider();
  const enqueuedId = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (!isVisible || !url || (enqueuedId.current === url && !skipQueue))
      return;

    enqueuedId.current = url;

    setIsLoading(EImageLoadStatus.LOADING);
    const queueItem: QueueItem = {
      skipQueue,
      parentId: parentId,
      url,
      resolve: (img) => {
        if (enqueuedId.current === url) {
          enqueuedId.current = undefined;
          setImage(img);
          setIsLoading(EImageLoadStatus.LOADED);
        }
      },
      reject: () => {
        if (enqueuedId.current === url) {
          enqueuedId.current = undefined;
          setIsLoading(EImageLoadStatus.FAILED);
        }
      },
    };

    enqueue(queueItem);

    return () => {
      if (isVisible && !skipQueue && enqueuedId.current === url) {
        remove(url);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, url, skipQueue]);

  return [image, isLoading];
};

export default useImage;
