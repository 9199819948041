import { useReactiveVar } from '@apollo/client';
import { measurementRunIdToHighestLoadedIndex } from 'global_state/image_feed_cache';
import { NOT_LOADED_IMAGE_SIZE_INDEX } from '../constants';

type TImageSizeIndexMapReturn = {
  getLowestImageSizeIndexWithinViewport: (
    measurementRunId: number,
    gridSize: TGridSize,
    viewPort: {
      topLeft: TPosition;
      bottomRight: TPosition;
    }
  ) => number;
  updateImageSizeIndexMap: (
    measurementRunId: number,
    imageSizeIndex: number,
    gridSize: TGridSize,
    row: number,
    column: number
  ) => void;
  getCurrentImageSizeIndex: (
    measurementRunId: number,
    gridSize: TGridSize,
    row: number,
    column: number
  ) => number;
};

const validateViewport = (viewPort: {
  topLeft: TPosition;
  bottomRight: TPosition;
}) => {
  if (
    viewPort.topLeft.x > viewPort.bottomRight.x ||
    viewPort.topLeft.y > viewPort.bottomRight.y
  ) {
    throw new Error('Invalid viewport');
  }
};

/**
 * Returns the lowest value in a subgrid.
 *
 * @param grid The whole grid
 * @param topLeft The top left corner of the subgrid
 * @param bottomRight The bottom right corner of the subgrid
 * @returns The lowest value in the subgrid
 */
function findLowestInSubgrid(
  grid: number[][],
  topLeft: TPosition,
  bottomRight: TPosition
) {
  let minValue = Infinity;

  for (let row = topLeft.y; row <= bottomRight.y; row++) {
    for (let col = topLeft.x; col <= bottomRight.x; col++) {
      if (grid[row]?.[col] === undefined) {
        console.error('Grid is not defined at', row, col);
        return Infinity;
      }
      if (grid[row]![col]! < minValue) {
        minValue = grid![row]![col]!;
      }
    }
  }

  return minValue;
}

export const useImageSizeIndexMap = (): TImageSizeIndexMapReturn => {
  const measurementRunIdToHighestLoadedIndexMap = useReactiveVar(
    measurementRunIdToHighestLoadedIndex
  );

  /**
   * Initializes the grid for a given measurement run ID.
   *
   * @param {number} measurementRunId - The ID of the measurement run.
   * @param {TGridSize} gridSize - The size of the grid.
   * @returns {Map<number, number[][]>} The updated map.
   */
  function initializeGrid(measurementRunId: number, gridSize: TGridSize) {
    const grid = Array.from({ length: gridSize.row }, () =>
      Array(gridSize.column).fill(NOT_LOADED_IMAGE_SIZE_INDEX)
    );
    measurementRunIdToHighestLoadedIndexMap.set(measurementRunId, grid);
    measurementRunIdToHighestLoadedIndex(
      measurementRunIdToHighestLoadedIndexMap
    );
    return measurementRunIdToHighestLoadedIndexMap.get(measurementRunId)!;
  }

  /**
   * Updates the measurement run ID to highest loaded index map.
   *
   * @param {number} measurementRunId - The measurement run ID.
   * @param {number} imageSizeIndex - The new image size index.
   * @param {TGridSize} gridSize - The size of the grid.
   * @param {number} row - The row of the image.
   * @param {number} column - The column of the image.
   */
  function updateImageSizeIndexMap(
    measurementRunId: number,
    imageSizeIndex: number,
    gridSize: TGridSize,
    row: number,
    column: number
  ) {
    let prevGrid =
      measurementRunIdToHighestLoadedIndexMap.get(measurementRunId);
    if (!prevGrid) {
      prevGrid = initializeGrid(measurementRunId, gridSize);
    }
    const prevMaxIndex = prevGrid[row]![column]!;
    prevGrid[row]![column] = Math.max(prevMaxIndex, imageSizeIndex);

    measurementRunIdToHighestLoadedIndexMap.set(measurementRunId, prevGrid);
    measurementRunIdToHighestLoadedIndex(
      measurementRunIdToHighestLoadedIndexMap
    );
  }

  /**
   * Returns the index of the lowest image size for a given measurement run ID within a viewport.
   *
   * @param {number} measurementRunId - The ID of the measurement run.
   * @param {TGridSize} gridSize - The size of the grid.
   * @param {{ topLeft: TPosition; bottomRight: TPosition }} viewPort - The current viewport.
   * @returns {number} The index of the highest image size.
   */
  function getLowestImageSizeIndexWithinViewport(
    measurementRunId: number,
    gridSize: TGridSize,
    viewPort: {
      topLeft: TPosition;
      bottomRight: TPosition;
    }
  ) {
    validateViewport(viewPort);
    if (!measurementRunIdToHighestLoadedIndexMap.get(measurementRunId)) {
      initializeGrid(measurementRunId, gridSize);
      return NOT_LOADED_IMAGE_SIZE_INDEX;
    }
    return findLowestInSubgrid(
      measurementRunIdToHighestLoadedIndexMap.get(measurementRunId)!,
      viewPort.topLeft,
      viewPort.bottomRight
    );
  }

  /**
   * Returns the current image size index for a given measurement run ID.
   *
   * @param measurementRunId The ID of the measurement run.
   * @param gridSize The size of the grid.
   * @param row The row of the image.
   * @param column The column of the image.
   * @returns The index of the current image size.
   */
  function getCurrentImageSizeIndex(
    measurementRunId: number,
    gridSize: TGridSize,
    row: number,
    column: number
  ) {
    let index =
      measurementRunIdToHighestLoadedIndexMap.get(measurementRunId)?.[row]?.[
        column
      ];
    if (index === undefined) {
      initializeGrid(measurementRunId, gridSize);
      index = NOT_LOADED_IMAGE_SIZE_INDEX;
    }
    return index;
  }

  return {
    updateImageSizeIndexMap,
    getLowestImageSizeIndexWithinViewport,
    getCurrentImageSizeIndex,
  };
};
