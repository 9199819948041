import { useGetHeatMapById, useGetHeatMaps } from 'api/heatmap';
import { closestTo } from 'date-fns';
import { useScreenSize } from 'hooks/useScreenSize';
import { useZones } from 'hooks/useZones';
import { useMemo } from 'react';
import { TGrowthCycle } from 'shared/interfaces/growthCycle';
import { EAggregationTypes, EGradientTypes } from 'shared/interfaces/heatmap';
import { MeasurementTypeConfig } from 'shared/interfaces/measurement';
import { getZoneImageUrl } from 'shared/utils/getters';
import { getHeatMapScale } from 'shared/utils/heatmap';
import { useHeatMapGradientValues } from './useHeatMapGradientValues';
import { useHeatMapOptimalRanges } from './useHeatMapOptimalRanges';
import { useHeatMapPlotData } from './useHeatMapPlotData';
import { useHeatMapStepValueStyles } from './useHeatMapStepValueStyles';

export const useHeatMap = ({
  typeConfig,
  selectedTime,
  startTime,
  endTime,
  aggregationType,
  gradientType,
  width,
  height,
  zoneId,
  growthCycle,
}: {
  typeConfig: MeasurementTypeConfig;
  selectedTime: Date;
  aggregationType: EAggregationTypes;
  gradientType: EGradientTypes;
  width: number;
  height: number;
  zoneId?: number;
  startTime?: number;
  endTime?: number;
  growthCycle: Maybe<TGrowthCycle>;
}) => {
  const { zones } = useZones();
  const { isMobile } = useScreenSize();
  const zone = zones.find((zone) => zone.id === zoneId);
  const zoneTimeZone = zone?.timeZone;
  const {
    loading: loadingMeasurement,
    globalStatistic,
    frameList,
  } = useGetHeatMaps({
    zoneUid: zone?.uid,
    zoneId: zone?.id,
    zoneTimeZone,
    start: startTime || growthCycle?.start_time,
    end: endTime || growthCycle?.end_time,
    enumeration: isMobile
      ? typeConfig.heatMapCodes?.mobile!
      : typeConfig.heatMapCodes?.desktop!,
    typeConfig,
    aggregationType,
  });

  const timelineDates = useMemo(
    () => frameList.map(({ startTime }) => startTime),
    [frameList]
  );

  const selectedMeasurementRunStarTime = useMemo(() => {
    return closestTo(new Date(selectedTime), timelineDates) ?? selectedTime;
  }, [selectedTime, timelineDates]);

  const lightInfo = useMemo(
    () => growthCycle?.metadata?.light_info || [],
    [growthCycle?.metadata?.light_info]
  );

  const { optimalRanges } = useHeatMapOptimalRanges({
    measurementType: typeConfig.statisticsKey,
    currentTime:
      selectedMeasurementRunStarTime &&
      new Date(selectedMeasurementRunStarTime),
    lightInfo,
    selectedCycle: growthCycle || null,
  });

  const currentFrame = frameList.find(
    ({ startTime }) =>
      startTime.valueOf() === selectedMeasurementRunStarTime.valueOf()
  );

  const heatMapId = currentFrame?.heatMapId;

  const { plotData, loading: loadingPlotData } = useGetHeatMapById({
    id: heatMapId,
    typeConfig,
    aggregationType,
  });

  const statistics = useMemo(() => {
    if (!plotData) {
      return null;
    }

    const { min, max } = plotData.statistic;

    // Offset is used when the min and max are the same.
    // When min and max are equal it causes issues when rendering the color gradient for heatmap.
    const offset = min === max ? 0.0001 : 0;
    return {
      min: min ?? 0,
      max: max !== null ? max + offset : 0 + offset,
    };
  }, [plotData]);

  const heatMapScale = useMemo(
    () =>
      getHeatMapScale({
        currentRunStatistic: statistics,
        globalStatistic,
        gradientType,
        typeConfig,
        optimalRanges,
      }),
    [globalStatistic, statistics, typeConfig, optimalRanges, gradientType]
  );

  const { gradientScaleValues } = useHeatMapGradientValues({
    gradientType,
    heatMapScale,
  });

  const { heatMapScaleValues } = useHeatMapStepValueStyles({
    gradientType,
    heatMapScale,
    statistics,
  });

  const layoutImageUrl = getZoneImageUrl(zone);
  const { heatMapPlotInformation } = useHeatMapPlotData({
    typeConfig,
    colorScale: heatMapScaleValues,
    layoutImageUrl,
    plotData,
    unit: typeConfig.unit,
    isMobile,
    width,
    height,
  });

  return {
    heatMapPlotInformation,
    heatMapScale,
    gradientScaleValues,
    loading: loadingPlotData || loadingMeasurement,
    frameList,
    currentFrame,
    heatMapId,
  };
};
