import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const LeafYellowingIcon = ({
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={cn('h-6 w-6', className)}
    {...props}
  >
    <path d="M12 15L12 23" />
    <path d="M15 15.5C15 20.7575 12 21 12 21C12 21 6.62499 17 8.49998 12.4999C10.375 7.99979 9.00004 4.5002 12 2.00003C14.9547 -0.462377 15 10.2425 15 15.5Z" />
  </svg>
);

export { LeafYellowingIcon };
