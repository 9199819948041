import { useGetZonesByOrganizationCodeQuery } from 'graphql/generated/react_apollo';
import { useMemo } from 'react';
import { DEFAULT_TIME_ZONE } from 'shared/constants/general';
import { TZone } from 'shared/interfaces/zone';

export const useGetZonesByOrganizationCode = ({
  organizationCode,
  hideInactiveZones = false,
  isVisible,
}: {
  organizationCode: Maybe<string>;
  hideInactiveZones: boolean;
  isVisible: (
    organizationCode: string,
    locationId: number,
    zoneUid: string
  ) => boolean;
}) => {
  const { data, ...result } = useGetZonesByOrganizationCodeQuery({
    variables: {
      code: organizationCode!,
      hideInactiveZones,
    },
    skip: !organizationCode,
  });

  const zones: TZone[] = useMemo(
    () =>
      (data?.zone ?? [])
        .map((zone) => {
          const timeZone = zone.location?.time_zone_name ?? DEFAULT_TIME_ZONE;
          return {
            id: zone.id,
            code: zone.enumeration.code,
            label: zone.enumeration.description,
            metadata: zone.metadata,
            timeZone,
            locationId: zone.location_id,
            locationName: zone.location.enumeration.description,
            organizationCode: organizationCode!,
            uid: zone.uid,
          };
        })
        .filter(({ organizationCode, locationId, uid }) =>
          isVisible(organizationCode, locationId, uid)
        ),
    [data?.zone, isVisible, organizationCode]
  );

  return { ...result, zones };
};
