import { ComponentPropsWithoutRef, forwardRef } from 'react';

/**
 * `FloatingAs` wrapper forces the floating element not to be transformed in breakpoints smaller than `sm`.
 * This is necessary so @headlessui Popover can be used as a fullscreen floating modal for the `RangePicker`
 */
export const FloatingAs = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'>
>(function FloatingAs(props, ref) {
  return (
    <div
      ref={ref}
      {...props}
      className="none:max-sm:!translate-x-0 none:max-sm:!translate-y-0"
    />
  );
});
