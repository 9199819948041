import { CheckIcon } from 'lucide-react';
import { FC } from 'react';
import { TPasswordRule } from 'shared/interfaces/general';
import { cn } from 'shared/utils/cn';

interface IPasswordRulesProps {
  /**
   * ConfirmPassword
   */
  confirmPassword: string;
  /**
   * Password
   */
  password: string;
  /**
   * Rules to check in the component.
   */
  rules: TPasswordRule[];
}

/**
 * PasswordRules component.
 *
 * @param {IPasswordRulesProps} - The component props.
 * @returns {JSX.Element} - The rendered component.
 */
export const PasswordRules: FC<IPasswordRulesProps> = ({
  confirmPassword,
  password,
  rules,
}) => {
  return (
    <ul className="mt-7 flex flex-col gap-3">
      {rules.map((rule) => (
        <li key={rule.label} className="flex">
          <span
            className={cn(
              'mr-4 text-green-700',
              !rule.check(password, confirmPassword) && 'text-neutral-300'
            )}
          >
            <CheckIcon className="stroke-[1.5px]" />
          </span>
          <span>{rule.label}</span>
        </li>
      ))}
    </ul>
  );
};
