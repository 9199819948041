import { useGetMeasurementsByTypeAndTimeRange } from 'api/measurement';
import { useGetSystemByZoneUid } from 'api/system';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { differenceInDays, endOfDay, startOfDay } from 'date-fns';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useGrowthCycles } from 'hooks/useGrowthCycles';
import { SpyderStatusIcon } from 'icons/SpyderStatusIcon';
import { MinusIcon, MoonIcon, PlusIcon, SunIcon } from 'lucide-react';
import pluralize from 'pluralize';
import {
  ComponentPropsWithoutRef,
  forwardRef,
  Fragment,
  useMemo,
  useState,
} from 'react';
import { TGrowthCycle } from 'shared/interfaces/growthCycle';
import {
  EMeasurementGroup,
  MeasurementAggregation,
  MeasurementTypeConfig,
} from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';
import { getDayRange, isBetween, isLight } from 'shared/utils/getters';
import { getLightHours, getOptimalRange } from 'shared/utils/growthCycle';
import { smartRound } from 'shared/utils/miscellaneous';

export const OverallStatus = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'>
>(function OverallStatus({ className, ...props }, ref) {
  const [showAll, setShowAll] = useState(false);
  const { staticSignals } = useTypeConfig();
  const { currentZone, currentTimeInCurrentZone, zoneTimeZone } =
    useCurrentZone();
  const { system } = useGetSystemByZoneUid({
    zoneUid: currentZone?.uid,
    currentTime: currentTimeInCurrentZone,
    zoneTimeZone,
  });
  const { spyderAriaLabel, spyderMessage, spyderStatus } = useMemo(() => {
    const spyderStatus = system?.status ?? 'DYNAMIC';
    const spyderMessage = system
      ? `${system.statusElapsedTimeSinceCreation} ago`
      : '';
    return {
      spyderAriaLabel: `Spyder status is ${spyderStatus} for ${system?.statusElapsedTimeSinceCreation} ago`,
      spyderMessage,
      spyderStatus,
    };
  }, [system]);
  const { currentCycle, latestCycle } = useGrowthCycles();
  const ongoingCycle = currentCycle ?? latestCycle;
  const {
    currentWeek,
    cycleEnded,
    daysPastHarvestMessage,
    harvestPercentage,
    harvestStatusMessage,
    currentLightsStatus,
    LightCycleIcon,
    nextLightCycleIn,
  } = useMemo(() => {
    return {
      ...getHarvest(ongoingCycle, currentTimeInCurrentZone),
      ...getLightCycleStatus(ongoingCycle, currentTimeInCurrentZone),
    };
  }, [currentTimeInCurrentZone, ongoingCycle]);
  const cultivars = useMemo(
    () =>
      (ongoingCycle?.cultivars ?? [])
        .map(({ strainName, count }) => `${strainName} (${count ?? 0})`)
        .join(', '),
    [ongoingCycle?.cultivars]
  );
  const signals = staticSignals.filter(
    ({ group }) => group === EMeasurementGroup.Environment
  );
  const measurementsQuery = useGetMeasurementsByTypeAndTimeRange({
    zoneId: currentZone!.id,
    zoneUid: currentZone!.uid,
    zoneTimeZone: currentZone!.timeZone,
    start: startOfDay(currentTimeInCurrentZone),
    end: endOfDay(currentTimeInCurrentZone),
    aggregation: MeasurementAggregation.CONTINUOUS,
    signals,
  });
  const conditions = useMemo(() => {
    const dayRange = getDayRange(
      ongoingCycle?.metadata?.light_info,
      currentTimeInCurrentZone
    );
    const conditions: [MeasurementTypeConfig, string, boolean][] = [];
    for (const [signal, data] of measurementsQuery.data) {
      if (data.length > 0) {
        const parameterRanges = getOptimalRange(
          currentTimeInCurrentZone,
          signal.statisticsKey,
          dayRange,
          ongoingCycle
        );
        // grab the latest value (they're expected to be sorted at this point)
        const value = smartRound(data.at(-1)!.at(1)!);
        const isOutOfRange = !parameterRanges
          ? true
          : !isBetween(
              value,
              parameterRanges.warning_range.min,
              parameterRanges.warning_range.max
            );
        conditions.push([signal, `${value} ${signal.unit}`, isOutOfRange]);
      } else {
        conditions.push([signal, `- ${signal.unit}`, false]);
      }
    }

    // add missing any signal with an empty value
    for (const signal of signals) {
      if (!conditions.some(([s]) => s.type === signal.type)) {
        conditions.push([signal, `- ${signal.unit}`, false]);
      }
    }

    return conditions.toSorted(
      ([a], [b]) => (a.order ?? Infinity) - (b.order ?? Infinity)
    );
  }, [currentTimeInCurrentZone, measurementsQuery.data, ongoingCycle, signals]);

  if (!ongoingCycle) {
    return null;
  }

  return (
    <div
      ref={ref}
      {...props}
      className={cn(
        'flex flex-col divide-y-[1px] divide-ash-200 text-gray-900',
        className
      )}
    >
      <div className="py-3 xl:py-4 px-4 xl:px-6  flex flex-col gap-2 xl:gap-3 rounded-t bg-white">
        <div className="grid grid-cols-2 gap-x-1 items-center">
          <h3 className="lg:text-xl xl:text-2xl font-semibold">{`Week ${currentWeek}`}</h3>
          <div
            className={cn(
              'relative h-[26px] xl:h-[29px] w-full rounded-full border-[1.5px] border-ash-100',
              cycleEnded && 'border-green-200'
            )}
          >
            <div
              className={cn(
                'absolute h-full min-w-3 rounded-s-full bg-ash-100',
                cycleEnded && 'rounded-full bg-green-200'
              )}
              style={{ width: `${harvestPercentage}%` }}
            />
            <div
              className={cn(
                'absolute h-full w-full flex items-center justify-center',
                'text-xs xl:text-sm bg-transparent',
                cycleEnded
              )}
            >
              {harvestStatusMessage}
            </div>
          </div>
          {cycleEnded && (
            <div className="col-span-2 text-sm text-gray-500">
              {daysPastHarvestMessage}
            </div>
          )}
        </div>
        <div className={'flex flex-col lg:flex-row gap-1 lg:gap-3'}>
          <h3 className="lg:w-1/2 text-sm xl:text-base font-semibold">
            Cultivars
          </h3>
          <div className="lg:w-1/2 text-sm xl:text-base">{cultivars}</div>
        </div>
      </div>
      <div
        className={cn(
          'py-3 xl:py-4 px-4 xl:px-6 hidden lg:flex gap-3 items-center bg-white',
          showAll && 'flex'
        )}
      >
        <h3 className="lg:w-1/2 text-sm xl:text-base font-semibold">Spyder</h3>
        <div className="lg:w-1/2 flex gap-2 items-center text-sm xl:text-base">
          <SpyderStatusIcon
            aria-hidden={false}
            aria-label={spyderAriaLabel}
            status={spyderStatus}
            className={cn(
              'icon size-5 xl:size-6',
              spyderStatus === 'STATIC' && 'stroke-orange-400',
              ['DYNAMIC', 'STATIONARY'].includes(spyderStatus) &&
                'stroke-green-500',
              ['HMI_PAUSED', 'HMI_HOME', 'IDLE_AFTER_AUTO_RESTART'].includes(
                spyderStatus
              ) && 'stroke-gray-500',
              ['E_STOP_PRESSED', 'UNKNOWN'].includes(spyderStatus) &&
                'stroke-red-500'
            )}
          />
          {spyderMessage}
        </div>
      </div>
      <div
        className={cn(
          'py-3 xl:py-4 px-4 xl:px-6 hidden lg:flex flex-col gap-1 xl:gap-3 bg-white lg:rounded-b',
          showAll && 'flex'
        )}
      >
        <h3 className="text-sm xl:text-base font-semibold">
          Current room conditions
        </h3>
        <div
          className="grid grid-cols-2 gap-x-3 gap-y-1 lg:gap-y-2 xl:gap-y-3 text-sm xl:text-base"
          role="list"
        >
          <div className="flex gap-2 items-center">
            {`Lighs are ${currentLightsStatus}`}
            <LightCycleIcon className="icon" />
          </div>
          <div>{nextLightCycleIn}</div>
          {conditions.map(([signal, value, isOutOfRange]) => (
            <Fragment key={signal.label}>
              <div aria-hidden="true">{signal.label}</div>
              <div
                role="listitem"
                aria-label={`${signal.label} is ${value}`}
                className={cn(isOutOfRange && 'text-red-500 font-bold')}
              >
                {value}
              </div>
            </Fragment>
          ))}
        </div>
      </div>
      <button
        className="lg:hidden py-3 px-4 flex gap-2 items-center justify-between rounded-b text-sm font-medium bg-sand-400 border-transparent"
        onClick={() => setShowAll((prev) => !prev)}
      >
        <div>{`${showAll ? 'Hide' : 'Show'} current room conditions`}</div>
        {showAll ? (
          <MinusIcon className="icon" />
        ) : (
          <PlusIcon className="icon" />
        )}
      </button>
    </div>
  );
});

function getHarvest(cycle: Nullable<TGrowthCycle>, currentTime: Date) {
  if (!cycle) {
    return {
      currentWeek: null,
      cycleEnded: null,
      daysPastHarvestMessage: null,
      harvestPercentage: null,
      harvestStatusMessage: null,
    };
  }

  const cycleTotalDays = differenceInDays(cycle.end_time, cycle.start_time);
  const currentDay = differenceInDays(currentTime, cycle.start_time) + 1;
  const currentWeek = Math.floor(currentDay / 7) + 1;
  const daysPastHarvest = currentDay - cycleTotalDays - 1;
  const daysPastHarvestMessage = `${daysPastHarvest} ${pluralize('day', daysPastHarvest)} past estimated harvest`;
  const harvestPercentage = Math.min(currentDay / cycleTotalDays, 1) * 100;
  const cycleEnded = currentDay >= cycleTotalDays;
  const harvestStatusMessage = cycleEnded
    ? 'End of cycle'
    : `Day ${currentDay} / ${cycleTotalDays}`;

  return {
    currentWeek,
    cycleEnded,
    daysPastHarvestMessage,
    harvestPercentage,
    harvestStatusMessage,
  };
}

function getLightCycleStatus(cycle: Nullable<TGrowthCycle>, currentTime: Date) {
  if (!cycle) {
    return {
      currentLightsStatus: null,
      LightCycleIcon: SunIcon,
      nextLightCycleIn: null,
    };
  }

  const lightInfo = cycle.metadata?.light_info || [];
  const isDaytime = isLight(lightInfo, currentTime);
  const { lightHours, currentTimeOffsetInHours } = getLightHours(
    currentTime,
    lightInfo
  );
  const isLightOn = currentTimeOffsetInHours < lightHours;
  const hours = isLightOn
    ? lightHours - currentTimeOffsetInHours
    : 24 - currentTimeOffsetInHours;
  const minutes = Math.round((hours - Math.floor(hours)) * 60);
  const nextLightsStatus = !isDaytime ? 'on' : 'off';
  const nextLightCycleIn = `${Math.floor(hours) ?? '0'}h${minutes ?? '00'}m until ${nextLightsStatus}`;

  return {
    currentLightsStatus: isDaytime ? 'on' : 'off',
    LightCycleIcon: isDaytime ? SunIcon : MoonIcon,
    nextLightCycleIn,
  };
}
