import {
  useGetImageFeedMeasurementRuns,
  useGetMeasurementsByRunIdsAndPosition,
} from 'api/measurement';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { usePrevious } from 'hooks/usePrevious';
import { useMemo } from 'react';
import { EImageTypes, ISectionCapture } from 'shared/interfaces/image';
import { useSectionCaptureList } from './useSectionCaptureList';

interface IGetSectionMeasurementsByZoneIdProps {
  /** The measurement range start time. */
  startTime?: number;
  /** The measurement range end time. */
  endTime?: number;
  /** The grid size. */
  gridSize: TGridSize;
  /** The image type, NDVI, RGB. */
  imageType: EImageTypes;
  /** The position of specified section. */
  position: TPosition;
  /** The zone id. */
  zoneId: string;
}

interface ISectionMeasurementsByZoneIdReturn {
  /** The capture list. */
  captures: ISectionCapture[];
}

/**
 * Gets the recent measurement runs that has specified section information by zone Id.
 *
 * @param {IGetSectionMeasurementsByZoneId} props - The measurement run query props
 * @returns {number[]} - The measurementIds which include certain section
 */
export const useGetSectionMeasurementsByZoneId = ({
  startTime,
  endTime,
  imageType,
  position,
  zoneId,
  gridSize,
}: IGetSectionMeasurementsByZoneIdProps): ISectionMeasurementsByZoneIdReturn => {
  const { zoneTimeZone } = useCurrentZone();

  const { measurementRuns } = useGetImageFeedMeasurementRuns(
    zoneTimeZone,
    startTime,
    endTime,
    zoneId
  );

  const { measurementIds } = useGetMeasurementsByRunIdsAndPosition({
    measurementRunIds: measurementRuns.map((run) => run.id),
    position,
    gridSize,
    imageType,
  });

  const currentCaptures = useSectionCaptureList({
    measurementIds,
    position,
    zoneId,
    imageType,
  });

  const previousCaptures = usePrevious(currentCaptures);

  const captures = useMemo(() => {
    return currentCaptures.length
      ? currentCaptures
      : (previousCaptures ?? currentCaptures);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCaptures]);

  return {
    captures,
  };
};
