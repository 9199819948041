import {
  ComponentPropsWithoutRef,
  forwardRef,
  MouseEventHandler,
  ReactNode,
} from 'react';
import { cn } from 'shared/utils/cn';
import { Label, LabelProps } from '../Label/Label';
import { StatusText } from '../StatusText/StatusText';

export interface SwitchProps
  extends Omit<ComponentPropsWithoutRef<'button'>, 'children' | 'onChange'> {
  /** Whether the switch is on or off. */
  checked?: boolean;
  /** When provided wraps the switch button in an HTML label element. */
  label: LabelProps['value'];
  /** Sets the placement of the radio label. Defaults to `right`. */
  labelPlacement?: LabelProps['placement'];
  /** Sets the className of the label. */
  labelClassName?: LabelProps['className'];
  /** An optional status text displayed below the field  */
  helperText?: ReactNode;
  /** An optional status text displayed below the field  */
  errorText?: ReactNode;
  /** Called when the switch or the label (when present) are clicked */
  onChange: (checked: boolean) => void;
}

/**
 * A switch is an input widget that allows users to choose one of two values: on or off.
 * Here we use a HTML button as the switch element and a `div` to provide graphical rendering of switch states.
 *
 * Inspired by https://www.w3.org/WAI/ARIA/apg/patterns/switch/examples/switch-button/
 */
export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  function Switch(
    {
      checked,
      className,
      label,
      labelPlacement = 'right',
      labelClassName,
      disabled,
      helperText,
      errorText,
      onChange,
      ...props
    },
    ref
  ) {
    const ariaLabel = typeof label === 'string' ? label : props['aria-label'];

    const handleClickLabel: MouseEventHandler<HTMLElement> = (event) => {
      event.preventDefault();
      onChange(!checked);
    };

    return (
      <fieldset className="group/switch flex flex-col gap-1">
        <Label
          value={label}
          placement={labelPlacement}
          className={labelClassName}
          disabled={disabled}
          onClick={handleClickLabel}
        >
          <button
            ref={ref}
            {...props}
            role="switch"
            disabled={disabled}
            aria-checked={checked ? 'true' : 'false'}
            aria-label={ariaLabel}
            className={cn(
              'inline-flex w-10 p-1 rounded-full bg-gray-300 group/switch-button',
              !checked && [
                'hover:bg-gray-400 active:bg-gray-500',
                disabled && 'hover:bg-gray-300 active:bg-gray-300',
              ],
              checked && [
                'bg-orange-400 hover:bg-orange-500 active:bg-orange-600',
                disabled && 'hover:bg-orange-400 active:bg-orange-400',
              ],
              errorText && 'bg-red-100 hover:bg-red-100 active:bg-red-100',
              className
            )}
          >
            <div
              className={cn(
                'size-4 rounded-full bg-white transition',
                !checked &&
                  'translate-x-0 group-disabled/switch-button:bg-gray-400',
                checked &&
                  'translate-x-4 group-disabled/switch-button:bg-orange-200',
                errorText && ['bg-red-50', checked && 'bg-red-500']
              )}
            />
          </button>
        </Label>
        {helperText && !errorText && (
          <StatusText className="block">{helperText}</StatusText>
        )}
        {errorText && (
          <StatusText className="text-red-500 block">{errorText}</StatusText>
        )}
      </fieldset>
    );
  }
);
