import { Input, InputProps } from 'components/common/Input/Input';
import { forwardRef } from 'react';

export interface PresetNameProps extends InputProps {}

/**
 * The preset name
 */
export const PresetName = forwardRef<HTMLInputElement, PresetNameProps>(
  function PresetName({ value, ...props }, ref) {
    return (
      <div className="w-full flex flex-col gap-2 p-4 my-4 bg-neutral-200 rounded-sm">
        <p className="font-semibold" id="preset-name">
          Preset name
        </p>
        <Input
          ref={ref}
          minLength={5}
          required
          errorText="The preset name is required and should have a minimum of 5 characters."
          {...props}
          aria-labelledby="preset-name"
          type="text"
          value={value}
        />
      </div>
    );
  }
);
