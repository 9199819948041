import { ZOOM_LEVELS } from 'components/image_feed/constants';
import Konva from 'konva';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

interface Stage {
  stage: Konva.Stage | null;
  scale: TScale;
  position: TPosition;
  size: TSize;
  initStage: (scale: TScale, position: TPosition) => void;
  setStage: (node: Konva.Stage | null) => void;
  setScale: (scale: TScale) => void;
  setPosition: (position: TPosition) => void;
  setPositionAndScale: (position: TPosition, scale: TScale) => void;
}

const DEFAULT_SCALE = {
  x: ZOOM_LEVELS.NORMAL,
  y: ZOOM_LEVELS.NORMAL,
};

const DEFAULT_POSITION = { x: 0, y: 0 };

const DEFAULT_SIZE = { width: 0, height: 0 };

const StageContext = createContext<Stage>(undefined!);

export const StageProvider = ({
  children,
}: {
  id: number | string | undefined;
  children: ReactNode;
}) => {
  const [stage, setStage] = useState<Konva.Stage | null>(null);
  const [{ scale, position }, setScaleAndPosition] = useState<{
    scale: TScale;
    position: TPosition;
  }>({
    scale: DEFAULT_SCALE,
    position: DEFAULT_POSITION,
  });
  const [size, setSize] = useState<TSize>(DEFAULT_SIZE);

  useEffect(() => {
    if (!stage) {
      return;
    }
    stage.scale(scale);
    stage.position(position);
    stage.batchDraw();
  }, [scale, position, stage]);

  const updateScale = useCallback(
    (scale: TScale) => {
      if (!stage) return;

      setScaleAndPosition((state) => ({ ...state, scale }));
    },
    [stage]
  );

  const updatePosition = useCallback(
    (position: TPosition) => {
      if (!stage) {
        return;
      }

      setScaleAndPosition((state) => ({ ...state, position }));
    },
    [stage]
  );

  const updatePositionAndScale = useCallback(
    (position: TPosition, scale: TScale) => {
      if (!stage) {
        return;
      }

      setScaleAndPosition({ position, scale });
    },
    [stage]
  );

  const updateSize = useCallback(
    ({ width, height }: TSize) => {
      if (
        !stage ||
        !width ||
        !height ||
        (width === size.width && height === size.height)
      ) {
        return;
      }

      setSize({ width, height });

      stage.width(width);
      stage.height(height);
    },
    [size, stage]
  );

  const initStage = useCallback(
    (scale: TScale, position: TPosition) => {
      updateScale(scale);
      updatePosition(position);
    },
    [updateScale, updatePosition]
  );

  /**
   * We have to make sure we sync the Stage's size whenever Stage's container size changes
   * because react-konva/konvajs requires width and height as parameters
   * to correctly initialise <canvas /> element.
   *
   * To avoid leaking this logic somewhere in the consumer
   * we will take the Stage's parent container as a reference size.
   */
  useEffect(() => {
    if (!stage) {
      return;
    }

    const onResize: ResizeObserverCallback = (entries) => {
      const stageParent = entries[0];

      if (!stageParent) return;

      const { width, height } = stageParent.contentRect;

      updateSize({ width, height });
    };

    const resizeObserver = new ResizeObserver(onResize);

    resizeObserver.observe(stage.attrs.container);
    return () => resizeObserver.unobserve(stage.attrs.container);
  }, [stage, updateSize]);

  return (
    <StageContext.Provider
      value={{
        stage,
        scale,
        position,
        size,
        initStage,
        setStage: setStage,
        setScale: updateScale,
        setPosition: updatePosition,
        setPositionAndScale: updatePositionAndScale,
      }}
    >
      {children}
    </StageContext.Provider>
  );
};

export const useStageProvider = () => {
  return useContext(StageContext);
};
