import isNil from 'lodash.isnil';
import { ArrowDownIcon, ArrowRightIcon } from 'lucide-react';
import React from 'react';
import {
  EGradientTypes,
  THeatMapScaleDataType,
  TScaleColorizedValue,
} from 'shared/interfaces/heatmap';
import { MeasurementTypeConfig } from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';

export interface IHeatMapGradientProps {
  /** */
  className?: string;
  /** Heatmap types. */
  gradientType: EGradientTypes;
  /** Heatmap step values in the gradient. */
  heatMapScale: THeatMapScaleDataType;
  /** The Measurement Option user selected. */
  typeConfig: MeasurementTypeConfig;
  /** The scalue values to render gradient. */
  scaleValues: TScaleColorizedValue[];
  /** Whether is there any content to render */
  visible: boolean;
}

export const HeatMapGradient: React.FC<IHeatMapGradientProps> = ({
  className,
  gradientType,
  heatMapScale,
  typeConfig,
  scaleValues,
  visible,
}) => {
  const { unit, hasOptimalRange } = typeConfig;

  const firstStepValue = heatMapScale.lowerBound;
  const lastStepValue = heatMapScale.upperBound;

  const totalDiff = lastStepValue - firstStepValue;

  const diffPercentValue = (value1: number, value2: number): number => {
    return (value2 - value1) / totalDiff;
  };

  const diffValuePercentString = (value1: number, value2: number) => {
    return `${diffPercentValue(value1, value2) * 100}%`;
  };

  const renderSetPoint = () => {
    if (isNil(heatMapScale.setPoint)) {
      return;
    }
    const setPointStyleLeft = diffValuePercentString(
      heatMapScale.lowerBound,
      heatMapScale.setPoint
    );

    const setPointAlignItems = () => {
      if (setPointStyleLeft === '0%') {
        return 'flex-start';
      } else if (setPointStyleLeft === '100%') {
        return 'flex-end';
      } else {
        return undefined;
      }
    };

    if (!hasOptimalRange) {
      return;
    }

    const pointValue = (
      <p className="text-sm" data-testid="heatmap-gradient-set-point-value">
        {`${parseFloat(heatMapScale.setPoint.toFixed(2))} ${unit}`}
      </p>
    );

    if (heatMapScale.setPoint > heatMapScale.upperBound) {
      return (
        <div className="absolute flex flex-col gap-1 top-1 right-0 items-end">
          <ArrowRightIcon className="stroke-[1.5px]" />
          {pointValue}
        </div>
      );
    }

    if (heatMapScale.setPoint < heatMapScale.lowerBound) {
      return (
        <div className="absolute flex flex-col gap-1 top-1 left-0 items-start">
          <ArrowRightIcon className="stroke-[1.5px] -rotate-180 transform text-green-700" />
          {pointValue}
        </div>
      );
    }

    return (
      <div
        className="absolute flex flex-col gap-1 top-1"
        style={{ left: setPointStyleLeft, alignItems: setPointAlignItems() }}
      >
        <ArrowDownIcon className="stroke-[1.5px] -rotate-180 transform text-green-700" />
        {pointValue}
      </div>
    );
  };

  const renderHeatMapEllipse = () => {
    return (
      <div
        className="absolute top-6 h-6 rounded-md border-2 border-neutral-500"
        style={{
          left: diffValuePercentString(
            heatMapScale.lowerBound,
            heatMapScale.currentRunLowerBound
          ),
          width: diffValuePercentString(
            heatMapScale.currentRunLowerBound,
            heatMapScale.currentRunUpperBound
          ),
        }}
      />
    );
  };

  return visible ? (
    <div
      className={cn('relative min-h-24 w-full px-1', className)}
      data-testid="heatmap-gradient"
    >
      <div>
        <div className="relative w-full h-8 flex justify-between">
          {Object.entries({
            lower: heatMapScale.lowerBound,
            upper: heatMapScale.upperBound,
          }).map(([key, value], index) => (
            <p
              key={index}
              className="text-sm"
              data-testid={`heatmap-gradient-${key}-bound`}
            >
              {`${parseFloat(value.toFixed(2))} ${unit}`}
            </p>
          ))}
        </div>

        <div
          className="relative w-full h-2 rounded-sm overflow-hidden"
          style={{
            background: `linear-gradient(90deg, ${scaleValues
              .map((value) => `${value[1]} ${value[0] * 100}%`)
              .join(', ')})`,
          }}
        />

        {!isNil(heatMapScale.setPoint) && (
          <div className="relative flex mt-1">{renderSetPoint()}</div>
        )}
      </div>
      <div className="absolute inset-0 overflow-hidden">
        {gradientType !== EGradientTypes.MICROCLIMATES &&
          renderHeatMapEllipse()}
      </div>
    </div>
  ) : null;
};
