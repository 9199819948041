export function getTrackedSearchParams(
  search: string,
  keysThatShouldBeTracked: string[]
): Record<string, string[]> {
  const params = new URLSearchParams(search);

  const trackedKeys = [...params.keys()].filter((k) =>
    keysThatShouldBeTracked.includes(k)
  );

  return trackedKeys.reduce(
    (pairs, key) => ({
      ...pairs,
      // We prefix all search params with sp_, so we are not running into potential
      // conflicts with other parameters that are being added to the events.
      [`sp_${key}`]: params.getAll(key),
    }),
    {}
  );
}
