import { useGrowthCycleReport } from 'hooks/useGrowthCycleReport';
import { cn } from 'shared/utils/cn';

export const GrowthCycleReportCultivarAndNotes = () => {
  const { selectedGrowthCycle, cultivars, notes } = useGrowthCycleReport();

  if (!selectedGrowthCycle || (cultivars.length === 0 && !notes)) {
    return null;
  }

  return (
    <div className="flex gap-6">
      {!!cultivars.length && (
        <div className="flex flex-col gap-2 basis-1/2">
          <h2 className="text-xl">Cultivar</h2>
          <div className="flex flex-col gap-[2px]">
            {cultivars.map((cultivar) => {
              return (
                <div
                  key={cultivar.id}
                  className={cn(
                    'flex bg-white justify-between px-6 py-4 first:rounded-t last:rounded-b'
                  )}
                >
                  <span className="font-semibold">{cultivar.strainName}</span>
                  <span>{cultivar.count}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {!!notes && (
        <div className="flex flex-col gap-2 basis-1/2">
          <h2 className="text-xl">Notes</h2>
          <div className="flex flex-col gap-3 px-6 py-4 rounded-md bg-white whitespace-pre-wrap">
            {notes}
          </div>
        </div>
      )}
    </div>
  );
};
