export enum EAuthStatus {
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',
  UNDETERMINED = 'UNDETERMINED',
}

export enum EAuthExceptionCodes {
  CODE_MISMATCH = 'CodeMismatchException',
  NOT_AUTHORIZED = 'NotAuthorizedException',
  LIMIT_EXCEEDED = 'LimitExceededException',
  CODE_EXPIRED = 'ExpiredCodeException',
  INVALID_PARAMETER = 'InvalidParameterException',
}
