import { isValid } from 'date-fns';
import { forwardRef } from 'react';
import { Input, InputProps } from '../Input/Input';

export type DatetimeProps = { type?: 'date' | 'datetime-local' } & Omit<
  InputProps,
  'type'
>;

/**
 * An input field that let the user enter a date or date & time,
 * either with a textbox that validates the input or a special date picker interface.
 */
export const Datetime = forwardRef<HTMLInputElement, DatetimeProps>(
  function Datetime({ type = 'datetime-local', onChange, ...props }, ref) {
    const handleOnChange: InputProps['onChange'] = (event) => {
      const { value } = event.currentTarget;
      if (isValid(new Date(value))) {
        onChange?.(event);
      }
    };

    return (
      <Input
        type={type}
        ref={ref}
        role="time"
        min="2024-01-01"
        max="2100-01-01"
        onChange={handleOnChange}
        {...props}
      />
    );
  }
);
