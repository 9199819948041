import { createContext, ReactNode, useContext } from 'react';
import { AppConfig } from 'shared/config/AppConfig';

export interface AppConfigProviderProps {
  appConfig: AppConfig;
  children?: ReactNode;
}

const AppConfigContext = createContext<AppConfigProviderProps>(null!);

export const AppConfigProvider = ({
  children,
  appConfig,
}: AppConfigProviderProps) => {
  return (
    <AppConfigContext.Provider value={{ appConfig }}>
      {children}
    </AppConfigContext.Provider>
  );
};

export const useAppConfig = () => useContext(AppConfigContext);
