import { Option } from 'components/common/Dropdown/Dropdown';
import { Config } from 'plotly.js';
import {
  EAggregationTypes,
  EGradientTypes,
  THeatMapStylingStep,
  TScaleColorizedValue,
} from 'shared/interfaces/heatmap';
import { EMeasurementTypes } from 'shared/interfaces/measurement';

export const getScaleColorizedValue = (
  value: THeatMapStylingStep,
  min: number,
  max: number
): TScaleColorizedValue => {
  return [(value.value - min) / (max - min), value.color];
};

export const PLOT_CONFIG: Partial<Config> = {
  modeBarButtonsToRemove: [
    'zoom2d',
    'pan2d',
    'zoomIn2d',
    'zoomOut2d',
    'autoScale2d',
    'toImage',
  ],
  showAxisDragHandles: false,
  displaylogo: false,
  displayModeBar: true,
  doubleClick: false,
  showTips: false,
  responsive: true,
};

export const { AGGREGATED, SINGLE } = EAggregationTypes;
export const { FIXED_RANGE, MICROCLIMATES, OPTIMAL_RANGE } = EGradientTypes;
export const { AirLeafTemperatureDifference, CalculatedDistance } =
  EMeasurementTypes;

export const tooltips = {
  [FIXED_RANGE]:
    'A consistent coloring is used across all measurement runs in the selected time range. Best to analyse changes over time.',
  [MICROCLIMATES]:
    'The legend matches the range visible in the selected measurement run. Best to analyse microclimates.',
  [OPTIMAL_RANGE]:
    'Same as Fixed Range, but adding green to represent which part of the space was in the specified optimal range. The optimal range can change depending on grow period and light cycle. Best to inspect where and when the zone was outside of the optimal range.',
};

export const HEATMAP_TYPE_OPTIONS: Option<EGradientTypes>[] = [
  { value: FIXED_RANGE, label: 'Fixed Range' },
  { value: MICROCLIMATES, label: 'Microclimates' },
  { value: OPTIMAL_RANGE, label: 'Optimal Range' },
];

export const HEATMAP_TYPE_OPTIONS_NO_OPTIMAL_RANGE: Option<EGradientTypes>[] =
  HEATMAP_TYPE_OPTIONS.slice(0, 2);
