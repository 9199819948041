import { useEffect, useRef } from 'react';

/**
 * Returns the previous value
 *
 * @param {T} value the current value
 * @returns {T} the previous value
 */
export function usePrevious<T extends any>(value: T) {
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
