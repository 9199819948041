import { useScreenSize } from 'hooks/useScreenSize';
import { useUsers } from 'hooks/useUsers';
import { FC } from 'react';
import { cn } from 'shared/utils/cn';

export interface IUserFilterForm {
  role: string;
}

interface IUserFilterBar {
  form: IUserFilterForm;
  onChange: (form: IUserFilterForm) => void;
}

export const UserFilterBar: FC<IUserFilterBar> = ({ form, onChange }) => {
  const { isMobile } = useScreenSize();
  const { userRoleTypesWithAll } = useUsers();

  return (
    <div className="flex justify-between mb-4">
      {!isMobile && (
        <div className="flex items-center gap-5">
          {userRoleTypesWithAll.map(({ code: role, filterLabel }) => (
            <span
              role="button"
              key={role}
              className={cn(
                'cursor-pointer text-gray-500',
                form.role === role && 'font-semibold text-orange-500'
              )}
              onClick={() => onChange({ ...form, role })}
            >
              {filterLabel}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
