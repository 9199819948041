import {
  useGrowthCyclesWithRecipes,
  useRemoveGrowthCycleWithPresets,
} from 'api/growth-cycle';
import {
  ActionableList,
  ActionableListProps,
} from 'components/common/ActionableList/ActionableList';
import { Alert } from 'components/common/Alert/Alert';
import { Modal } from 'components/common/Modal/Modal';
import { error, success } from 'components/common/Toast/Toast';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { addDays, differenceInDays } from 'date-fns';
import { Disclosure, useDisclosure } from 'hooks/useDisclosure';
import { FC, useState } from 'react';
import { TGrowthCycle } from 'shared/interfaces/growthCycle';
import { TOrganization } from 'shared/interfaces/organization';
import { TZone } from 'shared/interfaces/zone';
import { getDateRangeLabel, overlapsCycle } from 'shared/utils/growthCycle';
import { EditCycle } from './EditCycle';

export type ManageCyclesProps = {
  zone: TZone;
  organization: TOrganization;
  today: Date;
  disclosure: Disclosure;
};

export const ManageCycles: FC<ManageCyclesProps> = ({
  disclosure,
  zone,
  organization,
  today,
}) => {
  const { presetTypes } = useTypeConfig();
  const { growthCycles: cycles, loading: loadingCycles } =
    useGrowthCyclesWithRecipes({
      zoneId: zone.id,
      zoneTimeZone: zone.timeZone,
      presetTypes,
    });
  const { remove } = useRemoveGrowthCycleWithPresets();
  const [state, setState] = useState<{
    cycle?: TGrowthCycle;
    action: 'create' | 'edit' | 'copy' | 'delete';
  }>();
  const cycleDisclosure = useDisclosure({ onClose: () => setState(undefined) });
  const discloseDeleteConfirmation = useDisclosure();
  const mode = state?.action === 'edit' ? 'update' : 'create';
  const items = cycles.map((cycle) => ({
    id: cycle.id,
    name: getDateRangeLabel(cycle.start_time, cycle.end_time),
    lastUpdated: cycle.end_time,
  }));
  const confirmationMessage =
    state?.action === 'delete'
      ? 'Are you sure you want to remove the growth cycle?'
      : null;
  const itemNameFormat: ActionableListProps['itemNameFormat'] = ({
    id,
    name,
  }) => {
    const cycle = cycles.find((cycle) => cycle.id === id)!;
    const isOverlapped = overlapsCycle(cycle, cycles);

    return isOverlapped ? (
      <div className="flex flex-col items-center sm:items-start">
        {name}
        <span className="text-sm text-orange-700">
          This growth cycle overlaps at least one other cycle
        </span>
      </div>
    ) : (
      name
    );
  };
  const handleCreate = () => {
    setState({ action: 'create' });
    cycleDisclosure.open();
  };
  const handleDelete: ActionableListProps['onDelete'] = ({ id }) => {
    setState({
      action: 'delete',
      cycle: cycles.find((cycle) => cycle.id === id),
    });
    discloseDeleteConfirmation.open();
  };
  const handleEdit: ActionableListProps['onEdit'] = ({ id }) => {
    setState({
      action: 'edit',
      cycle: cycles.find((cycle) => cycle.id === id),
    });
    cycleDisclosure.open();
  };
  const handleClone: ActionableListProps['onClone'] = ({ id }) => {
    const cycleToClone = cycles.find((c) => c.id === id)!;
    if (cycleToClone) {
      const latestCycle = cycles.sort((a, b) => b.end_time - a.end_time).at(0)!;
      const startTime = addDays(latestCycle?.end_time, 1).valueOf();
      const totalDays = differenceInDays(
        cycleToClone.end_time,
        cycleToClone.start_time
      );
      const endTime = addDays(startTime, totalDays).valueOf();

      setState({
        action: 'copy',
        cycle: {
          ...cycleToClone,
          id: -1,
          start_time: startTime,
          end_time: endTime,
        },
      });

      cycleDisclosure.open();
    }
  };

  const handleDeleteConfirmation = async () => {
    if (state?.action === 'delete' && state.cycle) {
      const { errors } = await remove(state.cycle);

      if (!errors) {
        setState(undefined);
        success(
          { content: 'Growth cycle successfully removed.' },
          { autoClose: 3000 }
        );
        discloseDeleteConfirmation.close();
      } else {
        error({
          content:
            'Something went wrong while trying to delete your growth cycle. Please try again.',
        });
      }
    }
  };
  const handleDeleteCancel = () => {
    setState(undefined);
    discloseDeleteConfirmation.close();
  };

  return (
    <>
      {disclosure.isOpen && !loadingCycles ? (
        <Modal open={disclosure.isOpen}>
          <Modal.Header closeButtonProps={{ onClick: disclosure.close }} />

          <Modal.Content className="py-4">
            <ActionableList
              mode="edit"
              items={items}
              title="Growth cycles"
              sortBy={['most-recent', 'oldest']}
              subtitle={`A list of ${zone.label}'s growth cycles.`}
              labels={{
                createNewItem: 'Create a new growth cycle',
                noItemsAvailableSubtitle: '',
                noItemsAvailableTitle: 'No growth cycles available',
              }}
              itemNameFormat={itemNameFormat}
              onCreate={handleCreate}
              onEdit={handleEdit}
              onClone={handleClone}
              onDelete={handleDelete}
            />

            {cycleDisclosure.isOpen && state && (
              <EditCycle
                mode={mode}
                cycle={state.cycle}
                zone={zone}
                organization={organization}
                today={today}
                disclosure={cycleDisclosure}
                cycles={cycles}
              />
            )}
          </Modal.Content>
        </Modal>
      ) : null}

      {discloseDeleteConfirmation.isOpen && (
        <Alert
          open={discloseDeleteConfirmation.isOpen}
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirmation}
        >
          {confirmationMessage}
        </Alert>
      )}
    </>
  );
};
