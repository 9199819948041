import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const PlantHeightIcon = ({
  stroke = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    stroke={stroke}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={cn('h-6 w-6', className)}
    {...props}
  >
    <path d="M10 21H20" />
    <path d="M5.54294 20.9975L5.54294 4.62966M8.54297 7L5.54297 4L2.54297 7" />
    <path d="M17.1 15C16.3376 16.1916 15.9541 17.5861 16 19C17.9 18.9 19.3 18.4 20.3 17.6C21.3 16.6 21.9 15.3 22 13C19.3 13.1 18 14 17.1 15Z" />
    <path d="M12.976 11.3378C13.9422 12.0405 14.5571 13.2703 14.9963 14.588C13.2395 14.9393 11.9219 14.9393 10.7799 14.3244C9.72581 13.7974 8.75955 12.6554 8.14465 10.6351C10.6042 10.1959 12.0097 10.6351 12.976 11.3378Z" />
    <path d="M15.3271 7.23483C15.2175 8.69165 15.5419 10.1645 16.2577 11.4593C17.9005 10.4991 18.9076 9.39482 19.4155 8.19395C19.8279 6.80696 19.7413 5.32335 18.7324 3.13733C16.3777 4.46264 15.6506 5.89349 15.3271 7.23483Z" />
    <path d="M12.7744 21.0019C18.2744 18.5019 13.9409 15.3004 16.1409 11.7004" />
  </svg>
);

export { PlantHeightIcon };
