import { Placement } from '@floating-ui/react-dom';
import { Button } from 'components/common/Button/Button';
import { Tooltip } from 'components/common/Tooltip/Tooltip';
import { getCoordinatesLabel } from 'components/image_feed/utils';
import { useScreenSize } from 'hooks/useScreenSize';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  type LucideIcon,
} from 'lucide-react';
import { FC, useCallback, useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { TImagesGrid } from 'shared/interfaces/image';
import { EEventKeyCodes } from 'shared/interfaces/keys';

enum EArrowDirection {
  Up = 'Up',
  Left = 'Left',
  Down = 'Down',
  Right = 'Right',
}

export const ArrowButtonList: [EArrowDirection, Placement[]][] = [
  [EArrowDirection.Up, ['bottom']],
  [EArrowDirection.Left, ['right']],
  [EArrowDirection.Down, ['top']],
  [EArrowDirection.Right, ['left']],
];

const getDirectionStyle = (isMobile: boolean, direction: EArrowDirection) => {
  const second = `calc(50% - ${isMobile ? 24 : 32}px)`;

  switch (direction) {
    case EArrowDirection.Up:
      return {
        top: 12,
        left: second,
      };
    case EArrowDirection.Left:
      return {
        top: second,
        left: 12,
      };
    case EArrowDirection.Down:
      return {
        bottom: 12,
        left: second,
      };
    case EArrowDirection.Right:
      return {
        top: second,
        right: 12,
      };
  }
};

type TArrowButtonDefinition = {
  dx: number;
  dy: number;
  hotKey: EEventKeyCodes;
  Icon: LucideIcon;
};

const arrowButtonDefinition: Record<EArrowDirection, TArrowButtonDefinition> = {
  [EArrowDirection.Up]: {
    dx: 0,
    dy: -1,
    hotKey: EEventKeyCodes.UP_ARROW,
    Icon: ChevronUpIcon,
  },
  [EArrowDirection.Left]: {
    dx: -1,
    dy: 0,
    hotKey: EEventKeyCodes.LEFT_ARROW,
    Icon: ChevronLeftIcon,
  },
  [EArrowDirection.Down]: {
    dx: 0,
    dy: 1,
    hotKey: EEventKeyCodes.DOWN_ARROW,
    Icon: ChevronDownIcon,
  },
  [EArrowDirection.Right]: {
    dx: 1,
    dy: 0,
    hotKey: EEventKeyCodes.RIGHT_ARROW,
    Icon: ChevronRightIcon,
  },
};

interface IImageArrowButtonProps {
  direction: EArrowDirection;
  tooltipPlacements: Placement[];
  gridSize: TGridSize;
  position: TPosition;
  onChangeSection: (position: TPosition) => void;
  imagesGrid: TImagesGrid;
}

export const ImageArrowButton: FC<IImageArrowButtonProps> = ({
  direction,
  tooltipPlacements,
  gridSize,
  position,
  onChangeSection,
  imagesGrid,
}) => {
  const { isMobile } = useScreenSize();

  const { dx, dy, hotKey, Icon } = arrowButtonDefinition[direction];

  const x = dx + position.x;
  const y = dy + position.y;

  const isValidCell = useMemo(
    () =>
      imagesGrid[y]?.find((images) => images.cellX === x)?.measurementId !== -1,
    [imagesGrid, x, y]
  );

  const isValidPosition = useMemo(() => {
    return (
      x >= 0 && y >= 0 && x < gridSize.column && y < gridSize.row && isValidCell
    );
  }, [isValidCell, x, y, gridSize]);

  const handleClickArrow = useCallback(() => {
    if (isValidPosition) {
      onChangeSection({ x, y });
    }
  }, [x, y, isValidPosition, onChangeSection]);

  useHotkeys(hotKey, handleClickArrow, [position]);

  if (!isValidPosition) {
    return null;
  }

  return (
    <Tooltip
      label={getCoordinatesLabel({ y, x })}
      noAriaLabelledby
      size="lg"
      allowedPlacements={tooltipPlacements}
    >
      <Button
        size="icon"
        variant="secondary"
        className="absolute"
        onClick={handleClickArrow}
        style={getDirectionStyle(isMobile, direction)}
        aria-label={`Next image ${direction}`}
        title=" "
      >
        <Icon className="stroke-[1.5px] size-4 xl:size-5" />
      </Button>
    </Tooltip>
  );
};
