import { Disclosure } from 'components/common/Disclosure/Disclosure';
import { Link } from 'components/common/Link';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { ERoutePath, PATH_PATTERNS } from 'shared/constants/url';
import { LocationIdAndName } from 'shared/interfaces/location';
import { TZone } from 'shared/interfaces/zone';
import { cn } from 'shared/utils/cn';
import { FacilityNotifications } from './FacilityNotifications';

interface FacilitySettingsProps extends ComponentPropsWithoutRef<'div'> {
  locations: LocationIdAndName[];
  zone: TZone;
  readOnly: boolean;
}

export const FacilitySettings = forwardRef<
  HTMLDivElement,
  FacilitySettingsProps
>(function FacilitySettings(
  { className, locations, zone, readOnly, ...props },
  ref
) {
  if (locations.length === 0) {
    return null;
  }
  const growthCycleSettingsLink = readOnly ? (
    <b>Growth cycle settings</b>
  ) : (
    <Link
      text="Growth cycle settings"
      to={PATH_PATTERNS[ERoutePath.GROWTH_CYCLE_SETTINGS]
        .replace(':organizationCode', zone.organizationCode)
        .replace(':zoneId?', zone.id.toString())}
    />
  );

  return (
    <div
      ref={ref}
      {...props}
      className={cn('flex flex-col gap-4 w-full sm:max-w-[632px]', className)}
    >
      <h3 className="text-lg font-semibold">Critical environmental settings</h3>

      <section className="prose-sm">
        <p>
          On this page, you can configure the critical environment parameters of
          interest for your facility.
        </p>

        <p>
          If a user has enabled critical environment notifications through their{' '}
          <Link
            text="Email notification settings"
            to={PATH_PATTERNS[ERoutePath.SETTINGS_NOTIFICATIONS]}
          />
          , they will receive an immediate email when an environment parameter
          surpasses the Critical Threshold, as configured in the zone&apos;s{' '}
          {growthCycleSettingsLink}. Notifications are repeated every 30 minutes
          until the parameter comes back into non-critical range.
        </p>

        <p>
          Facility administrators can check the boxes below to mark an
          environment parameter as relevant throughout the day and/or night
          period.
        </p>
      </section>

      <ul className="flex flex-col gap-2" aria-label="All locations">
        {locations.map(({ id, name }) => (
          <Disclosure key={id} summary={name} role="listitem" defaultOpen>
            <FacilityNotifications locationId={id!} readOnly={readOnly} />
          </Disclosure>
        ))}
      </ul>
    </div>
  );
});
