import { useAvailableMentions } from 'api/user';
import { TextEditor } from 'components/common/TextEditor/TextEditor';
import { usePermissions } from 'hooks/usePermissions';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { TDiscussion } from 'shared/interfaces/discussion';
import { cn } from 'shared/utils/cn';
import { formatDateEEEMMMMdhmmaa } from 'shared/utils/date';
import { formatCategory } from 'shared/utils/discussion';
import { CommentThread } from './CommentThread';

const DISCUSSION_BODY_MAX_CONTENT_LENGTH = 280;

interface ViewCommentProps extends ComponentPropsWithoutRef<'div'> {
  discussion: TDiscussion;
}

export const ViewComment = forwardRef<HTMLDivElement, ViewCommentProps>(
  function ViewComment({ discussion, className, ...props }, ref) {
    const { annotationType, category, endTime, firstComment, startTime } =
      discussion;
    const startTimeLabel = formatDateEEEMMMMdhmmaa(startTime);
    const endTimeLabel = formatDateEEEMMMMdhmmaa(endTime);
    const isTimeRange = annotationType === 'time_range_annotation';
    const lastUpdatedAtLabel = `Last updated at ${formatDateEEEMMMMdhmmaa(
      firstComment.lastUpdatedAt!
    )}`;
    const availableMentions = useAvailableMentions(discussion.zoneUid);
    const permissions = usePermissions();

    return (
      <div
        ref={ref}
        {...props}
        className={cn('w-full h-full flex flex-col', className)}
      >
        <div className="px-4 pb-4 flex flex-col border-b-[1px] border-neutral-500">
          <p className="text-xl font-bold">{formatCategory(category)}</p>

          <p className="text-sm">
            {startTimeLabel}
            {isTimeRange && ` - ${endTimeLabel}`}
          </p>
        </div>

        <div className="p-4 flex flex-col gap-4">
          <div>
            <p className="text-lg font-bold">{firstComment.authorName}</p>
            <p className="text-sm text-neutral-500">{lastUpdatedAtLabel}</p>
          </div>
          <div className="text-sm break-words whitespace-pre-wrap">
            <TextEditor
              key={firstComment.content.content}
              initialEditorState={firstComment.content.content}
              editable={false}
              readMoreContentLength={DISCUSSION_BODY_MAX_CONTENT_LENGTH}
              availableMentions={availableMentions}
            />
          </div>

          {permissions.comments.canView && (
            <CommentThread discussion={discussion} />
          )}
        </div>
      </div>
    );
  }
);
