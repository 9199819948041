import { useGrowthCyclesByDates } from 'api/growth-cycle';
import { useGetMeasurementsByTypeAndTimeRange } from 'api/measurement';
import { Button } from 'components/common/Button/Button';
import {
  Chart,
  ChartProps,
  getPlotBands,
  getPolygonSeriesAndZones,
  getZonedPointsAndZones,
  Y_AXIS_BASE_OPTIONS,
} from 'components/common/Chart/Chart';
import { TimelineRange } from 'components/common/TimelineRange/TimelineRange';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import {
  useLineChartURL,
  useZoneDetailsPageURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import {
  differenceInDays,
  eachDayOfInterval,
  endOfDay,
  format,
  hoursToMilliseconds,
  isSameDay,
  isWithinInterval,
  minutesToMilliseconds,
  startOfDay,
} from 'date-fns';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useScreenSize } from 'hooks/useScreenSize';
import { useSignals } from 'hooks/useSignals';
import isNil from 'lodash.isnil';
import merge from 'lodash.merge';
import { ExpandIcon, GhostIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TTimeRange } from 'shared/interfaces/general';
import {
  MeasurementAggregation,
  MeasurementTypeConfig,
  SignalMeasurements,
} from 'shared/interfaces/measurement';
import { TZone } from 'shared/interfaces/zone';
import { cn } from 'shared/utils/cn';
import { getLighCyclesRanges } from 'shared/utils/getters';
import { smartRound } from 'shared/utils/miscellaneous';
import { Annotations } from './Annotations';
import { useChartHints } from './hooks/useChartHints';
import { Sidebar } from './Sidebar';
import { SummaryMetrics } from './SummaryMetrics';
import { Toolbar } from './Toolbar';
import { VerticalDivider } from './VerticalDivider';

const exceedsMaxAllowedWindow = (start: number, end: number) =>
  start && end ? differenceInDays(end, start) > 7 : false;

const { AVERAGE } = MeasurementAggregation;

const seriesId = (signal: MeasurementTypeConfig, isSingle: boolean) =>
  isSingle ? `${signal.type}-single` : signal.type;

const LineChart = ({
  zone,
  startTime,
  endTime,
  disabledAggregation,
}: {
  zone: TZone;
  startTime: number;
  endTime: number;
  disabledAggregation: boolean;
}) => {
  const { isMobile } = useScreenSize();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [chart, setChart] = useState<Maybe<Highcharts.Chart>>();
  const start = useMemo(() => new Date(startTime), [startTime]);
  const end = useMemo(() => new Date(endTime), [endTime]);
  const timelineDates = useMemo(() => [start, end], [start, end]);
  const [timeRange, setTimeRange] = useState<TTimeRange>({
    start: startOfDay(start),
    end: endOfDay(end),
  });
  const { viewType: aggregation, showSummary } = useLineChartURL();
  const { signals } = useSignals();
  const [selectedSignals, setSelectedSignals] = useState<
    MeasurementTypeConfig[]
  >([]);
  const { presetTypes } = useTypeConfig();
  const { growthCycle } = useGrowthCyclesByDates({
    zoneId: zone.id,
    start,
    end,
    zoneTimeZone: zone.timeZone,
    presetTypes,
  });
  const measurementsQuery = useGetMeasurementsByTypeAndTimeRange({
    zoneId: zone.id,
    zoneUid: zone.uid,
    zoneTimeZone: zone.timeZone,
    start,
    end,
    signals,
    aggregation,
  });
  const summaryMetrics = useMemo(() => {
    const data = new SignalMeasurements();
    let key = '';

    for (const [signal, values] of measurementsQuery.data) {
      if (
        selectedSignals.length === 0 ||
        selectedSignals.some(({ type }) => type === signal.type)
      ) {
        data.set(
          signal,
          values.filter(([timestamp]) => isWithinInterval(timestamp, timeRange))
        );
        key += `${signal.type}-`;
      }
    }

    return { data, key };
  }, [measurementsQuery.data, selectedSignals, timeRange]);
  const showResetZoom =
    start.valueOf() < timeRange.start.valueOf() ||
    end.valueOf() > timeRange.end.valueOf();
  const isSingle = signals.length === 1;
  const chartAriaLabel = `Multi-line chart with ${signals.map(({ label }) => label).join()}`;
  const ranges = useMemo(
    function computeRanges() {
      return getLighCyclesRanges({
        start,
        end,
        lightInfo: growthCycle?.metadata.light_info ?? [],
      });
    },
    [end, growthCycle?.metadata.light_info, start]
  );
  const plotBands = useMemo(
    function computePlotBands() {
      return getPlotBands(ranges);
    },
    [ranges]
  );
  const yAxis = useMemo(
    function computeYAxis() {
      if (signals.length === 0) {
        return [{ title: undefined }];
      }

      return signals.reduce<Highcharts.YAxisOptions[]>((axes, signal) => {
        const exists = axes.some(({ id }) => id === signal.unit);

        if (!exists) {
          axes.push(
            merge({}, Y_AXIS_BASE_OPTIONS, {
              id: signal.unit,
              reversed: signal.reversedYAxis,
              visible: isSingle,
            })
          );
        }

        return axes;
      }, []);
    },
    [isSingle, signals]
  );
  const { polygonSeries, warningRanges } = useMemo(
    function computeOptimalAndWarningRanges() {
      if (
        signals.length === 0 ||
        isNil(signals[0]) ||
        !isSingle ||
        isNil(growthCycle) ||
        isNil(start) ||
        isNil(end)
      ) {
        return {
          polygonSeries: [],
          warningRanges: [],
        };
      }

      const signal = signals[0];

      const { polygonSeries = [], warningRanges } = getPolygonSeriesAndZones({
        end,
        growthCycle,
        start,
        ranges,
        signal,
      });

      // assign corresponding Y axis
      for (const series of polygonSeries) {
        series.yAxis = signal.unit;
      }

      return {
        polygonSeries,
        warningRanges,
      };
    },
    [isSingle, end, growthCycle, ranges, signals, start]
  );
  const lineSeries = useMemo<Highcharts.SeriesLineOptions[]>(
    function computeLineSeries() {
      const availableSignals = Array.from(measurementsQuery.data.keys());
      const lineSeries: Highcharts.SeriesLineOptions[] = [];

      for (const [signal, data] of measurementsQuery.data) {
        // Ensure an unique name
        const name = availableSignals.some(
          ({ type, label }) => label === signal.label && type !== signal.type
        )
          ? `${signal.label} (${signal.group})`
          : signal.label;
        const series: Highcharts.SeriesLineOptions = {
          id: seriesId(signal, isSingle),
          data,
          name,
          type: 'line',
          enableMouseTracking: true,
          states: {
            hover: { enabled: true },
          },
          className: cn(
            isSingle ? 'highcharts-neutral-900' : signal.style?.svg
          ),
          yAxis: signal.unit,
          tooltip: {
            valueDecimals: 2,
            valueSuffix: ` ${signal.unit}`,
          },
        };

        if (!!signal.preset && isSingle) {
          const { points, zones } = getZonedPointsAndZones({
            data,
            warningRanges,
          });

          series.data = points;
          series.zoneAxis = 'x';
          series.zones = zones;
        }

        lineSeries.push(series);
      }

      if (lineSeries.length === 0) {
        return [{ type: 'line', data: [] }];
      }

      return lineSeries;
    },
    [isSingle, measurementsQuery.data, warningRanges]
  );
  const cycleDays = useMemo(() => {
    if (!growthCycle) {
      return [];
    }
    return eachDayOfInterval({
      start: growthCycle.start_time,
      end: growthCycle.end_time,
    });
  }, [growthCycle]);
  const options = useMemo<ChartProps['options']>(
    function computeOptions() {
      return {
        accessibility: {
          description: chartAriaLabel,
        },
        chart: {
          spacingBottom: 8,
          ...(lineSeries.length > 0
            ? {
                panKey: 'shift',
                panning: { enabled: true, type: 'x' },
                zooming: {
                  type: 'x',
                  pinchType: 'x',
                  mouseWheel: { sensitivity: 2 },
                },
              }
            : {}),
        },
        tooltip: {
          enabled: true,
          split: true,
          formatter: function () {
            // find the cycle date that matches this point
            const day =
              cycleDays.findIndex((cd) => isSameDay(cd, this.point.x)) + 1;
            const dateFormatString =
              aggregation === AVERAGE ? 'MMM d h:mm aa' : 'MMM d h:mm:ss aa';

            return [
              // This is the header of the tooltip. It displays the cycle day + timestamp
              `<b>Day ${day}</b> | ${format(this.point.x, dateFormatString)}`,
              // These are the points that are being hovered by the tooltip
              ...(this.points ?? []).map(
                (point) =>
                  `${point.series.name} at <b>${smartRound(point.y!)} ${point.series.tooltipOptions?.valueSuffix}</b>`
              ),
            ];
          },
        },
        xAxis: {
          crosshair: true,
          max: end.valueOf(),
          min: start.valueOf(),
          minRange: minutesToMilliseconds(5),
          events: {
            afterSetExtremes: (event) => {
              setTimeRange({
                start: new Date(event.min),
                end: new Date(event.max),
              });
            },
          },
          plotBands,
          // When there's no data, set minRange to a full day
          ...(lineSeries.length === 0
            ? { minRange: hoursToMilliseconds(24) }
            : undefined),
        },
        yAxis,
        series: [...polygonSeries, ...lineSeries],
      };
    },
    [
      aggregation,
      chartAriaLabel,
      cycleDays,
      end,
      lineSeries,
      plotBands,
      polygonSeries,
      start,
      yAxis,
    ]
  );
  const handleToggleSeries = useCallback(
    (selectedSignals: MeasurementTypeConfig[]) => {
      setSelectedSignals(selectedSignals);
      if (chart) {
        for (const series of chart.series.filter(
          ({ options: { type } }) => type === 'line'
        )) {
          const isSelected = selectedSignals.some(
            (signal) => seriesId(signal, isSingle) === series.userOptions.id
          );

          if (selectedSignals.length === 0 || isSelected) {
            series.setVisible(true, false);
          } else if (!isSelected) {
            series.setVisible(false, false);
          }
        }

        // force chart redraw a single time after updating all series visibility
        chart.redraw();
      }
    },
    [chart, isSingle]
  );

  useChartHints({ chart, showResetZoom, signals });

  return (
    <>
      <div className="h-full flex">
        <Sidebar
          className={cn('min-w-[280px] w-0 hidden', sidebarOpen && 'flex')}
        />

        <VerticalDivider
          open={sidebarOpen}
          title="source selection"
          onClick={() => {
            setSidebarOpen((prev) => !prev);
          }}
        />

        <div className="relative w-full min-w-0 flex flex-col gap-2">
          <Toolbar
            disabledAggregation={
              disabledAggregation ||
              measurementsQuery.loading ||
              signals.length === 0
            }
            onToggleSeries={handleToggleSeries}
          />

          <div className="flex h-full min-h-0">
            <div className="relative min-w-0 flex-auto">
              <Chart
                className={cn(
                  'w-full h-full rounded',
                  showSummary && 'rounded-e-none'
                )}
                aria-label={chartAriaLabel}
                options={options}
                loading={measurementsQuery.loading}
                onInitialization={setChart}
              />

              <div className="highcharts-bindings-container absolute top-0 right-0 flex gap-2">
                {showResetZoom && !measurementsQuery.loading && chart && (
                  <Button
                    size="responsive"
                    variant="secondary"
                    className="shadow"
                    leadingIcon={
                      <ExpandIcon className="stroke-[1.5px] size-4 xl:size-5" />
                    }
                    onClick={() => chart.zoomOut()}
                  >
                    Reset zoom
                  </Button>
                )}

                <div id="slot-chart-tools" />
              </div>

              {signals.length === 0 && (
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-2 items-center text-neutral-500">
                  <GhostIcon size={32} className="stroke-[1.5px]" />
                  Select a source to see it in action
                </div>
              )}
            </div>

            {showSummary && !isMobile && summaryMetrics.data.size > 0 && (
              <SummaryMetrics
                key={summaryMetrics.key}
                data={summaryMetrics.data}
                className="min-w-24 max-w-[33%] flex-none"
              />
            )}
          </div>
        </div>
      </div>

      {chart?.hasLoaded && (
        <Annotations
          chart={chart}
          data={measurementsQuery.data}
          signals={signals}
          timeRange={timeRange}
          zone={zone}
          start={start}
          end={end}
          isLoading={measurementsQuery.loading || signals.length === 0}
        />
      )}

      <TimelineRange mode="range" dates={timelineDates} brush={timeRange} />
    </>
  );
};

export const NewLineChart = () => {
  const { getRangeStartTime, getRangeEndTime } = useZoneDetailsPageURL();
  const { viewType, setViewType } = useLineChartURL();
  const { currentZone, currentTimeInCurrentZone, zoneTimeZone } =
    useCurrentZone();
  const rangeStartTime = getRangeStartTime(zoneTimeZone);
  const rangeEndTime = getRangeEndTime(zoneTimeZone);
  const forceAverage =
    rangeStartTime && rangeEndTime
      ? exceedsMaxAllowedWindow(
          rangeStartTime,
          Math.min(rangeEndTime, currentTimeInCurrentZone.valueOf())
        )
      : false;

  useEffect(() => {
    if (forceAverage && viewType !== AVERAGE) {
      setViewType(AVERAGE);
    }
  }, [forceAverage, setViewType, viewType]);

  if (isNil(currentZone) || isNil(rangeStartTime) || isNil(rangeEndTime)) {
    return null;
  }

  return (
    <LineChart
      zone={currentZone}
      startTime={rangeStartTime}
      endTime={rangeEndTime}
      disabledAggregation={forceAverage}
    />
  );
};
