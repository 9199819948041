import * as toast from 'components/common/Toast/Toast';
import isNil from 'lodash.isnil';
import { useEffect } from 'react';
import { Preset } from 'shared/interfaces/growthCycle';

/**
 *
 */
export function useWarningSharedPreset(preset: Preset) {
  useEffect(() => {
    if (!isNil(preset?.count) && preset.count > 1) {
      toast.warning(
        {
          content: `This preset is shared between ${preset.count} growth cycles. Modifying it will reflect on those growth cycles.`,
        },
        { autoClose: 5000, toastId: 'shared-preset' }
      );
    }
  }, [preset.count]);
}
