import { useCallback } from 'react';
import { PublicRole } from 'shared/interfaces/user';
import { AnalyticsEvent } from './AnalyticsEvent';
import { getAnalyticsServiceInstance } from './AnalyticsService';

export const useAnalytics = () => {
  const analyticsService = getAnalyticsServiceInstance();

  const resetUser = useCallback(() => {
    analyticsService.resetUser();
  }, [analyticsService]);

  const sendEvent = useCallback(
    (
      eventType: AnalyticsEvent,
      params?: Record<string, string | number | boolean>
    ) => {
      analyticsService.sendEvent(eventType, params);
    },
    [analyticsService]
  );

  const sendPageLeave = useCallback(
    (path: string) => {
      analyticsService.sendPageLeave(path);
    },
    [analyticsService]
  );

  const sendPageView = useCallback(
    (
      name: string | undefined,
      actualPath: string | undefined,
      matchedPath: string | undefined,
      searchParams: Record<string, string[]>
    ) => {
      analyticsService.sendPageView(
        name,
        actualPath,
        matchedPath,
        searchParams
      );
    },
    [analyticsService]
  );

  const sendSearchParamsChange = useCallback(
    (searchParams: Record<string, string[]>) => {
      analyticsService.sendSearchParamsChange(searchParams);
    },
    [analyticsService]
  );

  const identifyUser = useCallback(
    (
      userId: string,
      organizationCode: string,
      publicRole: PublicRole,
      excludeFromAnalytics: boolean
    ) => {
      analyticsService.identifyUser(
        userId,
        organizationCode,
        publicRole,
        excludeFromAnalytics
      );
    },
    [analyticsService]
  );

  const setCurrentOrganizationCode = useCallback(
    (organizationCode: string | undefined) => {
      analyticsService.setCurrentOrganizationCode(organizationCode);
    },
    [analyticsService]
  );

  const setCurrentZoneId = useCallback(
    (zoneId: string | undefined) => {
      analyticsService.setCurrentZoneId(zoneId);
    },
    [analyticsService]
  );

  return {
    resetUser,
    sendEvent,
    sendPageLeave,
    sendPageView,
    sendSearchParamsChange,
    setCurrentOrganizationCode,
    setCurrentZoneId,
    identifyUser,
  };
};
