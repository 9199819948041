import isNil from 'lodash.isnil';
import {
  EImageTypes,
  IResolutionInformation,
  TLabelStatistics,
} from 'shared/interfaces/image';
import { TResolution } from 'shared/interfaces/measurement';
import {
  IMeasurementRunFrame,
  IMeasurementRunImageInfo,
  TMeasurementRun,
} from 'shared/interfaces/measurementRun';
import { convertNumberToLetters } from 'shared/utils/converters';
import { clamp, mapRange } from 'shared/utils/miscellaneous';
import { ZOOM_LEVELS } from './constants';

/**
 * @param {TPosition} position - The coordinate position.
 * @returns {string} - The coordinate label.
 */
export const getCoordinatesLabel = (position: TPosition): string => {
  const rowLabel = convertNumberToLetters(position.y);
  const colLabel = String(position.x + 1);
  return `${rowLabel}-${colLabel}`;
};

/**
 * Sort resolution list by area smallest to largest.
 *
 * @param {TResolution[]} resolutionList - the resolutions
 * @returns {TResolution[]} - resolution list by area smallest to largest
 */
export const sortResolutionList = (
  resolutionList: TResolution[]
): TResolution[] => {
  if (!resolutionList.length) return [[0, 0]];
  const resolutionListCopy = [...resolutionList];
  resolutionListCopy.sort((a, b) => a[0] * a[1] - b[0] * b[1]);
  return resolutionListCopy;
};

/**
 * Get RGB image sizes from metadata
 *
 * @param {TMeasurementRun} measurementRun Object with RGB image size
 * @returns {*}  {Maybe<TResolution[]>}
 */
export const getRgbImageSizes = (
  measurementRun: TMeasurementRun
): Maybe<TResolution[]> => {
  const metadata = measurementRun?.metadata;
  let imageSizes: Maybe<TResolution[]> = (
    metadata?.image_info as IMeasurementRunImageInfo
  )?.resolutions;

  if (isNil(imageSizes) && !isNil(metadata)) {
    imageSizes = (metadata as IMeasurementRunImageInfo).rgb_resolutions;
  }
  return imageSizes;
};

/**
 * Get NDVI image sizes from metadata
 *
 * @param {TMeasurementRun} measurementRun Object with RGB image size
 * @returns {*}  {Maybe<TResolution[]>}
 */
export const getNdviImageSizes = (
  measurementRun: TMeasurementRun
): Maybe<TResolution[]> => {
  const metadata = measurementRun?.metadata;
  let imageSizes: Maybe<TResolution[]> = (
    metadata?.image_info as IMeasurementRunImageInfo
  )?.resolutions;

  if (isNil(imageSizes) && !isNil(metadata)) {
    imageSizes = (metadata as IMeasurementRunImageInfo).ndvi_resolutions;
  }
  return imageSizes;
};

export const getImageFeedResolutionInformation = (
  measurementRun: TMeasurementRun,
  imageType: EImageTypes
): Nullable<IResolutionInformation> => {
  let imageSizes: Maybe<TResolution[]>;

  if (imageType === EImageTypes.RGB) {
    imageSizes = getRgbImageSizes(measurementRun);
  } else {
    imageSizes = getNdviImageSizes(measurementRun);
  }

  if (!imageSizes || imageSizes.length === 0) {
    return null;
  }

  const sortedResolutions = sortResolutionList(imageSizes);
  const minResolution = sortedResolutions[0]!;
  const maxResolution = sortedResolutions.at(-1)!;

  return {
    minResolution,
    maxResolution,
    sortedResolutions,
  };
};

/**
 * Determines if a position is valid
 *
 * @param {number} position position to test
 * @returns {*}  {boolean}
 */
export const isValidPosition = (position: number): boolean => {
  return !Number.isNaN(position) && Math.abs(position) !== Infinity;
};

export const convertIntoMeasurementRunFrame = (
  measurementRun: TMeasurementRun,
  pendingStatusId?: Nullable<Number>,
  notifiedStatusId?: Nullable<Number>
): IMeasurementRunFrame => {
  return {
    measurementRunId: measurementRun.id,
    startTime: new Date(measurementRun.start_time),
    endTime: new Date(measurementRun.end_time),
    marked:
      measurementRun.annotation_status_id === pendingStatusId ||
      measurementRun.annotation_status_id === notifiedStatusId,
  };
};

export const selectMeasurementRunIndexWithStartTime = (
  measurementRuns: TMeasurementRun[],
  measurementRunStartTime: Nullable<number> | undefined
): number => {
  if (!measurementRuns.length) {
    return -1;
  }

  if (!measurementRunStartTime) {
    return measurementRuns.length - 1;
  }

  const lastRun = measurementRuns.at(-1)!;
  if (new Date(lastRun.start_time).valueOf() < measurementRunStartTime) {
    return measurementRuns.length - 1;
  }

  const run = measurementRuns.find(
    (run) => new Date(run.start_time).valueOf() >= measurementRunStartTime
  );

  let runIndex = measurementRuns.length - 1;
  if (run) {
    const measurementRunIndex = measurementRuns.findIndex(
      ({ id }) => id === run.id
    );
    runIndex = Math.min(measurementRunIndex, measurementRuns.length - 1);
  }

  return runIndex;
};

/**
 * Returns the next position for an image in a grid, given the current position
 * and the size of the grid.
 *
 * @param {TPosition} currentPosition - The current position of the image.
 * @param {TGridSize} gridSize - The size of the grid.
 * @returns {TPosition} The next position for the image.
 */
export function getNextImagePostition(
  currentPosition: TPosition,
  gridSize: TGridSize
): TPosition {
  const imagesGridWidth = gridSize.column - 1;
  const imagesGridHeight = gridSize.row - 1;
  const resultPosition: TPosition = { ...currentPosition };

  if (
    currentPosition.x >= imagesGridWidth &&
    currentPosition.y >= imagesGridHeight
  ) {
    // Go back to origin.
    resultPosition.x = 0;
    resultPosition.y = 0;
  } else if (
    currentPosition.x >= imagesGridWidth &&
    currentPosition.y < imagesGridHeight
  ) {
    // Go to next row.
    resultPosition.x = 0;
    resultPosition.y = currentPosition.y + 1;
  } else {
    // Go to next column.
    resultPosition.x = currentPosition.x + 1;
  }
  return resultPosition;
}

export const MIN_SECTION_HIGHLIGHT_INTENSITY = 0.4;
export const MAX_SECTION_HIGHLIGHT_INTENSITY = 0.85;
const INTENSITY_OFFSET_SECTION_HIGHLIGHT_INTENSITY = 0.15;
/**
 * @param {Maybe<number>} labelCount - Label Count
 * @param {Optional<TMinMax>} stats - Statistics
 * @returns {number} - Logarithmic Label Count Intensity representation
 */
export const getSectionHeatOverlayIntensityFromLabelCount = (
  labelCount: Maybe<number>,
  stats: Optional<TLabelStatistics>
) => {
  if (typeof labelCount === 'undefined' || labelCount === null || !stats) {
    return 0;
  }

  const lowerLabelCountBoundary = stats.min!;
  const upperLabelCountBoundary = stats.max!;

  let offset = 0;
  if (lowerLabelCountBoundary === labelCount) {
    offset = 0.1;
  }
  const logCount = Math.log10(labelCount);
  const logMinCount = Math.log10(lowerLabelCountBoundary - offset);
  const logMaxCount = Math.log10(upperLabelCountBoundary + offset);

  let intensity =
    ((logCount - logMinCount) / (logMaxCount - logMinCount)) *
      MAX_SECTION_HIGHLIGHT_INTENSITY +
    INTENSITY_OFFSET_SECTION_HIGHLIGHT_INTENSITY;

  if (!Number.isFinite(intensity)) return 0;

  intensity = clamp(
    intensity,
    MIN_SECTION_HIGHLIGHT_INTENSITY,
    MAX_SECTION_HIGHLIGHT_INTENSITY
  );
  intensity = Math.round(intensity * 100) / 100;
  return intensity;
};

const getStageViewCursorBox = (totalImageSize: TSize, scale: TScale) => {
  return {
    width: totalImageSize.width * scale.x,
    height: totalImageSize.height * scale.y,
  };
};

const getCursorBoxBoundaries = (
  presentationSize: TSize,
  stageViewCursorBox: TSize
) => {
  return {
    top: 0,
    right: presentationSize.width - stageViewCursorBox.width,
    bottom: presentationSize.height - stageViewCursorBox.height,
    left: 0,
  };
};

// NOTE: x and y can be different due to different aspection ratios
export const getMinScale = (presentationSize: TSize, totalImageSize: TSize) => {
  const minX = presentationSize.width / totalImageSize.width;
  const minY = presentationSize.height / totalImageSize.height;

  return Math.min(minX, minY);
};

export const getNewScaleWithBoundingBox = (
  scale: TScale,
  minScale: ReturnType<typeof getMinScale>
) => {
  if (scale.x <= minScale || scale.y <= minScale) {
    return { x: minScale, y: minScale };
  }

  if (scale.x >= ZOOM_LEVELS.MAX || scale.y >= ZOOM_LEVELS.MAX) {
    return { x: ZOOM_LEVELS.MAX, y: ZOOM_LEVELS.MAX };
  }

  return scale;
};

// NOTE: https://en.wikipedia.org/wiki/Euclidean_distance
// calculate distance between two points on 2D
export const getDistanceBetweenTwoPoints = (
  point1: TPosition,
  point2: TPosition
) => {
  return Math.sqrt(
    Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2)
  );
};

export const getPositionRelativeToPointer = (
  pointer: TPosition,
  position: TPosition,
  scale: TScale
) => {
  return {
    x: (pointer.x - position.x) / scale.x,
    y: (pointer.y - position.y) / scale.y,
  };
};

export const getNewPositionRelativeToCurrent = (
  pointer: TPosition,
  currentPositionRelativeToPointer: TPosition,
  newScale: TScale
) => {
  return {
    x: pointer.x - currentPositionRelativeToPointer.x * newScale.x,
    y: pointer.y - currentPositionRelativeToPointer.y * newScale.y,
  };
};

export const getNewPositionWithBoundingBox = (
  position: TPosition,
  scale: TScale,
  presentationSize: TSize,
  totalImageSize: TSize
) => {
  // NOTE: think: magnifying glass through which one views the canvas, a window
  const stageViewCursorBox = getStageViewCursorBox(totalImageSize, scale);

  // NOTE: whenever cursor box is either full height or width
  // set position to middle either or both { x, y }
  if (
    stageViewCursorBox.width === presentationSize.width ||
    stageViewCursorBox.height === presentationSize.height
  ) {
    const minScale = getMinScale(presentationSize, totalImageSize);
    const _scale = {
      x: minScale,
      y: minScale,
    };

    return getScaleCenterPosition(_scale, presentationSize, totalImageSize);
  }

  // NOTE: "snap" cursor box to horizontal/vertical presentation boundaries
  return offsetPositionToBoundingBox(
    position,
    stageViewCursorBox,
    presentationSize
  );
};

const offsetPositionToBoundingBox = (
  position: TPosition,
  stageViewCursorBox: ReturnType<typeof getStageViewCursorBox>,
  presentationSize: TSize
  // NOTE: splitting this f-n further will make debugging harder
) => {
  let { x, y } = position;
  // NOTE: cursor box boundaries
  const cursorBoxBoundaries = getCursorBoxBoundaries(
    presentationSize,
    stageViewCursorBox
  );
  // NOTE: "snap" cursor box to horizontal left/right boundaries
  // accounting for different aspect-ratios of the presentation
  if (stageViewCursorBox.width > presentationSize.width) {
    if (x > cursorBoxBoundaries.left) {
      x = cursorBoxBoundaries.left;
    } else if (x < cursorBoxBoundaries.right) {
      x = cursorBoxBoundaries.right;
    }
  } else if (stageViewCursorBox.width < presentationSize.width) {
    if (x < cursorBoxBoundaries.left) {
      x = cursorBoxBoundaries.left;
    } else if (x > cursorBoxBoundaries.right) {
      x = cursorBoxBoundaries.right;
    }
  }

  // NOTE: "snap" presentation box to vertical top/bottom boundaries
  // accounting for different aspect-ratios of the presentation
  if (stageViewCursorBox.height > presentationSize.height) {
    if (y > cursorBoxBoundaries.top) {
      y = cursorBoxBoundaries.top;
    } else if (y < cursorBoxBoundaries.bottom) {
      y = cursorBoxBoundaries.bottom;
    }
  } else if (stageViewCursorBox.height < presentationSize.height) {
    if (y < cursorBoxBoundaries.top) {
      y = cursorBoxBoundaries.top;
    } else if (y > cursorBoxBoundaries.bottom) {
      y = cursorBoxBoundaries.bottom;
    }
  }

  return { x, y };
};

export const getScaleCenterPosition = (
  scale: TScale,
  presentationSize: TSize,
  totalImageSize: TSize
) => {
  let x = 0;
  let y = 0;

  const stageViewCursorBox = getStageViewCursorBox(totalImageSize, scale);
  const cursorBoxBoundaries = getCursorBoxBoundaries(
    presentationSize,
    stageViewCursorBox
  );

  if (stageViewCursorBox.height < presentationSize.height) {
    if (cursorBoxBoundaries.left === 0 && cursorBoxBoundaries.right === 0) {
      x = 0;
    }

    y = (presentationSize.height - stageViewCursorBox.height) / 2;
  }

  if (stageViewCursorBox.width < presentationSize.width) {
    x = (presentationSize.width - stageViewCursorBox.width) / 2;

    if (cursorBoxBoundaries.top === 0 && cursorBoxBoundaries.bottom === 0) {
      y = 0;
    }
  }

  return { x, y };
};

/**
 * Retrieves the upper count bound for the closest day (less than grow day) overview based on the given grow day.
 *
 * @param {number} growDay - The grow day for which to retrieve the upper count bound.
 * @param {Record<string, number>} labelOverviewUpperCountBound - The upper count bounds for label overviews.
 * @returns {number} The upper count bound for the closest label overview.
 */
export function getClosestLabelUpperBoundCount(
  growDay: number,
  labelOverviewUpperCountBound: Record<string, number>
) {
  const keyByDayNumberMap = Object.entries(labelOverviewUpperCountBound).reduce(
    (acc, [dayKey, upperBoundValue]) => {
      // Convert day string "day_0" to number 0
      const dayNumber = Number(dayKey.split('_')[1]);
      return {
        ...acc,
        [dayNumber]: upperBoundValue,
      };
    },
    {} as Record<number, number>
  );

  const dayNumbersSortDescending = Object.keys(keyByDayNumberMap)
    .sort((a, b) => Number(b) - Number(a))
    .map((day) => Number(day));
  // find the day number that is closest to the current day and is less than day number
  let startIndex = 0;
  let closestDayNumber = dayNumbersSortDescending[startIndex]!;
  while (growDay < closestDayNumber) {
    closestDayNumber = dayNumbersSortDescending[startIndex++]!;
  }
  return keyByDayNumberMap[closestDayNumber];
}

/**
 * Calculates the offset for highlighting a section based on the given scale.
 *
 * @param {number} scale - The scale value used to calculate the offset.
 * @return {number} The offset value for highlighting the section.
 */
export function getSectionHighlightOffset(scale: number = 0): number {
  const minOffset = 0;
  const maxOffset = 25;
  const scaleThreshold = 0.2;
  // Maps range [scale, scaleThreshold] to [maxOffset, minOffset]
  let result = mapRange(scale, 0, scaleThreshold, maxOffset, minOffset);
  // Clamps the calculated font size to be within the minimum and maximum font size
  result = clamp(result, minOffset, maxOffset);
  // Rounds the result to the nearest integer
  result = Math.round(result);
  return result;
}
