import { KonvaEventObject } from 'konva/lib/Node';
import isNil from 'lodash.isnil';
import { Fragment, useCallback, useState } from 'react';
import { Circle, Group, Line, Rect, Text } from 'react-konva';
import { generateSequentialArray } from 'shared/utils/array';

const TEXT_STYLE = {
  marginX: 20,
  marginY: 20,
  offsetRatio: 1.25,
};
const SECTION_FONT_SIZE = 24;

const CULTIVAR_TEXT_COLOR = '#ffffff';
const CULTIVAR_SEPARATION_LINE_COLOR = '#F48E42';
const UNCHECKED_SECTION_COLOR = '#FFFFFF';
const TRANSPARENT_COLOR = '#00000000';

const shadow = {
  shadowBlur: 3,
  shadowColor: 'black',
  shadowOffsetX: 3,
  shadowOffsetY: 3,
  shadowOpacity: 1,
};

type TSameStrainGeometry = {
  topLine: boolean;
  rightLine: boolean;
  bottomLine: boolean;
  leftLine: boolean;
  topLeftCircle: boolean;
  topRightCircle: boolean;
  bottomRightCircle: boolean;
  bottomLeftCircle: boolean;
};

type CultivarsLayerProps = {
  cultivarLayout: number[][];
  strainMap: Record<string, string>;
  sectionWidth: number;
  sectionHeight: number;
  scale: number;
  editMode: boolean;
  checkedGrid: boolean[][];
  onCheck: (args: {
    fromRow: number;
    fromColumn: number;
    toRow: number;
    toColumn: number;
    checked: boolean;
  }) => void;
};

const KonvaCheckbox = ({
  x,
  y,
  size,
  isChecked,
  color,
}: {
  x: number;
  y: number;
  size: number;
  isChecked: boolean;
  color: string;
}) => {
  const checkmarkLine = [
    x + size * 0.3,
    y + size / 2,
    x + size / 2,
    y + size * 0.7,
    x + size * 0.7,
    y + size * 0.3,
  ];
  return (
    <>
      <Circle
        x={x + size / 2}
        y={y + size / 2}
        radius={size / 2}
        fill={isChecked ? color : TRANSPARENT_COLOR}
        stroke={color}
        strokeWidth={size / 10}
      />
      {isChecked && (
        <Line
          points={checkmarkLine}
          stroke={'white'}
          strokeWidth={size / 10}
          lineCap={'round'}
          lineJoin={'round'}
          listening={false}
        />
      )}
    </>
  );
};

const getSameStrainGeometry = (
  cultivarLayout: number[][],
  row: number,
  column: number,
  cellStrain: number | undefined
): TSameStrainGeometry => {
  const leftCellStrain = cultivarLayout?.[row]?.[column - 1];
  const rightCellStrain = cultivarLayout?.[row]?.[column + 1];
  const topCellStrain = cultivarLayout?.[row - 1]?.[column];
  const bottomCellStrain = cultivarLayout?.[row + 1]?.[column];

  const topLine = isNil(topCellStrain) || topCellStrain === cellStrain;
  const rightLine = isNil(rightCellStrain) || rightCellStrain === cellStrain;
  const bottomLine = isNil(bottomCellStrain) || bottomCellStrain === cellStrain;
  const leftLine = isNil(leftCellStrain) || leftCellStrain === cellStrain;

  const topLeftCircle = topLine && leftLine;
  const topRightCircle = topLine && rightLine;
  const bottomRightCircle = bottomLine && rightLine;
  const bottomLeftCircle = bottomLine && leftLine;

  return {
    topLine,
    rightLine,
    bottomLine,
    leftLine,
    topLeftCircle,
    topRightCircle,
    bottomRightCircle,
    bottomLeftCircle,
  };
};

const getElementsVisibility = (
  getSameStrainGeometry: TSameStrainGeometry,
  editMode: boolean,
  row: number,
  column: number,
  numberOfRows: number,
  numberOfColumns: number,
  isChecked: boolean
) => {
  const topLine = (!getSameStrainGeometry.topLine || editMode) && row > 0;
  const rightLine =
    (!getSameStrainGeometry.rightLine || editMode) &&
    column < numberOfColumns - 1;
  const bottomLine =
    (!getSameStrainGeometry.bottomLine || editMode) && row < numberOfRows - 1;
  const leftLine = (!getSameStrainGeometry.leftLine || editMode) && column > 0;
  const topRightCircle =
    isChecked &&
    (!getSameStrainGeometry.topRightCircle || editMode) &&
    column < numberOfColumns - 1 &&
    row > 0;
  const bottomRightCircle =
    (!getSameStrainGeometry.bottomRightCircle || editMode) &&
    column < numberOfColumns - 1 &&
    row < numberOfRows - 1;
  const bottomLeftCircle =
    isChecked &&
    (!getSameStrainGeometry.bottomLeftCircle || editMode) &&
    column > 0 &&
    row < numberOfRows - 1;
  const topLeftCircle =
    (!getSameStrainGeometry.topLeftCircle || editMode) && column > 0 && row > 0;
  return {
    topLine,
    rightLine,
    bottomLine,
    leftLine,
    topRightCircle,
    bottomRightCircle,
    bottomLeftCircle,
    topLeftCircle,
  };
};

const getSectionGeometry = (
  x: number,
  y: number,
  width: number,
  height: number
) => {
  const topLeft = { x, y };
  const topRight = { x: x + width, y };
  const bottomRight = { x: x + width, y: y + height };
  const bottomLeft = { x, y: y + height };
  const topLine = [topLeft.x, topLeft.y, topRight.x, topRight.y];
  const rightLine = [topRight.x, topRight.y, bottomRight.x, bottomRight.y];
  const bottomLine = [bottomRight.x, bottomRight.y, bottomLeft.x, bottomLeft.y];
  const leftLine = [bottomLeft.x, bottomLeft.y, topLeft.x, topLeft.y];
  return {
    topLeft,
    topRight,
    bottomRight,
    bottomLeft,
    topLine,
    rightLine,
    bottomLine,
    leftLine,
  };
};

const CultivarSection = ({
  row,
  column,
  strainMap,
  cultivarLayout,
  checkedGrid,
  width,
  height,
  editMode,
  scale,
  onCheck,
  onHover,
  isHovered,
}: {
  row: number;
  column: number;
  cultivarLayout: number[][];
  checkedGrid: boolean[][];
  strainMap: Record<string, string>;
  width: number;
  height: number;
  editMode: boolean;
  scale: number;
  onCheck: (
    event: KonvaEventObject<Event>,
    row: number,
    column: number
  ) => void;
  onHover: (row: number, column: number, hovered: boolean) => void;
  isHovered: boolean;
}) => {
  const numberOfRows = cultivarLayout.length;
  const numberOfColumns = cultivarLayout[0]?.length ?? 0;

  const maxFontSize = width / 20;
  const scaledFontSize = SECTION_FONT_SIZE / scale;
  const fontSize = Math.min(scaledFontSize, maxFontSize);
  const marginStrokeWidth = editMode ? fontSize / 4 : fontSize / 2;
  const circleRadius = marginStrokeWidth / 2;

  const cellStrain = cultivarLayout?.[row]?.[column];
  const sameStrainGeometry = getSameStrainGeometry(
    cultivarLayout,
    row,
    column,
    cellStrain
  );
  const geometry = getSectionGeometry(
    column * width,
    row * height,
    width,
    height
  );
  const isChecked = !!checkedGrid[row]?.[column];
  const isAssigned = !isNil(cellStrain) && cellStrain !== 0;

  const isVisible = getElementsVisibility(
    sameStrainGeometry,
    editMode,
    row,
    column,
    numberOfRows,
    numberOfColumns,
    isChecked
  );

  const handleMouseEnter = () => {
    onHover(row, column, true);
  };

  const handleMouseLeave = () => {
    onHover(row, column, false);
  };

  let color = CULTIVAR_SEPARATION_LINE_COLOR;
  if (editMode) {
    color =
      isChecked || isHovered
        ? CULTIVAR_SEPARATION_LINE_COLOR
        : UNCHECKED_SECTION_COLOR;
  }

  return (
    <Fragment>
      {editMode && (
        <Rect
          x={geometry.topLeft.x + marginStrokeWidth / 2}
          y={geometry.topLeft.y + marginStrokeWidth / 2}
          width={width - marginStrokeWidth}
          height={height - marginStrokeWidth}
          fill={
            !editMode
              ? TRANSPARENT_COLOR
              : color + (isAssigned || isHovered ? '00' : '50')
          }
          onClick={(event) => onCheck(event, row, column)}
          onTap={(event) => onCheck(event, row, column)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      )}
      <Text
        x={geometry.topLeft.x + TEXT_STYLE.marginX + fontSize}
        y={geometry.topLeft.y + TEXT_STYLE.marginY + fontSize}
        text={String.fromCharCode(65 + row) + '-' + (column + 1)}
        fontSize={fontSize * 2}
        fontFamily={'Poppins'}
        fontStyle="bold"
        fill={CULTIVAR_TEXT_COLOR}
        width={width - TEXT_STYLE.marginX * 2}
        listening={false}
        {...shadow}
      />
      {!isNil(cellStrain) && (
        <Text
          x={geometry.topLeft.x + TEXT_STYLE.marginX + fontSize}
          y={geometry.topLeft.y + TEXT_STYLE.marginY + fontSize + fontSize * 2}
          text={strainMap[cellStrain]}
          fontSize={fontSize * 2}
          fontFamily={'Poppins'}
          fill={CULTIVAR_TEXT_COLOR}
          width={width - TEXT_STYLE.marginX * 2}
          listening={false}
          {...shadow}
        />
      )}

      {editMode && (
        <KonvaCheckbox
          x={geometry.bottomRight.x - fontSize * 3}
          y={geometry.bottomRight.y - fontSize * 3}
          size={fontSize * 2}
          isChecked={!!checkedGrid[row]?.[column]}
          color={color}
        />
      )}

      {isVisible.topLine && (
        <Line
          points={geometry.topLine}
          stroke={
            sameStrainGeometry.topLine ? color : CULTIVAR_SEPARATION_LINE_COLOR
          }
          strokeWidth={marginStrokeWidth}
        />
      )}

      {isVisible.rightLine && (
        <Line
          points={geometry.rightLine}
          stroke={
            sameStrainGeometry.rightLine
              ? color
              : CULTIVAR_SEPARATION_LINE_COLOR
          }
          strokeWidth={marginStrokeWidth}
        />
      )}

      {isVisible.bottomLine && (
        <Line
          points={geometry.bottomLine}
          stroke={
            sameStrainGeometry.bottomLine
              ? color
              : CULTIVAR_SEPARATION_LINE_COLOR
          }
          strokeWidth={marginStrokeWidth}
        />
      )}

      {isVisible.leftLine && (
        <Line
          points={geometry.leftLine}
          stroke={
            sameStrainGeometry.leftLine ? color : CULTIVAR_SEPARATION_LINE_COLOR
          }
          strokeWidth={marginStrokeWidth}
        />
      )}

      {isVisible.topRightCircle && (
        <Circle
          {...geometry.topRight}
          radius={circleRadius}
          fill={
            sameStrainGeometry.topRightCircle
              ? color
              : CULTIVAR_SEPARATION_LINE_COLOR
          }
        />
      )}

      {isVisible.bottomRightCircle && (
        <Circle
          {...geometry.bottomRight}
          radius={circleRadius}
          fill={
            sameStrainGeometry.bottomRightCircle
              ? color
              : CULTIVAR_SEPARATION_LINE_COLOR
          }
        />
      )}

      {isVisible.bottomLeftCircle && (
        <Circle
          {...geometry.bottomLeft}
          radius={circleRadius}
          fill={
            sameStrainGeometry.bottomLeftCircle
              ? color
              : CULTIVAR_SEPARATION_LINE_COLOR
          }
        />
      )}

      {isVisible.topLeftCircle && (
        <Circle
          {...geometry.topLeft}
          radius={circleRadius}
          fill={
            sameStrainGeometry.topLeftCircle
              ? color
              : CULTIVAR_SEPARATION_LINE_COLOR
          }
        />
      )}
    </Fragment>
  );
};

export const CultivarsLayer = ({
  cultivarLayout,
  strainMap,
  sectionWidth: width,
  sectionHeight: height,
  scale,
  editMode,
  checkedGrid,
  onCheck,
}: CultivarsLayerProps) => {
  const [lastCheckedSection, setLastCheckedSection] = useState<{
    row: number;
    column: number;
  } | null>(null);

  const [hoveredSection, setHoveredSection] = useState<{
    row: number;
    column: number;
  } | null>(null);

  const handleCheck = (
    event: KonvaEventObject<Event>,
    row: number,
    column: number
  ) => {
    const shiftPressed = (event.evt as MouseEvent)?.shiftKey;
    const fromRow =
      !isNil(lastCheckedSection?.row) && shiftPressed
        ? lastCheckedSection?.row
        : row;
    const fromColumn =
      !isNil(lastCheckedSection?.column) && shiftPressed
        ? lastCheckedSection?.column
        : column;
    !shiftPressed && setLastCheckedSection({ row, column });
    onCheck({
      fromRow,
      fromColumn,
      toRow: row,
      toColumn: column,
      checked: !checkedGrid[row]?.[column],
    });
  };

  const handleHover = (row: number, column: number, hovered: boolean) => {
    if (hovered) {
      setHoveredSection({ row, column });
    } else {
      setHoveredSection(null);
    }
  };

  const hovered = useCallback(
    (row: number, column: number) => {
      return hoveredSection?.row === row && hoveredSection?.column === column;
    },
    [hoveredSection]
  );

  const numberOfRows = cultivarLayout.length;
  const numberOfColumns = cultivarLayout[0]?.length ?? 0;
  const rows = generateSequentialArray(numberOfRows);
  const columns = generateSequentialArray(numberOfColumns);
  return (
    <Group>
      {/* first unchecked sections */}
      {rows.map((row) => {
        return (
          <Group key={`unchecked-section-row-${row}`}>
            {columns
              .filter(
                (column) => !checkedGrid[row]?.[column] && !hovered(row, column)
              )
              .map((column) => (
                <CultivarSection
                  key={`unchecked-section-row-${row}-column-${column}`}
                  row={row}
                  column={column}
                  strainMap={strainMap}
                  cultivarLayout={cultivarLayout}
                  checkedGrid={checkedGrid}
                  width={width}
                  height={height}
                  scale={scale}
                  editMode={editMode}
                  onCheck={handleCheck}
                  onHover={handleHover}
                  isHovered={false}
                />
              ))}
          </Group>
        );
      })}
      {/* then, on top, checked sections */}
      {rows.map((row) => {
        return (
          <Group key={`checked-section-row-${row}`}>
            {columns
              .filter(
                (column) => checkedGrid[row]?.[column] && !hovered(row, column)
              )
              .map((column) => (
                <CultivarSection
                  key={`checked-section-row-${row}-column-${column}`}
                  row={row}
                  column={column}
                  strainMap={strainMap}
                  cultivarLayout={cultivarLayout}
                  checkedGrid={checkedGrid}
                  width={width}
                  height={height}
                  scale={scale}
                  editMode={editMode}
                  onCheck={handleCheck}
                  onHover={handleHover}
                  isHovered={false}
                />
              ))}
          </Group>
        );
      })}
      {hoveredSection && (
        <CultivarSection
          key={`hovered-section-row-${hoveredSection.row}-column-${hoveredSection.column}`}
          row={hoveredSection.row}
          column={hoveredSection.column}
          strainMap={strainMap}
          cultivarLayout={cultivarLayout}
          checkedGrid={checkedGrid}
          width={width}
          height={height}
          scale={scale}
          editMode={editMode}
          onCheck={handleCheck}
          onHover={handleHover}
          isHovered={true}
        />
      )}
    </Group>
  );
};
