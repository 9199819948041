import { Float, FloatProps } from '@headlessui-float/react';
import { Portal } from '@headlessui/react';
import { Disclosure } from 'hooks/useDisclosure';
import { useIsElementScrolled } from 'hooks/useIsElementScrolled';
import { ChevronLeftIcon, XIcon } from 'lucide-react';
import {
  ComponentPropsWithoutRef,
  FC,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { EEventKeyCodes } from 'shared/interfaces/keys';
import { cn } from 'shared/utils/cn';
import { Button } from '../Button/Button';
import { FloatingAs } from './FloatingAs';

export type PopoverProps = Omit<ComponentPropsWithoutRef<'div'>, 'title'> & {
  title?: ReactNode;
  referenceElement?: Maybe<RefObject<HTMLElement>>;
  onClickBackButton?: () => void;
  floatProps?: Omit<FloatProps, 'children'>;
  headerActions?: ReactNode;
  contentClassName?: string;
  floatingElementClassName?: string;
  showScrollbar?: boolean;
  closeOnWheel?: boolean;
  withBackground?: boolean;
  refreshId?: string;
} & Pick<Disclosure, 'close' | 'isOpen'>;

export const Popover: FC<PopoverProps> = ({
  title,
  referenceElement,
  children,
  headerActions,
  floatProps,
  className,
  isOpen,
  contentClassName,
  floatingElementClassName,
  close,
  onClickBackButton,
  refreshId,
  showScrollbar = false,
  closeOnWheel = false,
  withBackground = true,
  ...props
}) => {
  const floatRef = useRef(null);
  const contentRef = useRef(null);
  const isContentScrolled = useIsElementScrolled(contentRef.current);
  const { top, left, width, height } = (() => {
    if (!referenceElement?.current) {
      return { top: 0, left: 0, width: 0, height: 0 };
    }
    if (floatProps?.strategy === 'fixed') {
      return referenceElement.current.getBoundingClientRect();
    }
    return {
      top: referenceElement.current.offsetTop,
      left: referenceElement.current.offsetLeft,
      width: referenceElement.current.offsetWidth,
      height: referenceElement.current.offsetHeight,
    };
  })();

  useHotkeys(EEventKeyCodes.ESCAPE, close!, { enabled: isOpen }, [close]);
  const overlayRef = useRef<Nullable<HTMLDivElement>>(null);
  useEffect(() => {
    if (!closeOnWheel) return;
    const ref = overlayRef.current;
    if (!ref) return;
    const handleWheel = () => {
      if (isOpen) {
        close?.();
      }
    };
    ref.addEventListener('wheel', handleWheel);
    return () => {
      ref.removeEventListener('wheel', handleWheel);
    };
  }, [isOpen, close, closeOnWheel]);

  const onUpdate = useCallback(() => {
    // Deliberately left empty
    // This is needed for smooth animations of the DiscussionBox and to prevent
    // re-renders at every button press of an input.
    // TODO: Remove this once we render the DiscussionBox after the animation.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshId]);

  return (
    <div className={className}>
      {/* overlay element */}
      {withBackground && (
        <Portal>
          {isOpen && (
            <div ref={overlayRef} className="fixed inset-0" onClick={close} />
          )}
        </Portal>
      )}

      <Float
        floatingAs={FloatingAs}
        transform
        portal
        placement="top-start"
        offset={8}
        strategy="absolute"
        autoUpdate
        flip
        zIndex="unset"
        show={isOpen}
        onUpdate={onUpdate}
        {...floatProps}
      >
        {/* reference element */}
        <div
          className={cn('absolute', floatProps?.strategy)}
          style={{ top, left, width, height }}
        />

        {/* floating element */}

        <div
          ref={floatRef}
          role="dialog"
          className={cn(
            'flex flex-col w-screen h-svh bg-white overflow-hidden',
            'sm:max-w-sm sm:h-fit sm:max-h-[75dvh] sm:rounded sm:shadow',
            floatingElementClassName
          )}
          {...props}
        >
          {/* popover header */}
          <div
            className={cn(
              'relative flex justify-between items-center p-4 gap-2',
              'z-10',
              isContentScrolled && 'shadow'
            )}
          >
            {onClickBackButton && (
              <Button
                variant="tertiary"
                size="icon"
                onClick={onClickBackButton}
                aria-label="Back"
              >
                <ChevronLeftIcon className="stroke-[1.5px] size-4 xl:size-5" />
              </Button>
            )}

            <p className="text-xl font-semibold truncate">{title}</p>

            <div className="flex flex-row gap-2 ml-auto">
              {headerActions}
              <Button
                variant="tertiary"
                size="icon"
                onClick={close}
                aria-label="Close modal"
              >
                <XIcon className="stroke-[1.5px] size-4 xl:size-5" />
              </Button>
            </div>
          </div>

          {/* popover content */}
          <div
            ref={contentRef}
            className={cn(
              'w-full h-full flex-1 overflow-y-auto p-4 pt-0',
              showScrollbar ? '' : 'no-scrollbar',
              contentClassName
            )}
          >
            {children}
          </div>
        </div>
      </Float>
    </div>
  );
};
