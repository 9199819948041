import { useGetLocationById, useUpdateLocationMetadata } from 'api/location';
import { Checkbox } from 'components/common/Checkbox/Checkbox';
import * as toast from 'components/common/Toast/Toast';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import {
  ChangeEventHandler,
  ComponentPropsWithoutRef,
  forwardRef,
} from 'react';
import { TLocation } from 'shared/interfaces/location';
import {
  ELightCycleType,
  EMeasurementStatisticsTypesV2,
  MeasurementTypeConfig,
} from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';

type LightCycle = ELightCycleType.Dark | ELightCycleType.Light;

type LocationMetadataKeys = keyof TLocation['metadata'];

const { Dark, Light } = ELightCycleType;

const checkboxAriaLabel = (
  type: MeasurementTypeConfig,
  isChecked: boolean,
  lightCycle: string
) =>
  `The critical alert for ${type.label} is ${isChecked ? 'active' : 'inactive'} during the ${lightCycle}`;

interface FacilityNotificationsProps extends ComponentPropsWithoutRef<'div'> {
  locationId: TLocation['id'];
  readOnly: boolean;
}

export const FacilityNotifications = forwardRef<
  HTMLDivElement,
  FacilityNotificationsProps
>(function FacilityNotifications(
  { className, locationId, readOnly, ...props },
  ref
) {
  const { presetTypes } = useTypeConfig();
  const { location } = useGetLocationById(locationId);
  const { update } = useUpdateLocationMetadata(locationId);
  const isChecked = (
    typeKey: EMeasurementStatisticsTypesV2,
    lightCycle: LightCycle
  ) => {
    if (!location) {
      return false;
    }
    const metadataKey: LocationMetadataKeys = `${lightCycle}_environment_parameters`;
    return location.metadata[metadataKey].some((type) => type === typeKey);
  };
  const handleCheckSetting =
    (
      typeKey: EMeasurementStatisticsTypesV2,
      lightCycle: LightCycle
    ): ChangeEventHandler<HTMLInputElement> =>
    async (event) => {
      if (!location) {
        return null;
      }
      try {
        const metadataKey: LocationMetadataKeys = `${lightCycle}_environment_parameters`;
        const metadata: TLocation['metadata'] = { ...location.metadata };
        if (event.target.checked) {
          metadata[metadataKey] = [...location.metadata[metadataKey], typeKey];
        } else {
          metadata[metadataKey] = location.metadata[metadataKey].filter(
            (type) => type !== typeKey
          );
        }

        await update(metadata);

        toast.success(
          { content: 'Change successfully saved.' },
          { autoClose: 3000, toastId: 'update-facility-notification-success' }
        );
      } catch (_error) {
        toast.error(
          {
            content:
              'Something went wrong while trying to save your changes. Please try again.',
          },
          { toastId: 'update-facility-notification-error' }
        );
      }
    };

  if (!location) {
    return null;
  }

  return (
    <div
      ref={ref}
      {...props}
      className={cn('w-full grid grid-cols-[5fr_1fr_1fr] pt-2', className)}
    >
      <div className="grid col-span-3 grid-cols-subgrid py-2 pb-4 font-semibold border-b-[1px] border-b-neutral-400">
        <div>Measurement</div>
        <div className="justify-self-center">Day</div>
        <div className="justify-self-center">Night</div>
      </div>
      <ul
        className="grid col-span-3 grid-cols-subgrid divide-y divide-dashed divide-neutral-400"
        aria-label="Measurements"
      >
        {presetTypes.map((type) => {
          const isDayChecked = isChecked(type.statisticsKeyV2, Light);
          const isNightChecked = isChecked(type.statisticsKeyV2, Dark);

          return (
            <li
              key={type.label}
              className="grid col-span-3 grid-cols-subgrid py-2 items-center"
              aria-label={type.label}
            >
              <div>{type.label}</div>
              <div className="justify-self-center">
                <Checkbox.Input
                  checked={isDayChecked}
                  readOnly={readOnly}
                  aria-label={checkboxAriaLabel(type, isDayChecked, 'Day')}
                  onChange={handleCheckSetting(type.statisticsKeyV2, Light)}
                />
              </div>
              <div className="justify-self-center">
                <Checkbox.Input
                  checked={isNightChecked}
                  readOnly={readOnly}
                  aria-label={checkboxAriaLabel(type, isNightChecked, 'Night')}
                  onChange={handleCheckSetting(type.statisticsKeyV2, Dark)}
                />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
});
