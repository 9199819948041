import { NavigateOptions } from 'react-router-dom';
import { ERoutePath } from 'shared/constants/url';
import { MeasurementAggregation } from 'shared/interfaces/measurement';
import { getQueryParams, QueryParamsSchema } from './URLQueryParams';
import { StoreArgs } from './URLStoreArgs';

export const getLineChartStore = (args: StoreArgs<ERoutePath.LINE_CHART>) => {
  const { routePath, searchParams, setSearchParams } = args;
  const queryParams = getQueryParams(searchParams, routePath);
  type SchemaType = QueryParamsSchema<typeof routePath>;
  const actions = {
    setSignalIds: (
      value: SchemaType['signal-ids'],
      navigateOptions?: NavigateOptions
    ) => {
      setSearchParams(
        {
          'signal-ids': value,
          'discussion-uid': undefined,
        },
        navigateOptions
      );
    },
    setShowComments: (showComments: boolean) => {
      setSearchParams(
        {
          'show-comments': showComments,
          'discussion-uid': undefined,
        },
        { replace: true }
      );
    },
    setShowSummary: (show: boolean) => {
      setSearchParams(
        {
          'show-summary': show,
        },
        { replace: true }
      );
    },
    setDiscussionUid: (discussionUid: SchemaType['discussion-uid']) => {
      setSearchParams(
        {
          'discussion-uid': discussionUid,
        },
        { replace: true }
      );
    },
    setViewType: (value: SchemaType['view-type']) => {
      setSearchParams({
        'view-type': value,
        'discussion-uid': undefined,
      });
    },
  };
  const state = {
    signalIds: queryParams['signal-ids'] ?? [],
    viewType: queryParams['view-type'] ?? MeasurementAggregation.AVERAGE,
    showComments: queryParams['show-comments'],
    showSummary: queryParams['show-summary'],
    discussionUid: queryParams['discussion-uid'],
  };
  return {
    ...actions,
    ...state,
  };
};
