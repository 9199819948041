import {
  GetMeasurementsOnGridByRunIdAndSensorTypeIdData,
  useGetMeasurementsByIds,
  useGetMeasurementsOnGridByRunIdAndSensorTypeId,
} from 'api/measurement';
import { useGetLabelAnnotationStatus } from 'components/image_feed/hooks/useGetLabelAnnotationStatus';
import { convertIntoMeasurementRunFrame } from 'components/image_feed/utils';
import { useImageFeedURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import isNil from 'lodash.isnil';
import { useMemo, useState } from 'react';
import { EImageTypes, EImageViewTypes } from 'shared/interfaces/image';
import {
  IMeasurementRunFrame,
  TMeasurementRun,
} from 'shared/interfaces/measurementRun';
import { IGridView } from '../types';

interface UseGetBaseImagesByZoneIdAndSensorProps {
  measurementRun: Nullable<TMeasurementRun>;
  measurementRuns: TMeasurementRun[];
  imageType: EImageTypes;
}

export const useGetImagesByZoneIdAndSensor = ({
  measurementRun,
  measurementRuns,
  imageType,
}: UseGetBaseImagesByZoneIdAndSensorProps) => {
  const { pendingStatusId, notifiedStatusId } = useGetLabelAnnotationStatus();
  const { imageViewType } = useImageFeedURL();

  const [gridViewData, setGridViewData] =
    useState<Optional<GetMeasurementsOnGridByRunIdAndSensorTypeIdData>>(
      undefined
    );

  const { loading: gridViewLoading } =
    useGetMeasurementsOnGridByRunIdAndSensorTypeId({
      measurementRun,
      imageType,
      options: {
        onCompleted: (data) => {
          setGridViewData(data);
        },
      },
    });

  const measurementIds = useMemo(
    () => gridViewData?.measurementIds || [],
    [gridViewData]
  );

  const {
    measurementsById,
    loading: measurementsLoading,
    called: measurementsCalled,
  } = useGetMeasurementsByIds({
    measurementIds,
    options: {
      skip: imageViewType !== EImageViewTypes.GRID,
    },
  });

  const measurementsGridView: IGridView = useMemo(() => {
    const measurementList = measurementIds.map((measurementId) => {
      return {
        id: measurementId,
        data: isNil(measurementId)
          ? null
          : measurementsById[measurementId]?.data,
      };
    });

    return {
      measurementRun,
      measurementList,
      size: {
        row: gridViewData?.numberOfRows || 0,
        column: gridViewData?.numberOfColumns || 0,
      },
    };
  }, [measurementsById, gridViewData, measurementRun, measurementIds]);

  const captures = useMemo(() => {
    const captures: IMeasurementRunFrame[] = measurementRuns.map((run) =>
      convertIntoMeasurementRunFrame(
        run,
        pendingStatusId?.enumeration?.[0]?.id,
        notifiedStatusId?.enumeration?.[0]?.id
      )
    );
    return captures;
  }, [measurementRuns, pendingStatusId, notifiedStatusId]);

  return {
    captures,
    gridViewLoading,
    gridViewData,
    measurementIds,
    measurementsById,
    measurementsGridView,
    measurementsLoading,
    measurementsCalled,
  };
};
