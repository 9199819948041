import { useAuth } from 'contexts/AuthProvider';
import {
  useGetEnumerationsByTypeQuery,
  useGetUsersByOrganizationQuery,
} from 'graphql/generated/react_apollo';
import isNil from 'lodash.isnil';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { ALL } from 'shared/constants/general';
import { EEnumerationTypes, TEnumeration } from 'shared/interfaces/enumeration';
import { NEATLEAF_ORGANIZATION_CODE } from 'shared/interfaces/organization';
import { ERole } from 'shared/interfaces/role';
import { TUser, TUserRoleType } from 'shared/interfaces/user';
import { getUserOrganizations } from 'shared/utils/user';

/**
 * UsersContext definition
 */
export interface IUseUsersReturn {
  /** `true` if loading the user information, `false` otherwise. */
  loading: boolean;
  /** Refetch the user list. */
  refetchUserList: VoidFunction;
  /** The users list. */
  users: TUser[];
  /** The user roles by code. */
  userRolesByCode: Record<string, TUserRoleType>;
  /** The user role type list. */
  userRoleTypes: TUserRoleType[];
  /** The user role type list with all option. */
  userRoleTypesWithAll: TUserRoleType[];
}

export const useUsers = (): IUseUsersReturn => {
  const { currentlySelectedOrganization, isNeatleafOrganizationMember } =
    useAuth();

  const {
    data,
    loading,
    refetch: refetchUserList,
  } = useGetUsersByOrganizationQuery({
    fetchPolicy: 'cache-and-network',
    variables: { organizationId: currentlySelectedOrganization?.id! },
    skip: isNil(currentlySelectedOrganization),
  });

  const users = useMemo(() => {
    const users = data?.user ?? [];
    if (isNeatleafOrganizationMember) return users;
    return users.filter((user) => {
      const organizationList = getUserOrganizations(user);
      return organizationList.every(
        ({ code }) => code !== NEATLEAF_ORGANIZATION_CODE
      );
    });
  }, [data, isNeatleafOrganizationMember]);

  const { data: enumerationsData } = useGetEnumerationsByTypeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      types: [EEnumerationTypes.ROLE_TYPE],
    },
  });

  const roleEnumerations = useMemo(
    () =>
      (enumerationsData?.enumeration || []).map((enumeration) => {
        return {
          id: enumeration.id,
          code: enumeration.code,
          type: enumeration.type,
          description: enumeration.description,
          metadata: enumeration.metadata,
        };
      }),
    [enumerationsData]
  );

  const userRoleTypes = useMemo(() => {
    const partionedRoleMap = roleEnumerations.reduce(
      (partitionMap, enumeration) => {
        let key = ERole.ANY_ROLE;
        if (enumeration.metadata?.is_neatleaf_only) {
          key = ERole.NEATLEAF_ONLY_ROLE;
        }
        if (isNil(partitionMap[key])) {
          partitionMap[key] = [];
        }
        partitionMap[key].push(enumeration);
        return partitionMap;
      },
      {} as Record<ERole, TEnumeration[]>
    );

    let roleList = partionedRoleMap[ERole.ANY_ROLE] ?? [];
    if (isNeatleafOrganizationMember) {
      roleList = roleList.concat(partionedRoleMap[ERole.NEATLEAF_ONLY_ROLE]);
    }

    return roleList
      .filter((role) => !isNil(role))
      .map((role) => ({
        id: role.id,
        code: role.code,
        label: role.description,
        filterLabel: pluralize(role.description),
      }));
  }, [roleEnumerations, isNeatleafOrganizationMember]);

  const userRolesByCode = useMemo(
    () =>
      userRoleTypes.reduce(
        (rolesByCode, role) => ({
          ...rolesByCode,
          [role.code]: role,
        }),
        {}
      ),
    [userRoleTypes]
  );

  const userRoleTypesWithAll = useMemo(
    () => [
      { id: 0, label: ALL, filterLabel: ALL, code: ALL },
      ...userRoleTypes,
    ],
    [userRoleTypes]
  );

  return {
    loading,
    refetchUserList,
    users,
    userRolesByCode,
    userRoleTypes,
    userRoleTypesWithAll,
  };
};
