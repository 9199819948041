import { useGetDiscussions } from 'api/discussion';
import { TextEditor } from 'components/common/TextEditor/TextEditor';
import { useAuth } from 'contexts/AuthProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useGrowthCycleReport } from 'hooks/useGrowthCycleReport';
import { annotationTypes, TAnnotationType } from 'shared/interfaces/discussion';
import { formatDateEEEMMMMdhmmaa } from 'shared/utils/date';

export const GrowthCycleReportDiscussions = () => {
  const { user } = useAuth();
  const { currentZone } = useCurrentZone();
  const { selectedGrowthCycle } = useGrowthCycleReport();
  const { discussions } = useGetDiscussions({
    annotationTypes: annotationTypes as unknown as TAnnotationType[],
    zone: currentZone,
    enabled: !!selectedGrowthCycle,
    startTime: selectedGrowthCycle?.start_time,
    endTime: selectedGrowthCycle?.end_time,
    userId: user?.id!,
    canViewInsighDraft: false,
  });
  return (
    <div className="flex flex-col gap-2">
      <h2 className="text-xl">Discussions</h2>
      <div className="max-h-[500px] overflow-scroll flex flex-col gap-2 px-1">
        {discussions.map((discussion) => {
          return (
            <div
              key={discussion.uid}
              className="break-words flex flex-col bg-white px-6 py-4 rounded"
            >
              <span className="font-semibold">{discussion.displayLabel}</span>
              <TextEditor
                initialEditorState={discussion.firstComment.content.content}
                editable={false}
                readMoreContentLength={280}
                availableMentions={[]}
              />
              <div className="flex gap-2 text-sm">
                <span>{discussion.firstComment.authorName}</span>
                <span className="text-gray-500">
                  {discussion.firstComment.createdAt &&
                    formatDateEEEMMMMdhmmaa(discussion.firstComment.createdAt)}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
