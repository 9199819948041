import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getTrackedSearchParams } from './search-params-util';
import { useAnalytics } from './useAnalytics';

export const useTrackSearchParams = (
  searchParamsKeysThatShouldBeTracked: string[]
) => {
  const { sendSearchParamsChange } = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    sendSearchParamsChange(
      getTrackedSearchParams(
        location.search,
        searchParamsKeysThatShouldBeTracked
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);
};
