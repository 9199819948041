import { DEFAULT_STEP_VALUES } from 'shared/constants/heatmap';
import {
  EGradientTypes,
  THeatMapScaleDataType,
} from 'shared/interfaces/heatmap';
import {
  MeasurementTypeConfig,
  TMeasurementStatistic,
  TMontioringParameterType,
} from 'shared/interfaces/measurement';

interface IGetHeatMapScaleInput {
  currentRunStatistic: TMeasurementStatistic | null;
  gradientType: EGradientTypes;
  globalStatistic: TMeasurementStatistic | null;
  typeConfig: Partial<MeasurementTypeConfig>;
  optimalRanges: TMontioringParameterType | undefined;
}

export const getHeatMapScale = ({
  currentRunStatistic,
  globalStatistic,
  gradientType,
  typeConfig,
  optimalRanges,
}: IGetHeatMapScaleInput): THeatMapScaleDataType => {
  if (!globalStatistic || !currentRunStatistic) {
    return DEFAULT_STEP_VALUES;
  }

  const result: THeatMapScaleDataType = { ...DEFAULT_STEP_VALUES };
  result.currentRunLowerBound = currentRunStatistic.min;
  result.currentRunUpperBound = currentRunStatistic.max;
  const statistic =
    gradientType === EGradientTypes.MICROCLIMATES
      ? currentRunStatistic
      : globalStatistic;
  result.lowerBound = statistic.min;
  result.upperBound = statistic.max;

  if (typeConfig.hasOptimalRange) {
    if (!optimalRanges) {
      return result;
    }
    result.optimalRangeLowerBound = optimalRanges.optimal_range.min;
    result.optimalRangeUpperBound = optimalRanges.optimal_range.max;
    result.setPoint =
      optimalRanges.set_point ??
      (optimalRanges.optimal_range.min + optimalRanges.optimal_range.max) / 2;
  }

  return result;
};
