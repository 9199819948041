import {
  addDays,
  addMinutes,
  differenceInDays,
  isAfter,
  isBefore,
  subDays,
} from 'date-fns';
import isEqual from 'lodash.isequal';
import isNil from 'lodash.isnil';
import memoize from 'memoizee';
import { DEFAULT_ZONE_MAP_URL, ZONE_TO_URL_MAP } from 'shared/constants/zone';
import { TLightInfo } from 'shared/interfaces/growthCycle';
import { ELightCycleType, IDayRange } from 'shared/interfaces/measurement';
import { TZone } from 'shared/interfaces/zone';
import {
  epochSecondsToDate,
  formatDateInFullDayTimeRounded,
  roundDateToNearestMinute,
} from 'shared/utils/date';

/**
 * Get the local storage item and parse it into JSON.
 * @param {string} key localStorage key to get the data from
 * @returns {unknown} localStorage value associated with the key
 */
export function getLocalStorageJSONItem(key: string): unknown {
  try {
    return JSON.parse(localStorage.getItem(key) || '{}');
  } catch {
    return null;
  }
}

export const isBetween = (x: number, a: number, b: number) => {
  if (b > a) {
    return a <= x && x <= b;
  } else {
    return b <= x && x <= a;
  }
};

export const mathRoundTo2Decimals = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100;

/**
 * Gets the light info closest to the specified date
 *
 * @param {Date} date Target date.
 * @param {Maybe<TLightInfo[]>} lightInfo List of light info.
 * @returns {Maybe<TLightInfo>}  lightInfo
 */
const getClosestLightInfoFunction = (
  date: Date,
  lightInfo: Maybe<TLightInfo[]>
): Maybe<TLightInfo> => {
  if (isNil(lightInfo) || lightInfo.length === 0) {
    return undefined;
  }
  // Find closest light info
  const lightInfoDescending = [...lightInfo].sort(
    (a: any, b: any) =>
      b.light_cycle_configuration_start_timestamp_seconds -
      a.light_cycle_configuration_start_timestamp_seconds
  );
  let closestLightInfo = lightInfoDescending[0];

  for (let index = 0; index < lightInfoDescending.length; index++) {
    const currentLightInfo = lightInfoDescending[index]!;
    const lightInfoStartTime = epochSecondsToDate(
      currentLightInfo.light_cycle_configuration_start_timestamp_seconds
    );
    closestLightInfo = lightInfoDescending[index];
    if (
      isAfter(date, lightInfoStartTime) ||
      isEqual(date, lightInfoStartTime)
    ) {
      break;
    }
  }
  return closestLightInfo;
};

// Memoized version of getClosestLightInfoFunction.
// Using normalized verions arguments lightInfoList and date.
export const getClosestLightInfo = memoize(getClosestLightInfoFunction, {
  normalizer: (args) => {
    const date: Optional<Date> = args[0];
    if (!date) throw new Error('Missing date argument');
    const lightInfoList: Maybe<TLightInfo[]> = args[1];
    let hash = '';
    if (isNil(lightInfoList) || lightInfoList.length === 0) {
      hash = 'get_closest_light_info_no_light_info';
    } else if (!isNil(lightInfoList) && lightInfoList.length === 1) {
      hash = `${
        lightInfoList[0]!.light_cycle_configuration_start_timestamp_seconds
      }-${lightInfoList[0]!.light_on_duration_minutes}-${
        lightInfoList[0]!.light_on_start_time?.hour
      }-${lightInfoList[0]!.light_on_start_time?.minute}`;
    } else {
      const lightInfoStartTimeListString = lightInfoList
        .map(
          (lightInfo: TLightInfo) =>
            `${lightInfo.light_cycle_configuration_start_timestamp_seconds}-${lightInfo.light_on_duration_minutes}-${lightInfo.light_on_start_time?.hour}-${lightInfo.light_on_start_time?.minute}`
        )
        .join(', ');
      const dateString = formatDateInFullDayTimeRounded(date);
      hash = `${lightInfoStartTimeListString}, ${dateString}`;
    }
    return hash;
  },
});

/**
 * This is a temporary function until we have this implemented on backend
 *
 * @param {Maybe<string>} zoneLabel - The zone label
 * @returns {string} - The zone map url
 */
export const getZoneImageUrl = (currentZone: Maybe<TZone>): string => {
  let url = currentZone?.metadata?.image_url;
  if (isNil(url)) {
    url = ZONE_TO_URL_MAP.get(currentZone?.uid || '') ?? DEFAULT_ZONE_MAP_URL;
  }
  return url;
};

export interface IEmailAddress {
  name: string;
  company: string;
  domain: string;
}

/**
 * Parses email into constituent parts
 *
 * @param {string} email Email to be parsed
 * @returns {*}  {IEmailAddress}
 */
export const parseEmail = (email: string): IEmailAddress => {
  try {
    const [name, companyDomain] = email.split('@');
    const [company, domain] = (companyDomain ?? '').split('.');
    return {
      name: name ?? '',
      company: company ?? '',
      domain: domain ?? '',
    };
  } catch (_error) {
    return {
      name: '',
      company: '',
      domain: '',
    };
  }
};

/**
 * Convert a circular JSON object to a string.
 * @param {any} obj The circular JSON object to convert.
 * @returns {string} The string representation of the circular JSON object.
 */
export function stringifyJSON(obj: any): string {
  let cache: Nullable<any[]> = [];
  const cachingFn = (_key: string, value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (cache!!.includes(value)) {
        return '[Circular]';
      }
      cache!!.push(value);
    }
    return value;
  };
  const result = JSON.stringify(obj, cachingFn, 2);
  cache = null;
  return result;
}

/**
 * Get the grow day based on current date and growth cycle information.
 *
 * @param {Date | number} date current date
 * @param {Date | number} growthCycleDataStartDate growth cycle data
 * @returns {number} result between 1 and N days
 */
export const getGrowDay = (
  date: Date | number,
  growthCycleDataStartDate: Date | number
): number => {
  let result = differenceInDays(date, growthCycleDataStartDate);
  result = Math.max(result, 1);
  return result;
};

/**
 * Returns the day range given the light information
 *
 * @param {TLightInfo} lightInfo the light information
 * @param {Date} date the given date
 * @returns {Maybe<IDayRange>} the day range of given date
 */
const getDayRangeFunction = (
  lightInfo: TLightInfo[],
  date: Date
): Maybe<IDayRange> => {
  if (isNil(lightInfo) || lightInfo.length === 0) {
    return undefined;
  }
  const dateRounded = roundDateToNearestMinute(date);
  const dayStartTime = new Date(dateRounded);
  let dayEndTime = new Date(dateRounded);
  const closestLightInfo = getClosestLightInfo(dateRounded, lightInfo)!;
  if (dayStartTime.getHours() < closestLightInfo?.light_on_start_time?.hour) {
    dayStartTime.setDate(dayStartTime.getDate() - 1);
  }
  dayStartTime.setHours(closestLightInfo?.light_on_start_time?.hour);
  dayStartTime.setMinutes(closestLightInfo?.light_on_start_time?.minute);
  dayEndTime = addMinutes(
    new Date(dayStartTime),
    closestLightInfo.light_on_duration_minutes
  );

  const nightEndTime = addDays(dayStartTime, 1);
  const nightEndTimeClosestLightInfo = getClosestLightInfo(
    nightEndTime,
    lightInfo
  )!;
  const nightEndTimeClosestLightDate = epochSecondsToDate(
    nightEndTimeClosestLightInfo.light_cycle_configuration_start_timestamp_seconds
  );
  const isTransitionDay =
    nightEndTime.getMonth() === nightEndTimeClosestLightDate.getMonth() &&
    nightEndTime.getDate() === nightEndTimeClosestLightDate.getDate();
  if (isTransitionDay) {
    // check nightEndTime against next days dayStartTime
    const tomorrowDayRange = getDayRange(lightInfo, nightEndTime)!;
    nightEndTime.setHours(tomorrowDayRange.dayStartTime.getHours());
    nightEndTime.setMinutes(tomorrowDayRange.dayStartTime.getMinutes());
  }
  return {
    dayStartTime,
    dayEndTime,
    nightStartTime: dayEndTime,
    nightEndTime,
  };
};

// Memoized version of getDayRangeFunction.
// Using normalized verions arguments lightInfoList and date.
export const getDayRange = memoize(getDayRangeFunction, {
  normalizer: (args) => {
    const lightInfoList: TLightInfo[] = args[0];
    const date: Optional<Date> = args[1];
    if (!date) throw new Error('Missing date argument');
    let hash = '';
    if (isNil(lightInfoList) || lightInfoList.length === 0) {
      hash = 'get_day_range_no_light_info';
    } else {
      const lightInfoStartTimeListString = lightInfoList
        .map(
          (lightInfo: TLightInfo) =>
            `${formatDateInFullDayTimeRounded(
              epochSecondsToDate(
                lightInfo.light_cycle_configuration_start_timestamp_seconds
              )
            )}-${
              lightInfoList[0]!.light_on_duration_minutes
            }-${lightInfoList[0]!.light_on_start_time?.hour}-${
              lightInfoList[0]!.light_on_start_time?.minute
            }`
        )
        .join(', ');
      const dateString = date.toISOString();
      hash = `${lightInfoStartTimeListString}, ${dateString}`;
    }
    return hash;
  },
});

/**
 * Determines if current date time is during the light or dark cycle
 *
 * @param {Date} date current date time
 * @param {Maybe<IDayRange>} dayRange range for light cycle
 * @returns {ELightCycleType}  ELightCycleType.Light or ELightCycleType.Dark
 */
export const getLightCycle = (
  date: Date | number,
  dayRange: Maybe<IDayRange>
): ELightCycleType => {
  if (isNil(dayRange)) {
    return ELightCycleType.Undefined;
  }
  const isWithinTodayDayRange =
    isAfter(date, dayRange.dayStartTime) && isBefore(date, dayRange.dayEndTime);
  return isWithinTodayDayRange ? ELightCycleType.Light : ELightCycleType.Dark;
};

/**
 * Whether the provided date is light or dark according the given lightInfo
 *
 * @param {TLightInfo[]} lightInfo The light info
 * @param {Date} date The date to check
 * @returns {boolean}
 */
export const isLight = (lightInfo: TLightInfo[], date: Date): boolean => {
  const dayRange = getDayRange(lightInfo, date);
  return getLightCycle(date, dayRange) === ELightCycleType.Light;
};

export function getLighCyclesRanges({
  lightInfo,
  start,
  end,
}: {
  lightInfo: TLightInfo[];
  start: Date;
  end: Date;
}) {
  const dayRangeList = [];
  const RANGE_DELTA_DAYS = 2;
  for (
    let date = subDays(start, RANGE_DELTA_DAYS);
    date <= addDays(end, RANGE_DELTA_DAYS);
    date = addDays(date, 1)
  ) {
    const dayRange = getDayRange(lightInfo, date);

    if (dayRange) {
      dayRangeList.push(dayRange);
    }
  }

  return dayRangeList;
}
