import { GrowthCycleNotes } from './GrowthCycleNotes';
import { HealthScore } from './HealthScore';
import { OverallStatus } from './OverallStatus';
import { PlantHealth } from './PlantHealth';
import { RecentInsights } from './RecentInsights';
import { SelectZone } from './SelectZone';

export const HomepageV2 = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-3">
      <div className="lg:max-w-[360px] xl:max-w-[420px] flex flex-col gap-3">
        <SelectZone className="lg:hidden" />
        <OverallStatus />
        <GrowthCycleNotes />
      </div>
      <div className="w-full flex-1 flex flex-col gap-3 overflow-hidden">
        <div className="w-full flex-1 flex flex-col lg:flex-row gap-3 ">
          <HealthScore className="lg:flex-1 min-h-[399px] lg:min-h-[419px] xl:min-h-[528px]" />
          <RecentInsights className="lg:flex-1 lg:hidden xl:flex xl:max-w-[372px]" />
        </div>
        <PlantHealth className="lg:flex-1" />
      </div>
    </div>
  );
};
