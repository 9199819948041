import { captureMessage } from '@sentry/core';
import {
  useInsertDiscussion,
  useUpdateDiscussion,
  useUpdateDiscussionStatus,
} from 'api/discussion';
import { useAuth } from 'contexts/AuthProvider';
import { max, min } from 'date-fns';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { usePermissions } from 'hooks/usePermissions';
import isNil from 'lodash.isnil';
import mapValues from 'lodash.mapvalues';
import { ReactNode, createContext, useEffect, useMemo, useState } from 'react';
import {
  InsightCommentContent,
  InsightSeverity,
  InsightType,
  TComment,
  TCommentDraft,
  TDiscussion,
  TDiscussionDraft,
  TDiscussionStatus,
  TDiscussionType,
  eventCategories,
  heatMapAggregateAnnotationCategories,
  heatMapAnnotationCategories,
  singleImageAnnotationCategories,
  timeRangeAnnotationCategories,
} from 'shared/interfaces/discussion';
import { TTimeRange } from 'shared/interfaces/general';
import { NEATLEAF_ORGANIZATION_CODE } from 'shared/interfaces/organization';
import { formatTimeInSemiFullStyle } from 'shared/utils/date';
import {
  getDefaultCategoryFromAnnotationType,
  getDiscussionLabel,
} from 'shared/utils/discussion';
import { v4 } from 'uuid';
import { z } from 'zod';
import { useNavigateToDiscussion } from './hooks/useNavigateToDiscussion';

const DiscussionSchema = z.object({
  category: z.enum(
    [
      ...eventCategories,
      ...singleImageAnnotationCategories,
      ...timeRangeAnnotationCategories,
      ...heatMapAnnotationCategories,
      ...heatMapAggregateAnnotationCategories,
    ],
    { message: 'Please select the category' }
  ),
  startTime: z.date({ message: 'Please choose a valid date and time' }),
  endTime: z.date({ message: 'Please choose a valid date and time' }),
});

const CommentSchema = z.object({
  content: z.string().min(1, { message: 'Please type your comment' }),
});

const InsightSchema = CommentSchema.merge(
  z.object({
    severity: z.nativeEnum(InsightSeverity, {
      message: 'Please provide the insight severity',
    }),
    type: z.nativeEnum(InsightType, {
      message: 'Please provide the insight type',
    }),
    title: z.string().min(1, { message: 'Please provide the comment title' }),
  })
);

function getModifiedDiscussionSchema(
  timeRange: Maybe<TTimeRange>,
  discussionDraft: TDiscussionDraft
) {
  if (timeRange) {
    const { startTime, endTime } = discussionDraft;
    const message = (start: Date, end: Date) =>
      `Please choose a date between ${formatTimeInSemiFullStyle(start)} and ${formatTimeInSemiFullStyle(end)}`;
    const maxMin =
      startTime === endTime
        ? timeRange.start
        : max([timeRange.start, startTime]);
    const minMax =
      startTime === endTime
        ? timeRange.end
        : min([timeRange.end, discussionDraft.endTime]);

    return DiscussionSchema.extend({
      startTime: z
        .date()
        .min(timeRange.start, { message: message(timeRange.start, minMax) })
        .max(minMax, { message: message(timeRange.start, minMax) }),
      endTime: z
        .date()
        .min(maxMin, { message: message(maxMin, timeRange.end) })
        .max(timeRange.end, { message: message(maxMin, timeRange.end) }),
    });
  }

  return DiscussionSchema;
}

const _fieldKeys = [
  ...DiscussionSchema.keyof().options,
  ...InsightSchema.keyof().options,
];

export type FieldKey = (typeof _fieldKeys)[number];

type DiscussionBoxMode = 'create' | 'view' | 'list' | 'update';

interface EditState {
  commentDraft: TCommentDraft;
  discussionDraft: TDiscussionDraft;
  isDirty: boolean;
  isSaving: boolean;
  isValid: boolean;
  timeRange: TTimeRange | undefined;
  touchedFields: Partial<Record<FieldKey, boolean>>;
  discussionErrors: z.inferFlattenedErrors<
    typeof DiscussionSchema
  >['fieldErrors'];
  commentErrors: z.inferFlattenedErrors<typeof CommentSchema>['fieldErrors'];
  insightErrors: z.inferFlattenedErrors<typeof InsightSchema>['fieldErrors'];
  updateDiscussion: (updates: Partial<TDiscussionDraft>) => void;
  updateContent: (updates: Partial<TComment['content']>) => void;
  updateDiscussionType: (discussionType: TDiscussionType) => void;
}

interface ListState {
  discussions: TDiscussion[];
  onChangeActiveDiscussion?: (discussionUid: Optional<string>) => void;
  selectDiscussion: (discussionUid: string) => void;
}

interface DiscussionBoxContextData {
  canEditOrDelete: boolean;
  editState: EditState | undefined;
  listState: ListState | undefined;
  mode: DiscussionBoxMode;
  showBackButton: boolean;
  title: string;
  selectedDiscussion?: TDiscussion;
  deleteDiscussion: () => Promise<void>;
  onClose: () => void;
  onDelete?: (promise: Promise<void>) => void;
  onSelectDiscussion?: (discussionUid: Optional<string>) => void;
  saveDiscussion: () => Promise<void>;
  setMode: (mode: DiscussionBoxMode) => void;
  showList: () => void;
  startEditing: () => void;
}

export interface DiscussionBoxContextProviderProps {
  children: ReactNode | ((value: DiscussionBoxContextData) => ReactNode);
  defaultMode?: DiscussionBoxMode;
  discussions?: TDiscussion[];
  selectedDiscussion?: TDiscussion | TDiscussionDraft;
  timeRange?: TTimeRange;
  onClose: () => void;
  onDelete?: (promise: Promise<any>) => void;
  onSaveDiscussion?: (promise: Promise<any>) => Promise<any>;
  onSelectDiscussion?: (discussionUid: Optional<string>) => void;
  onChangeActiveDiscussion?: (discussionUid: Optional<string>) => void;
}

export const DiscussionBoxContext = createContext<DiscussionBoxContextData>({
  canEditOrDelete: false,
  editState: undefined,
  listState: undefined,
  mode: 'create',
  onDelete: undefined,
  showBackButton: false,
  title: '',
  selectedDiscussion: undefined,
  deleteDiscussion: () => Promise.resolve(),
  onClose: () => {},
  onSelectDiscussion: () => {},
  saveDiscussion: () => Promise.resolve(),
  setMode: () => {},
  showList: () => {},
  startEditing: () => {},
});

export function DiscussionBoxContextProvider({
  defaultMode,
  children,
  selectedDiscussion,
  discussions,
  timeRange,
  onClose,
  onDelete,
  onSaveDiscussion,
  onSelectDiscussion,
  onChangeActiveDiscussion,
}: DiscussionBoxContextProviderProps) {
  const { user, currentlySelectedOrganization, isNeatleafOrganizationMember } =
    useAuth();
  const { zoneTimeZone, currentZone } = useCurrentZone();
  if (
    isNil(user?.id) ||
    isNil(currentlySelectedOrganization) ||
    isNil(currentZone)
  ) {
    throw new Error('User, organization, and zone must be defined');
  }
  const permissions = usePermissions();
  const { updateStatus } = useUpdateDiscussionStatus();
  const computeDiscussionLink = useNavigateToDiscussion(true);
  const { insert, ...insertState } = useInsertDiscussion(zoneTimeZone);
  const { update, ...updateState } = useUpdateDiscussion(zoneTimeZone);

  const isSaving = insertState.loading || updateState.loading;

  const [mode, setMode] = useState<DiscussionBoxMode>(() => {
    if (defaultMode) {
      return defaultMode;
    } else if (!selectedDiscussion && discussions && discussions.length > 1) {
      return 'list';
    } else if (!selectedDiscussion?.uid) {
      return 'create';
    }
    return 'view';
  });

  const [
    { discussionDraft, commentDraft, isDirty, touchedFields },
    setDraftState,
  ] = useState<{
    discussionDraft: TDiscussionDraft | undefined;
    commentDraft: TCommentDraft;
    isDirty: EditState['isDirty'];
    touchedFields: EditState['touchedFields'];
  }>(() => {
    let discussionDraft = undefined;
    let commentDraft: TCommentDraft = {
      firstCommentInDiscussion: true,
      organizationCode: currentlySelectedOrganization.code,
      zoneUid: currentZone.uid,
      content: {
        severity: InsightSeverity.Mild,
        type: InsightType.Environment,
        occurrence: '',
        title: '',
        content: '',
        recommendation: '',
      },
    };
    if (selectedDiscussion) {
      discussionDraft = createDiscussionDraft(selectedDiscussion);
      if (selectedDiscussion.firstComment) {
        commentDraft = {
          ...selectedDiscussion.firstComment,
        };
      }
    }

    return {
      discussionDraft,
      commentDraft,
      isDirty: false,
      touchedFields: {},
    };
  });

  const deleteDiscussion = async () => {
    if (selectedDiscussion?.uid && !!onDelete) {
      onDelete(updateStatus([selectedDiscussion as TDiscussion], 'deleted'));
    }
  };

  const saveDiscussion = async () => {
    if (!onSaveDiscussion) {
      throw new Error('onSaveDiscussion is required to save the discussion.');
    }

    if (
      isNil(discussionDraft) ||
      isNil(discussionDraft.category) ||
      isNil(user?.id) ||
      isNil(currentlySelectedOrganization) ||
      !currentZone
    ) {
      console.warn(
        `Could not save discussion`,
        discussionDraft,
        user?.id,
        currentlySelectedOrganization,
        currentZone
      );
      captureMessage('Could not save discussion', {
        extra: {
          userId: user?.id,
          organization: currentlySelectedOrganization,
          currentZone,
          category: discussionDraft?.category,
        },
      });
      return;
    }

    const author = {
      authorId: user.id,
      authorOrganizationCode: isNeatleafOrganizationMember
        ? NEATLEAF_ORGANIZATION_CODE
        : currentlySelectedOrganization.code,
    };
    const discussionUid = discussionDraft.uid || v4();
    const commentWithId: TComment = {
      uid: v4(),
      discussionUid: discussionUid,
      ...commentDraft,
      ...author,
    };
    const status: TDiscussionStatus =
      discussionDraft.type === 'insight' ? 'draft' : 'published';
    const discussionWithId: TDiscussion = {
      uid: discussionUid,
      status: status,
      zoneUid: currentZone.uid,
      zoneId: currentZone.id,
      category: discussionDraft.category,
      displayLabel: getDiscussionLabel(
        discussionDraft.type,
        discussionDraft.category,
        status,
        (commentWithId.content as InsightCommentContent).title
      ),
      ...discussionDraft,
      ...author,
      firstComment: commentWithId,
      organizationCode: currentlySelectedOrganization.code,
    };

    const upsert = discussionDraft?.uid
      ? (discussion: TDiscussion, discussionLink: string) =>
          update(
            discussion,
            selectedDiscussion!.firstComment!.content,
            discussionLink
          )
      : insert;
    await onSaveDiscussion(
      upsert(
        discussionWithId,
        (await computeDiscussionLink(discussionWithId)) as string
      )
    );
    setMode('view');
  };

  const showList = () => {
    setMode('list');
    onSelectDiscussion?.(undefined);
  };

  const startEditing = () => {
    setMode('update');
  };

  const title = useMemo(() => {
    if (mode === 'update') {
      return 'Edit';
    }
    if (mode === 'list' && discussions && discussions.length > 1) {
      return `${discussions.length} comments`;
    }
    return !!discussionDraft && discussionDraft.type === 'insight'
      ? 'Insight'
      : 'Comment';
  }, [discussionDraft, discussions, mode]);

  const canEditOrDelete = useMemo(() => {
    return (
      !isNil(user?.id) &&
      selectedDiscussion?.authorId === user.id &&
      (permissions.discussions.canUpdate || permissions.discussions.canDelete)
    );
  }, [
    permissions.discussions.canDelete,
    permissions.discussions.canUpdate,
    selectedDiscussion?.authorId,
    user?.id,
  ]);

  const showBackButton = useMemo(() => {
    return (
      mode === 'view' &&
      !!discussions &&
      discussions.length > 1 &&
      !!selectedDiscussion
    );
  }, [discussions, mode, selectedDiscussion]);

  const selectedDiscussionIfNotDraftAndInViewMode = useMemo(() => {
    const inViewMode = mode === 'view';
    const notDraft = !isNil(selectedDiscussion?.uid);
    return notDraft && inViewMode
      ? (selectedDiscussion as TDiscussion)
      : undefined;
  }, [mode, selectedDiscussion]);

  const editState = useMemo(() => {
    if ((mode === 'create' || mode === 'update') && discussionDraft) {
      const updateDiscussion = (updates: Partial<TDiscussionDraft>) => {
        setDraftState((prev) => {
          return {
            ...prev,
            discussionDraft: {
              ...prev.discussionDraft!,
              ...updates,
            },
            touchedFields: {
              ...prev.touchedFields,
              ...mapValues(updates, () => true),
            },
          };
        });
      };
      const updateContent = (updates: Partial<TComment['content']>) => {
        setDraftState((prev) => ({
          ...prev,
          commentDraft: {
            ...prev.commentDraft,
            content: {
              ...prev.commentDraft.content,
              ...updates,
            },
          },
          touchedFields: {
            ...prev.touchedFields,
            ...mapValues(updates, () => true),
          },
        }));
      };
      const updateDiscussionType = (discussionType: TDiscussionType) => {
        setDraftState((prev) => {
          let category = prev.discussionDraft!.category;
          if (discussionType === 'comment') {
            category = getDefaultCategoryFromAnnotationType(
              prev.discussionDraft!.annotationType
            );
          } else {
            category = 'other';
          }

          return {
            ...prev,
            touchedFields: {
              ...prev.touchedFields,
              type: true,
              category: true,
            },
            discussionDraft: {
              ...prev.discussionDraft!,
              type: discussionType,
              category,
            },
          };
        });
      };

      const discussionErrors: EditState['discussionErrors'] = (() => {
        const result = getModifiedDiscussionSchema(
          timeRange,
          discussionDraft
        ).safeParse(discussionDraft);
        return result.success ? {} : result.error.flatten().fieldErrors;
      })();

      const commentErrors: EditState['commentErrors'] = (() => {
        const result = CommentSchema.safeParse(commentDraft.content);
        return result.success ? {} : result.error.flatten().fieldErrors;
      })();

      const insightErrors: EditState['insightErrors'] = (() => {
        const result = InsightSchema.safeParse(commentDraft.content);
        return result.success ? {} : result.error.flatten().fieldErrors;
      })();

      const isValid = (() => {
        const validDiscussion = Object.keys(discussionErrors).length === 0;

        if (discussionDraft.type === 'comment') {
          return validDiscussion && Object.keys(commentErrors).length === 0;
        }

        if (discussionDraft.type === 'insight') {
          return validDiscussion && Object.keys(insightErrors).length === 0;
        }

        return false;
      })();

      const editState: EditState = {
        commentDraft,
        discussionDraft,
        touchedFields,
        isDirty,
        isSaving,
        isValid,
        timeRange,
        discussionErrors,
        commentErrors,
        insightErrors,
        updateDiscussion,
        updateContent,
        updateDiscussionType,
      };

      return editState;
    }
  }, [
    commentDraft,
    discussionDraft,
    isDirty,
    isSaving,
    mode,
    timeRange,
    touchedFields,
  ]);

  const listState = useMemo(() => {
    if (mode === 'list' && discussions) {
      const selectDiscussion = (discussionUid: string) => {
        const discussion = discussions.find(
          (discussion) => discussion.uid === discussionUid
        );
        if (!discussion) {
          throw new Error(`Discussion not found: ${discussionUid}`);
        }
        if (discussion.annotationType === 'event') {
          setMode('view');
          setDraftState((prev) => ({
            ...prev,
            discussionDraft: { ...discussion },
            commentDraft: { ...discussion.firstComment },
          }));
        }
        onSelectDiscussion?.(discussion.uid);
      };
      const listState: ListState = {
        discussions,
        selectDiscussion,
        onChangeActiveDiscussion,
      };
      return listState;
    }
  }, [discussions, mode, onChangeActiveDiscussion, onSelectDiscussion]);

  useEffect(() => {
    setDraftState((prev) => {
      const categoryChanged =
        discussionDraft?.category !== selectedDiscussion?.category;
      const startTimeChanged =
        discussionDraft?.startTime !== selectedDiscussion?.startTime;
      const endTimeChanged =
        discussionDraft?.endTime !== selectedDiscussion?.endTime;
      const commentChanged =
        commentDraft.content.content !==
        selectedDiscussion?.firstComment?.content.content;
      const recommendationChanged =
        (commentDraft.content as InsightCommentContent).recommendation !==
        (selectedDiscussion?.firstComment?.content as InsightCommentContent)
          ?.recommendation;
      const insightTitleChanged =
        !!(commentDraft.content as InsightCommentContent).title &&
        (commentDraft.content as InsightCommentContent).title !==
          (selectedDiscussion?.firstComment?.content as InsightCommentContent)
            ?.title;
      const insightRecommendationChanged =
        !!(commentDraft.content as InsightCommentContent).recommendation &&
        (commentDraft.content as InsightCommentContent).recommendation !==
          (selectedDiscussion?.firstComment?.content as InsightCommentContent)
            ?.recommendation;
      const insightTypeChanged =
        !!(commentDraft.content as InsightCommentContent).type &&
        (commentDraft.content as InsightCommentContent).type !==
          (selectedDiscussion?.firstComment?.content as InsightCommentContent)
            ?.type;
      const insightSeverityChanged =
        !!(commentDraft.content as InsightCommentContent).severity &&
        (commentDraft.content as InsightCommentContent).severity !==
          (selectedDiscussion?.firstComment?.content as InsightCommentContent)
            ?.severity;
      const insightOccurrenceChanged =
        !!(commentDraft.content as InsightCommentContent).occurrence &&
        (commentDraft.content as InsightCommentContent).occurrence !==
          (selectedDiscussion?.firstComment?.content as InsightCommentContent)
            ?.occurrence;

      return {
        ...prev,
        isDirty:
          !!commentDraft.content.content &&
          (commentChanged ||
            recommendationChanged ||
            categoryChanged ||
            startTimeChanged ||
            endTimeChanged ||
            insightTitleChanged ||
            insightRecommendationChanged ||
            insightTypeChanged ||
            insightSeverityChanged ||
            insightOccurrenceChanged),
      };
    });
  }, [
    commentDraft.content,
    discussionDraft?.category,
    discussionDraft?.endTime,
    discussionDraft?.startTime,
    selectedDiscussion?.category,
    selectedDiscussion?.endTime,
    selectedDiscussion?.firstComment?.content,
    selectedDiscussion?.startTime,
  ]);

  const value = {
    canEditOrDelete,
    editState,
    listState,
    mode,
    showBackButton,
    title,
    selectedDiscussion: selectedDiscussionIfNotDraftAndInViewMode,
    deleteDiscussion,
    onClose,
    onDelete,
    onSelectDiscussion,
    saveDiscussion,
    setMode,
    showList,
    startEditing,
  };

  return (
    <DiscussionBoxContext.Provider value={value}>
      <>{typeof children === 'function' ? children(value) : children}</>
    </DiscussionBoxContext.Provider>
  );
}

function createDiscussionDraft(discussion: TDiscussionDraft): TDiscussionDraft {
  const draft = { ...discussion };
  const { annotationType, startTime, endTime, measurementId, area } = draft;
  if (
    !annotationType ||
    ![
      'event',
      'single_image_annotation',
      'time_range_annotation',
      'heatmap_annotation',
      'heatmap_aggregate_annotation',
    ].includes(annotationType)
  ) {
    throw new Error(`Unknown discussion type: ${annotationType}`);
  }

  if (isNil(startTime) || isNil(endTime)) {
    throw new Error('Both start and end times are required');
  }

  if (
    annotationType === 'single_image_annotation' &&
    (isNil(measurementId) || isNil(area))
  ) {
    throw new Error('Both measurement iD and area are required');
  }

  if (!draft.category) {
    draft.category = getDefaultCategoryFromAnnotationType(annotationType);
  }

  return draft as TDiscussionDraft;
}
