import { ERoutePath, PATH_PATTERNS } from 'shared/constants/url';
import { getQueryParams } from './URLQueryParams';
import { StoreArgs } from './URLStoreArgs';

export const getResetPasswordStore = (
  args: StoreArgs<ERoutePath.RESET_PASSWORD>
) => {
  const { routePath, searchParams, navigate } = args;
  const queryParams = getQueryParams(searchParams, routePath);
  const actions = {
    navigateToLogin: () => {
      navigate(PATH_PATTERNS[ERoutePath.LOGIN]);
    },
  };
  const state = {
    username: queryParams.username,
  };
  return {
    ...actions,
    ...state,
  };
};
