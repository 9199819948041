import { ConvertFunction } from 'shared/interfaces/general';

/**
 * Clamps a number between a range
 *
 * Example: clamp(-10, 1,3) -> 1
 *
 * Example: clamp(10, 1,3) -> 3
 *
 * Example: clamp(2, 1,3) -> 2
 *
 * @param {number} input number to clamp
 * @param {number} min min value to clamp
 * @param {number} max max value to clamp
 * @returns {*}  {number}
 */
export function clamp(input: number, min: number, max: number): number {
  return input < min ? min : input > max ? max : input;
}

/**
 * Transform `current` from an origin range `inputMin` - `inputMax`
 * into its proportional value within the range `outputMin` - `outputMax`.
 *
 * It's also ensured the resulting value doesn't fall outside of
 * the range `outputMin` - `outputMax`.
 *
 * Example: mapRange(0.09, 0.1, 1, 110, 200) -> 110
 *
 * Example: mapRange(1.1, 0.1, 1, 110, 200) -> 200
 *
 * Example: mapRange(0.35, 0.1, 1, 110, 200) -> 135
 *
 * @export
 * @param {number} current value to transform
 * @param {number} inputMin input range min
 * @param {number} inputMax input range max
 * @param {number} outputMin output range min
 * @param {number} outputMax output range max
 * @returns {*}  {number} the transformed value
 */
export function mapRange(
  current: number,
  inputMin: number,
  inputMax: number,
  outputMin: number,
  outputMax: number
): number {
  const mapped: number =
    ((current - inputMin) * (outputMax - outputMin)) / (inputMax - inputMin) +
    outputMin;
  return clamp(
    mapped,
    Math.min(outputMin, outputMax),
    Math.max(outputMin, outputMax)
  );
}

/**
 * Returns a function that Maps a range of numbers to another range of numbers.
 *
 * @param {number} inputMin - The minimum value of the input range.
 * @param {number} inputMax - The maximum value of the input range.
 * @param {number} outputMin - The minimum value of the output range.
 * @param {number} outputMax - The maximum value of the output range.
 * @returns {"(current: number) => number"} A function that maps a range of numbsr to another range of numbers.
 */
export function mapRangeFunction(
  inputMin: number,
  inputMax: number,
  outputMin: number,
  outputMax: number
): (current: number) => number {
  return (current: number) =>
    mapRange(current, inputMin, inputMax, outputMin, outputMax);
}

/** If `convert` exists then do conversion and round to one decimal point. If not, returns value. */
export function convertIf(
  value: number | null,
  convert: ConvertFunction,
  round = false
) {
  const sanitizedValue = value ?? 0;
  let n = convert ? convert(sanitizedValue) : sanitizedValue;
  if (round) {
    n = Number(n.toFixed(2));
  }
  return n;
}

export function smartRound(num: number) {
  if (num < 1) {
    // Convert the number to a string
    const str = num.toString();

    // Get the decimal part
    const decimalPart = str.split('.')[1] || '';

    // Find the index of the first non-zero digit after the decimal
    const firstNonZeroIndex = decimalPart.search(/[1-9]/);

    // If no non-zero digits are found, return the number itself
    if (firstNonZeroIndex === -1) {
      return num;
    }

    // Calculate how many decimal places to retain (up to the first non-zero digit + 1)
    const decimalsToKeep = firstNonZeroIndex + 2;

    // Apply rounding with the correct precision
    const factor = Math.pow(10, decimalsToKeep);
    return Math.round(num * factor) / factor;
  }

  // For numbers >= 1, round to 2 decimal places
  return Number(num.toFixed(2));
}
