import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const LeafNecrosisIcon = ({ className, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={cn('h-6 w-6', className)}
    {...props}
  >
    <path d="M12 20.9998C12 20.9998 7 16.9704 7 11.9998C7 7.02925 12 1.99982 12 1.99982C12 1.99982 17 7.02925 17 11.9998" />
    <path d="M12 17L12 22" />
    <path d="M15 15L20 20" />
    <path d="M20 15L15 20" />
  </svg>
);

export { LeafNecrosisIcon };
