import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const LeafTemperatureIcon = ({
  stroke = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    stroke={stroke}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={cn('h-6 w-6', className)}
    {...props}
  >
    <path d="M11.145 11.6793C11.145 15.9119 6.88736 19.343 6.88736 19.343C6.88736 19.343 2.6297 15.9119 2.6297 11.6793C2.6297 7.44667 6.88736 3.16394 6.88736 3.16394C6.88736 3.16394 11.145 7.44667 11.145 11.6793Z" />
    <path d="M19.3703 4V14.54C20.1329 14.9803 20.7289 15.6599 21.0658 16.4734C21.4028 17.2869 21.4619 18.1888 21.234 19.0394C21.0061 19.8899 20.5039 20.6415 19.8053 21.1775C19.1068 21.7136 18.2508 22.0041 17.3703 22.0041C16.4898 22.0041 15.6338 21.7136 14.9353 21.1775C14.2367 20.6415 13.7345 19.8899 13.5066 19.0394C13.2787 18.1888 13.3378 17.2869 13.6748 16.4734C14.0118 15.6599 14.6077 14.9803 15.3703 14.54V4C15.3703 3.46957 15.581 2.96086 15.9561 2.58579C16.3312 2.21071 16.8399 2 17.3703 2C17.9007 2 18.4094 2.21071 18.7845 2.58579C19.1596 2.96086 19.3703 3.46957 19.3703 4Z" />
    <path d="M6.88739 14.2339V21.0461" />
  </svg>
);

export { LeafTemperatureIcon };
