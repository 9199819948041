import { Auth } from 'aws-amplify';
import { UserAvatar } from 'components/common/UserAvatar';
import {
  ChangePasswordForm,
  IChangePasswordForm,
} from 'components/user/ChangePasswordForm';
import {
  EditProfileForm,
  IEditProfileForm,
} from 'components/user/EditProfileForm';
import { useAuth } from 'contexts/AuthProvider';
import { useSettingsEditProfileURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { useUpdateUserMutation } from 'graphql/generated/react_apollo';
import isNil from 'lodash.isnil';
import { FC, useCallback, useMemo } from 'react';
import { EProfileTabs } from 'shared/interfaces/settings';

export const Profile: FC = () => {
  const { nameInitials, email, firstName, user, lastName, phoneNumber } =
    useAuth();

  const { setProfileTab, profileTab } = useSettingsEditProfileURL();

  const profileDefaultValues: IEditProfileForm = useMemo(() => {
    return {
      active: user?.active ?? true,
      email,
      firstName,
      lastName,
      phoneNumber,
      roles: [],
    };
  }, [email, firstName, lastName, phoneNumber, user]);

  const [updateUser] = useUpdateUserMutation();

  const handleOpenChangePassword = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setProfileTab(EProfileTabs.CHANGE_PASSWORD);
    },
    [setProfileTab]
  );

  const handleCloseChangePassword = useCallback(() => {
    setProfileTab(EProfileTabs.EDIT_PROFILE);
  }, [setProfileTab]);

  const handleChangePassword = async (data: IChangePasswordForm) => {
    return Auth.currentAuthenticatedUser().then((user) =>
      Auth.changePassword(user, data.currentPassword, data.newPassword)
    );
  };
  const handleChangeProfile = useCallback(
    async (data: IEditProfileForm) => {
      if (isNil(user?.id)) {
        return;
      }

      await updateUser({
        variables: {
          id: user.id,
          active: true,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber,
        },
      });
    },
    [user?.id, updateUser]
  );

  return (
    <div>
      {profileTab === EProfileTabs.EDIT_PROFILE && (
        <div className="flex items-start gap-5 flex-wrap">
          <UserAvatar nameInitials={nameInitials} />
          <div className="flex flex-col gap-3">
            <p className="text-lg font-semibold">
              {[firstName, lastName].join(' ')}
            </p>
            <EditProfileForm
              defaultValues={profileDefaultValues}
              onClickChangePassword={handleOpenChangePassword}
              onSubmit={handleChangeProfile}
            />
          </div>
        </div>
      )}

      {profileTab === EProfileTabs.CHANGE_PASSWORD && (
        <div className="flex flex-col gap-2">
          <h1 className="text-xl font-bold">Change password</h1>
          <ChangePasswordForm
            onClose={handleCloseChangePassword}
            onSubmit={handleChangePassword}
          />
        </div>
      )}
    </div>
  );
};
