import { useAvailableMentions } from 'api/user';
import { Datetime } from 'components/common/Datetime/Datetime';
import { Input } from 'components/common/Input/Input';
import { Radio } from 'components/common/Radio/Radio';
import { Textarea } from 'components/common/Textarea/Textarea';
import { max, min } from 'date-fns';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { ComponentPropsWithoutRef, forwardRef, useContext } from 'react';
import {
  InsightCommentContent,
  InsightSeverity,
  InsightType,
} from 'shared/interfaces/discussion';
import { cn } from 'shared/utils/cn';
import { formatDateyyyyMMddHHmm, isDateValid } from 'shared/utils/date';
import { DiscussionBoxContext, FieldKey } from './DiscussionBoxContext';

const { High, Moderate, Mild, None } = InsightSeverity;
const { Environment, Health } = InsightType;

interface EditInsightProps extends ComponentPropsWithoutRef<'div'> {
  disabledFields?: Partial<Record<FieldKey, boolean>>;
}

export const EditInsight = forwardRef<HTMLDivElement, EditInsightProps>(
  function EditInsight({ className, disabledFields, ...props }, ref) {
    const { editState } = useContext(DiscussionBoxContext);
    const {
      discussionDraft: { annotationType, startTime, endTime },
      commentDraft,
      timeRange,
      discussionErrors,
      insightErrors,
      touchedFields,
      updateDiscussion,
      updateContent,
    } = editState!;
    const content = commentDraft.content as InsightCommentContent;

    const { currentZone } = useCurrentZone();
    const availableMentions = useAvailableMentions(currentZone?.uid);

    return (
      <div
        ref={ref}
        {...props}
        className={cn('w-full h-full flex flex-col gap-4', className)}
      >
        {annotationType === 'event' && (
          <Datetime
            label="Time Stamp"
            labelPlacement="top"
            labelClassName="font-bold"
            value={formatDateyyyyMMddHHmm(startTime)}
            required={touchedFields.startTime}
            disabled={!!disabledFields?.startTime}
            errorText={(discussionErrors.startTime ?? [])[0]}
            {...(timeRange && {
              min: formatDateyyyyMMddHHmm(timeRange.start),
              max: formatDateyyyyMMddHHmm(timeRange.end),
            })}
            onChange={(event) => {
              const time = new Date(event.target.value);
              if (isDateValid(time)) {
                updateDiscussion({ startTime: time, endTime: time });
              }
            }}
          />
        )}
        {annotationType === 'time_range_annotation' && (
          <>
            <Datetime
              label="Start time"
              labelPlacement="top"
              labelClassName="font-bold"
              className="w-full"
              value={formatDateyyyyMMddHHmm(startTime)}
              required={touchedFields.startTime}
              disabled={!!disabledFields?.startTime}
              errorText={(discussionErrors.startTime ?? [])[0]}
              {...(timeRange && {
                min: formatDateyyyyMMddHHmm(timeRange.start),
                max: formatDateyyyyMMddHHmm(min([timeRange.end, endTime])),
              })}
              onChange={(event) => {
                const time = new Date(event.target.value);
                if (isDateValid(time)) {
                  updateDiscussion({ startTime: time });
                }
              }}
            />
            <Datetime
              label="End time"
              labelPlacement="top"
              labelClassName="font-bold"
              value={formatDateyyyyMMddHHmm(endTime)}
              required={touchedFields.endTime}
              disabled={!!disabledFields?.endTime}
              errorText={(discussionErrors.endTime ?? [])[0]}
              {...(timeRange && {
                min: formatDateyyyyMMddHHmm(max([timeRange.start, startTime])),
                max: formatDateyyyyMMddHHmm(timeRange.end),
              })}
              onChange={(event) => {
                const time = new Date(event.target.value);
                if (isDateValid(time)) {
                  updateDiscussion({ endTime: time });
                }
              }}
            />
          </>
        )}
        <div className="flex flex-col gap-2 w-full">
          <Input
            value={content.title}
            placeholder="Title"
            aria-label="Comment title"
            label="Comment"
            labelPlacement="top"
            labelClassName="font-bold"
            minLength={1}
            required={touchedFields.title}
            errorText={(insightErrors.title ?? [])[0]}
            onChange={(event) => updateContent({ title: event.target.value })}
          />
          <div className="h-auto">
            <Textarea
              placeholder="Type your comment here"
              aria-label="Comment content"
              value={content.content}
              className="min-h-24 h-24 transition-all"
              minLength={1}
              required={touchedFields.content}
              errorText={(insightErrors.content ?? [])[0]}
              onChange={(content) => updateContent({ content })}
              isLexicalEditor={true}
              availableMentions={availableMentions}
            />
          </div>
        </div>
        <div className="h-auto w-full">
          <Textarea
            placeholder="Type your recommendations here"
            value={content.recommendation}
            className="min-h-24 h-24 transition-all"
            label="Recommendations"
            labelPlacement="top"
            labelClassName="font-bold"
            onChange={(recommendation) => updateContent({ recommendation })}
            isLexicalEditor={true}
            availableMentions={availableMentions}
          />
        </div>
        <Radio.Group
          name="type"
          value={content.type}
          orientation="horizontal"
          className="capitalize"
          label="Type"
          labelClassName="font-bold"
          required={touchedFields.type}
          errorText={(insightErrors.type ?? [])[0]}
          onChange={(event) =>
            updateContent({ type: event.target.value as InsightType })
          }
        >
          <Radio.Input
            key={Environment}
            value={Environment}
            label={Environment}
          />
          <Radio.Input key={Health} value={Health} label={Health} />
        </Radio.Group>
        <Radio.Group
          name="severity"
          value={content.severity}
          orientation="horizontal"
          className="capitalize"
          label="Severity"
          labelClassName="font-bold"
          required={touchedFields.severity}
          errorText={(insightErrors.severity ?? [])[0]}
          onChange={(event) =>
            updateContent({ severity: event.target.value as InsightSeverity })
          }
        >
          <Radio.Input key={High} value={High} label={High} />
          <Radio.Input key={Moderate} value={Moderate} label={Moderate} />
          <Radio.Input key={Mild} value={Mild} label={Mild} />
          <Radio.Input key={None} value={None} label={None} />
        </Radio.Group>
        <Input
          label="Occurrence or Location"
          labelPlacement="top"
          labelClassName="font-bold"
          value={content.occurrence}
          onChange={(event) =>
            updateContent({ occurrence: event.target.value })
          }
        />
      </div>
    );
  }
);
