import { type PlantHealthValue, useGetPlantHealth } from 'api/labels';
import { HorizontallyScrollable } from 'components/common/HorizontallyScrollable';
import { Wrap } from 'components/common/Wrap/Wrap';
import { PlantHealthCard } from 'components/plant_health/PlantHealthCard';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { useZoneDetailsPageURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useScreenSize } from 'hooks/useScreenSize';
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useCallback,
  useMemo,
} from 'react';
import {
  EMeasurementStatisticsTypesV2,
  MeasurementTypeConfig,
} from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';

const {
  CALCULATED_DISTANCE,
  YELLOWING_GENERAL,
  NECROSIS_GENERAL,
  ABNORMAL_SHAPE_FOLDING,
  OTHER_POWDER,
  OBJECT_BUD,
} = EMeasurementStatisticsTypesV2;

type OnClickCard = (signal: MeasurementTypeConfig) => void;

export const PlantHealth = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'>
>(function PlantHealth({ className, ...props }, ref) {
  const { currentTimeInCurrentZone, zoneTimeZone } = useCurrentZone();
  const { navigateToImageFeed, navigateToLineChart, zoneId } =
    useZoneDetailsPageURL();
  const zonedStartTime = startOfDay(subDays(currentTimeInCurrentZone, 3));
  const zonedEndTime = endOfDay(subDays(currentTimeInCurrentZone, 1));
  const { isMobile } = useScreenSize();
  const { getMeasurementType } = useTypeConfig();
  const {
    data: { growth, symptoms },
  } = useGetPlantHealth({
    zoneId: Number(zoneId),
    zoneTimeZone,
    start: zonedStartTime,
    end: zonedEndTime,
    getMeasurementType,
  });
  const onClickGrowth = useCallback(
    (signal: MeasurementTypeConfig) => {
      navigateToLineChart({
        signalIds: [signal.type],
        zonedStartTime: zonedStartTime.valueOf(),
        zonedEndTime: zonedEndTime.valueOf(),
        timeZone: zoneTimeZone,
      });
    },
    [navigateToLineChart, zoneTimeZone, zonedEndTime, zonedStartTime]
  );
  const onClickSymptom = useCallback(
    (signal: MeasurementTypeConfig) => {
      navigateToImageFeed({
        imageLabelCode: signal.statisticsKeyV2,
        zonedStartTime: zonedStartTime.valueOf(),
        zonedEndTime: zonedEndTime.valueOf(),
        timeZone: zoneTimeZone,
      });
    },
    [navigateToImageFeed, zoneTimeZone, zonedEndTime, zonedStartTime]
  );
  const cards = useMemo(() => {
    const cards: [string, PlantHealthValue[], OnClickCard][] = [];
    for (const signal of [
      getMeasurementType(YELLOWING_GENERAL),
      getMeasurementType(ABNORMAL_SHAPE_FOLDING),
      getMeasurementType(OTHER_POWDER),
      getMeasurementType(NECROSIS_GENERAL),
    ]) {
      if (
        !symptoms.some(
          (s) => s.signal.statisticsKeyV2 === signal.statisticsKeyV2
        )
      ) {
        symptoms.push({
          signal,
          title: signal.labelHealth ?? signal.label,
        });
      }
    }
    for (const signal of [
      getMeasurementType(CALCULATED_DISTANCE),
      getMeasurementType(OBJECT_BUD),
    ]) {
      if (
        !growth.some((s) => s.signal.statisticsKeyV2 === signal.statisticsKeyV2)
      ) {
        growth.push({
          signal,
          title: signal.labelHealth ?? signal.label,
        });
      }
    }

    cards.push([
      'Plant symptoms',
      symptoms.toSorted(sortItems),
      onClickSymptom,
    ]);
    cards.push(['Plant growth', growth.toSorted(sortItems), onClickGrowth]);

    return cards;
  }, [getMeasurementType, growth, onClickGrowth, onClickSymptom, symptoms]);

  return (
    <Wrap
      condition={!isMobile}
      with={(children) => (
        <HorizontallyScrollable ref={ref} {...props} className={className}>
          {children}
        </HorizontallyScrollable>
      )}
    >
      <div
        {...(isMobile ? { ref, ...props } : {})}
        className={cn(
          'lg:w-fit flex flex-wrap lg:flex-nowrap gap-3 overflow-x-auto',
          className
        )}
      >
        {cards.map(([title, categories, onClick]) => (
          <Wrap
            key={title}
            condition={isMobile}
            with={(children) => (
              <HorizontallyScrollable>{children}</HorizontallyScrollable>
            )}
          >
            <div className="w-fit py-3 px-4 xl:py-4 xl:px-6 rounded flex flex-col gap-2 xl:gap-3 bg-white text-gray-900 overflow-x-auto">
              <h2 className="text-sm md:text-base xl:text-lg font-semibold">
                {title}
              </h2>
              <div role="list" className="flex gap-1">
                {categories.map((c) => (
                  <PlantHealthCard
                    variant="trend"
                    value={c}
                    onSelectSignal={onClick}
                    key={c.title}
                    className="w-[144px] xl:w-[242px]"
                    imgClassName="w-full h-[81px] lg:h-[108px] xl:h-[136px]"
                    role="listitem"
                  />
                ))}
              </div>
            </div>
          </Wrap>
        ))}
      </div>
    </Wrap>
  );
});

const plantHealthOrderMap = new Map<EMeasurementStatisticsTypesV2, number>([
  [YELLOWING_GENERAL, 0],
  [ABNORMAL_SHAPE_FOLDING, 1],
  [OTHER_POWDER, 2],
  [NECROSIS_GENERAL, 3],
  [CALCULATED_DISTANCE, 4],
  [OBJECT_BUD, 5],
]);

function sortItems(a: PlantHealthValue, b: PlantHealthValue) {
  const aIndex = plantHealthOrderMap.get(a.signal.statisticsKeyV2) ?? -1;
  const bIndex = plantHealthOrderMap.get(b.signal.statisticsKeyV2) ?? -1;

  return aIndex - bIndex;
}
