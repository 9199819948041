import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import isNil from 'lodash.isnil';
import { useMemo } from 'react';
import { NotificationLevel } from 'shared/constants/notification';
import { TGrowthCycle } from 'shared/interfaces/growthCycle';
import { TCurrentZoneMeasurements } from 'shared/interfaces/measurement';
import { getDayRange } from 'shared/utils/getters';
import { getOptimalRange } from 'shared/utils/growthCycle';

export const useMeasurementCards = (
  now: Date,
  measurements: TCurrentZoneMeasurements,
  growthCycle: Maybe<TGrowthCycle>
) => {
  const { measurementTypes } = useTypeConfig();

  return useMemo(() => {
    const dayRange = getDayRange(growthCycle?.metadata?.light_info, now);
    const airTemp = measurementTypes.air_temperature;
    const humidity = measurementTypes.humidity;
    const leafVpd = measurementTypes.leaf_vpd;
    const airVpd = measurementTypes.air_vpd;
    const co2 = measurementTypes.co2;
    const ppfd = measurementTypes.ppfd;
    const leafTemp = measurementTypes.leaf_temperature;

    return [
      {
        title: airTemp.label,
        value: measurements?.[airTemp.statisticsKeyV2]?.mean,
        unit: airTemp.unit,
        notificationLevel: NotificationLevel.NOMINAL,
        decimal: 1,
        enabled: true,
        measurementType: airTemp.statisticsKey,
      },
      {
        title: humidity.label,
        value: measurements?.[humidity.statisticsKeyV2]?.mean,
        unit: humidity.unit,
        notificationLevel: NotificationLevel.NOMINAL,
        decimal: 1,
        enabled: true,
        measurementType: humidity.statisticsKey,
      },
      {
        title: leafVpd.label,
        value: measurements?.[leafVpd.statisticsKeyV2]?.mean,
        unit: leafVpd.unit,
        notificationLevel: NotificationLevel.NOMINAL,
        decimal: 2,
        enabled: true,
        measurementType: leafVpd.statisticsKey,
      },
      {
        title: airVpd.label,
        value: measurements?.[airVpd.statisticsKeyV2]?.mean,
        unit: airVpd.unit,
        notificationLevel: NotificationLevel.NOMINAL,
        decimal: 2,
        enabled: true,
        measurementType: airVpd.statisticsKey,
      },
      {
        title: co2.label,
        value: measurements?.[co2.statisticsKeyV2]?.mean,
        unit: co2.unit,
        notificationLevel: NotificationLevel.NOMINAL,
        decimal: 0,
        enabled: true,
        measurementType: co2.statisticsKey,
      },
      {
        title: ppfd.label,
        value: measurements?.[ppfd.statisticsKeyV2]?.mean,
        unit: ppfd.unit,
        notificationLevel: NotificationLevel.NOMINAL,
        decimal: 1,
        enabled: true,
        measurementType: ppfd.statisticsKey,
      },
      {
        title: leafTemp.label,
        value: measurements?.[leafTemp.statisticsKeyV2]?.mean,
        unit: leafTemp.unit,
        notificationLevel: NotificationLevel.NOMINAL,
        decimal: 1,
        enabled: true,
        measurementType: leafTemp.statisticsKey,
      },
    ]
      .filter((option) => option.enabled && option.value !== undefined)
      .map((option) => {
        if (!growthCycle) {
          return option;
        }

        try {
          const parameterRanges = getOptimalRange(
            now,
            option.measurementType,
            dayRange,
            growthCycle
          );

          if (
            !isNil(option.value) &&
            parameterRanges &&
            (option.value < parameterRanges.warning_range.min ||
              option.value > parameterRanges.warning_range.max)
          ) {
            option.notificationLevel = NotificationLevel.WARNING;
          }

          return option;
        } catch (_error) {
          return option;
        }
      });
  }, [growthCycle, measurementTypes, measurements, now]);
};
