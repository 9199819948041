import { useMutation } from '@tanstack/react-query';
import { useApi } from 'contexts/ApiProvider';

export const usePublishInsightsMutation = () => {
  const { apiUrl, httpClient } = useApi();
  const url = new URL('dashboard/v1/insights/publications', apiUrl).toString();

  return useMutation({
    mutationKey: ['publish-insights'],
    mutationFn: (discussionUids: string[]) =>
      httpClient
        .post(url, {
          body: JSON.stringify({ discussionUids }),
        })
        .json(),
  });
};
