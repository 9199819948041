import { NavigateOptions } from 'react-router-dom';
import { ERoutePath } from 'shared/constants/url';
import { ESettingsTabs } from 'shared/interfaces/settings';
import { navigateToFactory } from './NavigateToFactory';
import { QueryParamsSchema, getQueryParams } from './URLQueryParams';
import { StoreArgs } from './URLStoreArgs';

export const getSettingsStore = (args: StoreArgs<ERoutePath.SETTINGS_TAB>) => {
  const {
    routePath,
    searchParams,
    navigate,
    pathParams,
    location,
    setSearchParams,
  } = args;
  const queryParams = getQueryParams(searchParams, routePath);
  type SchemaType = QueryParamsSchema<typeof routePath>;
  const navigateTo = navigateToFactory(
    navigate,
    location.pathname,
    searchParams,
    routePath
  );
  const actions = {
    setOrganization: (
      value: SchemaType['organization'],
      navigateOptions?: NavigateOptions
    ) => setSearchParams({ organization: value }, navigateOptions, true),
    navigateToTab: (tab: ESettingsTabs, navigateOptions?: NavigateOptions) => {
      navigateTo(
        {
          pathParams: {
            tab: tab,
            method: undefined,
            id: undefined,
          },
          queryParams,
        },
        navigateOptions
      );
    },
    navigateToCreateNewUserForm: (navigateOptions?: NavigateOptions) =>
      navigateToFactory(
        navigate,
        location.pathname,
        searchParams,
        ERoutePath.SETTINGS_ADD_USER
      )({}, navigateOptions),
    navigateToEditUserForm: (
      userId: number,
      navigateOptions?: NavigateOptions
    ) =>
      navigateToFactory(
        navigate,
        location.pathname,
        searchParams,
        ERoutePath.SETTINGS_EDIT_USER
      )(
        {
          pathParams: {
            userId: userId,
          },
        },
        navigateOptions
      ),
  };
  const state = {
    organization: queryParams.organization,
    tab: pathParams?.tab ?? ESettingsTabs.PROFILE,
  };
  return {
    ...actions,
    ...state,
  };
};
