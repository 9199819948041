import { useGetPlantHealth } from 'api/labels';
import { PlantHealthCard } from 'components/plant_health/PlantHealthCard';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useGrowthCycleReport } from 'hooks/useGrowthCycleReport';

export const PlantLabels = () => {
  const { currentZone, zoneTimeZone } = useCurrentZone();
  const { selectedGrowthCycle } = useGrowthCycleReport();
  const { getMeasurementType } = useTypeConfig();
  const { data } = useGetPlantHealth({
    zoneTimeZone,
    zoneId: currentZone?.id,
    start: new Date(selectedGrowthCycle?.start_time || 0),
    end: new Date(selectedGrowthCycle?.end_time || 0),
    getMeasurementType,
  });

  if (!currentZone || !selectedGrowthCycle || !data.symptoms.length) {
    return null;
  }

  return (
    <div className="flex gap-2 p-6 bg-white rounded">
      {data.symptoms.map((g) => (
        <PlantHealthCard
          variant="max"
          value={g}
          key={g.title}
          imgClassName="h-[104px]"
        />
      ))}
    </div>
  );
};
