import * as Sentry from '@sentry/react';
import { Button } from 'components/common/Button/Button';
import { NeatleafLogo } from 'icons/NeatleafLogo';
import { ErrorOccuredImg } from 'pages/not_found/ErrorOccuredImg';
import { ReactNode } from 'react';

interface ErrorBoundaryProps {
  children?: ReactNode;
}
export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  return (
    <Sentry.ErrorBoundary
      fallback={(event) => (
        <div className="relative flex w-screen flex-col gap-6 p-6 py-[14px] align-middle md:h-screen md:p-8 md:py-[14px]">
          <NeatleafLogo
            className="mr-2 cursor-pointer w-[184px]"
            onClick={() => {
              window.location.href = '/';
            }}
          />
          <div className="flex flex-1 flex-col flex-wrap gap-6 md:flex-row">
            <div className="flex flex-1">
              <ErrorOccuredImg className="w-full" />
            </div>

            <div className="flex flex-1 flex-col gap-4 md:justify-center">
              <h1 className="text-3xl font-bold">An error occurred</h1>
              <p className="font-semibold">{event?.error?.message}</p>
              <p className="font-semibold">
                If the error continues, please email support@neatleaf.com
              </p>
              <Button
                onClick={() => {
                  window.location.href = '/';
                }}
                className="w-full"
              >
                Go to home page
              </Button>
            </div>
          </div>
        </div>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
