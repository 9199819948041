import { ApolloError } from '@apollo/client';
import isNil from 'lodash.isnil';
import { useMemo } from 'react';
import { TGrowthCycle, TLightInfo } from 'shared/interfaces/growthCycle';
import {
  EMeasurementStatisticsTypes,
  TMontioringParameterType,
} from 'shared/interfaces/measurement';
import { getDayRange } from 'shared/utils/getters';
import { getOptimalRange } from 'shared/utils/growthCycle';

export interface IHeatMapOptimalRangeData {
  /** The measurement option */
  measurementType: EMeasurementStatisticsTypes;
  /** The current time */
  currentTime?: Date;
  /** Light information in the cycle. */
  lightInfo: TLightInfo[];
  selectedCycle: Nullable<TGrowthCycle>;
}

export interface IHeatMapOptimalRangeReturn {
  /** The current optimal ranges */
  optimalRanges: TMontioringParameterType;
  /** The loading status if the optimal ranges are loaded or not */
  loading: boolean;
  /** The error while loading optimal ranges from graphql */
  error?: ApolloError;
}

/**
 * Get the optimal ranges of current measurementType and time
 *
 * @param {IHeatMapOptimalRangeData} data - The measurementType and time values.
 * @returns {IHeatMapOptimalRangeReturn} The optimal ranges data.
 */
export const useHeatMapOptimalRanges = ({
  measurementType,
  currentTime,
  lightInfo,
  selectedCycle,
}: IHeatMapOptimalRangeData) => {
  const dayRange = useMemo(() => {
    return currentTime && getDayRange(lightInfo, currentTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCycle, currentTime]);

  const optimalRanges: TMontioringParameterType | undefined = useMemo(() => {
    if (!selectedCycle) {
      return {
        optimal_range: {
          min: 0,
          max: 0,
        },
        set_point: 0,
        warning_range: {
          min: 0,
          max: 0,
        },
      };
    }
    if (isNil(currentTime)) return undefined;
    return getOptimalRange(
      currentTime,
      measurementType,
      dayRange,
      selectedCycle
    );
  }, [dayRange, currentTime, measurementType, selectedCycle]);

  return { optimalRanges };
};
