import {
  GetPermissionsByRoleIdsQuery,
  GetUserByEmailQuery,
} from 'graphql/generated/react_apollo';
import { PredefinedTimeRanges } from './growthCycle';
import { TPreferredUnits } from './measurement';

export type TDefaultZone = { zone_uid: string; organization: string };

export type TUser = ArrayElement<GetUserByEmailQuery['user']>;

export interface UserWithMetadata extends TUser {
  metadata: Nullable<UserMetadata>;
}

export interface UserMetadata {
  version: string;
  publicRole: PublicRole;
  default_zone?: TDefaultZone[];
  preferred_units?: TPreferredUnits;
  predefined_time_range?: Exclude<PredefinedTimeRanges, 'CUSTOM'>;
  excludeFromAnalytics?: boolean;
  alwaysVisibleForTagging?: boolean;
}

export type TUserRoleType = {
  id: number;
  code: string;
  label: string;
  filterLabel: string;
};

export enum EUserRole {
  ADMINISTRATOR = 'ADMINISTRATOR',
  ANNOTATOR = 'ANNOTATOR',
  CULTIVATOR = 'CULTIVATOR',
  KIOSK = 'KIOSK',
}

export type TUserInfo = any;

export type TUserOrganizationRole = ArrayElement<
  TUser['user_organization_roles']
>;

export type TUserRole = TUserOrganizationRole['role'];

export type TUserRolesByOrganization = Record<number, TUserRole[]>;

export type TUserPermission = ArrayElement<
  GetPermissionsByRoleIdsQuery['role_permission']
>;

export type TUserDefaultZoneByOrganization = Record<string, string>;

export type TUserNotification = {
  id: number;
  userId: number;
  zoneId: number;
  notificationSettingId: number;
  notificationType: string;
};

export enum PublicRole {
  Consultant = 'consultant',
  CultivationLead = 'cultivation_lead',
  CultivationManager = 'cultivation_manager',
  CultivationTech = 'cultivation_tech',
  DirectorOfCultivation = 'director_of_cultivation',
  DirectorOfOperations = 'director_of_operations',
  Fertigation = 'fertigation',
  Kiosk = 'kiosk',
  Maintenance = 'maintenance',
  NotSpecified = 'not_specified',
  PestManagement = 'pest_management',
}
