import { useEffect, useRef } from 'react';
import { useCanopy } from './useCanopy';

export interface CanopyProps {
  week: number;
  startTime: number;
  growthCycleEndTime: number;
  zoneId: string;
  zoneTimeZone: string;
}

export const Canopy = ({
  week,
  startTime,
  growthCycleEndTime,
  zoneId,
  zoneTimeZone,
}: CanopyProps) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const canvasWidth = 700;
  const minCanvasHeight = 350;

  const { canvasHeight, urls, imgHeight, imgWidth } = useCanopy({
    startTime,
    zoneId,
    growthCycleEndTime,
    zoneTimeZone,
    width: canvasWidth,
  });

  useEffect(() => {
    const canvas = canvasRef.current;

    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    urls.forEach((urlRow, rowIdx) => {
      urlRow.forEach((url, colIdx) => {
        const image = new Image();
        image.addEventListener('load', () =>
          ctx.drawImage(
            image,
            imgWidth * colIdx,
            imgHeight * rowIdx,
            imgWidth,
            imgHeight
          )
        );
        image.src = url;
      });
    });
  }, [imgHeight, imgWidth, urls]);

  return (
    <div
      style={{
        width: canvasWidth,
        height: canvasHeight,
        minHeight: minCanvasHeight,
      }}
    >
      {urls.length ? (
        <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight} />
      ) : (
        <span>No images for week {week}</span>
      )}
    </div>
  );
};
