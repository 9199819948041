import { AugmentedRouteObject } from 'Routes';
import { useZoneDetailsPageURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { useEffect } from 'react';
import { RouteObject, matchRoutes, useLocation } from 'react-router-dom';
import { getTrackedSearchParams } from './search-params-util';
import { useAnalytics } from './useAnalytics';

export const useTrackPageViews = (
  flattenedRoutes: RouteObject[],
  searchParamsKeysThatShouldBeTracked: string[]
) => {
  const {
    sendPageView,
    sendPageLeave,
    setCurrentOrganizationCode,
    setCurrentZoneId,
  } = useAnalytics();
  const { zoneId, organizationCode } = useZoneDetailsPageURL();
  const location = useLocation();

  useEffect(() => {
    setCurrentOrganizationCode(organizationCode);
    setCurrentZoneId(zoneId);
    const [routeMatch] = (matchRoutes(flattenedRoutes, location) ?? []).splice(
      -1
    );

    if (
      routeMatch &&
      (routeMatch.route as AugmentedRouteObject).shouldTrackPageView
    ) {
      sendPageView(
        routeMatch.route.id,
        location.pathname,
        routeMatch.route.path,
        getTrackedSearchParams(
          location.search,
          searchParamsKeysThatShouldBeTracked
        )
      );
    }

    return () => {
      if (
        routeMatch &&
        (routeMatch.route as AugmentedRouteObject).shouldTrackPageView
      ) {
        sendPageLeave(location.pathname);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
};
