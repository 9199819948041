import { Auth } from 'aws-amplify';
import { NonAuthForm } from 'components/auth/NonAuthForm';
import { NonAuthLayout } from 'components/auth/NonAuthLayout';
import { PasswordRules } from 'components/auth/PasswordRules';
import { Alert } from 'components/common/Alert/Alert';
import { Button } from 'components/common/Button/Button';
import { Input } from 'components/common/Input/Input';
import { Link } from 'components/common/Link';
import { useAuth } from 'contexts/AuthProvider';
import { useURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PASSWORD_RULES } from 'shared/constants/password';
import { ERoutePath, PATH_PATTERNS } from 'shared/constants/url';
import { EAuthExceptionCodes } from 'shared/interfaces/auth';

export enum EVerificationStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
  IN_PROGRESS = 'inProgress',
  NONE = 'none',
}
type TVerifyUserForm = {
  email: string;
  code: string;
  password: string;
  confirmPassword: string;
};

export const VerifyUserPage: FC = () => {
  const { changeInitialPassword, confirmInitialPasswordChange } = useAuth();
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shouldShowRules, setShouldShowRules] = useState(false);
  const [showChangedDialog, setShowChangedDialog] = useState(false);
  const { navigateToLogin } = useURL();
  const { handleSubmit, register, watch } = useForm<TVerifyUserForm>();

  const values = watch();

  const checkPasswordRules = (password: string, confirmPassword: string) =>
    PASSWORD_RULES.every((rule) => rule.check(password, confirmPassword));

  enum ESubmitType {
    CONFIRM_SIGN_UP = 'confirmSignUp',
    CONFIRM_SIGN_UP_FAIL = 'confirmSignUpFail',
    RESEND_SIGN_UP = 'resendSignUp',
    FORGOT_PASSWORD_SUBMIT = 'forgotPasswordSubmit',
  }

  const getSubmitType = async () => {
    return Auth.confirmSignUp(values.email, values.code.trim())
      .then((confirmSignupResponse) => {
        return { type: ESubmitType.CONFIRM_SIGN_UP, ...confirmSignupResponse };
      })
      .catch(({ code, message }) => {
        let type = ESubmitType.CONFIRM_SIGN_UP_FAIL;
        if (EAuthExceptionCodes.CODE_MISMATCH === code) {
          /**
           * If the confirm signup failed, that code could be mismatched.
           * Then we need to resend signup.
           */
          type = ESubmitType.RESEND_SIGN_UP;
        } else if (EAuthExceptionCodes.CODE_EXPIRED === code) {
          /**
           * If the confirm signup failed, that code could be expired.
           * Then we need to try the forgot password path.
           */
          type = ESubmitType.FORGOT_PASSWORD_SUBMIT;
        } else if (
          EAuthExceptionCodes.INVALID_PARAMETER === code &&
          message.includes(
            'failed to satisfy constraint: Member must satisfy regular expression pattern: [\\S]+'
          )
        ) {
          message = 'Code must be only numbers, no spaces. Try again.';
        }
        return { type, message, code };
      });
  };

  function doVerify() {
    return changeInitialPassword(
      values.email.toLowerCase(),
      import.meta.env.VITE_INITIAL_PASSWORD ?? '',
      values.password
    );
  }

  const doForgotPassword = async () => {
    return Auth.forgotPasswordSubmit(
      values.email.toLowerCase() || '',
      values.code.trim(),
      values.password
    );
  };

  const onSubmit = handleSubmit(async (values) => {
    const isValid = checkPasswordRules(values.password, values.confirmPassword);
    if (!isValid) {
      setShouldShowRules(true);
      return;
    }
    setError('');
    setIsSubmitting(true);

    const { type, message } = await getSubmitType();
    let action: Promise<any> = new Promise(() => {});
    if (ESubmitType.CONFIRM_SIGN_UP === type) {
      sessionStorage.setItem(
        'verificationStatus',
        EVerificationStatus.IN_PROGRESS
      );
      action = doVerify();
    } else if (ESubmitType.CONFIRM_SIGN_UP_FAIL === type) {
      action = new Promise(() => {
        throw new Error(message || 'Internal Error');
      });
    } else if (ESubmitType.RESEND_SIGN_UP === type) {
      action = Auth.resendSignUp(values.email.toLocaleLowerCase());
    } else if (ESubmitType.FORGOT_PASSWORD_SUBMIT === type) {
      action = doForgotPassword();
    }
    action
      .then(() => {
        if (ESubmitType.CONFIRM_SIGN_UP === type) {
          sessionStorage.setItem(
            'verificationStatus',
            EVerificationStatus.SUCCESS
          );
        }
        setShowChangedDialog(true);
      })
      .catch((error) => {
        if (ESubmitType.CONFIRM_SIGN_UP === type) {
          sessionStorage.setItem(
            'verificationStatus',
            EVerificationStatus.FAILURE
          );
        }
        setError((error as Error).message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  });

  const handleGoToLogIn = () => {
    setShowChangedDialog(false);
    sessionStorage.removeItem('verificationStatus');
    navigateToLogin();
    confirmInitialPasswordChange();
  };

  useEffect(() => {
    const status = sessionStorage.getItem('verificationStatus');
    if (status === EVerificationStatus.SUCCESS) {
      setShowChangedDialog(true);
    }
  }, [setShowChangedDialog]);

  return (
    <NonAuthLayout formClassName="min-h-[600px]">
      <NonAuthForm
        instructionHeader={
          <>
            <h1 className="text-3xl leading-none">Set</h1>
            <h1 className="text-3xl font-bold leading-none">password</h1>
          </>
        }
        instructions={
          <p className="text-gray-500 leading-7">
            Enter the verification code you received and the new password you
            would like to use.
          </p>
        }
        footer={
          <>
            <p>Do you have an existing account?</p>
            <Link
              to={PATH_PATTERNS[ERoutePath.LOGIN]}
              text="Login"
              className="text-base"
            />
          </>
        }
      >
        <form role="form" onSubmit={onSubmit}>
          <div className="flex flex-col gap-3">
            <Input
              placeholder="Email"
              data-testid="email"
              {...register('email', {
                required: true,
              })}
            />
            <Input
              placeholder="Verification code"
              data-testid="verification-code"
              {...register('code', { required: true })}
            />
            <Input
              placeholder="New Password"
              type="password"
              data-testid="new-password"
              {...register('password', {
                required: true,
              })}
            />
            <Input
              placeholder="Confirm Password"
              type="password"
              data-testid="confirm-password"
              {...register('confirmPassword', {
                required: true,
              })}
            />

            {shouldShowRules && (
              <PasswordRules
                confirmPassword={values.confirmPassword}
                password={values.password}
                rules={PASSWORD_RULES}
              />
            )}

            <Button disabled={isSubmitting} type="submit" className="w-full">
              Set password
            </Button>

            {error && <p>{error}</p>}
          </div>
        </form>
      </NonAuthForm>
      {showChangedDialog ? (
        <Alert
          open
          confirmLabel="Continue"
          onConfirm={handleGoToLogIn}
          onCancel={handleGoToLogIn}
        >
          Password changed!
        </Alert>
      ) : null}
    </NonAuthLayout>
  );
};
