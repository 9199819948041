import { warning } from 'components/common/Toast/Toast';
import { useImageFeedURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { useEffect } from 'react';
import { EImageTypes } from 'shared/interfaces/image';
import { useDebouncedCallback } from 'use-debounce';

const ALERT_TOAST_DELAY = 1000;

export interface IUseMissingImagesAlertProps {
  /** The images count in the feed. */
  imageCount: number;
  /** `true` if the NDVI image s are available. */
  isNDVIAvailable: boolean;
  /** `false` if the RGB images are available. */
  hasNoRGBImages: boolean;
}

export const useMissingImagesAlert = ({
  imageCount,
  hasNoRGBImages,
  isNDVIAvailable,
}: IUseMissingImagesAlertProps) => {
  const { imageType } = useImageFeedURL();

  const debouncedToast = useDebouncedCallback((content: string) => {
    warning({ content });
  }, ALERT_TOAST_DELAY);

  useEffect(() => {
    if (!imageCount && imageType === EImageTypes.NDVI && isNDVIAvailable) {
      debouncedToast(
        `There are no NDVI images in this time range, please use RGB images.`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageType, imageCount, isNDVIAvailable]);

  useEffect(() => {
    if (hasNoRGBImages && imageType === EImageTypes.RGB) {
      debouncedToast(`There are no RGB images in this time range.`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageType, hasNoRGBImages]);
};
