import { useGrowthCyclesByDates } from 'api/growth-cycle';
import { useGetMeasurementsByTypeAndTimeRange } from 'api/measurement';
import { Button } from 'components/common/Button/Button';
import { CenteredLoader } from 'components/common/CenteredLoader';
import {
  Chart,
  ChartProps,
  getPlotBands,
  getPolygonSeriesAndZones,
  getZonedPointsAndZones,
} from 'components/common/Chart/Chart';
import { Dropdown, Option } from 'components/common/Dropdown/Dropdown';
import { Tooltip, topPlacements } from 'components/common/Tooltip/Tooltip';
import { HeatMapGradient } from 'components/heat_map/HeatMapGradient';
import { useHeatMap } from 'components/heat_map/hooks/useHeatMap';
import {
  HEATMAP_TYPE_OPTIONS,
  HEATMAP_TYPE_OPTIONS_NO_OPTIMAL_RANGE,
  PLOT_CONFIG,
  tooltips,
} from 'components/heat_map/utils';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { hoursToMilliseconds } from 'date-fns';
import { useSignals } from 'hooks/useSignals';
import isNil from 'lodash.isnil';
import { ExternalLinkIcon, InfoIcon } from 'lucide-react';
import Plotly from 'plotly.js-cartesian-dist-min';
import { useEffect, useMemo, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import createPlotlyComponent from 'react-plotly.js/factory';
import { useResizeDetector } from 'react-resize-detector';
import {
  InsightCommentContent,
  TDiscussion,
} from 'shared/interfaces/discussion';
import { TGrowthCycle } from 'shared/interfaces/growthCycle';
import { EAggregationTypes, EGradientTypes } from 'shared/interfaces/heatmap';
import {
  EMeasurementGroup,
  MeasurementTypeConfig,
} from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';
import { getLighCyclesRanges } from 'shared/utils/getters';

const Plot = createPlotlyComponent(Plotly);

const PreviewHeatMap = ({
  discussion,
  zoneId,
  width,
  height,
  signals,
  growthCycle,
}: {
  discussion: TDiscussion;
  zoneId: number;
  width: number;
  height: number;
  signals: MeasurementTypeConfig[];
  growthCycle: Optional<TGrowthCycle>;
}) => {
  const aggregationType = !isNil(discussion.heatMapId)
    ? EAggregationTypes.SINGLE
    : EAggregationTypes.AGGREGATED;
  const [gradientType, setGradientType] = useState<EGradientTypes>(
    discussion.area?.gradientType || EGradientTypes.MICROCLIMATES
  );
  const typeConfig = signals[0]!;
  const { heatMapPlotInformation, heatMapScale, gradientScaleValues, loading } =
    useHeatMap({
      typeConfig,
      selectedTime: discussion.startTime,
      gradientType,
      aggregationType,
      width,
      height: Math.min(296, height),
      zoneId,
      growthCycle,
    });

  const hasOptimalRange =
    !isNil(heatMapScale.optimalRangeLowerBound) &&
    !isNil(heatMapScale.optimalRangeUpperBound);

  const heatMapOptions = useMemo(() => {
    if (!hasOptimalRange || !typeConfig.hasOptimalRange) {
      return HEATMAP_TYPE_OPTIONS_NO_OPTIMAL_RANGE;
    }
    return HEATMAP_TYPE_OPTIONS;
  }, [hasOptimalRange, typeConfig.hasOptimalRange]);

  if (loading) return <CenteredLoader />;

  return (
    <div className="relative flex-1 flex flex-col gap-2 items-center justify-between">
      {heatMapPlotInformation && (
        <div className="relative h-fit w-fit">
          <Plot
            className="!flex flex-1 items-center justify-center"
            config={PLOT_CONFIG}
            data={[heatMapPlotInformation.data]}
            layout={heatMapPlotInformation.layout}
            useResizeHandler={true}
          />
          <div
            className="pointer-events-none border-orange-500 border-2 absolute"
            style={{
              top: discussion.area!.points![0]!.y * 100 + '%',
              left: discussion.area!.points![0]!.x * 100 + '%',
              width:
                (discussion.area!.points![1]!.x -
                  discussion.area!.points![0]!.x) *
                  100 +
                '%',
              height:
                (discussion.area!.points![1]!.y -
                  discussion.area!.points![0]!.y) *
                  100 +
                '%',
            }}
          />
        </div>
      )}
      <HeatMapGradient
        gradientType={gradientType}
        heatMapScale={heatMapScale}
        typeConfig={typeConfig}
        scaleValues={gradientScaleValues}
        visible={!!heatMapPlotInformation}
      />
      <div
        className={cn(
          'absolute bottom-2 flex items-center gap-2',
          !isNil(heatMapScale.setPoint) &&
            heatMapScale.setPoint <
              (heatMapScale.lowerBound + heatMapScale.upperBound) / 2
            ? 'right-2'
            : 'left-2'
        )}
      >
        <Dropdown
          variant="secondary"
          value={heatMapOptions.find((option) => option.value === gradientType)}
          onChange={(option) =>
            setGradientType((option as Option<EGradientTypes>).value)
          }
          options={heatMapOptions}
          aria-label="selected heatmap type"
        />
        <Tooltip
          label={tooltips[gradientType]}
          size="lg"
          allowedPlacements={topPlacements}
        >
          <span>
            <InfoIcon className="stroke-[1.5px]" />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

const PreviewHealth = ({
  discussion,
  width,
  height,
}: {
  discussion: TDiscussion;
  width: number;
  height: number;
}) => {
  const { area, previewHealth, firstComment } = discussion;
  const insight = firstComment.content as InsightCommentContent;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      !canvasRef.current ||
      !previewHealth ||
      !area ||
      !area.points ||
      area.points.length !== 2
    ) {
      return;
    }

    const bottomLeft = {
      x: area.points[0].x * previewHealth.resolution[0],
      y: area.points[0].y * previewHealth.resolution[1],
    };
    const topRight = {
      x: area.points[1].x * previewHealth.resolution[0],
      y: area.points[1].y * previewHealth.resolution[1],
    };
    const sourceWidth = topRight.x - bottomLeft.x;
    const sourceHeight = topRight.y - bottomLeft.y;
    const aspectRatio = sourceWidth / sourceHeight;

    let destWidth, destHeight;
    if (width / height > aspectRatio) {
      // Taller than wider or square
      destHeight = height;
      destWidth = height * aspectRatio;
    } else {
      // Wider than tall
      destWidth = width;
      destHeight = width / aspectRatio;
    }

    // Center the image in the canvas
    const destX = (width - destWidth) / 2;
    const destY = (height - destHeight) / 2;

    const context = canvasRef.current.getContext('2d');
    if (context) {
      // Retain the pixels' sharpness
      context.imageSmoothingEnabled = false;

      // clear canvas because of re-renders
      context.clearRect(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );

      const image = new Image();
      image.onload = () => {
        context.drawImage(
          image,
          bottomLeft.x,
          bottomLeft.y,
          sourceWidth,
          sourceHeight,
          destX,
          destY,
          destWidth,
          destHeight
        );

        setLoading(false);
      };

      setLoading(true);

      // Set the source of the image
      image.src = previewHealth.url;
    }
  }, [area, height, previewHealth, width]);

  return (
    <>
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        aria-label={`Health image for ${insight.title}`}
      />
      {loading && <CenteredLoader />}
    </>
  );
};

const PreviewEnvironment = ({
  discussion,
  zoneId,
  zoneUid,
  zoneTimeZone,
  height,
  signals,
  growthCycle,
}: {
  discussion: TDiscussion;
  zoneId: number;
  zoneUid: string;
  zoneTimeZone: string;
  height: number;
  signals: MeasurementTypeConfig[];
  growthCycle: Optional<TGrowthCycle>;
}) => {
  const { startTime: start, endTime: end, area, firstComment } = discussion;
  const insight = firstComment.content as InsightCommentContent;
  const measurementsQuery = useGetMeasurementsByTypeAndTimeRange({
    zoneId,
    zoneUid,
    zoneTimeZone,
    start,
    end,
    signals,
    aggregation: area?.viewType,
  });
  const isSingle = signals.length === 1;
  const chartAriaLabel = `Insight preview chart for ${insight.title}`;
  const ranges = useMemo(
    function computeRanges() {
      return getLighCyclesRanges({
        start,
        end,
        lightInfo: growthCycle?.metadata.light_info ?? [],
      });
    },
    [end, growthCycle?.metadata.light_info, start]
  );
  const plotBands = useMemo(
    function computePlotBands() {
      return getPlotBands(ranges);
    },
    [ranges]
  );
  const { polygonSeries, warningRanges } = useMemo(
    function computeOptimalAndWarningRanges() {
      if (
        !isSingle ||
        !growthCycle ||
        isNil(start) ||
        isNil(end) ||
        measurementsQuery.loading ||
        !measurementsQuery.called
      ) {
        return {
          polygonSeries: [],
          warningRanges: [],
        };
      }

      const { polygonSeries = [], warningRanges } = getPolygonSeriesAndZones({
        end,
        growthCycle,
        start,
        ranges,
        signal: signals[0]!,
      });

      return {
        polygonSeries,
        warningRanges,
      };
    },
    [
      isSingle,
      end,
      growthCycle,
      measurementsQuery.called,
      measurementsQuery.loading,
      ranges,
      signals,
      start,
    ]
  );
  const yAxis = useMemo(
    () =>
      signals.reduce<Highcharts.YAxisOptions[]>((axes, signal) => {
        const exists = axes.some(({ id }) => id === signal.unit);

        if (!exists) {
          axes.push({
            id: signal.unit,
            reversed: signal.reversedYAxis,
            visible: isSingle,
          });
        }

        return axes;
      }, []),
    [isSingle, signals]
  );
  const lineSeries = useMemo<Highcharts.SeriesLineOptions[]>(
    function computeLineSeries() {
      const availableSignals = Array.from(measurementsQuery.data.keys());
      const lineSeries: Highcharts.SeriesLineOptions[] = [];

      for (const [signal, data] of measurementsQuery.data) {
        // Ensure an unique name
        const name = availableSignals.some(
          ({ type, label }) => label === signal.label && type !== signal.type
        )
          ? `${signal.label} (${signal.group})`
          : signal.label;
        const series: Highcharts.SeriesLineOptions = {
          id: signal.type,
          data,
          name,
          sortingHash: `${signal.group}_${name}`,
          type: 'line',
          className: cn(
            isSingle ? 'highcharts-neutral-900' : signal.style?.svg
          ),
          yAxis: signal.unit,
          showInLegend: true,
          legendSymbol: 'lineMarker',
          marker: {
            enabledThreshold: 6,
          },
        };

        if (signal.group === EMeasurementGroup.Environment && isSingle) {
          const { points, zones } = getZonedPointsAndZones({
            data,
            warningRanges,
          });

          series.data = points;
          series.zoneAxis = 'x';
          series.zones = zones;
        }

        lineSeries.push(series);
      }

      return lineSeries.toSorted((a, b) =>
        (a.sortingHash ?? '').localeCompare(b.sortingHash ?? '')
      );
    },
    [isSingle, measurementsQuery.data, warningRanges]
  );
  const options = useMemo<ChartProps['options']>(
    function computeOptions() {
      if (measurementsQuery.loading || !measurementsQuery.called) {
        return undefined;
      }

      return {
        accessibility: {
          description: chartAriaLabel,
        },
        boost: {
          pixelRatio: 0,
        },
        chart: {
          marginTop: 10,
          spacingTop: 0,
        },
        xAxis: {
          plotBands,
          // When there's no data, set minRange to a full day
          ...(lineSeries.length === 0
            ? { minRange: hoursToMilliseconds(24) }
            : undefined),
        },
        yAxis,
        series: [...polygonSeries, ...lineSeries],
        legend: {
          align: 'center',
          alignColumns: false,
          className: cn(isSingle && 'pointer-events-none'),
          enabled: true,
          itemDistance: 8,
          labelFormat: '{name} ({yAxis.userOptions.id})',
          margin: 8,
          padding: 0,
          verticalAlign: 'bottom',
        },
      };
    },
    [
      chartAriaLabel,
      isSingle,
      lineSeries,
      measurementsQuery.called,
      measurementsQuery.loading,
      plotBands,
      polygonSeries,
      yAxis,
    ]
  );

  return (
    <Chart
      style={{ height }}
      options={options}
      loading={measurementsQuery.loading}
      aria-label={chartAriaLabel}
    />
  );
};

export const InsightPreview = ({
  discussion,
  zoneId,
  zoneUid,
  zoneTimeZone,
  onClick,
}: {
  discussion: TDiscussion;
  zoneId: number;
  zoneUid: string;
  zoneTimeZone: string;
  onClick: () => void;
}) => {
  const {
    annotationType,
    area,
    firstComment,
    uid,
    startTime: start,
    endTime: end,
  } = discussion;
  const insight = firstComment.content as InsightCommentContent;
  const { rawSignals } = useSignals({
    rawSignalIds: area?.signalIds,
    zoneUid,
  });
  const { presetTypes } = useTypeConfig();
  const { growthCycle } = useGrowthCyclesByDates({
    zoneId,
    start,
    end,
    zoneTimeZone,
    presetTypes,
  });
  const { width = 576, height = 300, ref } = useResizeDetector();

  if (annotationType === 'event') {
    return null;
  }

  return (
    <div
      ref={ref}
      className={cn(
        'relative rounded-sm overflow-hidden group border border-neutral-400'
      )}
    >
      <>
        <InView>
          {({ ref, inView }) => (
            <div
              ref={ref}
              className={cn(
                'h-[300px] bg-neutral-200 flex flex-col',
                ['heatmap_annotation', 'heatmap_aggregate_annotation'].includes(
                  annotationType
                ) && 'h-auto min-h-[300px] max-h-[400px]'
              )}
            >
              {inView && annotationType === 'single_image_annotation' && (
                <PreviewHealth
                  key={uid}
                  discussion={discussion}
                  width={width}
                  height={height}
                />
              )}

              {inView &&
                annotationType === 'time_range_annotation' &&
                rawSignals.length > 0 && (
                  <PreviewEnvironment
                    key={uid}
                    discussion={discussion}
                    height={height}
                    zoneId={zoneId}
                    zoneUid={zoneUid}
                    zoneTimeZone={zoneTimeZone}
                    signals={rawSignals}
                    growthCycle={growthCycle}
                  />
                )}

              {inView &&
                (annotationType === 'heatmap_annotation' ||
                  annotationType === 'heatmap_aggregate_annotation') &&
                rawSignals.length > 0 && (
                  <PreviewHeatMap
                    key={uid}
                    discussion={discussion}
                    height={height}
                    width={width}
                    zoneId={zoneId}
                    signals={rawSignals}
                    growthCycle={growthCycle}
                  />
                )}
            </div>
          )}
        </InView>

        <div
          className="absolute top-0 right-0 bg-transparent cursor-pointer hidden group-hover:block p-4"
          onClick={onClick}
        >
          <Button
            size="icon"
            variant="tertiary"
            aria-label={`Go to ${insight.title}`}
          >
            <ExternalLinkIcon className="stroke-[1.5px] size-4 xl:size-5" />
          </Button>
        </div>
      </>
    </div>
  );
};
