import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const BudIcon = ({ className, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={cn('h-6 w-6', className)}
    {...props}
  >
    <path d="M12 22V18" />
    <path d="M17 8V8.8C18.1625 9.46723 19.0682 10.5045 19.5725 11.7464C20.0767 12.9883 20.1507 14.3633 19.7824 15.6521C19.4142 16.9409 18.625 18.0693 17.5408 18.8574C16.4565 19.6454 15.1396 20.0476 13.8 20H10C8.62897 19.9069 7.32269 19.3817 6.26881 18.4998C5.21493 17.6178 4.46768 16.4246 4.13438 15.0914C3.80109 13.7582 3.8989 12.3537 4.41377 11.0796C4.92864 9.80549 5.83407 8.72734 7.00002 8C7.00002 6.67392 7.52681 5.40215 8.46449 4.46447C9.40217 3.52678 10.6739 3 12 3C13.3261 3 14.5979 3.52678 15.5356 4.46447C16.4732 5.40215 17 6.67392 17 8Z" />
    <path d="M6.15771 18.306C5.73115 17.4843 5.64758 16.527 5.92528 15.6437C6.20299 14.7605 6.81936 14.0233 7.63943 13.5935" />
    <path d="M12 11.0749C12.925 11.1147 13.7966 11.5194 14.4238 12.2005C15.0511 12.8815 15.383 13.7833 15.3468 14.7085" />
    <path d="M7.01215 7.97922C8.80282 6.74841 9.93088 6.78273 11.7249 7.19667" />
  </svg>
);

export { BudIcon };
