import {
  NavigateFunction,
  NavigateOptions,
  generatePath,
  matchPath,
} from 'react-router-dom';
import { ERoutePath, PATH_PATTERNS } from 'shared/constants/url';
import { QueryParamsSchema, QueryParamsSchemasKey } from './URLQueryParams';
import { objectToURLSearchParams } from './utils';

export function navigateToFactory<T extends QueryParamsSchemasKey>(
  navigate: NavigateFunction,
  fromPathname: string,
  fromSearchParams: URLSearchParams,
  toPath: ERoutePath
) {
  return (
    params: {
      pathParams?: Record<string, string | number | undefined>;
      queryParams?: Partial<QueryParamsSchema<T>>;
      clearPreviousQueryParams?: boolean;
      skipNavigation?: boolean;
    },
    navigateOptions?: NavigateOptions
  ) => {
    const match = matchPath(PATH_PATTERNS[toPath], fromPathname);

    const newSearchParams = objectToURLSearchParams<T>({
      params: params.queryParams ?? {},
      prevSearch: fromSearchParams,
      clearPreviousQueryParams: params.clearPreviousQueryParams,
    });

    const path =
      generatePath(PATH_PATTERNS[toPath], {
        ...(match?.params as any),
        ...params.pathParams,
      }) +
      '?' +
      newSearchParams.toString();

    !params.skipNavigation && navigate(path, navigateOptions);

    return path;
  };
}
