import {
  TBoundingBoxWithDiscussion,
  TDiscussion,
} from 'shared/interfaces/discussion';
import { TEnumeration, TLabelEnumeration } from 'shared/interfaces/enumeration';
import { EImageLabelCategory, TLabelByCategory } from 'shared/interfaces/image';
import { TResolution } from 'shared/interfaces/measurement';

/**
 * Get the public resource path given the metadata url
 *
 * @param {string} resourcePath - current url
 * @param {TResolution} rgbImageSize - the size of the image in pixels
 * @param {string} bucket - Image  S3 bucket
 * @param {boolean} isFullResolution - Is image full resolution
 * @returns {string} - public url
 */
export const getPublicResourcePath = (
  resourcePath: string,
  rgbImageSize: TResolution,
  bucket: string = '',
  isFullResolution: boolean = false
): string => {
  const subPaths = resourcePath.split('/');

  let baseUrl = '';
  let result = '';
  const size = `${rgbImageSize[0]}x${rgbImageSize[1]}`;
  const fileName = subPaths.at(-1);

  if (bucket) {
    // Version 2.0 has bucket info.
    // {
    //   thumbnails: [
    //     [4056, 3040],
    //     [173, 130],
    //     [266, 199],
    //   ],
    //   image_bucket: 'dev-spyder-v2-imagebucket-archive',
    //   resource_path:
    //     'rgb/rgb_spyder-SPY-1-2_0001_zone-NEATLEAF_SCOTTS_VALLEY_OFFICE_SV_JENKINS_org-NEATLEAF_pb-1663113062_mt-1663117506__14055449.jpg',
    //   thumbnail_bucket: 'dev-spyder-v2-imagebucket-artifacts',
    // };
    baseUrl = `https://${bucket}.s3.us-west-2.amazonaws.com`;

    // Version 2.5 does not use size info for url.
    if (isFullResolution) {
      result = [baseUrl, resourcePath].join('/');
    } else {
      result = [baseUrl, size, fileName].join('/');
    }
  } else {
    // Version 1.0 has no bucket info so use subPaths from path.
    // { "resource_path": dev-spyder-v1-imagebucket/4032x3040/rgb_spyder-3_org-1_pb-1640123578_mt-1640123600_313553166.jpg" }
    baseUrl = `https://${subPaths[0]}.s3.us-west-2.amazonaws.com`;
    result = [baseUrl, size, fileName].join('/');
  }

  return result;
};

/**
 * Convert enumeration to label category
 *
 * @param {TEnumeration} enumeration - The enumeration
 * @returns {TLabelByCategory} - The label category
 */
export const convertEnumerationToLabel = (
  enumeration: TEnumeration | TLabelEnumeration
): TLabelByCategory => {
  const enumerationCodes = enumeration.code.split(':');
  let enumerationId = null;

  if ('id' in enumeration) enumerationId = enumeration.id;
  else enumerationId = enumeration.enumerationId;

  return {
    enumerationId,
    category: enumerationCodes[0] as EImageLabelCategory,
    subcategory: enumerationCodes[1] ?? '',
    code: enumeration.code,
    description: enumeration.description,
  };
};

export const getDrawnBoundingBoxWithMetadataFromDiscussion = (
  item: TDiscussion,
  resolution: TSize
) => {
  const x0 = (item.area?.points?.[0].x || 0) * resolution.width;
  const y0 = (item.area?.points?.[0].y || 0) * resolution.height;
  const x1 = (item.area?.points?.[1].x || 0) * resolution.width;
  const y1 = (item.area?.points?.[1].y || 0) * resolution.height;
  const boundingBox: TBoundingBoxWithDiscussion = {
    x: x0,
    y: y0,
    width: x1 - x0,
    height: y1 - y0,
    drawing: false,
    visible: true,
    metadata: {
      discussion: item,
    },
  };
  return boundingBox;
};
