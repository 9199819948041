import { useGetMeasurementsByTypeAndTimeRange } from 'api/measurement';
import { Chart, ChartProps } from 'components/common/Chart/Chart';
import { Chip } from 'components/common/Chip/Chip';
import { hoursToMilliseconds } from 'date-fns';
import { useSignals } from 'hooks/useSignals';
import { DiamondIcon } from 'lucide-react';
import { useCallback, useMemo } from 'react';
import {
  MeasurementAggregation,
  MeasurementTypeConfig,
} from 'shared/interfaces/measurement';
import { TZone } from 'shared/interfaces/zone';
import { cn } from 'shared/utils/cn';

interface HealthIndicatorsProps {
  zone: TZone;
  startTime: number;
  endTime: number;
}
export const HealthIndicators = ({
  zone,
  startTime,
  endTime,
}: HealthIndicatorsProps) => {
  const { allSignals, signalIds, updateSignalIds, signals } = useSignals({
    zoneUid: zone.uid,
    startTime: startTime,
    endTime: endTime,
    measurementAggregation: MeasurementAggregation.AVERAGE,
  });

  const { data } = useGetMeasurementsByTypeAndTimeRange({
    zoneId: zone.id,
    zoneUid: zone.uid,
    zoneTimeZone: zone.timeZone,
    start: new Date(startTime),
    end: new Date(endTime),
    signals,
    aggregation: MeasurementAggregation.AVERAGE,
  });

  const dataKeys = useMemo(() => {
    return Array.from(data.keys());
  }, [data]);

  const plantHealthSignals = useMemo(() => {
    return allSignals.filter((s) => s.group === 'Plant health');
  }, [allSignals]);

  const handleToggleSignals = useCallback(
    (signals: MeasurementTypeConfig[]) => () => {
      const ids = signals.map(({ type }) => type as string);
      const checkedIds = signalIds.filter((id) => ids.includes(id));

      if (checkedIds.length < ids.length) {
        // check ids
        updateSignalIds(Array.from(new Set([...signalIds, ...ids])));
      } else {
        // uncheck ids
        updateSignalIds(signalIds.filter((id) => !ids.includes(id)));
      }
    },
    [signalIds, updateSignalIds]
  );

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-col gap-2 p-6 bg-white rounded-t">
        <h2 className="font-semibold">Health indicators over time</h2>
        <p>
          Select any parameter that you would like to display for this growth
          cycle report. All selected parameters will be displayed as charts
          below.
        </p>
        <div className="flex flex-wrap gap-2">
          {plantHealthSignals.map((signal) => {
            return (
              <Chip
                variant="neutral"
                key={signal.type}
                selected={signalIds.includes(signal.type)}
                onClick={handleToggleSignals([signal])}
                className="cursor-pointer"
              >
                <DiamondIcon
                  className={cn(
                    'icon fill-current',
                    signal.style?.stroke,
                    signal.style?.text
                  )}
                />
                {signal.label}
              </Chip>
            );
          })}
        </div>
      </div>
      {signals.map((signal) => {
        const measurementKey = dataKeys.find((k) => k.type === signal.type)!;
        const measurementData = data.get(measurementKey);
        return (
          <div
            key={`signal-${signal.type}`}
            className="flex flex-col gap-2 p-6 bg-white last:rounded-b items-center"
          >
            <Chip variant="neutral" selected={true}>
              <DiamondIcon
                className={cn(
                  'icon fill-current',
                  signal.style?.stroke,
                  signal.style?.text
                )}
              />
              {signal.label}
            </Chip>
            <LineChart
              height={230}
              signal={signal}
              measurementKey={measurementKey}
              measurementData={measurementData}
              start={startTime}
              end={endTime}
            />
          </div>
        );
      })}
    </div>
  );
};

const LineChart = ({
  measurementData,
  measurementKey,
  height,
  signal,
  start,
  end,
}: {
  height: number;
  signal: MeasurementTypeConfig;
  measurementKey: MeasurementTypeConfig;
  measurementData: [number, number][] | undefined;
  start: number;
  end: number;
}) => {
  const chartAriaLabel = `Line chart for ${measurementKey.label}`;

  const yAxis: Highcharts.YAxisOptions[] = useMemo(() => {
    return [
      {
        id: signal.unit,
        reversed: signal.reversedYAxis,
      },
    ];
  }, [signal.reversedYAxis, signal.unit]);

  const lineSeries = useMemo<Highcharts.SeriesLineOptions[]>(() => {
    const lineSeries: Highcharts.SeriesLineOptions[] = [];

    // Ensure an unique name
    const name = measurementKey.label;
    const series: Highcharts.SeriesLineOptions = {
      id: measurementKey.type,
      data: measurementData,
      name,
      type: 'line',
      className: cn(measurementKey.style?.svg),
      yAxis: measurementKey.unit,
      showInLegend: true,
      legendSymbol: 'lineMarker',
      marker: {
        enabledThreshold: 6,
      },
    };

    lineSeries.push(series);

    return lineSeries;
  }, [measurementData, measurementKey]);

  const options = useMemo<ChartProps['options']>(
    function computeOptions() {
      return {
        accessibility: {
          description: chartAriaLabel,
        },
        boost: {
          pixelRatio: 0,
        },
        chart: {
          marginTop: 10,
          spacingTop: 0,
        },
        xAxis: {
          max: end,
          min: start,
          // When there's no data, set minRange to a full day
          ...(lineSeries.length === 0
            ? { minRange: hoursToMilliseconds(24) }
            : undefined),
        },
        yAxis,
        series: [...lineSeries],
      };
    },
    [chartAriaLabel, end, lineSeries, start, yAxis]
  );

  return (
    <Chart style={{ height }} options={options} aria-label={chartAriaLabel} />
  );
};
