import { wrapUseRoutes } from '@sentry/react';
import { HeatMap } from 'components/heat_map/HeatMap';
import { Homepage } from 'components/homepage/Homepage';
import { ImageFeed } from 'components/image_feed/ImageFeed';
import { ForgotPasswordPage } from 'pages/auth/ForgotPasswordPage';
import { LoginPage } from 'pages/auth/LoginPage';
import { ResetPasswordPage } from 'pages/auth/ResetPasswordPage';
import { VerifyUserPage } from 'pages/auth/VerifyUserPage';
import { GrowthCycleReportPage } from 'pages/growth-cycle-report/GrowthCycleReportPage';
import { GrowthCycleSettings } from 'pages/growth-cycle-settings/GrowthCycleSettings';
import { HomepageV2 } from 'pages/homepage/HomepageV2';
import { GlobalInsightsPage } from 'pages/insights/GlobalInsightsPage';
import { ZoneInsightsPage } from 'pages/insights/ZoneInsightsPage';
import { NewLineChart } from 'pages/line-chart/NewLineChart';
import { NotFoundPage } from 'pages/not_found/NotFoundPage';
import { SettingsPage } from 'pages/settings/SettingsPage';
import { SettingsTab } from 'pages/settings/SettingsTab';
import { UserAdd } from 'pages/settings/UserAdd';
import { UserEdit } from 'pages/settings/UserEdit';
import { Terms } from 'pages/terms/Terms';
import { ZoneDetailsPage } from 'pages/zone_details/ZoneDetailsPage';
import { RouteObject, useRoutes } from 'react-router-dom';
import { ERoutePath, PATH_PATTERNS } from 'shared/constants/url';
import { EZoneDetailTabs } from 'shared/interfaces/zone';

const sentryRoutes = wrapUseRoutes(useRoutes);

/**
 * This function is used to extract the variable part of a path.
 *
 * For example, if the path is '/:organizationCode/zone-details/:zoneId/:oldPage/:tab',
 * and the base path is '/:organizationCode/zone-details/:zoneId',
 * then the variable part is ':oldPage/:tab'.
 *
 * @param fullPath The full path.
 * @param basePath The base path.
 * @returns The variable part of the path.
 *
 * @example
 * const fullPath = '/:organizationCode/zone-details/:zoneId/:oldPage/:tab';
 * const basePath = '/:organizationCode/zone-details/:zoneId';
 * const variablePart = extractVariablePath(fullPath, basePath);
 * console.log(variablePart); // ':oldPage/:tab'
 *
 */
function extractVariablePath(fullPath: string, basePath: string) {
  let variablePart = fullPath.replace(basePath, '');

  if (variablePart.startsWith('/')) {
    variablePart = variablePart.substring(1);
  }

  return variablePart;
}

export function RouteTree() {
  return sentryRoutes(getRoutes());
}

export function getFlattenedRoutes(): RouteObject[] {
  return flattenedRoutes(getRoutes());
}

export type AugmentedRouteObject = RouteObject & {
  shouldTrackPageView: boolean;
  shouldCheckAuth: boolean;
};

function getRoutes(): AugmentedRouteObject[] {
  const settingsRoutes: AugmentedRouteObject[] = [
    {
      id: ERoutePath.SETTINGS_TAB,
      path: extractVariablePath(
        PATH_PATTERNS[ERoutePath.SETTINGS_TAB],
        PATH_PATTERNS[ERoutePath.SETTINGS]
      ),
      element: <SettingsTab />,
      shouldTrackPageView: true,
      shouldCheckAuth: true,
    },
    {
      id: ERoutePath.SETTINGS_ADD_USER,
      path: extractVariablePath(
        PATH_PATTERNS[ERoutePath.SETTINGS_ADD_USER],
        PATH_PATTERNS[ERoutePath.SETTINGS]
      ),
      element: <UserAdd />,
      shouldTrackPageView: true,
      shouldCheckAuth: true,
    },
    {
      id: ERoutePath.SETTINGS_EDIT_USER,
      path: extractVariablePath(
        PATH_PATTERNS[ERoutePath.SETTINGS_EDIT_USER],
        PATH_PATTERNS[ERoutePath.SETTINGS]
      ),
      element: <UserEdit />,
      shouldTrackPageView: true,
      shouldCheckAuth: true,
    },
  ];

  const zoneDetailsRoutes: AugmentedRouteObject[] = [
    {
      id: ERoutePath.HOMEPAGE,
      path: EZoneDetailTabs.HOMEPAGE,
      element: <Homepage />,
      shouldTrackPageView: true,
      shouldCheckAuth: true,
    },
    {
      id: ERoutePath.HOMEPAGEV2,
      path: EZoneDetailTabs.HOMEPAGEV2,
      element: <HomepageV2 />,
      shouldTrackPageView: true,
      shouldCheckAuth: true,
    },
    {
      id: ERoutePath.IMAGE_FEED,
      path: EZoneDetailTabs.IMAGE_FEED,
      element: <ImageFeed />,
      shouldTrackPageView: true,
      shouldCheckAuth: true,
    },
    {
      id: ERoutePath.HEAT_MAP,
      path: EZoneDetailTabs.HEAT_MAP,
      element: <HeatMap />,
      shouldTrackPageView: true,
      shouldCheckAuth: true,
    },
    {
      id: ERoutePath.LINE_CHART,
      path: EZoneDetailTabs.LINE_CHART,
      element: <NewLineChart />,
      shouldTrackPageView: true,
      shouldCheckAuth: true,
    },
    {
      id: ERoutePath.GROWTH_CYCLE_SETTINGS,
      path: EZoneDetailTabs.GROWTH_CYCLE_SETTINGS,
      element: <GrowthCycleSettings />,
      shouldTrackPageView: true,
      shouldCheckAuth: true,
    },
    {
      id: ERoutePath.ZONE_INSIGHTS,
      path: EZoneDetailTabs.INSIGHTS,
      element: <ZoneInsightsPage />,
      shouldTrackPageView: true,
      shouldCheckAuth: true,
    },
    {
      id: ERoutePath.GROWTH_CYCLE_REPORT,
      path: EZoneDetailTabs.GROWTH_CYCLE_REPORT,
      element: <GrowthCycleReportPage />,
      shouldTrackPageView: true,
      shouldCheckAuth: true,
    },
  ];

  const routes: AugmentedRouteObject[] = [
    {
      id: ERoutePath.GLOBAL_INSIGHTS,
      path: PATH_PATTERNS[ERoutePath.GLOBAL_INSIGHTS],
      element: <GlobalInsightsPage />,
      shouldTrackPageView: true,
      shouldCheckAuth: true,
    },
    {
      id: ERoutePath.TERMS,
      path: PATH_PATTERNS[ERoutePath.TERMS],
      element: <Terms />,
      shouldTrackPageView: true,
      shouldCheckAuth: false,
    },
    {
      id: ERoutePath.LOGIN,
      path: PATH_PATTERNS[ERoutePath.LOGIN],
      element: <LoginPage />,
      shouldTrackPageView: true,
      shouldCheckAuth: true,
    },
    {
      id: ERoutePath.FORGOT_PASSWORD,
      path: PATH_PATTERNS[ERoutePath.FORGOT_PASSWORD],
      element: <ForgotPasswordPage />,
      shouldTrackPageView: true,
      shouldCheckAuth: false,
    },
    {
      id: ERoutePath.RESET_PASSWORD,
      path: PATH_PATTERNS[ERoutePath.RESET_PASSWORD],
      element: <ResetPasswordPage />,
      shouldTrackPageView: true,
      shouldCheckAuth: false,
    },
    {
      id: ERoutePath.VERIFY_PASSWORD,
      path: PATH_PATTERNS[ERoutePath.VERIFY_PASSWORD],
      element: <VerifyUserPage />,
      shouldTrackPageView: true,
      shouldCheckAuth: false,
    },
    {
      path: PATH_PATTERNS[ERoutePath.SETTINGS],
      element: <SettingsPage />,
      shouldTrackPageView: false,
      shouldCheckAuth: true,
      children: settingsRoutes,
    },
    {
      id: ERoutePath.ZONE_DETAILS_PAGE,
      path: PATH_PATTERNS[ERoutePath.ZONE_DETAILS_PAGE],
      element: <ZoneDetailsPage />,
      shouldTrackPageView: false,
      shouldCheckAuth: true,
      children: zoneDetailsRoutes,
    },
    {
      id: ERoutePath.ROOT,
      path: PATH_PATTERNS[ERoutePath.ROOT],
      element: <ZoneDetailsPage />,
      shouldTrackPageView: false,
      shouldCheckAuth: true,
    },
    {
      id: 'not-found',
      path: '*',
      element: <NotFoundPage />,
      shouldTrackPageView: true,
      shouldCheckAuth: false,
    },
  ];

  return routes;
}

function flattenedRoutes(routes: RouteObject[], all: RouteObject[] = []) {
  let flattened = all;

  const list = routes.map((route) => {
    if (route.children) {
      flattened = flattenedRoutes(route.children, flattened);
    }

    return route;
  });

  return [...flattened, ...list];
}
