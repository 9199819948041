import { Button } from 'components/common/Button/Button';
import { IUserFilterForm, UserFilterBar } from 'components/user/UserFilterBar';
import { UserTable } from 'components/user/UserTable';
import { useAuth } from 'contexts/AuthProvider';
import {
  useSettingsURL,
  useSettingsUsersURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import { useUsers } from 'hooks/useUsers';
import { FC, useCallback, useMemo, useState } from 'react';
import { ALL } from 'shared/constants/general';

export const UserList: FC = () => {
  const { navigateToCreateNewUserForm, navigateToEditUserForm } =
    useSettingsURL();
  const { page, setPage } = useSettingsUsersURL();

  const [filterForm, setFilterForm] = useState<IUserFilterForm>({
    role: ALL,
  });

  const { users } = useUsers();
  const { currentlySelectedOrganization } = useAuth();

  const totalCount = users.length;
  const inactiveCount = useMemo(
    () => users.filter((user) => !user.active).length,
    [users]
  );

  const filteredUsers = useMemo(() => {
    return users.filter((user) => {
      // Filter by role.
      if (filterForm.role == ALL) {
        return true;
      }
      return user.user_organization_roles.find(
        (userOrganizationRole) =>
          userOrganizationRole.role.code === filterForm.role &&
          (userOrganizationRole.organization_id ===
            currentlySelectedOrganization?.id ||
            userOrganizationRole.location?.organization_id ===
              currentlySelectedOrganization?.id ||
            userOrganizationRole.zone?.location.organization_id ===
              currentlySelectedOrganization?.id)
      );
    });
  }, [filterForm.role, users, currentlySelectedOrganization?.id]);

  const handleClickAddUser = useCallback(() => {
    navigateToCreateNewUserForm();
  }, [navigateToCreateNewUserForm]);

  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <div className="flex items-center gap-1">
          <p className="text-lg font-bold">{totalCount} total</p>
          <span>·</span>
          <p className="text-lg text-gray-500">{inactiveCount} inactive</p>
        </div>

        <Button className="font-semibold" onClick={handleClickAddUser}>
          + Add User
        </Button>
      </div>

      <UserFilterBar form={filterForm} onChange={setFilterForm} />

      <UserTable
        page={page}
        users={filteredUsers}
        onClick={navigateToEditUserForm}
        onPageChange={setPage}
      />
    </div>
  );
};
