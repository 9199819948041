import {
  EMeasurementStatisticsTypesV2,
  EMontioringParameterVersion,
  TMontioringParameterType,
} from './measurement';

export type TPlantInfo = {
  id: string;
  strainName: string;
  count: number;
  layoutNumber: number;
};

export type TCycle = {
  startValue: number;
  endValue: number;
};

export type TGrowthCycle = {
  id: number;
  start_time: number;
  end_time: number;
  zone_id: number;
  metadata?: GrowthCycleMetadata | any;
  isActive?: boolean;
  environmentalPreset?: Maybe<EnvironmentalPreset>;
  lightCyclesPreset?: Maybe<LightCyclesPreset>;
  cultivars?: Maybe<TPlantInfo[]>;
  cultivarLayout?: Maybe<Record<string, number[][]>>;
};

export type TGrowthCycleEdit = WithRequired<
  TGrowthCycle,
  'cultivars' | 'lightCyclesPreset' | 'environmentalPreset'
>;

export type GrowthCycleMetadata = {
  notes?: string;
  light_info?: TLightInfo[];
  show_harvest_progress?: boolean;
  label_overview_upper_count_bound?: Record<string, number>;
  monitoring_parameters?: Record<
    string,
    TMontioringParameterType | EMontioringParameterVersion
  >;
};

export type TLightInfo = {
  light_on_start_time: {
    hour: number;
    minute: number;
  };
  light_on_duration_minutes: number;
  light_cycle_configuration_start_timestamp_seconds: number;
};

export type TGrowthCycleOption = {
  id: number;
  label: string;
  start: Date;
  end: Date;
};

export type Parameters = {
  setPoint: Nullable<number>;
  ideal: Nullable<number>;
  critical: Nullable<number>;
  idealUpper: Nullable<number>;
  idealLower: Nullable<number>;
  criticalUpper: Nullable<number>;
  criticalLower: Nullable<number>;
};

export type CycleParameters = keyof Pick<Setting, 'light' | 'dark'>;

export interface Preset {
  id?: number;
  name: string;
  lastUpdated: Date;
  locationId: number;
  organizationId: number;
  isActive: boolean;
  count?: number;
}

export type Setting = {
  id: string;
  type: `${EMeasurementStatisticsTypesV2}`;
  day: number;
  light: Parameters;
  dark: Parameters;
};
export interface EnvironmentalPreset extends Preset {
  settings: Setting[] | [];
}

export type LightCycle = {
  id: string;
  start: number;
  onAt: string;
  duration: number;
};

export interface LightCyclesPreset extends Preset {
  lightCycles: LightCycle[] | [];
}

export const PREDEFINED_TIME_RANGES = {
  THREE_DAYS: 'Past 3 days',
  ONE_WEEK: 'Past week',
  TWO_WEEKS: 'Past 2 weeks',
  FULL_CYCLE: 'Full cycle',
  CUSTOM: 'Custom',
} as const;

export type PredefinedTimeRanges = keyof typeof PREDEFINED_TIME_RANGES;

export type PredefinedTimeRangeLabels =
  (typeof PREDEFINED_TIME_RANGES)[PredefinedTimeRanges];

export type TPredefinedTimeRange = {
  range: PredefinedTimeRanges;
  label: PredefinedTimeRangeLabels | string;
  start: Date;
  end: Date;
};
