import { Checkbox } from 'components/common/Checkbox/Checkbox';
import { useAuth } from 'contexts/AuthProvider';
import { useUsers } from 'hooks/useUsers';
import { ChangeEvent, FC } from 'react';
import { NEATLEAF_ORGANIZATION_CODE } from 'shared/interfaces/organization';
import {
  NEATLEAF_PLANT_SCIENTIST_CODE,
  NEATLEAF_PLANT_SCIENTIST_PUBLISHER_CODE,
} from 'shared/interfaces/role';
import { EUserRole, TUserRoleType } from 'shared/interfaces/user';
import { cn } from 'shared/utils/cn';

interface IUserRoleSelectorProps {
  /** The user roles. */
  roles: string[];
  /** The callback to change user roles. */
  onChange: (roles: string[]) => void;
}

export const UserRoleSelector: FC<IUserRoleSelectorProps> = ({
  roles,
  onChange,
}) => {
  const { userRoleTypes } = useUsers();
  const { currentlySelectedOrganization } = useAuth();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const role = event.target.name;
    const checked = event.target.checked;
    if (checked) {
      onChange([...roles, role]);
    } else {
      onChange(roles.filter((each) => each !== role));
    }
  };

  const roleSet = new Set(roles);

  const renderRoleType = (roleType: TUserRoleType) => {
    const isCultivatorRole = roleType.code === EUserRole.CULTIVATOR;
    const checked = roleSet.has(roleType.code) || isCultivatorRole;

    return (
      <div
        key={roleType.code}
        className={cn(
          'flex justify-center bg-white border-2 border-gray-400 p-6 rounded'
        )}
      >
        <Checkbox.Input
          className="checked:text-orange-500"
          name={roleType.code}
          checked={checked}
          onChange={handleChange}
          label={roleType.label}
          disabled={isCultivatorRole}
        />
      </div>
    );
  };

  return (
    <div className="py-2 flex flex-col gap-2">
      <p className="text-gray-500">Role</p>

      <div className="flex flex-wrap gap-2">
        {userRoleTypes
          .filter((userRoleType) => {
            // eslint-disable-next-line sonarjs/prefer-single-boolean-return
            if (
              currentlySelectedOrganization &&
              currentlySelectedOrganization.code !==
                NEATLEAF_ORGANIZATION_CODE &&
              (userRoleType.code === NEATLEAF_PLANT_SCIENTIST_CODE ||
                userRoleType.code === NEATLEAF_PLANT_SCIENTIST_PUBLISHER_CODE)
            ) {
              return false;
            }
            return true;
          })
          .map(renderRoleType)}
      </div>
    </div>
  );
};
