import {
  Dropdown,
  DropdownProps,
  findSelected,
  Option,
} from 'components/common/Dropdown/Dropdown';
import { useZoneDetailsPageURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useZones } from 'hooks/useZones';
import groupBy from 'lodash.groupby';
import { ComponentPropsWithoutRef, forwardRef, useMemo } from 'react';
import { TZone } from 'shared/interfaces/zone';

export const SelectZone = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'>
>(function SelectZone(props, ref) {
  const { currentZone } = useCurrentZone();
  const { navigateToZone } = useZoneDetailsPageURL();
  const { zones } = useZones();
  const zoneOptions = useMemo(() => {
    if (!zones) {
      return undefined;
    }
    const groupedZones = groupBy(zones, (z) => z.locationName);
    const keys = Object.keys(groupedZones);
    return keys
      .sort((a, b) => a.localeCompare(b))
      .reduce(
        (optionsOrGroups = [], key) => {
          const options = groupedZones[key]!.sort((a, b) =>
            a.label.localeCompare(b.label)
          ).map<Option<TZone>>((zone) => ({
            label: zone.label,
            value: zone,
            selected: currentZone?.id === zone.id,
          }));

          if (key) {
            optionsOrGroups.push({
              label: key,
              options,
            });
          } else {
            optionsOrGroups.push(...options);
          }

          return optionsOrGroups;
        },
        [] as DropdownProps<TZone>['options']
      );
  }, [currentZone?.id, zones]);
  const selectedZoneOption = useMemo(
    () => findSelected<TZone>(zoneOptions),
    [zoneOptions]
  );

  return (
    <div ref={ref} {...props}>
      <Dropdown<TZone>
        buttonClassName="bg-white"
        value={selectedZoneOption}
        options={zoneOptions}
        variant="flat"
        selectedOptionRenderer={(option) => {
          if (option && !Array.isArray(option)) {
            return (
              <span className="flex gap-2">
                <span className="font-semibold">
                  {option.value.locationName}
                </span>
                {option.value.label}
              </span>
            );
          }
        }}
        onChange={(option) => {
          if (option && !Array.isArray(option)) {
            navigateToZone(option.value.id);
          }
        }}
      />
    </div>
  );
});
