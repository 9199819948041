import { Button } from 'components/common/Button/Button';
import { AwardIcon } from 'lucide-react';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { cn } from 'shared/utils/cn';

export interface SuccessProps extends ComponentPropsWithoutRef<'div'> {
  /** Called when the primary button is clicked */
  onNavigate?: () => void;
  /** Called when the tertiary button is clicked */
  onClose: () => void;
}

/**
 * Displays a success message giving the option to navigate to the notifications tab
 */
export const Success = forwardRef<HTMLDivElement, SuccessProps>(
  function Success({ className, onNavigate, onClose, ...props }, ref) {
    return (
      <div
        ref={ref}
        {...props}
        className={cn(
          'flex w-full max-w-[592px] flex-col items-center justify-center gap-4 text-sm text-green-800 text-center',
          className
        )}
      >
        <AwardIcon className="stroke-[1.5px] size-12" />

        <h3 className="text-xl font-bold">
          Growth Cycle Successfully Created!
        </h3>

        {onNavigate && (
          <>
            <p>
              Don’t forget to set your critital alerts and notification for each
              room.
            </p>

            <Button onClick={onNavigate}>Go to email notifications</Button>
          </>
        )}

        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </div>
    );
  }
);
