import { CenteredLoader } from 'components/common/CenteredLoader';
import { NotificationLevel } from 'shared/constants/notification';
import { TGrowthCycle } from 'shared/interfaces/growthCycle';
import { TCurrentZoneMeasurements } from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';
import { useMeasurementCards } from './hooks/useMeasurementCards';

interface RoomConditionsProps {
  /** Allows styling of this component root element */
  className?: string;
  /** The current date */
  currentTime: Date;
  /** The current growth cycle */
  growthCycle: TGrowthCycle;
  /** The measurements for the current zone */
  measurements: TCurrentZoneMeasurements;
  /** Whether the data is loading */
  loading?: boolean;
}

/** Displays the environmental data for the current room  */
export const RoomConditions = ({
  className,
  currentTime,
  growthCycle,
  measurements,
  loading,
}: RoomConditionsProps) => {
  const measurementConditions = useMeasurementCards(
    currentTime,
    measurements,
    growthCycle
  );

  return (
    <div className={cn('w-full', className)}>
      <p
        className="text-xs font-bold text-neutral-600"
        id="current-room-conditions"
      >
        Current Room Conditions
      </p>

      <div
        aria-labelledby="current-room-conditions"
        className={cn(
          'relative w-full mt-2 border-t-[1px] border-t-neutral-400',
          loading && 'opacity-50'
        )}
        role="list"
      >
        {measurementConditions.map((condition) => {
          const value = condition.value?.toFixed(condition.decimal ?? 0);
          const isWarning =
            condition.notificationLevel === NotificationLevel.WARNING;

          return (
            <div
              key={condition.title}
              aria-label={`${condition.title} is ${value}`}
              className="w-full flex py-1 justify-between border-b-[1px] last-of-type:border-0 border-dashed border-b-neutral-400"
              role="listitem"
            >
              <p className="text-xs">{condition.title}</p>
              <p
                className={cn('text-xs w-1/3', isWarning && 'text-red-500')}
                aria-label={`${condition.title} is${isWarning ? ' not ' : ' '}nominal`}
                role="note"
              >
                {value} <sup>{condition.unit}</sup>
              </p>
            </div>
          );
        })}

        {!measurementConditions.length && (
          <div className="w-full flex py-1 justify-between">
            <p className="text-xs text-red-500" role="alert">
              There are no room conditions available yet.
            </p>
          </div>
        )}
        {loading && measurementConditions.length > 0 && <CenteredLoader />}
      </div>
    </div>
  );
};
