import { Button, ButtonProps } from 'components/common/Button/Button';
import { Input } from 'components/common/Input/Input';
import { Radio } from 'components/common/Radio/Radio';
import {
  CloudOffIcon,
  CopyPlusIcon,
  PencilIcon,
  SearchIcon,
  Trash2Icon,
} from 'lucide-react';
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  ReactNode,
  forwardRef,
  useState,
} from 'react';
import { cn } from 'shared/utils/cn';

const TEXTS = {
  noItemsAvailableTitle: 'No preset available',
  noItemsAvailableSubtitle:
    'Create a new preset, and use it next time you create a growth cycle.',
  createNewItem: 'Create a new preset',
  searchLabel: 'Type something to filter the list',
  searchPlaceholder: 'Type something to filter the list',
};

const criteria = ['most-recent', 'oldest', 'most-used'] as const;

type SortBy = (typeof criteria)[number];

const sortByOptions = [
  {
    label: 'Most recent',
    value: 'most-recent',
  },
  {
    label: 'Oldest',
    value: 'oldest',
  },
  {
    label: 'Most used',
    value: 'most-used',
  },
] as Array<ComponentProps<typeof Radio.Input> & { value: SortBy }>;

const sortItems =
  (sorting: SortBy) => (a: ActionableItem, b: ActionableItem) => {
    if (sorting === 'oldest') {
      return a.lastUpdated.valueOf() - b.lastUpdated.valueOf();
    } else if (sorting === 'most-used') {
      return (b.count ?? Infinity) - (a.count ?? Infinity);
    }

    return b.lastUpdated.valueOf() - a.lastUpdated.valueOf();
  };

const _actions = ['share', 'edit', 'clone', 'delete'] as const;

type Actions = (typeof _actions)[number];

type ActionButtons = Array<
  Pick<ButtonProps, 'variant' | 'children' | 'aria-label'> & {
    action: Actions;
  }
>;

type ActionableActions = Partial<
  Record<Actions, (item: ActionableItem) => void>
>;

const selectOptions = [
  {
    variant: 'primary',
    children: 'Use & Share',
    action: 'share',
  },
  {
    variant: 'secondary',
    children: 'Use & Make Copy',
    action: 'clone',
  },
] as ActionButtons;

const editOptions = [
  {
    variant: 'secondary',
    children: <PencilIcon className="stroke-[1.5px] size-4 xl:size-5" />,
    'aria-label': 'Edit',
    action: 'edit',
  },
  {
    variant: 'secondary',
    children: <CopyPlusIcon className="stroke-[1.5px] size-4 xl:size-5" />,
    'aria-label': 'Copy',
    action: 'clone',
  },
  {
    variant: 'error',
    children: <Trash2Icon className="stroke-[1.5px] size-4 xl:size-5" />,
    'aria-label': 'Delete',
    action: 'delete',
  },
] as ActionButtons;

type ActionableItem = {
  id: number;
  lastUpdated: Date | number;
  name: string;
  count?: number;
};

export type ActionableListProps = ComponentPropsWithoutRef<'div'> & {
  /** */
  subtitle?: string;
  /** */
  mode: 'select' | 'edit';
  /** */
  items: ActionableItem[];
  /** */
  sortBy?: SortBy[];
  /** */
  labels?: {
    noItemsAvailableTitle?: string;
    noItemsAvailableSubtitle?: string;
    createNewItem?: string;
    searchLabel?: string;
    searchPlaceholder?: string;
  };
  /** */
  itemNameFormat?: (item: ActionableItem) => ReactNode;
  /** */
  onShare?: (item: ActionableItem) => void;
  /** */
  onEdit?: (item: ActionableItem) => void;
  /** */
  onClone?: (item: ActionableItem) => void;
  /** */
  onDelete?: (item: ActionableItem) => void;
} & Exclusive<
    {
      mode: 'edit';
      onCreate: () => void;
    },
    {
      mode: 'select';
      onCreate?: never;
    }
  >;

/**
 * ...
 *
 * When mode is `edit` then:
 *   - ...
 * When mode is `select` then:
 *   - ...
 */
export const ActionableList = forwardRef<HTMLDivElement, ActionableListProps>(
  function ActionableList(
    {
      mode = 'edit',
      items,
      className,
      title,
      subtitle,
      sortBy = criteria,
      labels,
      itemNameFormat,
      onShare,
      onCreate,
      onEdit,
      onClone,
      onDelete,
      ...props
    },
    ref
  ) {
    const [searchTerm, setSearchTerm] = useState('');
    const [criteria, setCriteria] = useState<SortBy>('most-recent');
    const hasItems = items.length > 0;
    const filteredItems = items
      .filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort(sortItems(criteria));
    const selectActions: ActionableActions = {
      ...(onShare ? { share: onShare } : {}),
      ...(onClone ? { clone: onClone } : {}),
    };
    const editActions: ActionableActions = {
      ...(onEdit ? { edit: onEdit } : {}),
      ...(onClone ? { clone: onClone } : {}),
      ...(onDelete ? { delete: onDelete } : {}),
    };
    const texts = Object.assign(TEXTS, labels);

    return (
      <div
        ref={ref}
        {...props}
        className={cn(
          'w-full flex flex-col flex-grow items-center justify-center gap-6 pb-4 text-base md:max-w-3xl',
          className
        )}
      >
        {hasItems && (
          <>
            <h3 className="text-xl font-bold">{title}</h3>

            {subtitle && (
              <p className="text-center" id="actionable-items-list">
                {subtitle}
              </p>
            )}
          </>
        )}

        {!hasItems && (
          <>
            <CloudOffIcon className="stroke-[1.5px] size-16" />

            <h3 className="text-lg font-semibold">
              {texts.noItemsAvailableTitle}
            </h3>

            {mode === 'edit' && (
              <p className="text-center" id="actionable-items-list">
                {texts.noItemsAvailableSubtitle}
              </p>
            )}
          </>
        )}

        {mode === 'edit' && (
          <Button onClick={onCreate}>{texts.createNewItem}</Button>
        )}

        {hasItems && (
          <div className="mt-6 flex w-full flex-col items-center justify-center gap-4">
            <div className="w-full flex flex-col-reverse items-center gap-4 justify-between md:flex-row">
              <div className="flex gap-2 items-center justify-center flex-wrap">
                <p className="font-semibold">Sort by</p>
                <Radio.Group
                  orientation="horizontal"
                  value={criteria}
                  onChange={(event) =>
                    setCriteria(event.target.value as SortBy)
                  }
                >
                  {sortByOptions
                    .filter((option) => sortBy.includes(option.value))
                    .map((option) => (
                      <Radio.Input
                        key={option.label as string}
                        {...option}
                        name="actionable-sort-by"
                      />
                    ))}
                </Radio.Group>
              </div>
              <div className="w-full md:w-2/5">
                <Input
                  aria-label={texts.searchLabel}
                  placeholder={texts.searchPlaceholder}
                  actionIcon={
                    <SearchIcon className="stroke-[1.5px] size-4 xl:size-5" />
                  }
                  onChange={(event) => setSearchTerm(event.target.value)}
                />
              </div>
            </div>

            <ul
              aria-labelledby="actionable-items-list"
              className="w-full flex flex-col gap-2"
            >
              {filteredItems.map((item) => {
                return (
                  <li
                    key={item.id.toString()}
                    aria-label={item.name}
                    className={cn(
                      'w-full flex flex-col gap-4 items-center justify-between p-4 border rounded-sm border-neutral-400',
                      'sm:flex-row'
                    )}
                  >
                    <p className="break-all">
                      {itemNameFormat ? itemNameFormat(item) : item.name}
                    </p>

                    <div className="flex sm:flex-nowrap gap-2">
                      {mode === 'select' && (
                        <>
                          {selectOptions
                            .filter(({ action }) => !!selectActions[action])
                            .map((option) => (
                              <Button
                                key={option.children as string}
                                {...option}
                                onClick={() =>
                                  selectActions[option.action]?.(item)
                                }
                              >
                                {option.children}
                              </Button>
                            ))}
                        </>
                      )}

                      {mode === 'edit' && (
                        <>
                          {editOptions
                            .filter((option) => !!editActions[option.action])
                            .map((option) => (
                              <Button
                                key={option['aria-label']}
                                size="icon"
                                {...option}
                                aria-label={`${option['aria-label']} ${item.name}`}
                                onClick={() =>
                                  editActions[option.action]?.(item)
                                }
                              />
                            ))}
                        </>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    );
  }
);
