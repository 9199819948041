import { getHexColorRGB } from './color';

/**
 * Calculates the color between two gradient values
 *
 * @param {string} color1 - The hex color string
 * @param {string} color2 - The hex color string
 * @param {int} weight - The percentage of the weight
 * @returns {string} - The hex color of result
 */
export const getPickHex = (color1: string, color2: string, weight: number) => {
  const weight1 = weight;
  const weight2 = 1 - weight1;
  const rgbColors1 = getHexColorRGB(color1);
  const rgbColors2 = getHexColorRGB(color2);

  const rgb = [
    Math.round(rgbColors1[0] * weight1 + rgbColors2[0] * weight2),
    Math.round(rgbColors1[1] * weight1 + rgbColors2[1] * weight2),
    Math.round(rgbColors1[2] * weight1 + rgbColors2[2] * weight2),
  ];

  return `#${rgb.map((color) => color.toString(16).padStart(2, '0')).join('')}`;
};
