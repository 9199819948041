import {
  useGetImageFeedMeasurementRuns,
  useGetMeasurementsByIds,
  useGetMeasurementsOnGridByRunIdAndSensorTypeId,
} from 'api/measurement';
import { addHours } from 'date-fns';
import { useMemo, useRef } from 'react';
import { NO_IMAGE } from 'shared/constants/image';
import { EImageTypes } from 'shared/interfaces/image';
import { IMeasurementData } from 'shared/interfaces/measurement';
import { initializeGrid } from 'shared/utils/array';
import { getPublicResourcePath } from 'shared/utils/image';

export interface UseCanopyProps {
  width: number;
  startTime: number;
  growthCycleEndTime: number;
  zoneId: string;
  zoneTimeZone: string;
}

const DEFAULT_WIDTH_TO_HEIGHT_RATIO = 1.33;

export const useCanopy = ({
  width,
  startTime,
  zoneId,
  zoneTimeZone,
  growthCycleEndTime,
}: UseCanopyProps) => {
  const { measurementRuns } = useGetImageFeedMeasurementRuns(
    zoneTimeZone,
    startTime,
    Math.min(addHours(startTime, 7 * 24 - 1).valueOf(), growthCycleEndTime),
    zoneId
  );

  const firstMeasurementRun = measurementRuns.at(0);

  const { data } = useGetMeasurementsOnGridByRunIdAndSensorTypeId({
    imageType: EImageTypes.RGB,
    measurementRun: firstMeasurementRun,
    options: {},
  });

  const numberOfRows = data.numberOfRows || 0;
  const numberOfColumns = data.numberOfColumns || 0;

  const grid = useMemo(
    () => getGrid(data.measurementIds, numberOfRows, numberOfColumns),
    [data.measurementIds, numberOfColumns, numberOfRows]
  );

  const { measurementsById } = useGetMeasurementsByIds({
    measurementIds: data.measurementIds,
  });

  const widthToHeightRatio = useRef(DEFAULT_WIDTH_TO_HEIGHT_RATIO);

  const urls = useMemo(
    () =>
      grid.map((row) => {
        return row.map((measurementId) => {
          if (!measurementId) {
            return NO_IMAGE.url;
          }
          const measurement = measurementsById[measurementId];

          if (!measurement) {
            return NO_IMAGE.url;
          }

          const data = measurement.data as IMeasurementData | undefined;

          if (!data || !data.resource_path || !data.thumbnail_bucket) {
            return NO_IMAGE.url;
          }

          const resolutions = (data.thumbnails || []).toSorted(
            (r1, r2) => r1[0] - r2[0]
          );

          const resolution = resolutions.at(0);

          if (!resolution) {
            return NO_IMAGE.url;
          }

          widthToHeightRatio.current = resolution[0] / resolution[1];

          const url = getPublicResourcePath(
            data.resource_path,
            resolution,
            data.thumbnail_bucket,
            false
          );

          if (!url) {
            return NO_IMAGE.url;
          }

          return url;
        });
      }),
    [grid, measurementsById]
  );

  const imgWidth = numberOfColumns > 0 ? width / numberOfColumns : 0;
  const imgHeight = imgWidth / widthToHeightRatio.current;
  const canvasHeight = numberOfRows * imgHeight;

  return {
    urls,
    canvasHeight,
    imgHeight,
    imgWidth,
  };
};

function getGrid(
  measurementIds: Nullable<number>[],
  numberOfRows: number,
  numberOfColumns: number
): Nullable<number>[][] {
  const grid = initializeGrid<Nullable<number>>(
    numberOfRows,
    numberOfColumns,
    null
  );

  // The measurement ids in the array are ordered by columns then rows. However the
  // row order in each column is flipped, i.e. upside down

  // Option 1: Taken from the ImageFeed, but hard to read
  // measurementIds.forEach((measurementId, idx) => {
  //   const cellX = (idx - (idx % numberOfRows)) / numberOfRows;
  //   const cellY = numberOfRows - (idx % numberOfRows) - 1;
  //   grid[cellY]![cellX] = measurementId;
  // });

  // Option 2: Outer loop through columns, inner loop through flipped rows
  let index = 0;
  for (let col = 0; col < numberOfColumns; col++) {
    for (let row = numberOfRows - 1; row >= 0; row--) {
      grid[row]![col] = measurementIds[index++];
    }
  }

  return grid;
}
