import { NonAuthForm } from 'components/auth/NonAuthForm';
import { NonAuthLayout } from 'components/auth/NonAuthLayout';
import { Button } from 'components/common/Button/Button';
import { Input } from 'components/common/Input/Input';
import { Link } from 'components/common/Link';
import { useAuth } from 'contexts/AuthProvider';
import { useScreenSize } from 'hooks/useScreenSize';
import isNil from 'lodash.isnil';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AnalyticsEvent } from 'shared/analytics/AnalyticsEvent';
import { useAnalytics } from 'shared/analytics/useAnalytics';
import { ERoutePath, PATH_PATTERNS } from 'shared/constants/url';
import { cn } from 'shared/utils/cn';

type TLoginForm = {
  email: string;
  password: string;
};

export const LoginPage: FC = () => {
  const { sendEvent } = useAnalytics();
  const [error, setError] = useState('');
  const { signIn } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isMobile, isPortrait } = useScreenSize();

  const { handleSubmit, register } = useForm<TLoginForm>();

  const onSubmit = handleSubmit(async ({ email, password }) => {
    // Clear out error.
    setError('');
    email = email.toLocaleLowerCase();
    if (email.indexOf(' ') !== -1) {
      setError('Please enter a valid email.');
      return;
    }

    setIsSubmitting(true);
    try {
      await signIn(email, password);

      sendEvent(AnalyticsEvent.Login);
    } catch (e) {
      const error = e as Error;
      const errorMessage =
        isNil(error.message) || error.message.length === 0
          ? 'Unknown error. Please try again later.'
          : error.message;
      setError(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  });

  const errorIndicator = (
    <div className="rounded-[10px] border border-red-700 bg-red-100 p-5 text-red-700">
      {error}
    </div>
  );

  return (
    <NonAuthLayout>
      <NonAuthForm
        header={
          <>
            <h1 className="text-3xl font-bold">Hey,</h1>
            <h1 className="text-3xl font-bold">Welcome back!</h1>
          </>
        }
        footer={
          <Link
            to={PATH_PATTERNS[ERoutePath.FORGOT_PASSWORD]}
            text="Forgot password?"
          />
        }
      >
        <form
          className={`${isMobile && isPortrait ? 'mt-[30px]' : ''}`}
          onSubmit={onSubmit}
        >
          <div className="flex flex-col gap-4">
            {error && !isMobile && <p>{errorIndicator}</p>}

            <div
              className={cn('mx-0 my-[30px]', isMobile && 'mb-[unset] mt-0')}
            >
              <h2 className="text-xl font-bold">Login</h2>
            </div>

            {error && isMobile && <p>{errorIndicator}</p>}

            <Input
              placeholder="Email"
              data-testid="email"
              {...register('email', { required: true })}
            />

            <Input
              placeholder="Password"
              type="password"
              data-testid="password"
              {...register('password', { required: true })}
            />

            <Button
              disabled={isSubmitting}
              className="h-12 w-full text-base"
              type="submit"
              id="sign_in_button"
              role="button"
            >
              Sign in
            </Button>
          </div>
        </form>
      </NonAuthForm>
    </NonAuthLayout>
  );
};
