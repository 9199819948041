import { NO_IMAGE } from 'shared/constants/image';
import {
  NO_IMAGE_BUCKET,
  NO_THUMBNAIL_BUCKET,
  NO_URL,
} from 'shared/constants/url';
import {
  IImageUrlInformation,
  IResolutionInformation,
} from 'shared/interfaces/image';
import { IMeasurementData } from 'shared/interfaces/measurement';
import { getPublicResourcePath } from 'shared/utils/image';

export const getImageUrlInformation = (
  resolutionInformation: IResolutionInformation,
  meausurementData: Optional<IMeasurementData>
): IImageUrlInformation => {
  const { minResolution, maxResolution, sortedResolutions } =
    resolutionInformation;

  const path = meausurementData?.resource_path ?? NO_URL;
  let minimumImageUrl = NO_IMAGE.url;
  let maximumImageUrl = NO_IMAGE.url;
  let sortedImageUrls: string[] = Array(sortedResolutions.length).fill(
    NO_IMAGE.url
  );

  if (path !== NO_URL) {
    minimumImageUrl = getPublicResourcePath(
      path,
      minResolution,
      meausurementData?.thumbnail_bucket ?? NO_THUMBNAIL_BUCKET
    );

    maximumImageUrl = getPublicResourcePath(
      path,
      maxResolution,
      meausurementData?.image_bucket ?? NO_IMAGE_BUCKET,
      true
    );

    sortedImageUrls = sortedResolutions.map((resolution, index) => {
      if (index !== sortedResolutions.length - 1) {
        return getPublicResourcePath(
          path,
          resolution,
          meausurementData?.thumbnail_bucket ?? NO_THUMBNAIL_BUCKET
        );
      } else {
        return getPublicResourcePath(
          path,
          resolution,
          meausurementData?.image_bucket ?? NO_IMAGE_BUCKET,
          true
        );
      }
    });
  }

  const result: IImageUrlInformation = {
    minImageUrl: minimumImageUrl,
    maxImageUrl: maximumImageUrl,
    sortedImageUrls,
  };

  return result;
};
