import { getScaleColorizedValue } from 'components/heat_map/utils';
import { useMemo } from 'react';
import {
  FIXED_RANGE_HEATMAP_COLORS,
  MICROCLIMATES_HEATMAP_COLORS,
  OPTIMAL_RANGE_HEATMAP_COLORS,
} from 'shared/constants/heatmap';
import {
  EGradientTypes,
  THeatMapScaleDataType,
  THeatMapStylingStep,
  TScaleColorizedValue,
} from 'shared/interfaces/heatmap';
import { getPickHex } from 'shared/utils/gradient';

export interface IHeatMapGradientValuesProps {
  /** The type of current heatmap. */
  gradientType: EGradientTypes;
  /** The step values to be rendered in the gradient. */
  heatMapScale: THeatMapScaleDataType;
}

export interface IHeatMapGradientValuesReturn {
  /** The gradient scale values to be used in the gradient. */
  gradientScaleValues: TScaleColorizedValue[];
}

/**
 * Get the colorized step values of gradient values based on heatMapScale.
 *
 * @param {IHeatMapGradientValuesProps} data - The heatMapScale and statistic.
 * @returns {IHeatMapGradientValuesReturn} The colorized scale values of gradient.
 */
export const useHeatMapGradientValues = ({
  gradientType,
  heatMapScale,
}: IHeatMapGradientValuesProps): IHeatMapGradientValuesReturn => {
  const colorScales = useMemo(() => {
    switch (gradientType) {
      case EGradientTypes.FIXED_RANGE:
        return FIXED_RANGE_HEATMAP_COLORS;
      case EGradientTypes.MICROCLIMATES:
        return MICROCLIMATES_HEATMAP_COLORS;
      case EGradientTypes.OPTIMAL_RANGE:
      default:
        return OPTIMAL_RANGE_HEATMAP_COLORS;
    }
  }, [gradientType]);

  const gradientScaleValues = useMemo(() => {
    const min = heatMapScale.lowerBound;
    const max = heatMapScale.upperBound;
    const diff = max - min;

    let stylingStepList: THeatMapStylingStep[] = [
      { color: colorScales.STEP1, value: min },
      { color: colorScales.STEP2, value: min + diff / 3 },
      { color: colorScales.STEP3, value: min + (diff / 3) * 2 },
      { color: colorScales.STEP4, value: max },
    ];

    if (
      gradientType === EGradientTypes.OPTIMAL_RANGE &&
      heatMapScale.optimalRangeLowerBound &&
      heatMapScale.optimalRangeUpperBound
    ) {
      stylingStepList = [
        { color: colorScales.STEP1, value: min },
        {
          color: colorScales.STEP2,
          value: heatMapScale.optimalRangeLowerBound,
        },
        {
          color: colorScales.STEP3,
          value: heatMapScale.optimalRangeUpperBound,
        },
        { color: colorScales.STEP4, value: max },
      ];
    }

    const updatedStylingStepList = [];

    for (let i = 0; i < stylingStepList.length; i++) {
      const currentStylingStep = stylingStepList[i]!;
      updatedStylingStepList.push(currentStylingStep);

      if (i === stylingStepList.length - 1) break;

      const nextStylingStep = stylingStepList[i + 1]!;

      if (currentStylingStep.value < min && min < nextStylingStep.value) {
        const weight =
          (nextStylingStep.value - min) /
          (nextStylingStep.value - currentStylingStep.value);
        const color = getPickHex(
          currentStylingStep.color,
          nextStylingStep.color,
          weight
        );
        updatedStylingStepList.push({ color, value: min });
      }

      if (currentStylingStep.value < max && max < nextStylingStep.value) {
        const weight =
          (nextStylingStep.value - max) /
          (nextStylingStep.value - currentStylingStep.value);
        const color = getPickHex(
          currentStylingStep.color,
          nextStylingStep.color,
          weight
        );
        updatedStylingStepList.push({ color, value: max });
      }
    }

    return updatedStylingStepList
      .filter((value) => value && min <= value.value && value.value <= max)
      .map((value) => getScaleColorizedValue(value, min, max));
  }, [colorScales, gradientType, heatMapScale]);

  return {
    gradientScaleValues,
  };
};
