export const capitalizeWord = (word: string) => {
  if (!word.length) return '';
  return word.slice(0, 1).toUpperCase() + word.slice(1);
};

/**
 * Truncates a given string to a maximum length.
 *
 * @param {string} str - The string to be truncated.
 * @param {number} maxLength - The maximum length of the truncated string. Default is 300.
 * @returns {Optional<string>} - The truncated string or undefined if the input string is empty.
 */
export function truncateString(str: string, maxLength: number = 300) {
  if (!str) {
    return undefined;
  }
  if (str.length <= maxLength) {
    return str;
  }
  return str.substring(0, maxLength) + '...';
}
