import { makeVar } from '@apollo/client';

export type TMeasurementRunIdToHighestLoadedIndex = Map<number, number[][]>;
const initialMeasurementRunIdToHighestLoadedIndex = new Map<
  number,
  number[][]
>();
export const measurementRunIdToHighestLoadedIndex =
  makeVar<TMeasurementRunIdToHighestLoadedIndex>(
    initialMeasurementRunIdToHighestLoadedIndex
  );
