import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const NeatleafLogo = ({
  textClassName,
  markClassName,
  className,
  ...props
}: SVGProps<SVGSVGElement> & {
  title?: string;
  textClassName?: string;
  markClassName?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 688 120"
    className={className}
    title="Neatleaf logo"
    {...props}
  >
    <path
      d="M208.661 50.112C203.299 44.432 196.08 41.536 187.23 41.536C180.963 41.536 175.343 43.12 170.546 46.224C166.67 48.736 163.666 52.112 161.583 56.288V42.752H144.674V118.32H161.567V78.176C161.567 70.848 163.376 65.248 166.945 61.488C170.498 57.744 175.311 55.856 181.238 55.856C186.987 55.856 191.558 57.616 194.853 61.088C198.147 64.576 199.827 69.6 199.827 76.016V118.304H216.72V74.4C216.736 63.968 214.023 55.776 208.661 50.112Z"
      className={cn('fill-gray-950', textClassName)}
    />
    <path
      d="M299.409 83.696C299.78 81.76 299.958 79.504 299.958 76.976C299.958 70.432 298.44 64.4 295.452 59.072C292.464 53.728 288.12 49.472 282.548 46.416C276.976 43.36 270.403 41.824 262.974 41.824C255.642 41.824 249.069 43.392 243.4 46.48C237.731 49.584 233.225 54.112 230.011 59.952C226.797 65.776 225.166 72.672 225.166 80.48C225.166 88.368 226.797 95.328 230.011 101.136C233.225 106.976 237.731 111.504 243.4 114.608C249.053 117.696 255.626 119.264 262.974 119.264C269.289 119.264 275.103 118.096 280.255 115.76C285.407 113.44 289.654 110.176 292.884 106.064C296.114 101.952 298.262 97.2 299.264 91.904L299.425 91.072H281.418L281.305 91.648C280.529 95.76 278.398 99.104 274.99 101.584C271.55 104.08 267.286 105.36 262.296 105.36C256.611 105.36 251.798 103.568 247.97 100.032C244.272 96.624 242.205 91.328 241.833 84.288H299.296L299.409 83.696ZM248.245 60.8C252.17 57.264 257.128 55.456 262.99 55.456C266.656 55.456 270.096 56.224 273.213 57.744C276.282 59.248 278.737 61.6 280.513 64.736C281.902 67.184 282.661 70.144 282.871 73.52H242.108C242.867 68.048 244.934 63.792 248.245 60.8Z"
      className={cn('fill-gray-950', textClassName)}
    />
    <path
      d="M369.841 57.28C367.741 53.12 364.689 49.648 360.764 46.928C355.854 43.536 349.879 41.824 342.967 41.824C336.345 41.824 330.353 43.392 325.137 46.496C319.92 49.6 315.786 54.144 312.846 59.968C309.907 65.776 308.421 72.688 308.421 80.48C308.421 88.368 309.907 95.312 312.846 101.12C315.786 106.96 319.92 111.488 325.137 114.592C330.337 117.696 336.329 119.264 342.967 119.264C349.879 119.264 355.871 117.552 360.764 114.16C364.705 111.44 367.741 107.968 369.841 103.808V118.336H386.734V42.768H369.841V57.28ZM366.933 93.056C365.012 96.608 362.363 99.392 359.036 101.328C355.725 103.28 351.914 104.256 347.715 104.256C341.061 104.256 335.683 102.128 331.726 97.952C327.753 93.76 325.734 87.872 325.734 80.464C325.734 73.056 327.753 67.168 331.726 62.976C335.683 58.784 341.077 56.672 347.715 56.672C351.914 56.672 355.725 57.664 359.036 59.6C362.347 61.536 364.995 64.352 366.933 67.936C368.855 71.552 369.841 75.76 369.841 80.464C369.841 85.264 368.855 89.504 366.933 93.056Z"
      className={cn('fill-gray-950', textClassName)}
    />
    <path
      d="M464.384 24.304H447.49V118.32H464.384V24.304Z"
      className={cn('fill-gray-950', textClassName)}
    />
    <path
      d="M547.154 84.256L547.267 83.68C547.638 81.744 547.816 79.488 547.816 76.96C547.816 70.416 546.298 64.384 543.31 59.056C540.322 53.712 535.978 49.456 530.406 46.4C524.834 43.344 518.261 41.808 510.832 41.808C503.5 41.808 496.926 43.376 491.258 46.464C485.589 49.568 481.083 54.096 477.869 59.936C474.655 65.76 473.024 72.656 473.024 80.464C473.024 88.352 474.655 95.312 477.869 101.12C481.083 106.96 485.589 111.488 491.258 114.592C496.91 117.68 503.483 119.248 510.832 119.248C517.146 119.248 522.961 118.08 528.112 115.744C533.264 113.424 537.512 110.16 540.742 106.048C543.972 101.936 546.12 97.184 547.121 91.888L547.283 91.056H529.275L529.162 91.632C528.387 95.744 526.255 99.088 522.847 101.568C519.407 104.064 515.144 105.344 510.153 105.344C504.469 105.344 499.656 103.552 495.828 100.016C492.13 96.608 490.063 91.312 489.691 84.272H547.154V84.256ZM496.087 60.8C500.011 57.264 504.969 55.456 510.832 55.456C514.498 55.456 517.938 56.224 521.055 57.744C524.123 59.248 526.578 61.6 528.355 64.736C529.744 67.184 530.503 70.144 530.713 73.52H489.949C490.725 68.048 492.776 63.792 496.087 60.8Z"
      className={cn('fill-gray-950', textClassName)}
    />
    <path
      d="M617.682 57.28C615.582 53.12 612.53 49.648 608.605 46.928C603.696 43.536 597.72 41.824 590.808 41.824C584.186 41.824 578.194 43.392 572.978 46.496C567.761 49.6 563.627 54.144 560.687 59.968C557.748 65.776 556.262 72.688 556.262 80.48C556.262 88.368 557.748 95.312 560.687 101.12C563.627 106.96 567.761 111.488 572.978 114.592C578.178 117.696 584.17 119.264 590.808 119.264C597.72 119.264 603.696 117.552 608.605 114.16C612.546 111.44 615.582 107.968 617.682 103.808V118.336H634.575V42.768H617.682V57.28ZM614.775 93.056C612.853 96.608 610.204 99.392 606.877 101.328C603.566 103.28 599.755 104.256 595.556 104.256C588.902 104.256 583.524 102.128 579.567 97.952C575.594 93.76 573.575 87.872 573.575 80.464C573.575 73.056 575.594 67.168 579.567 62.976C583.524 58.784 588.918 56.672 595.556 56.672C599.755 56.672 603.566 57.664 606.877 59.6C610.188 61.536 612.837 64.352 614.775 67.936C616.697 71.552 617.682 75.76 617.682 80.464C617.682 85.264 616.713 89.504 614.775 93.056Z"
      className={cn('fill-gray-950', textClassName)}
    />
    <path
      d="M685.965 29.312L686.708 29.344V14.48L686.046 14.448C675.209 13.904 666.956 15.968 661.497 20.608C656.006 25.264 653.228 32.304 653.228 41.536V42.752H643.038V57.344H653.228V118.32H670.267V57.344H686.708V42.752H670.267C670.267 37.696 671.43 34.08 673.707 32.032C675.984 29.968 680.102 29.04 685.965 29.312Z"
      className={cn('fill-gray-950', textClassName)}
    />
    <path
      d="M422.958 24.304H405.92V42.752H395.18V57.2H405.92V118.32H422.958V57.2H438.915V42.752H422.958V24.304Z"
      className={cn('fill-gray-950', textClassName)}
    />
    <path
      d="M77.7317 102.572C88.8617 113.73 104.269 120 120 120L119.692 65.9448C119.692 29.529 90.2388 0 53.9165 0H33.471C33.471 0 41.1826 0.925826 51.6159 11.3698L97.0269 56.8814C100.008 59.8701 101.677 63.9145 101.677 68.1538V77.2983L41.9602 17.4283C30.8302 6.26962 15.731 0 0 0V54.0552C0 90.471 29.4532 120 65.7755 120H86.221C86.221 120 78.5094 119.074 68.076 108.63L22.665 63.1186C19.684 60.1299 18.0154 56.0855 18.0154 51.8462V42.7017L77.7317 102.572Z"
      className={cn('fill-orange-500', markClassName)}
    />
  </svg>
);

export { NeatleafLogo };
