import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const LeafVpdIcon = ({
  stroke = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    stroke={stroke}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={cn('h-6 w-6', className)}
    {...props}
  >
    <path d="M13.6332 20.939C12.3451 20.9429 11.1025 20.4626 10.152 19.5932C9.20146 18.7238 8.61238 17.529 8.50158 16.2456C8.39079 14.9623 8.76638 13.6841 9.55385 12.6647C10.3413 11.6453 11.4832 10.9591 12.7529 10.7422C16.9343 9.93525 18.0347 9.55379 19.5019 7.7345C20.2355 9.20167 20.969 10.8009 20.969 13.6032C20.969 17.6379 17.4625 20.939 13.6332 20.939Z" />
    <path d="M7.03094 21.6727C7.03094 19.4719 8.38808 17.7407 10.7576 17.2712C12.5328 16.9191 14.3668 15.804 15.1004 15.0704" />
    <path d="M4.80924 6C5.07719 6.1 5.60317 6.53 5.55355 7.36C5.494 8.19 4.63061 8.56 4.56114 9.38C4.51152 10.16 4.89856 10.62 5.28559 11" />
    <path d="M9.71067 2.7345C9.97862 2.8345 10.5046 3.2645 10.455 4.0945C10.3954 4.9245 9.53203 5.2945 9.46256 6.1145C9.41294 6.8945 9.79998 7.3545 10.187 7.7345" />
    <path d="M15.3528 1.68274C15.6207 1.78274 16.1467 2.21274 16.0971 3.04274C16.0375 3.87274 15.1742 4.24274 15.1047 5.06274C15.0551 5.84274 15.4421 6.30274 15.8291 6.68274" />
  </svg>
);

export { LeafVpdIcon };
