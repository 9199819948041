import { Float, FloatProps } from '@headlessui-float/react';
import { Menu as HeadlessUIMenu } from '@headlessui/react';
import {
  ComponentPropsWithoutRef,
  Fragment,
  ReactNode,
  cloneElement,
  forwardRef,
  isValidElement,
  useRef,
} from 'react';
import { cn } from 'shared/utils/cn';
import { Button, ButtonProps } from './Button/Button';

export interface MenuProps extends ComponentPropsWithoutRef<'div'> {
  /** The button that will open the menu. Required. */
  button: ReactNode;
  /** The placement of the menu. Optional. Defaults to `bottom-start`. */
  floatProps?: Omit<FloatProps, 'children'>;
  /** The items to display in the menu. Required. */
  items: ButtonProps[];
}

/** Displays a button that opens a floating menu. */
export const Menu = forwardRef<HTMLDivElement, MenuProps>(function Menu(
  { button, items, className, floatProps },
  ref
) {
  if (!isValidElement(button)) throw new Error('Button is required');
  const buttonWidth = useRef<Nullable<number>>(null);
  const buttonClone = cloneElement(button as React.ReactElement, {
    ref: (element: HTMLButtonElement) => {
      if (element) {
        buttonWidth.current = element.offsetWidth;
      }
    },
  });
  return (
    <HeadlessUIMenu
      ref={ref}
      as="div"
      className={cn('relative inline-block ', className)}
    >
      <Float
        placement="bottom-start"
        {...floatProps}
        portal
        offset={8}
        autoUpdate
      >
        <HeadlessUIMenu.Button as={Fragment}>
          {buttonClone}
        </HeadlessUIMenu.Button>
        <HeadlessUIMenu.Items
          className={cn(
            'overflow-hidden flex flex-col gap-1 rounded bg-white shadow outline-none',
            'border-[1.5px] border-sand-400'
          )}
          style={{ minWidth: buttonWidth.current ?? 0 }}
        >
          <div className="flex flex-col max-h-[368px] overflow-y-auto px-1 py-1 gap-1">
            {items.map((item, index) => (
              <HeadlessUIMenu.Item
                key={index}
                as={Button}
                className={cn(
                  'w-full justify-start rounded text-gray-900 hover:bg-sand-500 active:bg-sand-600 hui-active:bg-sand-600',
                  item.className
                )}
                {...item}
              />
            ))}
          </div>
        </HeadlessUIMenu.Items>
      </Float>
    </HeadlessUIMenu>
  );
});
