import { useCallback, useEffect, useMemo } from 'react';
import { AnalyticsEvent } from './AnalyticsEvent';
import { useAnalytics } from './useAnalytics';

const handleNativeEvent =
  (
    event: AnalyticsEvent,
    dataAttribute: string,
    trackFn: (event: AnalyticsEvent, trackingName: string) => void
  ) =>
  (e: KeyboardEvent | MouseEvent) => {
    // search for the tracking name one level up if the current element doesn't have it
    const trackingName =
      e.target &&
      ((e.target as HTMLElement).dataset[dataAttribute] ??
        (e.target as HTMLElement).parentElement?.dataset[dataAttribute]);

    if (trackingName) {
      trackFn(event, trackingName);
    }
  };

const handleSubmitEvent =
  (
    eventName: AnalyticsEvent,
    trackFn: (event: AnalyticsEvent, trackingName: string) => void
  ) =>
  (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleNativeEvent(eventName, 'trackSubmit', trackFn)(e);
    }
  };

export const useTrackEvents = (selector?: string | null) => {
  const { sendEvent } = useAnalytics();

  const trackFn = useCallback(
    (eventName: AnalyticsEvent, trackingName: string) => {
      sendEvent(eventName, { trackingName });
    },
    [sendEvent]
  );

  const target = useMemo(
    () => (selector ? document.querySelector(selector) : window) ?? window,
    [selector]
  );

  useEffect(() => {
    const clickHandler = handleNativeEvent(
      AnalyticsEvent.Click,
      'trackClick',
      trackFn
    ) as EventListener;

    const filterHandler = handleNativeEvent(
      AnalyticsEvent.Filter,
      'trackFilter',
      trackFn
    ) as EventListener;

    const submitHandler = handleSubmitEvent(
      AnalyticsEvent.Submit,
      trackFn
    ) as EventListener;
    target.addEventListener('click', clickHandler);
    target.addEventListener('keyup', filterHandler);
    target.addEventListener('keydown', submitHandler);

    return () => {
      target.removeEventListener('click', clickHandler);
      target.removeEventListener('keyup', filterHandler);
      target.removeEventListener('keydown', submitHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target]);
};
