import { checkIfLabelIsSelected } from 'components/image_label/utils';
import {
  GetLabelsByIdsQuery,
  useGetLabelsByIdsQuery,
} from 'graphql/generated/react_apollo';
import memoize from 'memoizee';
import { TBoundingBox } from 'shared/interfaces/general';
import { TLabelByCategory } from 'shared/interfaces/image';
import { mathRoundTo2Decimals } from 'shared/utils/getters';
import { convertEnumerationToLabel } from 'shared/utils/image';

const getLabelIds = memoize((labelsByCategory: TLabelByCategory[]): number[] =>
  Array.from(
    new Set(labelsByCategory.map((label) => [...(label.labelIds ?? [])]).flat())
  ).sort()
);

/**
 * Get the boudning boxes from the labels.
 *
 * @param {TLabelByCategory[]} labels - The labels
 * @returns {TBoundingBox[]} - The bounding boxes
 */
const getBoundingBoxes = memoize(
  (
    imageLabelCode: string,
    labels: GetLabelsByIdsQuery['label']
  ): TBoundingBox[] => {
    const measurementLabels = labels
      .filter((label) => label.active)
      .map((label) => {
        const enumeration = label.enumeration;
        return {
          ...convertEnumerationToLabel(enumeration),
          metadata: label.metadata,
          measurementId: label.measurement?.id,
          measurementRunId: label.measurement_run?.id,
          id: label.id,
        };
      });

    const filteredLabels = measurementLabels.filter((label) =>
      checkIfLabelIsSelected(label, imageLabelCode)
    );

    const boxes: TBoundingBox[] = [];

    filteredLabels.forEach((label) => {
      const box = label.metadata?.location?.box;
      if (!box) {
        return null;
      }

      boxes.push({
        x: mathRoundTo2Decimals(box[0]),
        y: mathRoundTo2Decimals(box[1]),
        width: mathRoundTo2Decimals(box[2] - box[0]),
        height: mathRoundTo2Decimals(box[3] - box[1]),
        comment: label.description,
        id: label.id,
      });
    });

    return boxes;
  }
);

interface IUseMeasurementBoundingBoxesProps {
  labelsByCategory: TLabelByCategory[];
  imageLabelCode: string;
}

export const useMeasurementBoundingBoxes = ({
  labelsByCategory,
  imageLabelCode,
}: IUseMeasurementBoundingBoxesProps): TBoundingBox[] => {
  const ids = getLabelIds(labelsByCategory);

  const { data } = useGetLabelsByIdsQuery({
    variables: { ids },
    skip: !ids.length,
  });

  return getBoundingBoxes(imageLabelCode, data?.label ?? []);
};
