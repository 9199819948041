import { Button } from 'components/common/Button/Button';
import { Dropdown, Option } from 'components/common/Dropdown/Dropdown';
import { useGrowthCycleReport } from 'hooks/useGrowthCycleReport';
import { DownloadIcon, MoveLeftIcon, MoveRightIcon } from 'lucide-react';
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useCallback,
  useMemo,
} from 'react';
import { TGrowthCycle } from 'shared/interfaces/growthCycle';
import { cn } from 'shared/utils/cn';
import { formatGrowthCycleDaterange } from './GrowthCycleReportUtils';

export interface GrowthCycleSelectionProps
  extends ComponentPropsWithoutRef<'div'> {}

export const GrowthCycleSelection = forwardRef<
  HTMLDivElement,
  GrowthCycleSelectionProps
>(function GrowthCycleSelection({ className }: GrowthCycleSelectionProps, ref) {
  const { cultivars, growthCycles, selectedGrowthCycle, setGrowthCycleId } =
    useGrowthCycleReport();

  const growthCycleOptions = useMemo(() => {
    return growthCycles.map((gc) => {
      const option: Option<TGrowthCycle> = {
        label: formatGrowthCycleDaterange(gc.start_time, gc.end_time),
        value: gc,
      };
      return option;
    });
  }, [growthCycles]);

  const selectedGrowthCycleOption =
    selectedGrowthCycle &&
    growthCycleOptions.find((gco) => gco.value.id === selectedGrowthCycle.id);

  const selectGrowthCycle = useCallback(
    (option: Option<TGrowthCycle> | Option<TGrowthCycle>[] | null) => {
      if (!option || Array.isArray(option)) {
        return;
      }
      setGrowthCycleId({ growthCycleId: option.value.id });
    },
    [setGrowthCycleId]
  );

  const canGoForward = useMemo(() => {
    return (
      selectedGrowthCycle &&
      growthCycles.some((gc) => gc.start_time > selectedGrowthCycle.start_time)
    );
  }, [growthCycles, selectedGrowthCycle]);

  const canGoBackward = useMemo(() => {
    return (
      selectedGrowthCycle &&
      growthCycles.some((gc) => gc.start_time < selectedGrowthCycle.start_time)
    );
  }, [growthCycles, selectedGrowthCycle]);

  const goForward = useCallback(() => {
    const index = growthCycles.findIndex(
      (gc) => gc.id === selectedGrowthCycle?.id
    );

    if (index === -1 || index === growthCycles.length - 1) {
      return;
    }

    const nextGc = growthCycles.at(index + 1);
    if (nextGc) {
      setGrowthCycleId({ growthCycleId: nextGc.id });
    }
  }, [selectedGrowthCycle?.id, setGrowthCycleId, growthCycles]);

  const goBackward = useCallback(() => {
    const index = growthCycles.findIndex(
      (gc) => gc.id === selectedGrowthCycle?.id
    );

    if (index === -1 || index === 0) {
      return;
    }

    const nextGc = growthCycles.at(index - 1);
    if (nextGc) {
      setGrowthCycleId({ growthCycleId: nextGc.id });
    }
  }, [selectedGrowthCycle?.id, setGrowthCycleId, growthCycles]);

  if (!selectedGrowthCycle) {
    return null;
  }

  return (
    <div className={cn('flex flex-col gap-[2px]', className)} ref={ref}>
      <div className="bg-white rounded-t-lg flex py-4 px-6 gap-3 justify-between">
        <Dropdown
          options={growthCycleOptions}
          value={selectedGrowthCycleOption}
          className="w-full"
          variant="secondary"
          onChange={(option) => selectGrowthCycle(option)}
        />
        <div className="flex gap-3">
          <Button
            variant="tertiary"
            size="icon"
            disabled={!canGoBackward}
            onClick={goBackward}
          >
            <MoveLeftIcon className="stroke-[1.5px] size-4 xl:size-5" />
          </Button>
          <Button
            variant="tertiary"
            size="icon"
            disabled={!canGoForward}
            onClick={goForward}
          >
            <MoveRightIcon className="stroke-[1.5px] size-4 xl:size-5" />
          </Button>
        </div>
      </div>
      <div className="bg-white flex flex-col py-4 px-6 gap-3">
        <h1 className="text-xl font-semibold">
          {formatGrowthCycleDaterange(
            selectedGrowthCycle.start_time,
            selectedGrowthCycle.end_time
          )}
        </h1>
        <div className="flex justify-between font-semibold">
          <div>Cultivar</div>
          <div>Count</div>
        </div>
        {cultivars.map((cultivar) => {
          return (
            <div className="flex justify-between" key={cultivar.id}>
              <div>{cultivar.strainName}</div>
              <div>{cultivar.count}</div>
            </div>
          );
        })}
      </div>
      <div className="bg-white rounded-b-lg flex py-4 px-6">
        <Button
          variant="tertiary"
          leadingIcon={
            <DownloadIcon className="stroke-[1.5px] size-4 xl:size-5" />
          }
          className="text-base"
        >
          Export
        </Button>
      </div>
    </div>
  );
});
