import { useGetEnumerationByCodeAndTypeQuery } from 'graphql/generated/react_apollo';
import {
  EEnumerationCodes,
  EEnumerationTypes,
} from 'shared/interfaces/enumeration';

export const useGetLabelAnnotationStatus = () => {
  const { data: pendingStatusId } = useGetEnumerationByCodeAndTypeQuery({
    variables: {
      code: EEnumerationCodes.PENDING,
      type: EEnumerationTypes.LABEL_ANNOTATION_STATUS,
    },
  });

  const { data: notifiedStatusId } = useGetEnumerationByCodeAndTypeQuery({
    variables: {
      code: EEnumerationCodes.NOTIFIED,
      type: EEnumerationTypes.LABEL_ANNOTATION_STATUS,
    },
  });

  return {
    pendingStatusId,
    notifiedStatusId,
  };
};
