import isNil from 'lodash.isnil';
import {
  EnvironmentalPreset,
  LightCyclesPreset,
  TGrowthCycleEdit,
  TPlantInfo,
} from 'shared/interfaces/growthCycle';
import { MeasurementTypeConfig } from 'shared/interfaces/measurement';

/** */
export function areEnviromentSettingsValid(
  settings: EnvironmentalPreset['settings']
) {
  return (
    settings.length > 0 &&
    settings.every(({ light, dark }) => {
      return [
        light.setPoint,
        light.ideal,
        light.critical,
        dark.setPoint,
        dark.ideal,
        dark.critical,
      ].every((param) => typeof param === 'number' && param >= 0);
    })
  );
}

/** */
export function checkEnvironmentalPreset(
  presetTypes: MeasurementTypeConfig[],
  preset: EnvironmentalPreset
) {
  const validSettings = presetTypes.every(({ statisticsKeyV2 }) =>
    areEnviromentSettingsValid(
      preset.settings.filter((s) => s.type === statisticsKeyV2)
    )
  );
  return preset.name.length >= 5 && validSettings;
}

/** */
export function checkCultivars(cultivars: Maybe<TPlantInfo[]>) {
  if (cultivars && cultivars.length > 0) {
    return cultivars.every(
      ({ strainName, count }) =>
        !isNil(strainName) &&
        strainName.length >= 3 &&
        !isNil(count) &&
        count >= 0
    );
  }
  return false;
}

/** */
export function checkLightCyclesPreset(preset: LightCyclesPreset) {
  const { name, lightCycles } = preset;

  return (
    name.length >= 5 &&
    lightCycles.length > 0 &&
    lightCycles.every(({ duration }) => duration >= 1)
  );
}

/** */
export function checkCycleStepValidity({
  presetTypes,
  step,
  cycle,
}: {
  presetTypes: MeasurementTypeConfig[];
  step: 'duration' | 'cultivars' | 'light-cycles' | 'environmental';
  cycle: TGrowthCycleEdit;
}) {
  const {
    start_time: startTime,
    end_time: endTime,
    cultivars,
    lightCyclesPreset,
    environmentalPreset,
  } = cycle;
  if (step === 'duration') {
    return Boolean(startTime && endTime);
  } else if (step === 'cultivars') {
    return checkCultivars(cultivars);
  } else if (step === 'light-cycles') {
    return (
      !isNil(lightCyclesPreset) && checkLightCyclesPreset(lightCyclesPreset)
    );
  } else if (step === 'environmental') {
    return (
      !isNil(environmentalPreset) &&
      checkEnvironmentalPreset(presetTypes, environmentalPreset)
    );
  }

  return true;
}
