import { captureException, captureMessage } from '@sentry/react';
import mixpanel, { Mixpanel } from 'mixpanel-browser';
import {
  Environment,
  MixpanelConfig,
  ReleaseConfig,
} from 'shared/config/AppConfig';
import { NEATLEAF_ORGANIZATION_CODE } from 'shared/interfaces/organization';
import { PublicRole } from 'shared/interfaces/user';
import { AnalyticsEvent } from './AnalyticsEvent';

const KIOSK_DEVICE_USER_AGENT_CODES = [
  'KFSNWI', // Amazon Fire Max 11
  'SM-X200', // Samsung Galaxy Tab A8
];

let instance: AnalyticsService | undefined;
export function getAnalyticsServiceInstance(): AnalyticsService {
  if (!instance) {
    throw new Error(`AnalyticsService has not been initialized yet.`);
  }
  return instance;
}

export function setAnalyticsService(service: AnalyticsService): void {
  instance = service;
}

export function initializeAnalyticsService(
  mixpanelConfig: MixpanelConfig,
  environment: Environment,
  releaseConfig: ReleaseConfig
): void {
  if (instance) {
    return;
  }

  instance = new AnalyticsService(mixpanelConfig, environment, releaseConfig);
}

export class AnalyticsService {
  isFirstPageLoad = true;
  isKioskDevice = false;
  skipSendingEvents = false;
  currentUserAgent: string | undefined = undefined;
  currentPage: string | undefined = undefined;
  currentZoneId: string | undefined = undefined;
  currentUserId: string | undefined = undefined;
  currentPublicRole: PublicRole = PublicRole.NotSpecified;
  currentOrganizationCode: string | undefined = undefined;
  currentSearchParams: Record<string, string[]> = {};
  mixpanelInstance: Mixpanel | undefined = undefined;

  constructor(
    private mixpanelConfig: MixpanelConfig,
    private environment: Environment,
    private releaseConfig: ReleaseConfig
  ) {
    this.currentUserAgent = window.navigator?.userAgent;
    this.isKioskDevice = this.getIsKioskDevice(this.currentUserAgent);

    if (mixpanelConfig.enabled) {
      // see https://docs.mixpanel.com/docs/quickstart/connect-your-data?sdk=javascript
      mixpanel.init(mixpanelConfig.apiToken, {
        debug: mixpanelConfig.debuggingEnabled,
        track_pageview: false,
        persistence: 'localStorage',
        loaded: (instance) => {
          this.mixpanelInstance = instance;
        },
      });
    }
  }

  public sendEvent(
    eventType: AnalyticsEvent,
    params?: Record<string, string | number | boolean>
  ): void {
    const augmentedParams = this.getAugmentedParams(params);

    this.wrapAnalyticsFn((mixpanelInstance) => {
      // see https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#sending-events
      mixpanelInstance.track(eventType, augmentedParams);
    });
  }

  public async identifyUser(
    userId: string,
    organizationCode: string,
    publicRole: PublicRole,
    excludeFromAnalytics: boolean
  ): Promise<void> {
    // This needs to come before the await, otherwise it is too late
    // for the first pageview event
    if (
      organizationCode === NEATLEAF_ORGANIZATION_CODE ||
      excludeFromAnalytics
    ) {
      this.skipSendingEvents = true;
    }

    const hashedUserId = await this.hashUserId(userId);

    if (!hashedUserId) {
      console.warn(
        `Failed to hash user id. Won't set user id in Analytics Service`
      );
      return;
    }

    if (
      this.currentUserId === hashedUserId &&
      this.currentPublicRole === publicRole
    ) {
      return;
    }

    this.currentUserId = hashedUserId;
    this.currentPublicRole = publicRole;

    const userProperties = {
      organization_code: organizationCode,
      public_role: publicRole,
    };

    this.wrapAnalyticsFn((mixpanelInstance) => {
      // See https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#identify
      mixpanelInstance.identify(hashedUserId);

      // See https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#storing-user-profiles
      mixpanelInstance.people.set(userProperties);
    });
  }

  public sendPageView(
    name: string | undefined,
    actualPath: string | undefined,
    matchedPath: string | undefined,
    searchParams: Record<string, string[]>
  ): void {
    this.currentPage = name;
    this.currentSearchParams = searchParams;

    if (this.isFirstPageLoad) {
      this.isFirstPageLoad = false;
      if (this.isKioskDevice) {
        return;
      }
    }

    const augmentedParams = this.getAugmentedParams({
      actualPath,
      matchedPath,
    });

    this.wrapAnalyticsFn((mixpanelInstance) => {
      // see https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#tracking-page-views
      mixpanelInstance.track_pageview(augmentedParams);
    });
  }

  public sendPageLeave(path: string): void {
    this.sendEvent(AnalyticsEvent.PageLeave, { path });
  }

  public sendSearchParamsChange(searchParams: Record<string, string[]>): void {
    this.currentSearchParams = searchParams;

    if (Object.keys(searchParams).length) {
      this.sendEvent(AnalyticsEvent.ChangeSearchParams);
    }
  }

  public setCurrentZoneId(zoneId: string | undefined): void {
    this.currentZoneId = zoneId;
  }

  public setCurrentOrganizationCode(
    organizationCode: string | undefined
  ): void {
    this.currentOrganizationCode = organizationCode;
  }

  public resetUser(): void {
    this.currentUserId = undefined;
    this.currentPublicRole = PublicRole.NotSpecified;
    this.skipSendingEvents = false;

    this.wrapAnalyticsFn((mixpanelInstance) => {
      // See https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#call-reset-at-logout
      mixpanelInstance.reset();
    });
  }

  private getAugmentedParams(
    params: Record<
      string,
      string | number | boolean | string[] | undefined
    > = {}
  ): Record<string, string | number | boolean | string[] | undefined> {
    let augmentedParams: Record<
      string,
      string | number | boolean | string[] | undefined
    > = {
      is_kiosk_device: this.isKioskDevice,
      environment: this.environment,
      git_commit: this.releaseConfig.commitSha,
      git_branch: this.releaseConfig.branchName,
      ...params,
    };

    if (this.currentUserAgent) {
      augmentedParams.current_user_agent = this.currentUserAgent;
    }

    if (this.releaseConfig.tagName) {
      augmentedParams.git_tag = this.releaseConfig.tagName;
    }

    if (this.currentOrganizationCode) {
      augmentedParams.current_organization_code = this.currentOrganizationCode;
    }

    if (this.currentZoneId) {
      augmentedParams.current_zone_id = this.currentZoneId;
    }

    if (this.currentPage) {
      augmentedParams.current_page = this.currentPage;
    }

    if (Object.keys(this.currentSearchParams).length) {
      augmentedParams = {
        ...augmentedParams,
        ...this.currentSearchParams,
      };
    }

    return augmentedParams;
  }

  private async hashUserId(userId: string): Promise<string | undefined> {
    if (!window.isSecureContext) {
      return undefined;
    }

    const enc = new TextEncoder();
    const encoded = enc.encode(userId);
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', encoded); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  }

  private wrapAnalyticsFn(fn: (mixpanelInstance: Mixpanel) => void): void {
    try {
      if (this.mixpanelConfig.enabled && !this.skipSendingEvents) {
        if (!this.mixpanelInstance) {
          captureMessage(`Mixpanel was enabled, but no instance was created.`, {
            extra: {
              config: this.mixpanelConfig,
            },
          });
        } else {
          fn(this.mixpanelInstance);
        }
      }
    } catch (e) {
      captureException(e);
    }
  }

  private getIsKioskDevice(userAgent: string | undefined): boolean {
    return (
      !!userAgent &&
      KIOSK_DEVICE_USER_AGENT_CODES.some((code) => userAgent.includes(code))
    );
  }
}
