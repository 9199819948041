import * as toast from 'components/common/Toast/Toast';
import { useZoneDetailsPageURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { useEffect, useRef } from 'react';
import { EZoneDetailTabs } from 'shared/interfaces/zone';
import { overlapsCycle } from 'shared/utils/growthCycle';
import { useCurrentZone } from './useCurrentZone';
import { useGrowthCycles } from './useGrowthCycles';
import { usePermissions } from './usePermissions';

/**
 * Warning the users about overlapping cycles after they log in.
 * If they dismiss the warning or navigate to the Growth cycle settings page
 * the warning is no longer displayed in the current session.
 */
export const useNotifyOverlappingGrowthCycles = () => {
  const { currentZone } = useCurrentZone();
  const { currentCycle: cycle, growthCycles: cycles } = useGrowthCycles();
  const { navigateToTab } = useZoneDetailsPageURL();
  const { canEditGrowthCycles } = usePermissions();
  const toastId = useRef<number | string | null>(null);

  useEffect(() => {
    if (
      currentZone &&
      canEditGrowthCycles &&
      cycle &&
      overlapsCycle(cycle, cycles)
    ) {
      const reminderId = 'overlappingGrowthCycleReminder-' + currentZone.id;
      if (sessionStorage.getItem(reminderId) !== 'dismissed') {
        toastId.current = toast.warning({
          content: (
            <p>
              This room has overlapping growth cycles.
              <button
                className="underline"
                onClick={() => {
                  sessionStorage.setItem(reminderId, 'dismissed');
                  navigateToTab(EZoneDetailTabs.GROWTH_CYCLE_SETTINGS);
                }}
              >
                Please adjust the cycles accordingly.
              </button>
            </p>
          ),
        });

        const unsubscribe = toast.onChange(({ id, status, data }) => {
          if (
            status === 'removed' &&
            id === toastId.current &&
            (data as any).dismissedByUser
          ) {
            sessionStorage.setItem(reminderId, 'dismissed');

            toastId.current = null;
            unsubscribe();
          }
        });
      }
    }

    return () => toast.dismiss(toastId.current ?? 0);
  }, [canEditGrowthCycles, currentZone, cycle, cycles, navigateToTab]);
};
