import {
  useGetPresetsByLocationId,
  useInsertLightCyclesPreset,
  useRemoveLightCyclePreset as useRemoveLightCyclesPreset,
  useUpdateLightCyclesPreset,
} from 'api/growth-cycle';
import {
  ActionableList,
  ActionableListProps,
} from 'components/common/ActionableList/ActionableList';
import { Alert } from 'components/common/Alert/Alert';
import { Button } from 'components/common/Button/Button';
import { Modal } from 'components/common/Modal/Modal';
import * as toast from 'components/common/Toast/Toast';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { Disclosure, useDisclosure } from 'hooks/useDisclosure';
import { FC, useState } from 'react';
import { LightCyclesPreset } from 'shared/interfaces/growthCycle';
import { TOrganization } from 'shared/interfaces/organization';
import { TZone } from 'shared/interfaces/zone';
import { cn } from 'shared/utils/cn';
import {
  computePresetName,
  getEmptyLightCycle,
} from 'shared/utils/growthCycle';
import { EditPresetLightCycles } from './EditPresetLightCycles';
import { checkLightCyclesPreset } from './validation';

export type ManagePresetsLightCyclesProps = {
  zone: TZone;
  organization: TOrganization;
  today: Date;
  disclosure: Disclosure;
};

export const ManagePresetsLightCycles: FC<ManagePresetsLightCyclesProps> = ({
  disclosure,
  zone,
  organization,
  today,
}) => {
  const { presetTypes } = useTypeConfig();
  const { insert, ...insertState } = useInsertLightCyclesPreset();
  const { update, ...updateState } = useUpdateLightCyclesPreset(zone);
  const { remove } = useRemoveLightCyclesPreset();
  const { lightCyclesPresets: presets, loading: loadingPresets } =
    useGetPresetsByLocationId({
      locationId: zone.locationId,
      presetTypes,
    });
  const [editingPreset, setEditingPreset] =
    useState<Maybe<LightCyclesPreset>>();
  const [presetIdToDelete, setPresetIdToDelete] = useState<number>();
  const discloseDeleteConfirmation = useDisclosure();
  const items = presets
    .filter(({ isActive }) => isActive)
    .map(({ id, name, lastUpdated, count }) => ({
      id: id!,
      name,
      lastUpdated,
      count,
    }));
  const saveDisabled =
    !editingPreset ||
    !checkLightCyclesPreset(editingPreset) ||
    insertState.loading ||
    updateState.loading;
  const title = () => {
    if (editingPreset?.id) {
      return 'Update the preset';
    }
    if (editingPreset?.name.includes(' copy')) {
      return `Create new preset from "${editingPreset.name.replace(' copy', '').trim()}"`;
    }

    return 'Create a new preset';
  };

  const updateEditingPreset = (
    preset: Partial<Pick<LightCyclesPreset, 'name' | 'lightCycles'>>
  ) => {
    const name = computePresetName(zone, today);
    setEditingPreset({
      name: preset.name ?? name,
      lastUpdated: today,
      locationId: zone.locationId,
      organizationId: organization.id,
      isActive: true,
      lightCycles: preset.lightCycles ?? [getEmptyLightCycle()],
    });
  };
  const handleCreate = () => {
    updateEditingPreset({});
  };
  const handleEdit: ActionableListProps['onEdit'] = ({ id }) => {
    setEditingPreset(presets.find((preset) => preset.id === id));
  };
  const handleClone: ActionableListProps['onClone'] = ({ id }) => {
    const presetToClone = presets.find((preset) => preset.id === id);
    if (presetToClone) {
      updateEditingPreset({
        name: `${presetToClone.name} copy`,
        lightCycles: presetToClone.lightCycles,
      });
    }
  };
  const handleDelete: ActionableListProps['onDelete'] = ({ id }) => {
    setPresetIdToDelete(id);
    discloseDeleteConfirmation.open();
  };
  const handleChange = (preset: LightCyclesPreset) => {
    setEditingPreset(preset);
  };
  const handleSave = async () => {
    if (editingPreset) {
      try {
        if (editingPreset.id) {
          await update(editingPreset);
        } else {
          await insert(editingPreset);
        }
        setEditingPreset(undefined);
        toast.success(
          { content: 'Preset successfully saved.' },
          { autoClose: 3000 }
        );
      } catch (_error) {
        toast.error({
          content:
            'Something went wrong while trying to save your preset. Please try again.',
        });
      }
    }
  };
  const handleCancel = () => {
    setEditingPreset(undefined);
  };
  const handleDeleteConfirmation = async () => {
    if (presetIdToDelete) {
      try {
        await remove(presetIdToDelete);
        setPresetIdToDelete(undefined);
        toast.success(
          { content: 'Preset successfully removed.' },
          { autoClose: 3000 }
        );
        discloseDeleteConfirmation.close();
      } catch (_error) {
        toast.error({
          content:
            'Something went wrong while trying to delete your preset. Please try again.',
        });
      }
    }
  };
  const handleDeleteCancel = () => {
    setPresetIdToDelete(undefined);
    discloseDeleteConfirmation.close();
  };

  return (
    <>
      {disclosure.isOpen ? (
        <Modal open={disclosure.isOpen}>
          <Modal.Header closeButtonProps={{ onClick: disclosure.close }} />

          <Modal.Content>
            <ActionableList
              className={cn(
                'hidden',
                !editingPreset && !loadingPresets && 'flex'
              )}
              mode="edit"
              items={items}
              sortBy={['most-recent', 'oldest', 'most-used']}
              title="Light Cycle Presets"
              subtitle="A list of your previously created light cycle presets."
              labels={{ createNewItem: 'Create a new light cycle preset' }}
              onCreate={handleCreate}
              onEdit={handleEdit}
              onClone={handleClone}
              onDelete={handleDelete}
            />

            {editingPreset && (
              <EditPresetLightCycles
                totalDays={Infinity}
                preset={editingPreset}
                title={title()}
                subtitle="Name your preset, then edit your light cycles."
                onChange={handleChange}
              />
            )}
          </Modal.Content>

          {editingPreset && (
            <Modal.Footer
              className={cn(
                'flex w-full flex-col justify-between gap-3 self-center sm:w-[592px] md:flex-row md:px-0'
              )}
            >
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button onClick={handleSave} disabled={saveDisabled}>
                Save
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      ) : null}

      {discloseDeleteConfirmation.isOpen && (
        <Alert
          open={discloseDeleteConfirmation.isOpen}
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirmation}
        >
          Are you sure you want to remove the preset?
        </Alert>
      )}
    </>
  );
};
