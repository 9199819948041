import { scaleLinear, scaleTime } from '@visx/scale';
import { useMemo } from 'react';
import { TTimeRange } from 'shared/interfaces/general';

const getTimeInput = (value: Date) => value.valueOf();

interface IUseHealthChartScalesProps {
  innerHeight: number;
  innerWidth: number;
  margin: TRect;
  timeRange: TTimeRange;
}

export const useHealthChartScales = ({
  innerHeight,
  innerWidth,
  margin,
  timeRange,
}: IUseHealthChartScalesProps) => {
  const timeScale = useMemo(
    () =>
      scaleTime<number>({
        range: [margin.left, innerWidth + margin.left],
        domain: [getTimeInput(timeRange.start), getTimeInput(timeRange.end)],
      }),
    [timeRange, margin, innerWidth]
  );

  const measurementScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [innerHeight + margin.top, margin.top],
        domain: [0, 1],
      }),
    [innerHeight, margin]
  );

  return {
    timeScale,
    measurementScale,
  };
};
