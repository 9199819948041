import { useEffect, useState } from 'react';

export const useIsElementScrolled = (element?: Maybe<HTMLElement>) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      element && setIsScrolled((element.scrollTop || 0) > 0);
    };
    element && element.addEventListener('scroll', handleScroll);

    return () => {
      element && element.removeEventListener('scroll', handleScroll);
    };
  }, [element]);

  return isScrolled;
};
