import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const AirVPDIcon = ({
  stroke = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    stroke={stroke}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={cn('h-6 w-6', className)}
    {...props}
  >
    <path d="M6 22C6.5 21.4 7 20.8 7 19.5C7 17 5 17 5 14.5C5 11.9 7 12.1 7 9.5C7 7 5 7 5 4.5C5 3.2 5.5 2.6 6 2M12 22C12.5 21.4 13 20.8 13 19.5C13 17 11 17 11 14.5C11 11.9 13 12.1 13 9.5C13 7 11 7 11 4.5C11 3.2 11.5 2.6 12 2M18 22C18.5 21.4 19 20.8 19 19.5C19 17 17 17 17 14.5C17 11.9 19 12.1 19 9.5C19 7 17 7 17 4.5C17 3.2 17.5 2.6 18 2" />
  </svg>
);

export { AirVPDIcon };
