import { ITab, TabGroup } from 'components/common/TabGroup';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useGrowthCycleReport } from 'hooks/useGrowthCycleReport';
import { useMemo, useState } from 'react';
import { Canopy } from './Canopy';

export const GrowthCycleReportCanopy = () => {
  const { currentZone, zoneTimeZone } = useCurrentZone();
  const { weeks, selectedGrowthCycle } = useGrowthCycleReport();

  const [currentTab, setCurrentTab] = useState(1);

  const week = useMemo(() => {
    return weeks.at(currentTab - 1);
  }, [currentTab, weeks]);

  const tabs = useMemo(
    () =>
      weeks.map((_, idx) => {
        const tab: ITab = {
          id: (idx + 1).toString(),
          label: `${idx + 1}`,
        };
        return tab;
      }),
    [weeks]
  );

  if (!currentZone || !selectedGrowthCycle || !week) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2 max-w-[750px]">
      <h2 className="text-xl">Your canopy week by week</h2>
      <div className="flex flex-col gap-2 p-6 bg-white rounded">
        <Canopy
          key={week}
          week={currentTab}
          startTime={week}
          growthCycleEndTime={selectedGrowthCycle.end_time}
          zoneId={currentZone.id.toString()}
          zoneTimeZone={zoneTimeZone}
        />
        <div className="flex gap-3 items-center">
          <span className="font-semibold">Week</span>
          <TabGroup
            tabs={tabs}
            value={currentTab.toString()}
            variant="full"
            onChange={(tab: string) => {
              setCurrentTab(parseInt(tab));
            }}
          />
        </div>
      </div>
    </div>
  );
};
