import { CardButton } from 'components/common/CardButton';
import { useAuth } from 'contexts/AuthProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useDisclosure } from 'hooks/useDisclosure';
import { useGrowthCycles } from 'hooks/useGrowthCycles';
import {
  ClipboardPenLineIcon,
  CloudCogIcon,
  LeafIcon,
  SunriseIcon,
} from 'lucide-react';
import { EditCycle } from './EditCycle';
import { ManageCycles } from './ManageCycles';
import { ManagePresetsEnvironmental } from './ManagePresetsEnvironmental';
import { ManagePresetsLightCycles } from './ManagePresetsLightCycles';

export const GrowthCycleSettings = () => {
  const { currentlySelectedOrganization } = useAuth();
  const { currentZone: zone, currentTimeInCurrentZone: today } =
    useCurrentZone();
  const { growthCycles: cycles } = useGrowthCycles();
  const discloseEditCycle = useDisclosure();
  const discloseCycles = useDisclosure();
  const discloseLightCyclePresets = useDisclosure();
  const discloseEnvironmentalPresets = useDisclosure();

  if (!currentlySelectedOrganization || !zone) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col h-full gap-6 items-center">
        <h1 className="text-xl font-semibold">{`${currentlySelectedOrganization.label} - ${zone.label}`}</h1>

        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 md:max-w-4xl">
          <CardButton
            icon={
              <LeafIcon className="stroke-[1.5px] size-10 md:size-12 text-orange-300" />
            }
            title="Start a new cycle"
            subtitle="Create from scratch a new growth cycle in 4 simple steps."
            onClick={discloseEditCycle.open}
          />
          <CardButton
            icon={
              <ClipboardPenLineIcon className="stroke-[1.5px] size-10 md:size-12 text-orange-300" />
            }
            title="Edit your growth cycles"
            subtitle="Change dates, light cycles, and environmental settings."
            onClick={discloseCycles.open}
          />
          <CardButton
            icon={
              <SunriseIcon className="stroke-[1.5px] size-10 md:size-12 text-orange-300" />
            }
            title="Light cycle presets"
            subtitle="Create, edit, copy, or delete light cycle presets."
            onClick={discloseLightCyclePresets.open}
          />
          <CardButton
            className="md:min-w-24 md:flex-grow"
            icon={
              <CloudCogIcon className="stroke-[1.5px] size-10 md:size-12 text-orange-300" />
            }
            title="Environmental presets"
            subtitle="Create, edit, copy, or delete environmental presets."
            onClick={discloseEnvironmentalPresets.open}
          />
        </div>
      </div>

      {discloseEditCycle.isOpen && (
        <EditCycle
          mode="create"
          zone={zone}
          organization={currentlySelectedOrganization}
          today={today}
          disclosure={discloseEditCycle}
          cycles={cycles}
        />
      )}

      {discloseCycles.isOpen && (
        <ManageCycles
          disclosure={discloseCycles}
          zone={zone}
          organization={currentlySelectedOrganization}
          today={today}
        />
      )}

      {discloseLightCyclePresets.isOpen && (
        <ManagePresetsLightCycles
          disclosure={discloseLightCyclePresets}
          zone={zone}
          organization={currentlySelectedOrganization}
          today={today}
        />
      )}

      {discloseEnvironmentalPresets.isOpen && (
        <ManagePresetsEnvironmental
          disclosure={discloseEnvironmentalPresets}
          zone={zone}
          organization={currentlySelectedOrganization}
          today={today}
        />
      )}
    </>
  );
};
