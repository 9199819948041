// A function given a starting index number, an offset number, and a list of number that wraps around the list.
export function wrapAround(
  index: number,
  offset: number,
  listLength: number
): number {
  if (listLength === 0) {
    return -1;
  }
  if (listLength === 1) {
    return 0;
  }
  let newIndex = index + offset;
  while (newIndex >= listLength) {
    newIndex -= listLength;
  }
  while (newIndex < 0) {
    newIndex += listLength;
  }
  return newIndex;
}
