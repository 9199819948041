import { useMutation } from '@tanstack/react-query';
import { useApi } from 'contexts/ApiProvider';
import { toZonedTime } from 'date-fns-tz';
import { useGetHealthDataByZoneIdQuery } from 'graphql/generated/react_apollo';
import { useMemo } from 'react';
import {
  MeasurementTypeConfig,
  SignalMeasurements,
  SignalMeasurementsType,
} from 'shared/interfaces/measurement';

export const useRecalculateHealthScoreMutation = () => {
  const { apiUrl, httpClient } = useApi();

  return useMutation({
    mutationKey: ['recalculate-health-score'],
    mutationFn: (zoneUid: string) => {
      const url = new URL(
        `dashboard/v1/zones/${zoneUid}/health-score`,
        apiUrl
      ).toString();
      return httpClient.post(url, {
        body: '{}', // Need to add an empty body to satisfy fastify. TODO: Find out how we can POST without sending a body
      });
    },
  });
};

export const useGetHealthDataByZoneId = ({
  zoneId,
  zoneTimeZone,
  start,
  end,
  signals,
}: {
  zoneId: number;
  zoneTimeZone: string;
  start: Date;
  end: Date;
  signals: MeasurementTypeConfig[];
}) => {
  const {
    previousData: previousRawData,
    data: rawData = previousRawData,
    ...result
  } = useGetHealthDataByZoneIdQuery({
    variables: {
      zoneId: zoneId,
      start: start,
      end: end,
    },
  });

  const data = useMemo<SignalMeasurementsType>(() => {
    const extractValues = (signal: MeasurementTypeConfig) => {
      if (!rawData) {
        return [];
      }

      return rawData.computed_measurement
        .filter(({ type }) => type?.code === signal.statisticsKeyV2)
        .map<
          [number, number]
        >(({ time, data }) => [toZonedTime(time, zoneTimeZone).valueOf(), Number(data.pm_health_score) * 100]);
    };

    const allValues = new SignalMeasurements();
    for (const signal of signals) {
      allValues.set(signal, extractValues(signal));
    }

    return allValues;
  }, [rawData, signals, zoneTimeZone]);

  return { data, ...result };
};
