import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from 'contexts/ApiProvider';

export enum NotificationType {
  CriticalEnvironment = 'CRITICAL_ENVIRONMENT',
  InsightReport = 'INSIGHT_REPORT',
  SpyderStatus = 'SPYDER_STATUS',
}

export enum NotificationTarget {
  Email = 'email',
  Sms = 'sms',
}

export interface UserNotificationSettingDto {
  uid: string;
  userId: string;
  organizationCode: string | null;
  zoneUid: string | null;
  locationId: string | null;
  notificationType: NotificationType;
  target: NotificationTarget;
}

export interface CreateUserNotificationSettingRequest {
  userId: string;
  organizationCode: string | null;
  zoneUid: string | null;
  locationId: string | null;
  notificationType: NotificationType;
  target: NotificationTarget;
}

export interface UserNotificationSettingsResponse {
  settings: UserNotificationSettingDto[];
}

export interface UseUserNotificationSettingsQueryProps {
  userId: number;
}

export const useUserNotificationSettingsQuery = ({
  userId,
}: UseUserNotificationSettingsQueryProps) => {
  const { apiUrl, httpClient } = useApi();

  const url = new URL(
    `dashboard/v1/users/${userId}/notification-settings`,
    apiUrl
  ).toString();

  const { data, ...result } = useQuery<UserNotificationSettingsResponse, Error>(
    {
      queryKey: ['user-notification-settings', userId.toString()],
      queryFn: () =>
        httpClient.get(url).json<UserNotificationSettingsResponse>(),
    }
  );

  return { data: data?.settings || [], ...result };
};

export interface UseBulkUpdateUserNotificationSettingsMutationProps {
  userId: number;
}
export const useBulkUpdateUserNotificationSettingsMutation = (
  props: UseBulkUpdateUserNotificationSettingsMutationProps
) => {
  const { apiUrl, httpClient } = useApi();
  const url = new URL(
    `dashboard/v1/user-notification-settings/bulk-update`,
    apiUrl
  ).toString();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['bulk-update-user-notification-settings'],
    mutationFn: (variables: {
      addedSettings: UserNotificationSettingDto[];
      removedSettings: UserNotificationSettingDto[];
    }) =>
      httpClient
        .post(url, {
          body: JSON.stringify({
            addedSettings: variables.addedSettings,
            removedSettings: variables.removedSettings,
          }),
        })
        .json(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user-notification-settings', props.userId.toString()],
      });
    },
  });
};

export const useCreateUserNotificationSettingMutation = () => {
  const { apiUrl, httpClient } = useApi();
  const url = new URL(
    `dashboard/v1/user-notification-settings`,
    apiUrl
  ).toString();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['create-user-notification-setting'],
    mutationFn: (variables: {
      setting: CreateUserNotificationSettingRequest;
      userId: string;
    }) =>
      httpClient
        .post(url, {
          body: JSON.stringify(variables.setting),
        })
        .json(),
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['user-notification-settings', variables.userId],
      });
    },
  });
};

export interface UseDeleteUserNotificationSettingMutationProps {
  userId: number;
}
export const useDeleteUserNotificationSettingMutation = (
  props: UseDeleteUserNotificationSettingMutationProps
) => {
  const { apiUrl, httpClient } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['delete-user-notification-setting'],
    mutationFn: (variables: { uid: string }) => {
      const url = new URL(
        `dashboard/v1/user-notification-settings/${variables.uid}`,
        apiUrl
      ).toString();
      return httpClient
        .delete(url, {
          body: '{}', // Need to add an empty body to satisfy fastify. TODO: Find out how we can DELETE without sending a body
        })
        .json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user-notification-settings', props.userId.toString()],
      });
    },
  });
};
