import {
  GetLabelCountsByMeasurementIdQuery,
  GetLabelCountsByMeasurementRunIdQuery,
  useGetLabelCountsByMeasurementIdQuery,
  useGetLabelCountsByMeasurementRunIdQuery,
} from 'graphql/generated/react_apollo';
import { useGetLabelEnumerations } from 'hooks/useGetLabelEnumerations';
import groupBy from 'lodash.groupby';
import isNil from 'lodash.isnil';
import { useMemo } from 'react';
import { EImageLabelCategory, TLabelByCategory } from 'shared/interfaces/image';
import { convertEnumerationToLabel } from 'shared/utils/image';
import { useCurrentImageFeedMeasurements } from './useCurrentImageFeedMeasurements';

export const useImageFeedLabelsCounts = () => {
  const { measurementId, measurementRunId } = useCurrentImageFeedMeasurements();

  const {
    data: labelCountsByMeasurementIdData,
    loading: labelCountsByMeasurementIdLoading,
  } = useGetLabelCountsByMeasurementIdQuery({
    variables: {
      measurementId: measurementId as number,
    },
    skip: !measurementId,
  });
  const {
    data: labelCountsByMeasurementRunIdData,
    loading: labelCountsByMeasurementRunIdLoading,
  } = useGetLabelCountsByMeasurementRunIdQuery({
    variables: {
      measurementRunId: measurementRunId as number,
    },
    skip: isNil(measurementRunId) || !isNil(measurementId),
  });

  const { labelEnumerationsById } = useGetLabelEnumerations();

  const labelData:
    | GetLabelCountsByMeasurementIdQuery
    | GetLabelCountsByMeasurementRunIdQuery
    | null = useMemo(() => {
    let result = null;
    if (measurementId && !labelCountsByMeasurementIdLoading) {
      result = labelCountsByMeasurementIdData ?? null;
    } else if (measurementRunId && !labelCountsByMeasurementRunIdLoading) {
      result = labelCountsByMeasurementRunIdData ?? null;
    }

    return result;
  }, [
    measurementId,
    measurementRunId,
    labelCountsByMeasurementIdLoading,
    labelCountsByMeasurementIdData,
    labelCountsByMeasurementRunIdLoading,
    labelCountsByMeasurementRunIdData,
  ]);

  const labelsCountsByCategory = useMemo(() => {
    const labelCounts = (labelData?.label_counts_by_measurement ?? []).map(
      (labelCountsByMeasurement) => {
        const {
          measurement_id: measurementId,
          measurement_run_id: measurementRunId,
          label_counts: labelCount,
          label_ids: labelIds,
        } = labelCountsByMeasurement;

        const enumeration =
          labelEnumerationsById?.[
            labelCountsByMeasurement?.label_name_id ?? ''
          ];
        const labelEnumeration = enumeration
          ? convertEnumerationToLabel(enumeration)
          : {};

        return {
          measurementId,
          measurementRunId,
          labelCount,
          labelIds,
          ...labelEnumeration,
        };
      }
    );

    return groupBy(labelCounts, 'category') as Record<
      EImageLabelCategory,
      TLabelByCategory[]
    >;
  }, [labelData, labelEnumerationsById]);

  const labelsCounts: TLabelByCategory[] = useMemo(
    () => Object.values(labelsCountsByCategory).flat(),
    [labelsCountsByCategory]
  );

  const labelsCountsByMeasurementId = useMemo(
    () => groupBy(labelsCounts, 'measurementId'),
    [labelsCounts]
  );

  return {
    labelsCountsByCategory,
    labelsCounts,
    labelsCountsByMeasurementId,
  };
};
