import { BanIcon, CheckIcon, InfoIcon, TriangleAlertIcon } from 'lucide-react';
import {
  ComponentPropsWithoutRef,
  MouseEventHandler,
  ReactNode,
  forwardRef,
} from 'react';
import { cn } from 'shared/utils/cn';
import { Button } from '../Button/Button';
import { Modal } from '../Modal/Modal';

const _variants = ['primary', 'error', 'warning', 'success'] as const;

type Variant = (typeof _variants)[number];

const iconMap = {
  primary: (
    <InfoIcon className="stroke-[1.5px] size-4 xl:size-5 text-orange-500" />
  ),
  error: <BanIcon className="stroke-[1.5px] size-4 xl:size-5 text-red-500" />,
  warning: (
    <TriangleAlertIcon className="stroke-[1.5px] size-4 xl:size-5 text-yellow-600" />
  ),
  success: (
    <CheckIcon className="stroke-[1.5px] size-4 xl:size-5 text-green-500" />
  ),
} as {
  [key in Variant]: ReactNode | null;
};

type AlertProps = ComponentPropsWithoutRef<'div'> & {
  /** Whether the alert dialog is open or not */
  open: boolean;
  /** The alert message */
  children: ReactNode;
  /** The style of the alert */
  variant?: Variant;
  /** Whether the buttons should show a loading state. Also disables the buttons when `true`. Optional. */
  loading?: boolean;
  /** Sets a custom confirm label */
  confirmLabel?: string;
  /** Called when Cancel button is clicked */
  onCancel: MouseEventHandler<HTMLButtonElement>;
  /** Called when Confirm button is clicked */
  onConfirm: MouseEventHandler<HTMLButtonElement>;
};

/** An alert dialog is a modal dialog that interrupts the user's workflow to communicate an important message and acquire a response. */
export const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  {
    open,
    variant = 'warning',
    children,
    loading,
    confirmLabel,
    onCancel,
    onConfirm,
  },
  ref
) {
  return (
    <Modal
      ref={ref}
      open={open}
      role="alertdialog"
      aria-describedby="alertdialog-message"
      className="bg-gray-400/50"
    >
      <Modal.Content className="h-full flex items-center justify-center">
        <div
          className={cn(
            'max-w-md flex flex-col gap-4 p-4 rounded-lg bg-white  text-gray-900 font-medium',
            variant === 'error' && 'text-red-500'
          )}
        >
          <div className="flex gap-2 justify-start" id="alertdialog-message">
            <i className="flex h-6 min-w-6 items-center justify-center">
              {iconMap[variant]}
            </i>
            {children}
          </div>
          <div className="w-full flex justify-between gap-2">
            <Button variant="secondary" onClick={onCancel} loading={loading}>
              Cancel
            </Button>
            <Button variant={variant} onClick={onConfirm} loading={loading}>
              {confirmLabel || 'Confirm'}
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
});
