export enum ESettingsTabs {
  PROFILE = 'profile',
  PREFERENCES = 'preferences',
  USERS = 'users',
  NOTIFICATIONS = 'notifications',
  CRITICAL_ENV_SETTINGS = 'critical-env-settings',
}

export enum EProfileTabs {
  EDIT_PROFILE = 'profile',
  CHANGE_PASSWORD = 'password',
}
