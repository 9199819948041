import { Button } from 'components/common/Button/Button';
import { Checkbox } from 'components/common/Checkbox/Checkbox';
import { Disclosure } from 'components/common/Disclosure/Disclosure';
import { Modal } from 'components/common/Modal/Modal';
import { useLineChartURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { useDisclosure } from 'hooks/useDisclosure';
import { useScreenSize } from 'hooks/useScreenSize';
import { useSignals } from 'hooks/useSignals';
import groupBy from 'lodash.groupby';
import { ActivityIcon, XIcon } from 'lucide-react';
import pluralize from 'pluralize';
import {
  ComponentProps,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import {
  EMeasurementGroup,
  MeasurementTypeConfig,
} from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';

const defaultOpenGroups = [
  EMeasurementGroup.Environment,
  EMeasurementGroup.PlantHealth,
];

export const Sidebar = forwardRef<HTMLDivElement, ComponentProps<'div'>>(
  function Sidebar({ className, ...props }, ref) {
    const { isMobile } = useScreenSize();
    const { allSignals, signalIds, updateSignalIds } = useSignals();
    const { viewType } = useLineChartURL();
    const [mobileSignalIds, setMobileSignalIds] = useState(signalIds);
    const disclosure = useDisclosure({
      onOpen: () => {
        setMobileSignalIds(signalIds);
      },
      onClose: () => {
        setMobileSignalIds(signalIds);
      },
    });
    const groups = useMemo(
      () =>
        Object.entries(
          groupBy(
            allSignals.filter(
              ({ group, aggregation }) =>
                // Signals with a group OR with aggregation matching the current viewType
                !!group &&
                [undefined, viewType.toLowerCase()].includes(
                  aggregation?.toLowerCase()
                )
            ),
            (signal) => signal.group
          )
        ) as [EMeasurementGroup, MeasurementTypeConfig[]][],
      [allSignals, viewType]
    );
    const handleToggleSignals = useCallback(
      (signals: MeasurementTypeConfig[]) => () => {
        const ids = signals.map(({ type }) => type as string);
        const checkedIds = signalIds.filter((id) => ids.includes(id));

        if (checkedIds.length < ids.length) {
          // check ids
          updateSignalIds(Array.from(new Set([...signalIds, ...ids])));
        } else {
          // uncheck ids
          updateSignalIds(signalIds.filter((id) => !ids.includes(id)));
        }
      },
      [signalIds, updateSignalIds]
    );
    const handleToggleSignalsMobile = useCallback(
      (signals: MeasurementTypeConfig[]) => () => {
        const ids = signals.map(({ type }) => type as string);
        const checkedIds = mobileSignalIds.filter((id) => ids.includes(id));

        if (checkedIds.length < ids.length) {
          // select ids
          setMobileSignalIds(Array.from(new Set([...mobileSignalIds, ...ids])));
        } else {
          // deselect ids
          setMobileSignalIds(mobileSignalIds.filter((id) => !ids.includes(id)));
        }
      },
      [mobileSignalIds]
    );
    const handleConfirmSignals = useCallback(() => {
      updateSignalIds(mobileSignalIds);
      disclosure.close();
    }, [disclosure, mobileSignalIds, updateSignalIds]);
    const handleClearAllSignals = useCallback(() => {
      updateSignalIds([]);
    }, [updateSignalIds]);
    const handleClearAllSignalsMobile = useCallback(() => {
      setMobileSignalIds([]);
    }, [setMobileSignalIds]);

    return (
      <>
        {!isMobile && (
          <div
            ref={ref}
            {...props}
            className={cn('flex flex-col gap-2 justify-between', className)}
          >
            <div className="pr-2 h-full flex flex-col  overflow-x-hidden overflow-y-auto scrollbar-stable">
              {groups.map(([group, groupSignals]) => {
                const enabledSignalsCount = groupSignals.filter(({ type }) =>
                  signalIds.includes(type)
                ).length;
                const title = `${group}${enabledSignalsCount > 0 ? ` (${enabledSignalsCount})` : ''}`;
                const isDefaultOpen =
                  defaultOpenGroups.includes(group) || enabledSignalsCount > 0;

                return (
                  <Disclosure
                    key={group}
                    role="article"
                    aria-label={group}
                    className="py-2 first:border-none first:pt-0 gap-0"
                    defaultOpen={isDefaultOpen}
                    summary={
                      <h2
                        className="text-sm font-medium hover:underline hover:cursor-pointer"
                        onClick={handleToggleSignals(groupSignals)}
                      >
                        {title}
                      </h2>
                    }
                  >
                    {groupSignals
                      .toSorted(
                        (a, b) => (a.order ?? Infinity) - (b.order ?? Infinity)
                      )
                      .map((signal) => (
                        <Checkbox.Input
                          key={signal.type}
                          name={signal.type}
                          label={signal.label}
                          checked={signalIds.includes(signal.type)}
                          labelPlacement="right"
                          onChange={handleToggleSignals([signal])}
                        />
                      ))}
                  </Disclosure>
                );
              })}
            </div>

            <Button
              variant="secondary"
              className="w-full"
              leadingIcon={
                <XIcon className="stroke-[1.5px] size-4 xl:size-5" />
              }
              disabled={signalIds.length === 0}
              onClick={handleClearAllSignals}
            >
              Clear all
            </Button>
          </div>
        )}

        {isMobile &&
          createPortal(
            <>
              <Button
                variant="secondary"
                leadingIcon={
                  <ActivityIcon className="stroke-[1.5px] size-4 xl:size-5" />
                }
                onClick={disclosure.open}
              >
                Select sources ({signalIds.length})
              </Button>

              <Modal open={disclosure.isOpen}>
                <Modal.Header
                  title="Sources"
                  closeButtonProps={{ onClick: disclosure.close }}
                />
                <Modal.Content>
                  <div className="flex flex-col gap-5 text-sm">
                    {groups.map(([group, groupSignals]) => {
                      const enabledSignalsCount = groupSignals.filter(
                        ({ type }) => signalIds.includes(type)
                      ).length;
                      const title = `${group}${enabledSignalsCount > 0 ? ` (${enabledSignalsCount})` : ''}`;

                      return (
                        <div key={group} className="flex flex-col gap-2">
                          <h2
                            className="text-sm font-medium hover:underline hover:cursor-pointer"
                            onClick={handleToggleSignalsMobile(groupSignals)}
                          >
                            {title}
                          </h2>

                          <div className="flex flex-wrap gap-2">
                            {groupSignals
                              .toSorted(
                                (a, b) =>
                                  (a.order ?? Infinity) - (b.order ?? Infinity)
                              )
                              .map((signal) => (
                                <Button
                                  key={signal.type}
                                  variant="tertiary"
                                  selected={mobileSignalIds.includes(
                                    signal.type
                                  )}
                                  onClick={handleToggleSignalsMobile([signal])}
                                >
                                  {signal.label}
                                </Button>
                              ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Modal.Content>
                <Modal.Footer className="w-full flex sm:flex-col justify-between gap-3 self-center">
                  <Button
                    variant="secondary"
                    className="w-full"
                    onClick={handleClearAllSignalsMobile}
                    leadingIcon={
                      <XIcon className="stroke-[1.5px] size-4 xl:size-5" />
                    }
                  >
                    Clear all
                  </Button>
                  <Button className="w-full" onClick={handleConfirmSignals}>
                    {mobileSignalIds.length === 0 && 'No source selected'}
                    {mobileSignalIds.length > 0 && (
                      <>
                        Select {mobileSignalIds.length}{' '}
                        {pluralize('source', mobileSignalIds.length)}
                      </>
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>
            </>,
            document.getElementById('filters-slot')!
          )}
      </>
    );
  }
);
