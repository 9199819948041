import { NavigateOptions } from 'react-router-dom';
import { ERoutePath } from 'shared/constants/url';
import { EAggregationTypes, EGradientTypes } from 'shared/interfaces/heatmap';
import { EMeasurementTypes } from 'shared/interfaces/measurement';
import { QueryParamsSchema, getQueryParams } from './URLQueryParams';
import { StoreArgs } from './URLStoreArgs';

export const getHeatMapStore = (args: StoreArgs<ERoutePath.HEAT_MAP>) => {
  const { routePath, searchParams, setSearchParams } = args;
  const queryParams = getQueryParams(searchParams, routePath);
  type SchemaType = QueryParamsSchema<typeof routePath>;
  const actions = {
    setAggregationType: (
      value: SchemaType['aggregation-type'],
      navigateOptions?: NavigateOptions
    ) => {
      setSearchParams({ 'aggregation-type': value }, navigateOptions);
    },
    setGradientType: (
      value: SchemaType['gradient-type'],
      navigateOptions?: NavigateOptions
    ) => {
      setSearchParams(
        {
          'gradient-type': value,
        },
        navigateOptions
      );
    },
    setSignalIds: (
      value: SchemaType['signal-ids'],
      navigateOptions?: NavigateOptions
    ) => {
      setSearchParams({ 'signal-ids': value }, navigateOptions);
    },
    setRange: (value: SchemaType['range']) => {
      setSearchParams({ range: value }, { replace: true });
    },
    setShowComments: (showComments: boolean) => {
      setSearchParams(
        {
          'show-comments': showComments,
          'discussion-uid': undefined,
        },
        { replace: true }
      );
    },
  };
  const state = {
    range: queryParams.range,
    measurementType: queryParams['signal-ids'] ?? [
      EMeasurementTypes.Temperature,
    ],
    gradientType: queryParams['gradient-type'] ?? EGradientTypes.MICROCLIMATES,
    aggregationType:
      queryParams['aggregation-type'] ?? EAggregationTypes.AGGREGATED,
    showComments: queryParams['show-comments'],
    discussionUid: queryParams['discussion-uid'],
  };
  return {
    ...actions,
    ...state,
  };
};
