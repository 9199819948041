import { Button } from 'components/common/Button/Button';
import { useAuth } from 'contexts/AuthProvider';
import { useURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { NeatleafLogo } from 'icons/NeatleafLogo';
import { FC } from 'react';
import { ErrorOccuredImg } from './ErrorOccuredImg';

interface INotFoundPageProps {
  message?: string;
}

export const NotFoundPage: FC<INotFoundPageProps> = ({ message }) => {
  const { navigateToLogin, navigateToHome } = useURL();
  const { signOut } = useAuth();

  const handleBackToLogin = async () => {
    await signOut();
    navigateToLogin();
  };

  const errorMessage =
    message ??
    'It seems like something went wrong. Please go back to the homepage or contact us at support@neatleaf.com';

  return (
    <div className="relative flex w-screen flex-col gap-6 p-6 py-[14px] align-middle md:h-screen md:p-8 md:py-[14px]">
      <NeatleafLogo
        className="mr-2 cursor-pointer w-[184px]"
        onClick={navigateToHome}
      />
      <div className="flex flex-1 flex-col flex-wrap gap-6 md:flex-row">
        <div className="flex flex-1">
          <ErrorOccuredImg className="w-full" />
        </div>

        <div className="flex flex-1 flex-col gap-4 md:justify-center">
          <h1 className="text-3xl font-bold">An error occurred</h1>
          <p className="font-semibold">{errorMessage}</p>
          <Button onClick={handleBackToLogin} className="w-full">
            Back to login
          </Button>
        </div>
      </div>
    </div>
  );
};
