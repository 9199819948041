import { useAuth } from 'contexts/AuthProvider';
import { useState } from 'react';
import { AnalyticsEvent } from 'shared/analytics/AnalyticsEvent';
import { useAnalytics } from 'shared/analytics/useAnalytics';

export const useLogOut = () => {
  const { sendEvent } = useAnalytics();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const { signOut } = useAuth();

  const handleLogout = async () => {
    setIsLoggingOut(true);
    try {
      sendEvent(AnalyticsEvent.Logout);
      await signOut();
    } finally {
      setIsLoggingOut(false);
    }
  };

  return {
    isLoggingOut,
    logOut: handleLogout,
  };
};
