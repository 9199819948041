import {
  NotificationTarget,
  NotificationType,
  UserNotificationSettingDto,
} from 'api/user-notification-settings';
import groupBy from 'lodash.groupby';
import { symmetricDifference } from 'shared/utils/set';
import { v4 } from 'uuid';

export const NotificationTypes = [
  NotificationType.CriticalEnvironment,
  NotificationType.InsightReport,
  NotificationType.SpyderStatus,
];

export const NotificationTargets = [
  NotificationTarget.Sms,
  NotificationTarget.Email,
];

export class UserNotificationSettingContainer {
  public orgSettings: UserNotificationSettingDto[] = [];
  public locationIds: string[] = [];

  public locationSettingsByLocationId = new Map<
    string,
    UserNotificationSettingDto[]
  >();
  public zoneSettingsByZoneUid = new Map<
    string,
    UserNotificationSettingDto[]
  >();

  constructor(
    public userId: string,
    public organizationCode: string,
    public zoneUidsByLocationId: Record<string, string[]>,
    public settings: UserNotificationSettingDto[]
  ) {
    this.locationIds = Object.keys(zoneUidsByLocationId);
    const zoneUids = Object.values(zoneUidsByLocationId).flat();

    for (const setting of settings) {
      if (setting.organizationCode === organizationCode) {
        this.initOrgSetting(setting);
      } else if (this.locationIds.some((l) => l === setting.locationId)) {
        this.initLocationSetting(setting);
      } else if (zoneUids.some((z) => z === setting.zoneUid)) {
        this.initZoneSetting(setting);
      }
    }

    if (
      this.orgSettings.length > 0 &&
      (this.locationSettingsByLocationId.size > 0 ||
        this.zoneSettingsByZoneUid.size > 0)
    ) {
      throw new Error(
        `Failed to initialize UserNotificationSettingContainer for user ${userId} in organization ${organizationCode}. Got organization settings as well as location or zone settings.`
      );
    }

    for (const locationId of this.locationIds) {
      const zoneUidsOfLocation = zoneUidsByLocationId[locationId]!;
      const locationSettings =
        this.locationSettingsByLocationId.get(locationId) || [];
      const zoneSettings = zoneUidsOfLocation
        .map((zoneUid) => this.zoneSettingsByZoneUid.get(zoneUid) || [])
        .flat();

      if (locationSettings.length > 0 && zoneSettings.length > 0) {
        throw new Error(
          `Failed to initialize UserNotificationSettingContainer for user ${userId} in organization ${organizationCode}. Got location settings as well as zone settings.`
        );
      }
    }
  }

  public getOrganizationSettings(): UserNotificationSettingDto[] {
    return this.orgSettings;
  }

  public getAllLocationSettings(): UserNotificationSettingDto[] {
    return [...this.locationSettingsByLocationId.values()].flat();
  }

  public getLocationSettings(locationId: string): UserNotificationSettingDto[] {
    return this.locationSettingsByLocationId.get(locationId) || [];
  }

  public getAllZoneSettings(): UserNotificationSettingDto[] {
    return [...this.zoneSettingsByZoneUid.values()].flat();
  }

  public getZoneSettings(zoneUid: string): UserNotificationSettingDto[] {
    return this.zoneSettingsByZoneUid.get(zoneUid) || [];
  }

  public hasOrganizationSettings(): boolean {
    return (
      !!this.orgSettings.length ||
      (!this.hasAnyLocationSettings() && !this.hasAnyZoneSettings())
    );
  }

  public hasAnyLocationSettings(): boolean {
    return !!this.locationSettingsByLocationId.size;
  }

  public hasZoneSettingsForLocation(locationId: string): boolean {
    return this.zoneUidsByLocationId[locationId]!.some((zoneUid) =>
      this.hasZoneSettings(zoneUid)
    );
  }

  public hasZoneSettings(zoneUid: string): boolean {
    return (this.zoneSettingsByZoneUid.get(zoneUid) || []).length > 0;
  }

  public hasAnyZoneSettings(): boolean {
    return !!this.zoneSettingsByZoneUid.size;
  }

  private initOrgSetting(setting: UserNotificationSettingDto) {
    if (
      this.orgSettings.some(
        (s) =>
          s.target === setting.target &&
          s.notificationType === setting.notificationType
      )
    ) {
      throw new Error(
        `Failed to initialize UserNotificationSettingContainer for user ${this.userId} in organization ${this.organizationCode}. Duplicate entry for target ${setting.target} and notification type ${setting.notificationType}`
      );
    }

    this.orgSettings.push(setting);
  }

  private initLocationSetting(setting: UserNotificationSettingDto) {
    if (this.locationSettingsByLocationId.has(setting.locationId!)) {
      const locationSettings = this.locationSettingsByLocationId.get(
        setting.locationId!
      )!;
      if (
        locationSettings.some(
          (s) =>
            s.target === setting.target &&
            s.notificationType === setting.notificationType
        )
      ) {
        throw new Error(
          `Failed to initialize UserNotificationSettingContainer for user ${this.userId} in organization ${this.organizationCode}. Duplicate entry for target ${setting.target}, notification type ${setting.notificationType} and location id ${setting.locationId}`
        );
      }
      locationSettings.push(setting);
    } else {
      this.locationSettingsByLocationId.set(setting.locationId!, [setting]);
    }
  }

  private initZoneSetting(setting: UserNotificationSettingDto) {
    if (this.zoneSettingsByZoneUid.has(setting.zoneUid!)) {
      const zoneSettings = this.zoneSettingsByZoneUid.get(setting.zoneUid!)!;
      if (
        zoneSettings.some(
          (s) =>
            s.target === setting.target &&
            s.notificationType === setting.notificationType
        )
      ) {
        throw new Error(
          `Failed to initialize UserNotificationSettingContainer for user ${this.userId} in organization ${this.organizationCode}. Duplicate entry for target ${setting.target}, notification type ${setting.notificationType} and zone uid ${setting.zoneUid}`
        );
      }
      zoneSettings.push(setting);
    } else {
      this.zoneSettingsByZoneUid.set(setting.zoneUid!, [setting]);
    }
  }

  public expandToFacilities(): UserNotificationSettingOperationResult {
    const added: UserNotificationSettingDto[] = [];
    const removed: UserNotificationSettingDto[] = [];

    for (const setting of this.orgSettings) {
      for (const locationId of this.locationIds) {
        const locationSetting = {
          ...setting,
          uid: v4(),
          organizationCode: null,
          locationId,
        };
        added.push(locationSetting);
      }
      removed.push(setting);
    }

    return {
      added,
      removed,
    };
  }

  public collapseToGlobal(): UserNotificationSettingOperationResult {
    const removedZone: UserNotificationSettingDto[] = [];
    const removedLocation: UserNotificationSettingDto[] = [];
    const added: UserNotificationSettingDto[] = [];

    const zoneSettings = [...this.zoneSettingsByZoneUid.values()].flat();

    for (const zoneSetting of zoneSettings) {
      removedZone.push(zoneSetting);
    }

    const locationSettings = [
      ...this.locationSettingsByLocationId.values(),
    ].flat();

    for (const locationSetting of locationSettings) {
      removedLocation.push(locationSetting);
    }

    if (this.canCollapseToGlobal()) {
      const expanedLocationSettings: UserNotificationSettingDto[] = [];

      for (const locationId of this.locationIds) {
        const zoneUidsOfLocation = this.zoneUidsByLocationId[locationId]!;
        const zoneSettings = zoneUidsOfLocation
          .map((zoneUid) => this.zoneSettingsByZoneUid.get(zoneUid) || [])
          .flat();

        const groupedByNotificationType = groupBy(
          zoneSettings,
          (s) => s.notificationType
        );

        for (const settingsOfNotificationType of Object.values(
          groupedByNotificationType
        )) {
          const groupedByTarget = groupBy(
            settingsOfNotificationType,
            (s) => s.target
          );

          for (const settingsOfTarget of Object.values(groupedByTarget)) {
            const firstSetting = settingsOfTarget[0]!;
            expanedLocationSettings.push({
              uid: v4(),
              locationId,
              notificationType: firstSetting.notificationType,
              target: firstSetting.target,
              organizationCode: null,
              zoneUid: null,
              userId: this.userId,
            });
          }
        }
      }

      const groupedByNotificationType = groupBy(
        [...locationSettings, ...expanedLocationSettings],
        (s) => s.notificationType
      );
      for (const settingsOfNotificationType of Object.values(
        groupedByNotificationType
      )) {
        const groupedByTarget = groupBy(
          settingsOfNotificationType,
          (s) => s.target
        );
        for (const settingsOfTarget of Object.values(groupedByTarget)) {
          const firstSetting = settingsOfTarget[0]!;
          added.push({
            uid: v4(),
            locationId: null,
            notificationType: firstSetting.notificationType,
            target: firstSetting.target,
            organizationCode: this.organizationCode,
            zoneUid: null,
            userId: this.userId,
          });
        }
      }
    }

    return {
      added,
      removed: [...removedZone, ...removedLocation],
    };
  }

  public expandToRooms(
    locationId: string
  ): UserNotificationSettingOperationResult {
    const removed: UserNotificationSettingDto[] = [];
    const added: UserNotificationSettingDto[] = [];
    const locationSettings =
      this.locationSettingsByLocationId.get(locationId) || [];

    for (const setting of locationSettings) {
      for (const zoneUid of this.zoneUidsByLocationId[locationId]!) {
        added.push({
          ...setting,
          uid: v4(),
          locationId: null,
          zoneUid,
        });
      }
      removed.push(setting);
    }

    return {
      added,
      removed,
    };
  }

  public collapseToFacility(
    locationId: string
  ): UserNotificationSettingOperationResult {
    const removed: UserNotificationSettingDto[] = [];
    const added: UserNotificationSettingDto[] = [];

    const zoneUids = this.zoneUidsByLocationId[locationId]!;

    for (const zoneUid of zoneUids) {
      const zoneSettings = this.zoneSettingsByZoneUid.get(zoneUid) || [];
      removed.push(...zoneSettings);
    }

    if (this.canCollapseToFacility(locationId)) {
      const zoneSettings = zoneUids
        .map((zoneUid) => this.zoneSettingsByZoneUid.get(zoneUid) || [])
        .flat();
      const groupedByNotificationType = groupBy(
        zoneSettings,
        (s) => s.notificationType
      );
      for (const settingsOfNotificationType of Object.values(
        groupedByNotificationType
      )) {
        const groupedByTarget = groupBy(
          settingsOfNotificationType,
          (s) => s.target
        );
        for (const settingsOfTarget of Object.values(groupedByTarget)) {
          const firstSetting = settingsOfTarget[0]!;
          added.push({
            uid: v4(),
            locationId,
            notificationType: firstSetting.notificationType,
            target: firstSetting.target,
            organizationCode: null,
            zoneUid: null,
            userId: this.userId,
          });
        }
      }
    }

    return {
      added,
      removed,
    };
  }

  public canCollapseToFacility(locationId: string): boolean {
    const zoneUids = this.zoneUidsByLocationId[locationId]!;
    const zoneUidsSet = new Set(zoneUids);

    const settingsOfZones = zoneUids
      .map((z) => this.zoneSettingsByZoneUid.get(z) || [])
      .flat();

    const groupedByNotificationType = groupBy(
      settingsOfZones,
      (s) => s.notificationType
    );

    for (const settingsOfNotificationType of Object.values(
      groupedByNotificationType
    )) {
      const groupedByTarget = groupBy(
        settingsOfNotificationType,
        (s) => s.target
      );
      for (const settingsOfTarget of Object.values(groupedByTarget)) {
        const zoneUidsOfSettings = new Set(
          settingsOfTarget.map((s) => s.zoneUid)
        );

        if (symmetricDifference(zoneUidsOfSettings, zoneUidsSet).size !== 0) {
          return false;
        }
      }
    }

    return true;
  }

  public canCollapseToGlobal(): boolean {
    const expandedSettings: UserNotificationSettingDto[] = [];
    for (const locationId of this.locationIds) {
      if (!this.canCollapseToFacility(locationId)) {
        return false;
      }
      const zoneUidsOfLocation = this.zoneUidsByLocationId[locationId]!;
      const zoneSettings = zoneUidsOfLocation
        .map((zoneUid) => this.zoneSettingsByZoneUid.get(zoneUid) || [])
        .flat();

      const groupedByNotificationType = groupBy(
        zoneSettings,
        (s) => s.notificationType
      );

      for (const settingsOfNotificationType of Object.values(
        groupedByNotificationType
      )) {
        const groupedByTarget = groupBy(
          settingsOfNotificationType,
          (s) => s.target
        );

        for (const settingsOfTarget of Object.values(groupedByTarget)) {
          const firstSetting = settingsOfTarget[0]!;
          expandedSettings.push({
            uid: v4(),
            locationId,
            notificationType: firstSetting.notificationType,
            target: firstSetting.target,
            organizationCode: null,
            zoneUid: null,
            userId: this.userId,
          });
        }
      }
    }
    const settingsOfLocations = [
      ...this.locationSettingsByLocationId.values(),
      ...expandedSettings,
    ].flat();

    const locationIdSet = new Set(this.locationIds);

    const groupedByNotificationType = groupBy(
      settingsOfLocations,
      (s) => s.notificationType
    );

    for (const settingsOfNotificationType of Object.values(
      groupedByNotificationType
    )) {
      const groupedByTarget = groupBy(
        settingsOfNotificationType,
        (s) => s.target
      );
      for (const settingsOfTarget of Object.values(groupedByTarget)) {
        const locationIdsOfSettings = new Set(
          settingsOfTarget.map((s) => s.locationId)
        );

        if (
          symmetricDifference(locationIdsOfSettings, locationIdSet).size !== 0
        ) {
          return false;
        }
      }
    }

    return true;
  }
}

export interface UserNotificationSettingOperationResult {
  added: UserNotificationSettingDto[];
  removed: UserNotificationSettingDto[];
}
