import { Auth } from 'aws-amplify';
import { NonAuthForm } from 'components/auth/NonAuthForm';
import { NonAuthLayout } from 'components/auth/NonAuthLayout';
import { Button } from 'components/common/Button/Button';
import { Input } from 'components/common/Input/Input';
import { Link } from 'components/common/Link';
import { useURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import isNil from 'lodash.isnil';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AWS_API_CODE } from 'shared/constants/code';
import { ERoutePath, PATH_PATTERNS } from 'shared/constants/url';

type TForgotPasswordForm = {
  email: string;
};

export const ForgotPasswordPage: FC = () => {
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { navigateToResetPassword } = useURL();
  const { handleSubmit, register } = useForm<TForgotPasswordForm>();

  const onSubmit = handleSubmit(async (values) => {
    setIsSubmitting(true);

    Auth.forgotPassword(values.email)
      .then(() => {
        setError('');
        navigateToResetPassword(values.email);
      })
      .catch((error) => {
        const errorMessage =
          !isNil(error.message) && error.message.length > 0
            ? error.message
            : '';
        if ((error as any)?.code === AWS_API_CODE.user_not_found_exception) {
          setError(`There was error during forgot password: ${errorMessage}`);
        } else {
          setError('Server is unreachable: ' + errorMessage);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  });

  return (
    <NonAuthLayout>
      <NonAuthForm
        instructionHeader={
          <>
            <h1 className="text-3xl leading-none">Forgot</h1>
            <h1 className="text-3xl font-bold leading-none">password</h1>
          </>
        }
        instructions={
          <p className="text-gray-500 leading-7">
            Enter your registered email below to receive password reset
            instructions. Please note that if you have never previously verified
            your account you will not receive an email. If you need a new
            verification link, please reach out to an organization
            administrator, or email support@neatleaf.com.
          </p>
        }
        footer={
          <>
            <p>Remember password?</p>
            <Link
              to={PATH_PATTERNS[ERoutePath.LOGIN]}
              text="Login"
              className="text-base"
            />
          </>
        }
      >
        <form role="form" onSubmit={onSubmit}>
          <div className="flex flex-col gap-3">
            <Input
              placeholder="Email"
              {...register('email', { required: true })}
            />

            <Button
              disabled={isSubmitting}
              className="h-12 w-full text-base"
              type="submit"
            >
              Send code
            </Button>

            {error && <p>{error}</p>}
          </div>
        </form>
      </NonAuthForm>
    </NonAuthLayout>
  );
};
