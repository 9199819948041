import backgroundMobile from 'assets/image/background-mobile.jpg';
import background from 'assets/image/background.png';
import { useScreenSize } from 'hooks/useScreenSize';
import { NeatleafLogo } from 'icons/NeatleafLogo';
import { ReactNode, useRef } from 'react';
import { cn } from 'shared/utils/cn';

interface INonAuthLayoutProps {
  /**
   * Extend form element styling
   */
  formClassName?: string;

  children?: ReactNode;
}

/**
 * The NonAuthLayout component.
 *  - will be used as a container of Login, ForgotPassword, ResetPassword pages.
 *
 * @param {INonAuthLayoutProps} props - The component props.
 * @returns {JSX.Element} - The rendered component.
 */
export const NonAuthLayout = ({
  children,
  formClassName,
}: INonAuthLayoutProps) => {
  const formContainerRef = useRef<HTMLInputElement>(null);
  const { isMobile, isPortrait } = useScreenSize();

  return (
    <div
      className={cn(
        'relative flex h-screen w-full',
        isMobile && isPortrait && 'min-h-[820px] min-w-[auto]'
      )}
    >
      <div className="relative h-full w-full flex-1">
        <div className="h-full w-full overflow-hidden">
          <img
            className={cn(
              'h-full w-full object-cover',
              isMobile && isPortrait && 'w-auto'
            )}
            alt="background"
            src={isMobile ? backgroundMobile : background}
          />
        </div>

        <NeatleafLogo
          className={cn(
            'absolute left-[40px] top-[100px] h-[40px] w-[200px]',
            'lg:left-[100px] lg:w-[220px]',
            isMobile && 'left-[40px] top-[60px] h-[30px] w-[167px]'
          )}
          textClassName="fill-white"
        />
        <div
          className={cn(
            'absolute left-[40px] top-[350px] w-[calc(520/1600*100vw)] text-white',
            'lg:left-[100px]',
            isMobile && 'left-[40px] top-[204px] w-[300px]'
          )}
        >
          <h2
            className={cn(
              'text-[44px] font-bold leading-tight text-white',
              'md:text-[60px] lg:text-[70px] xl:text-[calc(70/1600*100vw)]'
            )}
          >
            Let&rsquo;s grow together
          </h2>
        </div>
        {!isMobile && (
          <div className="absolute bottom-[50px] right-[100px] text-base text-white">
            {`© ${new Date().getFullYear()} Neatleaf.`}
          </div>
        )}
      </div>
      <div
        ref={formContainerRef}
        className={cn(
          'box-border w-[600px] bg-white p-[100px]',
          isMobile && [
            'rounded-t-[30px] p-[40px]',
            isPortrait &&
              'absolute bottom-0 left-0 right-0 min-h-[492px] w-full ',
            !isPortrait && 'flex-1 overflow-auto',
          ],
          formClassName
        )}
      >
        {children}
      </div>
    </div>
  );
};
