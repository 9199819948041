import { imageLabelWithIcons } from 'components/image_label/constants';
import groupBy from 'lodash.groupby';
import { TLabelEnumeration } from 'shared/interfaces/enumeration';
import {
  EImageLabelCategory,
  IMAGE_LABEL_CATEGORY_TITLES,
  TImageLabelWithIcon,
  TLabelByCategory,
  TSelectedLabelCode,
} from 'shared/interfaces/image';

const getCategoryImageLabel = (category: string): TImageLabelWithIcon => {
  return (
    imageLabelWithIcons.find((icon) => icon.category === category) ??
    imageLabelWithIcons.find(
      (icon) => icon.category === EImageLabelCategory.OTHER
    )!
  );
};

export const getImageLabelDetails = (labels: TLabelByCategory[]): string[] => {
  const labelsByCategory = groupBy(labels, 'category');
  const result: string[] = [];
  Object.entries(labelsByCategory).forEach(([category, labels]) => {
    const categoryTitle = getCategoryImageLabel(category).title;
    labels.forEach((label) => {
      result.push(
        `${categoryTitle}: ${label.description} (${label.labelCount})`
      );
    });
  });
  return result;
};

export const checkIfLabelIsSelected = (
  label: TLabelByCategory,
  selectedLabelCode: TSelectedLabelCode
) => [label.category, label.code].includes(selectedLabelCode);

export const getUnselectedButtonLabel = (
  category: EImageLabelCategory,
  enumerations: TLabelEnumeration[]
) => {
  const categoryLabel = IMAGE_LABEL_CATEGORY_TITLES[category];
  if (enumerations.length !== 1) {
    return categoryLabel;
  }
  return `${categoryLabel}:${enumerations[0]!.description}`;
};

export const getLabelCountsByLabelCode = (labels: TLabelByCategory[]) => {
  return labels.reduce((labelCountsByCode, label) => {
    labelCountsByCode[label.code] =
      (labelCountsByCode[label.code] ?? 0) + (label.labelCount ?? 0);

    return labelCountsByCode;
  }, {} as Dictionary<number>);
};

export const getLabelCodeWithMaxLabelCounts = (labels: TLabelByCategory[]) => {
  const labelCountsByCode = getLabelCountsByLabelCode(labels);

  return Object.entries(labelCountsByCode).reduce(
    (maxLabelCode, [currentCode, labelCount]) => {
      if (!maxLabelCode) {
        return currentCode;
      }

      return labelCount > labelCountsByCode[maxLabelCode]!
        ? currentCode
        : maxLabelCode;
    },
    null as string | null
  );
};
