import { useAuth } from 'contexts/AuthProvider';
import {
  useSettingsURL,
  useZoneDetailsPageURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import { toZonedTime } from 'date-fns-tz';
import { useZones } from 'hooks/useZones';
import { useMemo } from 'react';
import { DEFAULT_TIME_ZONE } from 'shared/constants/general';
import {
  TUserDefaultZoneByOrganization,
  UserWithMetadata,
} from 'shared/interfaces/user';
import { TZone } from 'shared/interfaces/zone';
import { userfromZonedTime } from 'shared/utils/growthCycle';
import { useFeatureFlags } from './useFeatureFlags';

export const useCurrentZone = () => {
  const { user } = useAuth();
  const { debugDateTime, regularlyUpdatedDebugDateTime } = useFeatureFlags();
  const { organizationCode, zoneId } = useZoneDetailsPageURL();
  const { organization } = useSettingsURL();
  const { zones } = useZones();
  const orgCode = organizationCode ?? organization;

  const userDefaultZoneUidByOrganizationCode = useMemo(() => {
    const defaultZones =
      (user as UserWithMetadata)?.metadata?.default_zone ?? [];

    return defaultZones.reduce(
      (defaultZoneByOrganization, zone) => ({
        ...defaultZoneByOrganization,
        [zone.organization]: zone.zone_uid,
      }),
      {} as TUserDefaultZoneByOrganization
    );
  }, [user]);

  const currentZone = useMemo(() => {
    let zone: TZone | undefined;
    if (zoneId === undefined || zones.length === 0) {
      const zoneUid = orgCode && userDefaultZoneUidByOrganizationCode[orgCode];
      zone = zones.find((zone) => zone.uid === zoneUid) ?? zones[0];
    } else {
      zone = zones.find((zone) => zone.id === Number(zoneId));
      if (!zone) throw new Error(`Zone with id ${zoneId} not found`);
    }

    return zone;
  }, [zoneId, zones, userDefaultZoneUidByOrganizationCode, orgCode]);

  const zoneTimeZone = currentZone?.timeZone || DEFAULT_TIME_ZONE;

  const currentTimeInCurrentZone = useMemo(
    () => toZonedTime(userfromZonedTime(debugDateTime), zoneTimeZone),
    [debugDateTime, zoneTimeZone]
  );

  const regularlyUpdatedCurrentTimeInCurrentZone = useMemo(
    () =>
      toZonedTime(
        userfromZonedTime(regularlyUpdatedDebugDateTime),
        zoneTimeZone
      ),
    [regularlyUpdatedDebugDateTime, zoneTimeZone]
  );

  return {
    currentZone,
    zoneTimeZone,
    currentTimeInCurrentZone,
    regularlyUpdatedCurrentTimeInCurrentZone,
  };
};
