import { useTermsAndConditionsQuery } from 'api/terms';
import { NeatleafLogo } from 'icons/NeatleafLogo';
import ReactMarkdown from 'react-markdown';

export const Terms = () => {
  const { data } = useTermsAndConditionsQuery();
  return (
    <main className="prose min-w-full px-4 py-8 md:px-12 md:py-16">
      <div className="mb-16">
        <a href="/">
          <NeatleafLogo className="w-48" />
        </a>
      </div>
      <ReactMarkdown>{data}</ReactMarkdown>
    </main>
  );
};
