import { useQuery } from '@tanstack/react-query';
import { useApi } from 'contexts/ApiProvider';
import { useAppConfig } from 'contexts/AppConfigProvider';

export const useTermsAndConditionsQuery = () => {
  const { appConfig } = useAppConfig();
  const { httpClient } = useApi(false);
  const url = new URL('termsandconditions.md', appConfig.assetsUrl).toString();

  return useQuery({
    queryKey: ['terms-and-conditions'],
    queryFn: () => httpClient.get(url).text(),
  });
};
