import {
  areIntervalsOverlapping,
  compareAsc,
  hoursToMilliseconds,
  isWithinInterval,
} from 'date-fns';
import HighchartsReact, {
  HighchartsReactProps,
  HighchartsReactRefObject,
} from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import * as Highcharts from 'highcharts/highstock';
// import HighchartsA11y from 'highcharts/modules/accessibility';
import HighchartsAnnotations from 'highcharts/modules/annotations-advanced';
import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsOfflineExporting from 'highcharts/modules/offline-exporting';
import HighchartsStockTools from 'highcharts/modules/stock-tools';
import merge from 'lodash.merge';
import memoize from 'memoizee';
import {
  ComponentPropsWithoutRef,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { TGrowthCycle } from 'shared/interfaces/growthCycle';
import {
  IDayRange,
  MeasurementTypeConfig,
} from 'shared/interfaces/measurement';
import { formatDateEEEMMMMdhmmaa, getMiddleTimestamp } from 'shared/utils/date';
import { getOptimalRange } from 'shared/utils/growthCycle';
import { CenteredLoader } from '../CenteredLoader';

if (typeof Highcharts === 'object') {
  HighchartsMore(Highcharts);
  HighchartsBoost(Highcharts);
  HighchartsAnnotations(Highcharts);
  HighchartsStockTools(Highcharts);
  HighchartsExporting(Highcharts);
  HighchartsOfflineExporting(Highcharts);
  // Disable Accessibility module until the performance issues are overcomed.
  // Waiting for help from HC team - https://www.highcharts.com/forum/viewtopic.php?p=194090
  // HighchartsA11y(Highcharts);
}

export const Y_AXIS_BASE_OPTIONS: Highcharts.YAxisOptions = {
  labels: { distance: 5 },
  title: {
    align: 'high',
    offset: 0,
    reserveSpace: false,
    rotation: 0,
    text: '',
    textAlign: 'left',
    y: -15,
    x: -5,
  },
};

const BASE_OPTIONS: HighchartsReactProps['options'] = {
  accessibility: { enabled: false },
  boost: {
    enabled: true,
    pixelRatio: 0,
    seriesThreshold: Infinity,
  },
  chart: {
    animation: false,
    styledMode: true,
  },
  credits: { enabled: false },
  exporting: {
    enabled: false,
  },
  legend: { enabled: false },
  navigation: {
    annotationsOptions: {
      draggable: '',
      typeOptions: {
        crosshairX: { enabled: false },
        crosshairY: { enabled: false },
        label: { enabled: false },
      },
    },
  },
  navigator: { enabled: false },
  plotOptions: {
    series: {
      allowPointSelect: false,
      animation: false,
      boostThreshold: 1000,
      enableMouseTracking: false,
      showInLegend: false,
      softThreshold: true,
      states: {
        hover: { enabled: false },
      },
    },
    line: {
      className: '!stroke-neutral-900 !fill-neutral-900',
      gapSize: hoursToMilliseconds(25),
      gapUnit: 'value',
      marker: {
        enabledThreshold: 2,
        symbol: 'circle',
        radius: 3,
      },
    },
  },
  stockTools: {
    gui: {
      enabled: false,
    },
  },
  time: {
    useUTC: false,
  },
  title: { text: '' },
  tooltip: {
    animation: false,
    enabled: false,
    outside: true,
    padding: 5,
    shadow: false,
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      hour: '%l %p',
      day: '%a %e',
      month: '%b %e',
    },
  },
  yAxis: Y_AXIS_BASE_OPTIONS,
};

const SERIES_ZONES = {
  zoneOk: {
    className: 'zone-ok',
    color: '#0A0A0A',
    fillColor: '#0A0A0A',
  },
  zoneOutOfRange: {
    className: 'zone-out-of-range',
    color: '#DB5151',
    fillColor: '#DB5151',
  },
} as Record<'zoneOk' | 'zoneOutOfRange', Highcharts.SeriesZonesOptionsObject>;

const getMatchingRange = memoize(function getMatchingRange(
  ranges: [number, number, number, number][],
  timestamp: number,
  value: number
) {
  return ranges.find(function findRange([start, min, end, max]) {
    return (
      isWithinInterval(timestamp!, { start, end }) &&
      (value <= min || value >= max)
    );
  });
});

const useDownloadChart = (
  chart: Maybe<Highcharts.Chart>,
  filename: Maybe<string>
) => {
  const download = useCallback(() => {
    if (chart && filename) {
      for (const type of [
        'image/png',
        'image/svg+xml',
      ] as Highcharts.ExportingMimeTypeValue[]) {
        chart.exportChartLocal(
          {
            allowHTML: true,
            scale: 2,
            sourceWidth: 1200,
            sourceHeight: 800,
            filename,
            type,
          },
          { boost: { enabled: false } }
        );
      }
    }
  }, [chart, filename]);

  useHotkeys(['shift+alt+d', 'shift+option+d'], download, [download]);
};

// Liang-Barsky algorithm implementation
const computeIntersections = memoize(function computeIntersections(
  rect: number[],
  line: number[]
): TPosition[] {
  const [xMin, yMin, xMax, yMax] = rect;
  const [x0, y0, x1, y1] = line;

  const dx = x1! - x0!;
  const dy = y1! - y0!;

  let t0 = 0.0;
  let t1 = 1.0;

  const p = [-dx, dx, -dy, dy];
  const q = [x0! - xMin!, xMax! - x0!, y0! - yMin!, yMax! - y0!];

  for (let i = 0; i < 4; i++) {
    const pi = p[i]!;
    const qi = q[i]!;

    if (pi === 0) {
      if (qi < 0) {
        // Line is parallel to and outside the rectangle
        return [];
      }
    } else {
      const t = qi / pi;
      if (pi < 0) {
        if (t > t1) {
          return [];
        } else if (t > t0) {
          t0 = t;
        }
      } else {
        if (t < t0) {
          return [];
        } else if (t < t1) {
          t1 = t;
        }
      }
    }
  }

  const points: TPosition[] = [];
  if (t0 > 0) {
    points.push({ x: x0! + t0 * dx, y: y0! + t0 * dy });
  }
  if (t1 < 1) {
    points.push({ x: x0! + t1 * dx, y: y0! + t1 * dy });
  }

  return points;
});

export function getPlotBands(ranges: IDayRange[]) {
  return ranges.reduce(function assemblePlotBands(plotBands, range) {
    if (range.nightStartTime && range.nightEndTime) {
      // Only night ranges get plotted
      plotBands.push({
        from: range.nightStartTime.valueOf(),
        to: range.nightEndTime.valueOf(),
      });
    }

    return plotBands;
  }, [] as Highcharts.XAxisPlotBandsOptions[]);
}

export const getPolygonSeriesAndZones = memoize(
  function getPolygonSeriesAndZones({
    signal,
    growthCycle,
    ranges,
    start,
    end,
  }: {
    signal: MeasurementTypeConfig;
    growthCycle: TGrowthCycle;
    ranges: IDayRange[];
    start: Date | number;
    end: Date | number;
  }) {
    const polygonSeries = [] as Highcharts.SeriesPolygonOptions[];
    const warningRanges = [] as [number, number, number, number][];
    const getPolygonSeriesName = (
      type: string,
      startTime: Date,
      endTime: Date,
      low: number,
      high: number
    ) =>
      `Optimal ${type} range from ${low + signal.unit} to ${high + signal.unit} between ${formatDateEEEMMMMdhmmaa(startTime)} and ${formatDateEEEMMMMdhmmaa(endTime)}`;

    for (const range of ranges) {
      const { dayEndTime, dayStartTime, nightEndTime, nightStartTime } = range;

      // DAY
      const dayOptimalMid = getMiddleTimestamp(dayStartTime, dayEndTime);
      const dayOptimalRange = getOptimalRange(
        dayOptimalMid,
        signal.statisticsKey,
        range,
        growthCycle
      );

      if (dayOptimalRange) {
        // They just need to intersect
        if (
          areIntervalsOverlapping(
            { start, end },
            { start: dayStartTime, end: dayEndTime },
            { inclusive: true }
          )
        ) {
          const low = Math.min(
            dayOptimalRange.optimal_range.max,
            dayOptimalRange.optimal_range.min
          );
          const high = Math.max(
            dayOptimalRange.optimal_range.max,
            dayOptimalRange.optimal_range.min
          );
          // Optimal
          polygonSeries.push({
            id: `${signal.type}-${dayStartTime.valueOf()}-${dayEndTime.valueOf()}`,
            type: 'polygon',
            name: getPolygonSeriesName(
              'day',
              dayStartTime,
              dayEndTime,
              low,
              high
            ),
            className: 'optimal-range',
            data: [
              [dayStartTime.valueOf(), high],
              [dayEndTime.valueOf(), high],
              [dayEndTime.valueOf(), low],
              [dayStartTime.valueOf(), low],
            ],
          });
        }

        if (
          isWithinInterval(dayOptimalMid, {
            start,
            end,
          })
        ) {
          // Warning
          warningRanges.push([
            dayStartTime.valueOf(),
            Math.min(
              dayOptimalRange.warning_range.max,
              dayOptimalRange.warning_range.min
            ),
            dayEndTime.valueOf(),
            Math.max(
              dayOptimalRange.warning_range.max,
              dayOptimalRange.warning_range.min
            ),
          ]);
        }
      }

      if (nightStartTime && nightEndTime) {
        // NIGHT
        const nightOptimalMid = getMiddleTimestamp(
          nightStartTime,
          nightEndTime
        );
        const nightOptimalRange = getOptimalRange(
          nightOptimalMid,
          signal.statisticsKey,
          range,
          growthCycle
        );

        if (nightOptimalRange) {
          // They just need to intersect
          if (
            areIntervalsOverlapping(
              { start, end },
              { start: nightStartTime, end: nightEndTime },
              { inclusive: false }
            )
          ) {
            const low = Math.min(
              nightOptimalRange.optimal_range.max,
              nightOptimalRange.optimal_range.min
            );
            const high = Math.max(
              nightOptimalRange.optimal_range.max,
              nightOptimalRange.optimal_range.min
            );
            // Optimal
            polygonSeries.push({
              id: `${signal.type}-${nightStartTime.valueOf()}-${nightEndTime.valueOf()}`,
              type: 'polygon',
              name: getPolygonSeriesName(
                'night',
                nightStartTime,
                nightEndTime,
                low,
                high
              ),
              className: 'optimal-range',
              data: [
                [nightStartTime.valueOf(), high],
                [nightEndTime.valueOf(), high],
                [nightEndTime.valueOf(), low],
                [nightStartTime.valueOf(), low],
              ],
            });
          }

          if (
            isWithinInterval(nightOptimalMid, {
              start,
              end,
            })
          ) {
            // Warning,
            warningRanges.push([
              nightStartTime.valueOf(),
              Math.min(
                nightOptimalRange.warning_range.max,
                nightOptimalRange.warning_range.min
              ),
              nightEndTime.valueOf(),
              Math.max(
                nightOptimalRange.warning_range.max,
                nightOptimalRange.warning_range.min
              ),
            ]);
          }
        }
      }
    }

    return {
      polygonSeries,
      warningRanges,
    };
  }
);

export const getZonedPointsAndZones = ({
  data,
  warningRanges,
}: {
  data: [number, number][];
  warningRanges: [number, number, number, number][];
}) => {
  /**
   * Add new points that represent the intersections between the warning range area
   * and the vectors that include the out-of-range values.
   */
  const points = data
    .reduce(
      function addIntersectionPoints(points = [], [timestamp, value], index) {
        points.push([timestamp, value]);

        const currentWarningRange = getMatchingRange(
          warningRanges,
          timestamp!,
          value!
        );
        if (currentWarningRange) {
          const intersections: TPosition[] = [];

          const previous = data[index - 1]!;
          if (previous) {
            intersections.push(
              ...computeIntersections(currentWarningRange, [
                previous[0],
                previous[1],
                timestamp,
                value,
              ])
            );
          }

          const next = data[index + 1]!;
          if (next) {
            intersections.push(
              ...computeIntersections(currentWarningRange, [
                timestamp,
                value,
                next[0],
                next[1],
              ])
            );
          }

          for (const { x, y } of intersections) {
            points.push([x, y]);
          }
        }

        return points;
      },
      [] as [number, number][]
    )
    .toSorted(function sortPoints(a, b) {
      return compareAsc(a[0]!, b[0]!);
    });

  /**
   * The zones represent the out-of-range segments and points.
   */
  const zones = points.reduce(
    function assembleZones(zones = [], [timestamp, value], index) {
      const currentOutOfRange = getMatchingRange(
        warningRanges,
        timestamp!,
        value!
      );
      /**
       * Check the previous point
       */
      const previous = points[index - 1];
      const previousOutOfRange =
        previous && getMatchingRange(warningRanges, previous[0]!, previous[1]!);
      if (!previousOutOfRange && currentOutOfRange) {
        zones.push({
          ...SERIES_ZONES.zoneOk,
          value: timestamp,
        });
      }

      /**
       * Check the next point
       */
      const next = points[index + 1];
      const nextOutOfRange =
        next && getMatchingRange(warningRanges, next[0]!, next[1]!);
      if (currentOutOfRange && !nextOutOfRange) {
        zones.push({
          ...SERIES_ZONES.zoneOutOfRange,
          value: timestamp,
        });
      }

      if (!next) {
        zones.push(SERIES_ZONES.zoneOk);
      }

      return zones;
    },
    [] as Highcharts.PlotLineOptions['zones']
  );

  return { points, zones };
};

export interface ChartProps extends ComponentPropsWithoutRef<'div'> {
  options: HighchartsReactProps['options'];
  loading?: boolean;
  immutableOptions?: boolean;
  onInitialization?: (chart: Maybe<Highcharts.Chart>) => void;
}

export const Chart = memo(
  forwardRef<HTMLDivElement, ChartProps>(function Chart(
    { options, loading, immutableOptions = false, onInitialization, ...props },
    ref
  ) {
    const chartRef = useRef<HighchartsReactRefObject>(null);
    const mergedOptions = useMemo(
      () => (!immutableOptions ? merge({}, BASE_OPTIONS, options) : options),
      [immutableOptions, options]
    );

    useEffect(() => {
      onInitialization?.(chartRef.current?.chart);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartRef.current]);

    useDownloadChart(chartRef.current?.chart, props['aria-label']);

    console.log(`### ${props['aria-label']} ###`);

    return (
      <>
        <HighchartsReact
          ref={chartRef}
          containerProps={{
            ref,
            role: 'figure',
            ...props,
          }}
          highcharts={Highcharts}
          options={mergedOptions}
        />
        {loading && <CenteredLoader />}
      </>
    );
  })
);

Chart.displayName = 'Chart';
