import { TOrganization } from 'shared/interfaces/organization';

interface GetDefaultOrganizationParams {
  organizations: TOrganization[];
  defaultOrganizationCode: string | undefined;
}

export const getDefaultOrganization = ({
  organizations,
  defaultOrganizationCode,
}: GetDefaultOrganizationParams) => {
  return (
    organizations.find(
      (organization) => organization.code === defaultOrganizationCode
    ) ?? organizations[0]
  );
};
