import {
  useGetImageFeedMeasurementRuns,
  useGetMeasurementsOnGridByRunIdAndSensorTypeId,
} from 'api/measurement';
import {
  useImageFeedURL,
  useZoneDetailsPageURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import isNil from 'lodash.isnil';
import { useMemo } from 'react';
import { EImageViewTypes } from 'shared/interfaces/image';
import { findClosest } from 'shared/utils/binarySearch';
import { isBetween } from 'shared/utils/getters';

export const useCurrentImageFeedMeasurements = () => {
  const { zoneTimeZone } = useCurrentZone();
  const {
    zoneId,
    getRangeEndTime,
    getRangeStartTime,
    getMeasurementRunStartTime,
  } = useZoneDetailsPageURL();
  const startTime = getRangeStartTime(zoneTimeZone);
  const endTime = getRangeEndTime(zoneTimeZone);
  const measurementRunStartTime = getMeasurementRunStartTime(zoneTimeZone);
  const { sectionPosition, imageViewType, imageType } = useImageFeedURL();

  const { measurementRuns, loading: measurementRunsLoading } =
    useGetImageFeedMeasurementRuns(zoneTimeZone, startTime, endTime, zoneId);

  const isSingleImage = imageViewType === EImageViewTypes.SINGLE_IMAGE;

  const [measurementRun, measurementRunId] = useMemo(() => {
    if (!measurementRunStartTime)
      return [undefined, measurementRuns[measurementRuns.length - 1]?.id, null];
    let measurementRun;
    if (isSingleImage) {
      measurementRun =
        measurementRuns.find((mr) =>
          isBetween(
            measurementRunStartTime,
            mr.start_time.valueOf(),
            mr.end_time.valueOf()
          )
        ) ?? measurementRuns.at(-1);
    } else {
      const result = findClosest(
        measurementRuns,
        measurementRunStartTime,
        'start_time',
        (startTime) => new Date(startTime).valueOf()
      );
      measurementRun = result.closest;
    }
    const measurementRunId = measurementRun?.id;
    return [measurementRun, measurementRunId];
  }, [measurementRuns, measurementRunStartTime, isSingleImage]);

  const { data: gridViewData } = useGetMeasurementsOnGridByRunIdAndSensorTypeId(
    {
      measurementRun,
      imageType,
      options: {
        skip: !isSingleImage,
      },
    }
  );

  const measurementId = useMemo(() => {
    const rows = gridViewData.numberOfRows;
    if (!isSingleImage || isNil(sectionPosition) || isNil(rows))
      return undefined;
    // index is based on bottom left corner
    const measurementIndex =
      rows - 1 - sectionPosition.y + sectionPosition.x * rows;
    return gridViewData.measurementIds[measurementIndex];
  }, [sectionPosition, isSingleImage, gridViewData]);

  return {
    measurementRunsLoading,
    measurementRunId,
    measurementId,
    measurementRuns,
    measurementRun: measurementRun ?? null,
  };
};
