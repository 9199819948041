import { createContext, ReactNode, useContext, useEffect, useRef } from 'react';

export type QueueItem = {
  skipQueue?: boolean;
  parentId: number;
  url: string;
  resolve: (value: HTMLImageElement) => void;
  reject: (reason?: any) => void;
};

type TImageQueueContextValue = {
  enqueue: (item: QueueItem) => void;
  remove: (url: string) => void;
};

const ImageQueueContext = createContext<TImageQueueContextValue>(undefined!);

const MIN_CONCURRENT_LOADS = 3;
const MAX_CONCURRENT_LOADS = 12;
const SCALE_DECREASE_FACTOR = 12;
const IMAGE_SIZE_INDEX_DECREASE_FACTOR = 3;

const maxConcurrentLoadsByScaleAndImageSizeIndex = (
  scale: number,
  imageSizeIndex: number = 0
) => {
  const maxByScale = Math.round(
    MAX_CONCURRENT_LOADS - SCALE_DECREASE_FACTOR * scale
  );
  const maxByImageScaleIndex = Math.round(
    MAX_CONCURRENT_LOADS - IMAGE_SIZE_INDEX_DECREASE_FACTOR * imageSizeIndex
  );
  return Math.max(
    MIN_CONCURRENT_LOADS,
    Math.min(maxByScale, maxByImageScaleIndex)
  );
};

export const ImageQueueProvider = ({
  imageSizeIndex,
  scale,
  parentId,
  children,
}: {
  imageSizeIndex: number;
  scale: number;
  parentId: number;
  children: ReactNode;
}) => {
  const queueRef = useRef<QueueItem[]>([]);
  const currentLoadsRef = useRef<number>(0);
  const maxConcurrentLoadsRef = useRef<number>(
    maxConcurrentLoadsByScaleAndImageSizeIndex(scale, imageSizeIndex)
  );
  const lastParentIdRef = useRef<number>(0);

  useEffect(() => {
    maxConcurrentLoadsRef.current = maxConcurrentLoadsByScaleAndImageSizeIndex(
      scale,
      imageSizeIndex
    );
  }, [scale, imageSizeIndex]);

  const dequeue = () => {
    if (
      queueRef.current.length === 0 ||
      currentLoadsRef.current >= maxConcurrentLoadsRef.current
    ) {
      return;
    }
    const item = queueRef.current.shift();
    currentLoadsRef.current++;
    if (item) {
      loadImage(item)
        .then(item.resolve)
        .catch(item.reject)
        .finally(() => {
          currentLoadsRef.current--;
          dequeue();
        });
    }
  };

  const enqueue = (item: QueueItem) => {
    if (item.skipQueue) {
      loadImage(item).then(item.resolve).catch(item.reject);
    } else {
      queueRef.current.push(item);
      dequeue();
    }
  };

  const remove = (url: string) => {
    const index = queueRef.current.findIndex((item) => item.url === url);
    if (index !== -1) {
      queueRef.current.splice(index, 1);
    }
  };

  const loadImage = async (item: QueueItem): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = item.url;
    });
  };

  /**
   * Keep only items with the provided parent id
   */
  const keepByParent = (parentId: number) => {
    if (parentId === lastParentIdRef.current) {
      return;
    }
    lastParentIdRef.current = parentId;

    queueRef.current = queueRef.current.filter(
      (item) => item.parentId === parentId
    );
  };

  /**
   * Remove items with the provided parent id
   */
  const removeByParent = (parentId: number) => {
    if (parentId === lastParentIdRef.current) {
      queueRef.current = queueRef.current.filter(
        (item) => item.parentId !== parentId
      );
    }
  };

  /** Only keep items with the current parent id */
  useEffect(() => {
    keepByParent(parentId);
    return () => {
      removeByParent(parentId);
    };
  }, [parentId]);

  return (
    <ImageQueueContext.Provider value={{ enqueue, remove }}>
      {children}
    </ImageQueueContext.Provider>
  );
};

export const useImageQueueProvider = () => {
  return useContext(ImageQueueContext);
};
