import { format, Locale } from 'date-fns';

/**
 * Convert epoch seconds to Date object.
 * @param {number} epochSeconds epoch seconds
 * @returns {Date} date object
 */
export function epochSecondsToDate(epochSeconds: number): Date {
  return new Date(epochSeconds * 1000);
}

/**
 * Returns the day in full month format.
 *
 * @param {Date} date The day.
 * @returns {string} The formatted date string.
 */
export const formatDateInMD = (date: Date) => {
  return format(date, 'M/d');
};

/** */
export const formatDateInMMMd = (date: Date | number): string => {
  return format(date, 'MMM d');
};

/**
 * Returns the date in full human readable day format.
 *
 * @param {Date} date The day.
 * @returns {string} The formatted date string.
 */
export const formatDateInMMMDD = (date: Date | number): string => {
  return format(date, 'MMM dd, yyyy');
};

/**
 * Returns the date in full day format.
 *
 * @param {Date} date The day.
 * @returns {string} The formatted date string.
 */
export const formatDateInFullDay = (date: Date): string => {
  return format(date, 'MM/dd/yyyy');
};

export const roundDateToNearestMinute = (date: Date, minute: number = 10) => {
  const coeff = 1000 * 60 * minute;
  return new Date(Math.round(date.getTime() / coeff) * coeff);
};

/**
 * Returns the date in full day format 'MM-dd HH:mm'.
 *
 * @param {Date} date The day.
 * @param {number} roundToMinute which mininute to round to
 * @returns {string} The formatted date string.
 */
export const formatDateInFullDayTimeRounded = (
  date: Date,
  roundToMinute: number = 10
): string => {
  return format(
    roundDateToNearestMinute(date, roundToMinute),
    'yyyy-MM-dd HH:mm'
  );
};

/**
 * Convert date to time string in full format: MMM dd, yyyy hh:mm aa
 *
 * @param {Date | number} date date to be displayed
 * @returns {string} time string after format
 */
export function formatTimeInFullStyle(date: Date | number) {
  return format(date, 'MMM dd, yyyy hh:mm aa');
}

/**
 * Convert date to time string in format: MMM dd hh:mm aa
 *
 * @param {Date | number} date date to be displayed
 * @returns {string} time string after format
 */
export function formatTimeInSemiFullStyle(date: Date | number) {
  return format(date, 'MMM d h:mm aa');
}

/**
 * Returns wheter a date is valid based on string representation.
 *
 * @param {Maybe<Date>} date Date to test.
 * @returns {boolean} Result of test.
 *
 */
export function isDateValid(date: Maybe<Date>): boolean {
  return (
    date != null && date !== undefined && date.toString() !== 'Invalid Date'
  );
}

const customWeekdays = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

/** Returns day of week string from custom array of choices */
export const formatShortWeekday = (
  date: Date,
  options: { locale?: Locale } | undefined
) => {
  const dayOfWeek = Number(format(date, 'i', { locale: options?.locale }));
  return customWeekdays[dayOfWeek - 1] || '';
};

/**  */
export const formatDateyyyyMMddHHmm = (date: Date | number) => {
  return format(date, 'yyyy-MM-dd HH:mm').replace(' ', 'T');
};

/** */
export const formatDateEEEMMMMdhmmaa = (date: Date | number) => {
  return format(date, 'EEE MMMM d, h:mm aa');
};

/**
 * returns the middle timestamp milliseconds between two dates
 * @param {Date} day0 first date
 * @param {Date} day1 second date
 * @returns {Date} time between two dates
 */
export const getMiddleTimestamp = (day0: Date, day1: Date): Date => {
  return new Date((day0.getTime() + day1.getTime()) / 2);
};
