import { discussionMapper } from 'api/discussion';
import {
  DiscussionBoolExp,
  useGetDiscussionsWithCommentsAndUnreadCommentsCountQuery,
} from 'graphql/generated/react_apollo';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { TDiscussion } from 'shared/interfaces/discussion';
import { TNotification } from 'shared/interfaces/notifications';
import { NEATLEAF_ORGANIZATION_CODE } from 'shared/interfaces/organization';
import { TZone } from 'shared/interfaces/zone';

export const useNotifications = ({
  limit,
  userId,
  zones,
  canViewDraft,
}: {
  limit: number;
  userId: number;
  zones: TZone[];
  canViewDraft: boolean;
}) => {
  const { data, previousData, ...result } =
    useGetDiscussionsWithCommentsAndUnreadCommentsCountQuery({
      variables: {
        user_id: userId,
        zone_uids: zones.map((zone) => zone.uid) || [],
        limit,
        customBoolExp: {
          _or: [
            { status: { _eq: 'published' } },
            {
              _and: [
                { status: { _eq: 'draft' } },
                { type: { _eq: 'insight' } },
                canViewDraft ? undefined : { author_id: { _eq: userId } },
              ].filter(Boolean) as DiscussionBoolExp[],
            },
          ],
        },
      },
      skip: zones.length === 0,
    });

  const nrUnreadComments =
    data?.unread_comments.aggregate?.count ??
    previousData?.unread_comments.aggregate?.count ??
    0;

  const notifications = useMemo(() => {
    return (data?.discussion ?? previousData?.discussion ?? []).reduce(
      (discussions, discussion) => {
        const unreadComments = discussion.comments
          .filter((comment) => !comment.user_comments[0]?.read_at)
          .sort(
            (a, b) =>
              new Date(b.last_updated_at).valueOf() -
              new Date(a.last_updated_at).valueOf()
          );
        const latestComment =
          unreadComments[0] ||
          discussion.comments[discussion.comments.length - 1];

        const zone = zones.find((zone) => zone.id === discussion.zone.id);

        if (latestComment && zone) {
          const title =
            discussion.type === 'insight' &&
            latestComment.author_organization_code ===
              NEATLEAF_ORGANIZATION_CODE
              ? 'Neatleaf Insights'
              : `${latestComment.user.first_name} ${latestComment.user.last_name}`;

          discussions.push({
            id: discussion.uid,
            title,
            date: new Date(latestComment.last_updated_at),
            message: latestComment.content.content,
            read: !!latestComment.user_comments[0]?.read_at,
            footnote:
              unreadComments.length === 0
                ? undefined
                : `${unreadComments.length} unread ${pluralize('comment', unreadComments.length)}`,
            data: {
              discussion: discussionMapper(discussion, zone),
            },
            severity: discussion.comments.find(
              (comment) => comment.first_comment_in_discussion
            )?.content?.severity,
          });
        }

        return discussions;
      },
      [] as TNotification<{
        discussion: TDiscussion;
      }>[]
    );
  }, [data?.discussion, previousData?.discussion, zones]);

  return {
    notifications,
    nrUnreadComments,
    ...result,
  };
};
