import { getScaleColorizedValue } from 'components/heat_map/utils';
import { useMemo } from 'react';
import {
  FIXED_RANGE_HEATMAP_COLORS,
  GRADIENT_SCALE,
  MICROCLIMATES_HEATMAP_COLORS,
  OPTIMAL_RANGE_HEATMAP_COLORS,
} from 'shared/constants/heatmap';
import {
  EGradientTypes,
  THeatMapScaleDataType,
  THeatMapStylingStep,
  TScaleColorizedValue,
} from 'shared/interfaces/heatmap';
import { TMeasurementStatistic } from 'shared/interfaces/measurement';
import { getPickHex } from 'shared/utils/gradient';

export interface IHeatMapStepValuesStylesProps {
  /** The type of current heatmap. */
  gradientType: EGradientTypes;
  /** The step values to be rendered in the gradient. */
  heatMapScale: THeatMapScaleDataType;
  /** The statistic of current measurement run. */
  statistics: TMeasurementStatistic | null;
}

export interface IHeatMapStepValueStylesReturn {
  /** The colorized scale values to be used in the heatmap. */
  heatMapScaleValues: TScaleColorizedValue[];
}

/**
 * Get the colorized step values of heatmap values based on heatMapScale and statistic.
 *
 * @param {IHeatMapStepValuesStylesProps} data - The heatMapScale and statistic.
 * @returns {IHeatMapStepValueStylesReturn} The colorized scale values of heatmap.
 */
export const useHeatMapStepValueStyles = ({
  gradientType,
  heatMapScale,
  statistics,
}: IHeatMapStepValuesStylesProps): IHeatMapStepValueStylesReturn => {
  const colorScales = useMemo(() => {
    switch (gradientType) {
      case EGradientTypes.FIXED_RANGE:
        return FIXED_RANGE_HEATMAP_COLORS;
      case EGradientTypes.MICROCLIMATES:
        return MICROCLIMATES_HEATMAP_COLORS;
      case EGradientTypes.OPTIMAL_RANGE:
      default:
        return OPTIMAL_RANGE_HEATMAP_COLORS;
    }
  }, [gradientType]);

  const gradientScaleValues = useMemo(() => {
    if (!statistics) {
      return [];
    }
    const min = statistics.min;
    const max = statistics.max;
    const firstValue = heatMapScale.lowerBound;
    const lastValue = heatMapScale.upperBound;
    const diff = lastValue - firstValue;

    let stylingStepList: THeatMapStylingStep[] = [
      { color: colorScales.STEP1, value: firstValue },
      { color: colorScales.STEP2, value: firstValue + diff / 3 },
      { color: colorScales.STEP3, value: firstValue + (diff / 3) * 2 },
      { color: colorScales.STEP4, value: lastValue },
    ];

    if (gradientType === EGradientTypes.OPTIMAL_RANGE) {
      stylingStepList = [
        {
          color: colorScales.STEP1,
          value: firstValue,
        },
        {
          color: colorScales.STEP2,
          value: Math.min(heatMapScale.optimalRangeLowerBound!, lastValue),
        },
        {
          color: colorScales.STEP3,
          value: Math.min(heatMapScale.optimalRangeUpperBound!, lastValue),
        },
      ];
      if (heatMapScale.optimalRangeUpperBound! < lastValue) {
        stylingStepList.push({
          color: colorScales.STEP4,
          value: lastValue,
        });
      }
    }

    const updatedStylingStepList = [];

    for (let i = 0; i < stylingStepList.length - 1; i++) {
      const currentStylingStep = stylingStepList[i]!;
      updatedStylingStepList.push(currentStylingStep);

      if (i === stylingStepList.length - 1) break;

      const nextStylingStep = stylingStepList[i + 1]!;

      if (currentStylingStep.value < min && min < nextStylingStep.value) {
        const weight =
          (nextStylingStep.value - min) /
          (nextStylingStep.value - currentStylingStep.value);
        const color = getPickHex(
          currentStylingStep.color,
          nextStylingStep.color,
          weight
        );
        updatedStylingStepList.push({ color, value: min });
      }

      if (currentStylingStep.value < max && max < nextStylingStep.value) {
        const weight =
          (nextStylingStep.value - max) /
          (nextStylingStep.value - currentStylingStep.value);
        const color = getPickHex(
          currentStylingStep.color,
          nextStylingStep.color,
          weight
        );
        updatedStylingStepList.push({ color, value: max });
      }
    }

    const values = updatedStylingStepList
      .map((value) => {
        return getScaleColorizedValue(value, min, max);
      })
      .filter(
        (scaleColorizedValue) =>
          0 <= scaleColorizedValue[0] && scaleColorizedValue[0] <= 1
      );

    const endValue = values.at(-1)!;
    const startValue = values[0]!;

    if (values.length && endValue[0] !== GRADIENT_SCALE.max) {
      values.push([GRADIENT_SCALE.max, endValue[1]]);
    }
    if (values.length && startValue[0] !== GRADIENT_SCALE.min) {
      values.unshift([GRADIENT_SCALE.min, startValue[1]]);
    }

    return values;
  }, [colorScales, statistics, heatMapScale, gradientType]);

  return {
    heatMapScaleValues: gradientScaleValues,
  };
};
