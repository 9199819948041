import {
  EImageLabelCategory,
  TImageLabelWithIcon,
} from 'shared/interfaces/image';

export const imageLabelWithIcons: TImageLabelWithIcon[] = [
  {
    category: EImageLabelCategory.YELLOWING,
    title: 'Yellowing',
  },
  {
    category: EImageLabelCategory.NECROSIS,
    title: 'Necrosis',
  },
  {
    category: EImageLabelCategory.ABNORMAL_SHAPE,
    title: 'Abnormal Shape',
  },
  {
    category: EImageLabelCategory.OTHER,
    title: 'Other',
  },
];
