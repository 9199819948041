type TRectangleWithUid = TRectangle & { uid: string };

export type Cluster = {
  rectangles: TRectangleWithUid[];
  centroid: TPosition;
};

function getCentroid(rect: TRectangleWithUid): TPosition {
  return { x: rect.x + rect.width / 2, y: rect.y + rect.height / 2 };
}
function squaredDistance(p1: TPosition, p2: TPosition): number {
  return (p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2;
}

export function clusterRectangles(
  rectangles: TRectangleWithUid[],
  zoomLevel: number
): Cluster[] {
  const baseThreshold = 100;
  const thresholdSquared = (baseThreshold / zoomLevel) ** 2;

  const centroids = rectangles.map(getCentroid);
  const n = rectangles.length;
  const visited = new Array(n).fill(false);
  const clusters: Cluster[] = [];

  for (let i = 0; i < n; i++) {
    if (!visited[i]) {
      const cluster: Cluster = { rectangles: [], centroid: centroids[i]! };
      visited[i] = true;
      cluster.rectangles.push(rectangles[i]!);

      for (let j = i + 1; j < n; j++) {
        if (
          !visited[j] &&
          squaredDistance(centroids[i]!, centroids[j]!) < thresholdSquared
        ) {
          visited[j] = true;
          cluster.rectangles.push(rectangles[j]!);
        }
      }

      // update cluster centroid to be the average of all the rectangles
      const x =
        cluster.rectangles.reduce(
          (acc, rect) => acc + rect.x + rect.width / 2,
          0
        ) / cluster.rectangles.length;
      const y =
        cluster.rectangles.reduce(
          (acc, rect) => acc + rect.y + rect.height / 2,
          0
        ) / cluster.rectangles.length;
      cluster.centroid = { x, y };

      clusters.push(cluster);
    }
  }

  return clusters;
}
