import { ComponentPropsWithoutRef, forwardRef, useEffect, useRef } from 'react';
import { cn } from 'shared/utils/cn';

const _variants = ['flat', 'nav', 'tertiary', 'full'] as const;

type Variant = (typeof _variants)[number];

export interface ITab {
  /** The tab id. */
  id: string;
  /** The tab label. */
  label: string;
  /** Whether the tab is disabled*/
  disabled?: boolean;
}

interface TabGroupProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  /** The class to be applied to the tabs container. */
  className?: string;
  /** The tabs to be displayed in the group. */
  tabs: ITab[];
  /** The selected tab. */
  value: Nullable<string>;
  /** The callback to be called when current tab changes. */
  onChange: (tab: string) => void;
  /** If yes, scrolls the current tab into view when clicked */
  shouldScrollTabIntoView?: boolean;
  /** The class to each tab element. */
  tabClassName?: string;
  /** The tabs variant. Optional. Defaults to `flat`.  */
  variant?: Variant;
}

export const TabGroup = forwardRef<HTMLDivElement, TabGroupProps>(
  function TabGroup(
    {
      className,
      tabClassName,
      children,
      shouldScrollTabIntoView = true,
      tabs,
      value,
      variant = 'flat',
      onChange,
      ...props
    },
    ref
  ) {
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
      if (buttonRef.current && shouldScrollTabIntoView) {
        buttonRef.current.scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
          block: 'nearest',
        });
      }
    }, [shouldScrollTabIntoView, value]);

    useEffect(() => {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (!buttonRef.current?.contains(document.activeElement)) return;

        if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
          const currentIndex = tabs.findIndex((tab) => tab.id === value);
          const nextIndex =
            e.key === 'ArrowLeft' ? currentIndex - 1 : currentIndex + 1;
          const nextTab = tabs[(nextIndex + tabs.length) % tabs.length];
          if (nextTab) {
            onChange(nextTab.id);
            buttonRef.current?.focus();
          }
        }
      };
      const handleKeyDownEvent = (e: KeyboardEvent) => {
        if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
          handleKeyDown(e);
        }
      };
      document.addEventListener('keydown', handleKeyDownEvent);
      return () => {
        document.removeEventListener('keydown', handleKeyDownEvent);
      };
    }, [onChange, tabs, value]);

    return (
      <div
        ref={ref}
        role="tablist"
        {...props}
        className={cn(
          'flex h-full w-fit max-w-full items-center justify-between',
          'overflow-hidden overflow-x-auto no-scrollbar outline-none',
          variant === 'flat' && '',
          variant === 'nav' &&
            'px-6 xl:px-7 gap-6 xl:gap-9 rounded-full bg-white',
          variant === 'tertiary' &&
            'h-9 xl:h-9 rounded-full border-[1.5px] border-ash-100',
          variant === 'full' && 'h-5 xl:h-5 rounded-full bg-sand-400',
          className
        )}
      >
        {tabs.map(({ id, label, disabled }) => (
          <div
            key={id}
            className={cn(
              'h-full px-3 flex-shrink-0 rounded-xs text-gray-900',
              'text-sm xl:text-base',
              'has-[:focus-visible]:outline cursor-pointer',
              'has-[:disabled]:bg-transparent has-[:disabled]:hover:bg-transparent has-[:disabled]:active:bg-transparent has-[:disabled]:cursor-default',
              variant === 'flat' && 'hover:bg-sand-500 active:bg-sand-600',
              variant === 'nav' &&
                'px-0 hover:bg-transparent active:bg-transparent hover:text-orange-700',
              variant === 'tertiary' && [
                'px-6 rounded-full',
                value === id && 'bg-ash-100',
              ],
              variant === 'full' && [
                'text-sm xl:text-sm',
                'rounded-full',
                value === id && 'bg-orange-300',
              ],
              tabClassName
            )}
          >
            <button
              ref={value === id ? buttonRef : undefined}
              tabIndex={value === id ? 0 : -1}
              className={cn(
                'h-9 xl:h-10 min-w-12',
                'flex items-center justify-center',
                'py-2 border-b-2 border-transparent outline-none',
                'disabled:text-gray-400',
                'aria-selected:border-orange-500',
                ['tertiary', 'full'].includes(variant) &&
                  'h-full xl:h-full border-none aria-selected:border-none'
              )}
              aria-selected={value === id}
              id={id}
              role="tab"
              disabled={disabled}
              onClick={() => onChange(id)}
            >
              {label}
            </button>
          </div>
        ))}
        {children}
      </div>
    );
  }
);
