import { TLabelByCategory } from 'shared/interfaces/image';

export enum EEnumerationTypes {
  ROLE_TYPE = 'ROLE_TYPE',
  LABEL_NAME = 'LABEL_NAME',
  LABEL_TYPE = 'LABEL_TYPE',
  LABEL_STATUS = 'LABEL_STATUS',
  LABEL_SOURCE_TYPE = 'LABEL_SOURCE_TYPE',
  LABEL_ANNOTATION_STATUS = 'ANNOTATION_STATUS',
  MEASUREMENT_TYPE = 'MEASUREMENT_TYPE',
  COMPUTED_METRIC = 'COMPUTED_METRIC',
  SENSOR_MODEL = 'SENSOR_MODEL',
}

export enum EEnumerationCodes {
  PENDING = 'PENDING',
  NOTIFIED = 'NOTIFIED',
}

export enum ECropTypeCodes {
  CANNABIS = 'CANNABIS',
  BLUEBERRY = 'BLUEBERRY',
}

export type TEnumeration = {
  // 999
  id: number;
  // YELLOWING:TIPS
  code: string;
  // Tips
  description: string;
  // LABEL_NAME
  type: string;
  metadata?: any;
};

export type TLabelEnumeration = Omit<TEnumeration, 'id'> & TLabelByCategory;

export type TEnumerationId = number;

export type TSelectedEnumerationIds = Set<TEnumerationId>;
