import { useGrowthCyclesWithRecipes } from 'api/growth-cycle';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { useZoneDetailsPageURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useMemo } from 'react';
import { getMatchingGrowthCycleOrFallbackToLatest } from 'shared/utils/growthCycle';

export const useGrowthCycles = () => {
  const { presetTypes } = useTypeConfig();
  const { currentZone, zoneTimeZone, currentTimeInCurrentZone } =
    useCurrentZone();
  const { getRangeStartTime, getRangeEndTime } = useZoneDetailsPageURL();
  const rangeStartTime = getRangeStartTime(zoneTimeZone);
  const rangeEndTime = getRangeEndTime(zoneTimeZone);
  const {
    growthCycles,
    loading: growthCyclesLoading,
    called: growthCyclesCalled,
    refetch,
  } = useGrowthCyclesWithRecipes({
    zoneId: currentZone?.id,
    zoneTimeZone,
    presetTypes,
  });

  const selectedCycle = useMemo(() => {
    const cycle = getMatchingGrowthCycleOrFallbackToLatest(
      growthCycles || [],
      rangeStartTime,
      rangeEndTime,
      currentTimeInCurrentZone
    );
    if (!cycle) return null;
    return cycle;
  }, [growthCycles, rangeStartTime, rangeEndTime, currentTimeInCurrentZone]);

  const latestCycle = useMemo(() => {
    return getMatchingGrowthCycleOrFallbackToLatest(
      growthCycles || [],
      undefined,
      undefined,
      currentTimeInCurrentZone
    );
  }, [growthCycles, currentTimeInCurrentZone]);

  const currentCycle = useMemo(() => {
    return (
      (growthCycles || []).find(
        (cycle) =>
          cycle.start_time <= currentTimeInCurrentZone.valueOf() &&
          cycle.end_time >= currentTimeInCurrentZone.valueOf()
      ) ??
      selectedCycle ??
      latestCycle
    );
  }, [currentTimeInCurrentZone, growthCycles, latestCycle, selectedCycle]);

  return {
    selectedCycle,
    latestCycle,
    currentCycle,
    growthCycles,
    growthCyclesLoading,
    growthCyclesCalled,
    refetch,
  };
};
