import circleUrl from 'assets/image/circle.png';

export interface IImage {
  url: string;
  width: number;
  height: number;
}

export const NO_IMAGE: IImage = {
  url: '/no-photography.png',
  width: 32,
  height: 32,
};

export const LOADING_IMAGE: IImage = {
  url: circleUrl,
  width: 32,
  height: 32,
};

export const NO_LABEL_CODE_SELECTION = 'None';
