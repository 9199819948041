import { ComponentPropsWithoutRef, FC } from 'react';
import { TBoundingBox } from 'shared/interfaces/general';
import { cn } from 'shared/utils/cn';
import { mapRange } from 'shared/utils/miscellaneous';

const MIN_BOX_AREA = 24 * 24;

const CIRCLES = [
  [80, 320, 0.3],
  [36, 144, 0.6],
  [12, 48, 1],
] as [minSize: number, maxSize: number, opacity: number][];

const getCircleSize = (scale: number, minSize: number, maxSize: number) => {
  const scaleThreshold = 0.025;
  const referenceScale = 0.6;
  return mapRange(scale, scaleThreshold, referenceScale, maxSize, minSize);
};

const getAriaLabel = ({
  comment,
  index,
  x,
  y,
}: {
  comment: string;
  index: number;
  x: number;
  y: number;
}) => `${comment} #${index} at ${Math.round(x)},${Math.round(y)}`;

interface IImageCaptureProps extends ComponentPropsWithoutRef<'img'> {
  /** The bounding boxes to display */
  boundingBoxes: TBoundingBox[];
  /** The image url */
  imageSrc: Optional<string>;
  /** The image size */
  imageSize: TSize;
  /** Current image scale  */
  scale: number;
  /** Called when the outer overlay circle is clicked */
  onClickOverlay: (element: HTMLElement) => void;
}

/**
 *  Depending on the provided scale it can display either 3 overlapping
 *  circles or a transparent rectangle identifying the overlay spot.
 * */
export const ImageCapture: FC<IImageCaptureProps> = ({
  boundingBoxes,
  imageSrc,
  imageSize,
  scale,
  onClickOverlay,
  ...props
}) => {
  if (!imageSrc) return null;
  return (
    <div
      className={cn('relative max-w-full')}
      style={{
        height: imageSize.height,
        width: imageSize.width,
      }}
    >
      <img
        {...props}
        className="object-contain"
        style={{ width: imageSize.width, height: imageSize.height }}
        src={imageSrc}
      />

      {boundingBoxes.map(
        ({ x: left, y: top, width, height, comment, id }, bbIndex) => {
          const realBoxArea = width * height * scale;
          const displayCircles = realBoxArea < MIN_BOX_AREA;

          if (displayCircles) {
            // Display 3 overlapping circles with a pulse animation

            const boxMiddle = {
              left: left + width / 2,
              top: top + height / 2,
            };

            return CIRCLES.map(([minSize, maxSize, opacity], index) => {
              const circleSize = getCircleSize(scale, minSize, maxSize);
              const a11yAttributes =
                index === 0
                  ? {
                      role: 'mark',
                      'aria-label': getAriaLabel({
                        comment,
                        index: bbIndex + 1,
                        x: boxMiddle.left,
                        y: boxMiddle.top,
                      }),
                    }
                  : {};

              return (
                <span
                  key={`${boxMiddle.left}-${boxMiddle.top}-${circleSize}-${comment}`}
                  className={cn(
                    'absolute rounded-[50%] hover:cursor-pointer -translate-x-1/2 -translate-y-1/2 bg-orange-400 animate-pulse'
                  )}
                  onClick={(evt) => onClickOverlay(evt.currentTarget)}
                  {...a11yAttributes}
                  style={{
                    ...boxMiddle,
                    width: circleSize,
                    height: circleSize,
                    opacity,
                  }}
                />
              );
            });
          }

          return (
            // Display a rectangle
            <span
              key={id}
              className={cn(
                'absolute border-orange-400 border-[3px] hover:cursor-pointer hover:border-orange-600'
              )}
              onClick={(evt) => onClickOverlay(evt.currentTarget)}
              style={{
                left,
                top,
                width,
                height,
              }}
              role="mark"
              aria-label={getAriaLabel({
                comment,
                index: bbIndex + 1,
                x: left,
                y: left,
              })}
            />
          );
        }
      )}
    </div>
  );
};
