import * as toast from 'components/common/Toast/Toast';
import { useZoneDetailsPageURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { useEffect, useRef } from 'react';
import { EZoneDetailTabs } from 'shared/interfaces/zone';
import { useCurrentZone } from './useCurrentZone';
import { useGrowthCycles } from './useGrowthCycles';
import { usePermissions } from './usePermissions';

export const useNotifyExpiredGrowthCycle = () => {
  const { currentZone, currentTimeInCurrentZone } = useCurrentZone();

  const { navigateToTab } = useZoneDetailsPageURL();

  const { currentCycle, latestCycle } = useGrowthCycles();
  const toastId = useRef<number | string | null>(null);

  const { canEditGrowthCycles } = usePermissions();

  useEffect(() => {
    return () => toast.dismiss(toastId.current ?? 0);
  }, []);

  useEffect(() => {
    if (currentZone && canEditGrowthCycles && latestCycle) {
      const reminderId = 'growthCycleExpirationReminder-' + currentZone.id;
      const timeUntilEnd = currentCycle
        ? currentCycle.end_time - currentTimeInCurrentZone.valueOf()
        : -1;

      let storage = localStorage;
      let toastFunction: Nullable<typeof toast.warning> = null;
      let message: string = '';
      if (timeUntilEnd < 0) {
        toastFunction = toast.error;
        storage = sessionStorage;
        message = `Your growth cycle has expired. `;
      } else if (timeUntilEnd < 7 * 24 * 60 * 60 * 1000 && timeUntilEnd > 0) {
        toastFunction = toast.warning;
        message = `Your growth cycle is about to end. `;
      }
      if (toastId.current !== reminderId) {
        toast.dismiss(toastId.current ?? 0);
      }
      if (!toastFunction) return;
      const growthCycleExpirationReminderDate = Number(
        storage.getItem(reminderId) ?? 0
      );
      if (
        growthCycleExpirationReminderDate <
        Date.now() - 24 * 60 * 60 * 1000
      ) {
        toastId.current = toastFunction!(
          {
            content: (
              <p>
                {message}
                <button
                  onClick={() => {
                    storage.setItem(reminderId, Date.now().toString());
                    setTimeout(() => toast.dismiss(toastId.current!), 500);
                    navigateToTab(EZoneDetailTabs.GROWTH_CYCLE_SETTINGS);
                  }}
                  className="underline"
                >
                  Create a new one.
                </button>
              </p>
            ),
          },
          {
            toastId: reminderId,
            delay: 1000,
            data: { storage },
          }
        );
        const unsubscribe = toast.onChange(({ id, status, data }) => {
          if (
            status === 'removed' &&
            id === toastId.current &&
            (data as any).dismissedByUser
          ) {
            (data as any).storage.setItem(String(id), Date.now().toString());
            toastId.current = null;
            unsubscribe();
          }
        });
        return unsubscribe;
      }
    }
  }, [
    latestCycle,
    currentCycle,
    currentZone,
    currentTimeInCurrentZone,
    navigateToTab,
    canEditGrowthCycles,
  ]);
};
