import {
  Column,
  ColumnFiltersState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { Button } from 'components/common/Button/Button';
import { Dropdown, Option } from 'components/common/Dropdown/Dropdown';
import { Input } from 'components/common/Input/Input';
import { useAuth } from 'contexts/AuthProvider';
import { useScreenSize } from 'hooks/useScreenSize';
import { useUsers } from 'hooks/useUsers';
import {
  ChevronDownIcon,
  ChevronsUpDownIcon,
  ChevronUpIcon,
} from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { TUser } from 'shared/interfaces/user';
import { getUserName } from 'shared/utils/user';
import { UserStatusIcon } from './UserStatusIcon';

interface IUserTableProps {
  page: number;
  users: TUser[];
  onClick: (userId: number) => void;
  onPageChange: (page: number) => void;
}

export const UserTable = ({
  page,
  users,
  onClick,
  onPageChange,
}: IUserTableProps) => {
  const { isMobile } = useScreenSize();
  const { userRoleTypes } = useUsers();
  const { currentlySelectedOrganization } = useAuth();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: page - 1,
    pageSize: 10,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const getUserRole = useCallback(
    (user: TUser) =>
      userRoleTypes
        .filter(({ code }) => {
          return user.user_organization_roles.find(
            (userOrganizationRole) =>
              userOrganizationRole.role.code === code &&
              (userOrganizationRole.organization_id ===
                currentlySelectedOrganization?.id ||
                userOrganizationRole.location?.organization_id ===
                  currentlySelectedOrganization?.id ||
                userOrganizationRole.zone?.location.organization_id ===
                  currentlySelectedOrganization?.id)
          );
        })
        .map((roleType) => {
          if (
            user.user_organization_roles.some(
              (userOrganizationRole) =>
                userOrganizationRole.organization_id ===
                currentlySelectedOrganization?.id
            )
          ) {
            return roleType.label;
          } else {
            const roleWithLocation = user.user_organization_roles.find(
              (userOrganizationRole) =>
                userOrganizationRole.location?.organization_id ===
                currentlySelectedOrganization?.id
            );

            if (roleWithLocation) {
              return `${roleType.label} (${roleWithLocation.location!.enumeration.description})`;
            }

            const roleWithZone = user.user_organization_roles.find(
              (userOrganizationRole) =>
                userOrganizationRole.zone?.location.organization_id ===
                currentlySelectedOrganization?.id
            );

            if (roleWithZone)
              return `${roleType.label} (${roleWithZone.zone!.enumeration.description})`;
          }
        })
        .join(', '),
    [userRoleTypes, currentlySelectedOrganization?.id]
  );

  const columnHelper = createColumnHelper<TUser>();

  const columns = [
    columnHelper.accessor((row) => getUserName(row), {
      id: 'name',
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor((row) => row, {
      id: 'mobile_name',
      cell: (ctx) => {
        const value = ctx.getValue();
        return (
          <div className="flex flex-col">
            <span>{getUserName(value)}</span>
            <span className="text-xs">{getUserRole(value)}</span>
          </div>
        );
      },
      header: () => <span>Name</span>,
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => getUserRole(row), {
      id: 'role',
      header: () => <span>Role</span>,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.phone_number, {
      id: 'phone_number',
      header: () => <span>Phone</span>,
      enableColumnFilter: true,
    }),
    columnHelper.accessor((row) => row.email, {
      id: 'email',
      header: () => <span>Email</span>,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('active', {
      cell: (ctx) => (
        <div className="flex items-center justify-start">
          <UserStatusIcon active={ctx.getValue()} />
        </div>
      ),
      header: () => <span>Active</span>,
      enableColumnFilter: false,
    }),
  ];

  const table = useReactTable({
    data: users,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: (updater) => {
      if (typeof updater === 'function') {
        const newState = updater(pagination);
        onPageChange(newState.pageIndex + 1);
      } else {
        onPageChange(updater.pageIndex + 1);
      }
      setPagination(updater);
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    state: {
      pagination,
      sorting,
      columnFilters,
    },
  });

  useEffect(() => {
    table.setColumnVisibility(() => {
      return {
        name: !isMobile,
        mobile_name: isMobile,
        active: true,
        role: !isMobile,
        phone_number: !isMobile,
        email: !isMobile,
      };
    });
  }, [isMobile, table]);

  const pageSizeOptions = [10, 20, 30, 40, 50].map((pageSize) => ({
    value: pageSize,
    label: `Show ${pageSize}`,
  }));

  return (
    <div className="flex flex-col gap-3">
      <table className="text-sm">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="border-b">
              {headerGroup.headers.map((header) => {
                const sort = header.column.getIsSorted();
                return (
                  <th key={header.id} className="p-4 align-top">
                    <div className="flex flex-col">
                      <div
                        className="cursor-pointer select-none flex items-center"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {sort === 'asc' ? (
                          <ChevronUpIcon className="stroke-[1.5px] size-4 xl:size-5" />
                        ) : sort === 'desc' ? (
                          <ChevronDownIcon className="stroke-[1.5px] size-4 xl:size-5" />
                        ) : (
                          <ChevronsUpDownIcon className="stroke-[1.5px] size-4 xl:size-5" />
                        )}
                      </div>
                      {header.column.getCanFilter() ? (
                        <Filter column={header.column} />
                      ) : null}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="cursor-pointer hover:bg-neutral-200 border-b"
              onClick={() => onClick(row.original.id)}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="p-4">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex items-center gap-2 justify-between">
        <Dropdown
          options={pageSizeOptions}
          value={pageSizeOptions.find(
            (o) => o.value === table.getState().pagination.pageSize
          )}
          onChange={(e) => {
            table.setPageSize((e as Option<number>).value);
          }}
        />
        <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount().toLocaleString()}
          </strong>
        </span>
        <div className="flex gap-2">
          <Button
            className="border rounded p-1"
            onClick={() => table.firstPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </Button>
          <Button
            className="border rounded p-1"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </Button>
          <Button
            className="border rounded p-1"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </Button>
          <Button
            className="border rounded p-1"
            onClick={() => table.lastPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </Button>
        </div>
      </div>
    </div>
  );
};

function Filter({ column }: { column: Column<any, unknown> }) {
  const columnFilterValue = column.getFilterValue();

  return (
    <Input
      onChange={(event) => column.setFilterValue(event.target.value)}
      placeholder={`Search...`}
      type="text"
      value={(columnFilterValue ?? '') as string}
    />
  );
}
