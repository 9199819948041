import { HEIGHT_INDEX, WIDTH_INDEX } from 'components/image_feed/constants';
import { getImageUrlInformation } from 'components/image_feed/hooks/utils';
import { getImageFeedResolutionInformation } from 'components/image_feed/utils';
import isNil from 'lodash.isnil';
import { useCallback, useMemo, useState } from 'react';
import {
  EImageTypes,
  IResolutionInformation,
  TImagesGrid,
} from 'shared/interfaces/image';
import { clamp } from 'shared/utils/miscellaneous';
import { IGridView } from '../types';

const DEFAULT_IMAGE_SIZE = {
  SMALL_WIDTH: 130,
  SMALL_HEIGHT: 130,
  LARGE_WIDTH: 4032,
  LARGE_HEIGHT: 3024,
};

const getEmptyImageGrid = ({ column, row }: TGridSize): TImagesGrid => {
  return new Array(row).fill(0).map((_row, yIndex) =>
    new Array(column).fill(0).map((_col, xIndex) => ({
      measurementId: null,
      cellX: xIndex,
      cellY: yIndex,
      minImageUrl: '',
      maxImageUrl: '',
      sortedImageUrls: [],
      runId: 0,
    }))
  );
};

const getImageSizes = (
  measurementRun: any,
  imageType: EImageTypes,
  imageSizeIndex: number
) => {
  const imageFeedResolutionInformation = getImageFeedResolutionInformation(
    measurementRun,
    imageType
  );

  if (!imageFeedResolutionInformation) {
    return null;
  }

  const { maxResolution, sortedResolutions } = imageFeedResolutionInformation;
  const resolutionIndex = Math.min(
    imageSizeIndex,
    sortedResolutions.length - 1
  );

  const minResolution = sortedResolutions[resolutionIndex]!;
  const smallImageSize = {
    width: sortedResolutions[resolutionIndex]?.[WIDTH_INDEX]!,
    height: sortedResolutions[resolutionIndex]?.[HEIGHT_INDEX]!,
  };

  const largeImageSize = {
    width: maxResolution[WIDTH_INDEX],
    height: maxResolution[HEIGHT_INDEX],
  };

  const sortedSizes: TSize[] = sortedResolutions.map(([width, height]) => ({
    width,
    height,
  }));

  return {
    minResolution,
    maxResolution,
    sortedResolutions,
    sortedSizes,
    largeImageSize,
    smallImageSize,
  };
};

export interface IUseImageFeedInformationProps {
  imageType: EImageTypes;
  measurementsGridView: IGridView;
}

export interface IUseImageFeedInformationReturn {
  /** The safe grid size which is bigger than 1. */
  gridSize: TGridSize;
  /** The grid images information. */
  imagesGrid: TImagesGrid;
  /** The small image size. */
  smallImageSize: TSize;
  /** The large image size. */
  largeImageSize: TSize;
  /** The total image size. */
  totalImageSize: Optional<TSize>;
  /** All available image sizes sorted from smalles to largest */
  sortedImageSizes: TSize[];
  /** Index of the small image size. */
  imageSizeIndex: number;
  /** The total image sizes. */
  totalImageSizes: TSize[];
  /** Index of the largest small image */
  largestSmallImageIndex: Optional<number>;
  /** Advance image size index */
  advanceImageSizeIndex: (scale: TScale, newIndex?: number) => void;
  /** Reset image size index */
  resetImageSizeIndex: (newIndex?: number) => void;
}
/**
 *
 *
 * @param {IUseImageFeedInformationProps} {
 *   imageType,
 *   measurementsGridView,
 * }
 * @return {*}  {IUseImageFeedInformationReturn}
 */
export const useImageFeedInformation = ({
  imageType,
  measurementsGridView,
}: IUseImageFeedInformationProps): IUseImageFeedInformationReturn => {
  const [imageSizeIndex, setImageSizeIndex] = useState(0);

  const {
    imagesGrid,
    gridSize,
    sortedImageSizes,
    smallImageSize,
    largeImageSize,
  } = useMemo(() => {
    const { measurementRun, measurementList, size } = measurementsGridView;
    const defaultSmallImageSize = {
      height: DEFAULT_IMAGE_SIZE.SMALL_HEIGHT,
      width: DEFAULT_IMAGE_SIZE.SMALL_WIDTH,
    };
    const defaultLargeImageSize = {
      height: 0,
      width: 0,
    };
    if (isNil(measurementRun)) {
      return {
        imagesGrid: [[]] as TImagesGrid,
        gridSize: { row: 0, column: 0 } as TGridSize,
        sortedImageSizes: [] as TSize[],
        smallImageSize: defaultSmallImageSize,
        largeImageSize: defaultLargeImageSize,
      };
    }

    const gridSize: TGridSize = size;

    const imagesGrid = getEmptyImageGrid(gridSize);

    const isNeverFetched = measurementList.every(
      (measurement) => !measurement.data
    );

    const imageSizes = getImageSizes(measurementRun, imageType, imageSizeIndex);
    let resolutionInformation: Optional<IResolutionInformation>;

    if (imageSizes) {
      resolutionInformation = {
        minResolution: imageSizes.minResolution,
        maxResolution: imageSizes.maxResolution,
        sortedResolutions: imageSizes.sortedResolutions,
      };
    }

    measurementList.forEach((measurement, idx) => {
      if (!measurement || !resolutionInformation) {
        return;
      }
      const imageUrlInformation = getImageUrlInformation(
        resolutionInformation,
        measurement.data
      );
      const cellX = (idx - (idx % gridSize.row)) / gridSize.row;
      const cellY = gridSize.row - (idx % gridSize.row) - 1;

      imagesGrid[cellY]![cellX] = {
        runId: measurementRun.id,
        measurementId: measurement.id,
        cellX,
        cellY,
        ...(isNeverFetched
          ? { minImageUrl: '', maxImageUrl: '', sortedImageUrls: [] }
          : imageUrlInformation),
      };
    });
    return {
      imagesGrid,
      gridSize,
      sortedImageSizes: imageSizes?.sortedSizes || [],
      smallImageSize: imageSizes?.smallImageSize || defaultSmallImageSize,
      largeImageSize: imageSizes?.largeImageSize || defaultLargeImageSize,
    };
  }, [measurementsGridView, imageType, imageSizeIndex]);

  const indexOfLargestSmallImage: Optional<number> =
    sortedImageSizes?.length - 2;
  const totalImageSizes: TSize[] =
    sortedImageSizes?.map(({ height, width }) => ({
      height: gridSize.row * height,
      width: gridSize.column * width,
    })) || [];
  const totalImageSize = totalImageSizes[imageSizeIndex];
  const ADVANCE_INDEX_RESOLUTION_ZOOM_LEVEL = 0.15;

  const advanceImageSizeIndex = (scale: TScale, newIndex?: number) => {
    let endPoint = indexOfLargestSmallImage - 1;
    if (scale.x > ADVANCE_INDEX_RESOLUTION_ZOOM_LEVEL) {
      endPoint = indexOfLargestSmallImage;
    }
    const sanitizedIndex = clamp((newIndex ?? imageSizeIndex) + 1, 0, endPoint);
    setImageSizeIndex(sanitizedIndex);
  };

  const resetImageSizeIndex = useCallback((newIndex: number = 0) => {
    setImageSizeIndex(Math.max(newIndex, 0));
  }, []);

  return {
    gridSize,
    imagesGrid,
    smallImageSize,
    largeImageSize,
    sortedImageSizes,
    imageSizeIndex,
    totalImageSize,
    totalImageSizes,
    largestSmallImageIndex: indexOfLargestSmallImage,
    advanceImageSizeIndex,
    resetImageSizeIndex,
  };
};
