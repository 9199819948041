import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const NeatleafMark = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement> & {
  title?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 120 120"
    className={cn('text-orange-500', className)}
    title="Neatleaf logo"
    {...props}
  >
    <path
      d="M77.7317 102.572C88.8617 113.73 104.269 120 120 120L119.692 65.9448C119.692 29.529 90.2388 0 53.9165 0H33.471C33.471 0 41.1826 0.925826 51.6159 11.3698L97.0269 56.8814C100.008 59.8701 101.677 63.9145 101.677 68.1538V77.2983L41.9602 17.4283C30.8302 6.26962 15.731 0 0 0V54.0552C0 90.471 29.4532 120 65.7755 120H86.221C86.221 120 78.5094 119.074 68.076 108.63L22.665 63.1186C19.684 60.1299 18.0154 56.0855 18.0154 51.8462V42.7017L77.7317 102.572Z"
      fill={fill}
    />
  </svg>
);

export { NeatleafMark };
