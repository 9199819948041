import { NavigateOptions } from 'react-router-dom';
import { ERoutePath } from 'shared/constants/url';
import { EProfileTabs, ESettingsTabs } from 'shared/interfaces/settings';
import { getQueryParams } from './URLQueryParams';
import { StoreArgs } from './URLStoreArgs';

export const getSettingsEditProfileStore = (
  args: StoreArgs<ERoutePath.SETTINGS_EDIT_PROFILE>
) => {
  const { routePath, searchParams, setSearchParams } = args;
  const queryParams = getQueryParams(searchParams, routePath);
  const actions = {
    setProfileTab: (tab: EProfileTabs, navigateOptions?: NavigateOptions) => {
      setSearchParams(
        {
          ...queryParams,
          tab,
        },
        navigateOptions
      );
    },
  };
  const state = {
    organization: queryParams.organization,
    tab: ESettingsTabs.PROFILE,
    profileTab: queryParams.tab ?? EProfileTabs.EDIT_PROFILE,
  };
  return {
    ...actions,
    ...state,
  };
};
