export type THeatMapStylingStep = {
  value: number;
  color: string;
};

export type THeatMapStylings = {
  low: THeatMapStylingStep;
  mediumLow: THeatMapStylingStep;
  medium: THeatMapStylingStep;
  mediumHigh: THeatMapStylingStep;
  high: THeatMapStylingStep;
};

export enum EAggregationTypes {
  AGGREGATED = 'Aggregated',
  SINGLE = 'Single',
}

export enum EGradientTypes {
  MICROCLIMATES = 'MICROCLIMATES',
  FIXED_RANGE = 'FIXED_RANGE',
  OPTIMAL_RANGE = 'OPTIMAL_RANGE',
}

export const GradientTypeLabels = {
  [EGradientTypes.MICROCLIMATES]: 'Microclimates',
  [EGradientTypes.FIXED_RANGE]: 'Fixed Range',
  [EGradientTypes.OPTIMAL_RANGE]: 'Optimal Range',
} as const;

export type TGradientTypeLabel = (typeof GradientTypeLabels)[EGradientTypes];

export type THeatMapScaleDataType = {
  lowerBound: number;
  upperBound: number;
  optimalRangeLowerBound?: number;
  optimalRangeUpperBound?: number;
  currentRunLowerBound: number;
  currentRunUpperBound: number;
  setPoint?: number;
};

export type TScaleColorizedValue = [number, string];

export interface IStatistic {
  min: number;
  max: number;
}

export interface IHeatMapData {
  x: Array<number>;
  y: Array<number>;
  z: Array<Array<Nullable<number>>>;
  id: string;
  statistic: IStatistic;
}

export interface IHeatMapDataFromDatabase {
  __typename?: 'heat_map' | undefined;
  data: any;
  measurement_run: {
    __typename?: 'measurement_run' | undefined;
    metadata?: any;
  };
}
