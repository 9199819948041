import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  createEditor,
} from 'lexical';

export const createEditorStateFromText = (text?: string) => {
  const editor = createEditor();
  editor.update(
    () => {
      const textNode = $createTextNode(text);
      const paragraphNode = $createParagraphNode();
      paragraphNode.append(textNode);
      $getRoot().append(paragraphNode);
    },
    { discrete: true }
  );
  return editor.getEditorState();
};

export const getValidEditorState = (editorState?: string) => {
  if (editorState === undefined)
    return { editorState: undefined, isObject: false, isString: false };
  const isValidEditorState = !!editorState?.trim?.().startsWith('{');
  if (isValidEditorState)
    return { editorState, isObject: false, isString: true };
  return {
    editorState: createEditorStateFromText(editorState),
    isObject: true,
    isString: false,
  };
};

export const hasText = (serializedString: string) => {
  const checkChildren = (children: any) => {
    for (const child of children) {
      if (child.text) {
        return child.text.trim().length > 0;
      } else if (child.children) {
        return checkChildren(child.children);
      }
    }
    return false;
  };

  if (serializedString.length === 0) {
    return false;
  }

  try {
    const editorStateObject = JSON.parse(serializedString);
    if (
      editorStateObject &&
      editorStateObject.root &&
      editorStateObject.root.children
    ) {
      return checkChildren(editorStateObject.root.children);
    }
  } catch {
    // if it fails to parse it means it has a regular string
    return true;
  }

  return false;
};
