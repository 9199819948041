import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { TDiscussion } from 'shared/interfaces/discussion';
import { cn } from 'shared/utils/cn';
import { formatDateEEEMMMMdhmmaa } from 'shared/utils/date';
import { InsightContent } from './InsightContent';

interface ViewInsightProps extends ComponentPropsWithoutRef<'div'> {
  discussion: TDiscussion;
  showStatus: boolean;
}

export const ViewInsight = forwardRef<HTMLDivElement, ViewInsightProps>(
  function ViewInsight({ discussion, showStatus, className, ...props }, ref) {
    const { annotationType, displayLabel, endTime, firstComment, startTime } =
      discussion;
    const startTimeLabel = formatDateEEEMMMMdhmmaa(startTime);
    const endTimeLabel = formatDateEEEMMMMdhmmaa(endTime);
    const isTimeRange = annotationType === 'time_range_annotation';
    const lastUpdatedAtLabel = `Last updated at ${formatDateEEEMMMMdhmmaa(
      firstComment.lastUpdatedAt!
    )}`;
    return (
      <div
        ref={ref}
        {...props}
        className={cn('w-full h-full flex flex-col', className)}
      >
        <div className="px-4 pb-4 flex flex-col border-b-[1px] border-neutral-500">
          <p className="text-xl font-bold">{displayLabel}</p>

          <p className="text-sm">
            {startTimeLabel}
            {isTimeRange && ` - ${endTimeLabel}`}
          </p>
        </div>

        <div className="p-4 flex flex-col gap-4">
          <p className="text-sm text-neutral-500">{lastUpdatedAtLabel}</p>
          <InsightContent discussion={discussion} showStatus={showStatus} />
        </div>
      </div>
    );
  }
);
