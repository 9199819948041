import { captureException } from '@sentry/core';
import { QueryParamsSchema, QueryParamsSchemasKey } from './URLQueryParams';

export function objectToURLSearchParams<T extends QueryParamsSchemasKey>({
  params,
  prevSearch,
  clearPreviousQueryParams,
}: {
  params: Partial<QueryParamsSchema<T>>;
  prevSearch: URLSearchParams;
  clearPreviousQueryParams?: boolean;
}) {
  const newSearch = new URLSearchParams(
    clearPreviousQueryParams ? {} : prevSearch
  );
  for (const [paramName, paramValue] of Object.entries(params)) {
    if (paramValue !== undefined) {
      if (Array.isArray(paramValue)) {
        newSearch.delete(paramName);
        paramValue.forEach((value: string | number) => {
          newSearch.append(paramName, value.toString());
        });
      } else if (
        typeof paramValue === 'string' ||
        typeof paramValue === 'number' ||
        typeof paramValue === 'boolean'
      ) {
        newSearch.set(paramName, paramValue.toString());
      } else {
        const error = new Error(
          `Can't set query param with value: ${paramValue}`
        );
        captureException(error);
        throw error;
      }
    } else {
      newSearch.delete(paramName);
    }
  }
  return newSearch;
}
