export enum AnalyticsEvent {
  ChangeSearchParams = 'change_search_params',
  Click = 'click',
  Filter = 'filter',
  Login = 'login',
  Logout = 'logout',
  PageLeave = 'page_leave',
  Search = 'search',
  SignUp = 'sign_up',
  Submit = 'submit',
  ViewSingleImage = 'view_single_image',
}
