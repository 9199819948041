import {
  getClosestLabelUpperBoundCount,
  getSectionHeatOverlayIntensityFromLabelCount,
} from 'components/image_feed/utils';
import { checkIfLabelIsSelected } from 'components/image_label/utils';
import { useGrowthCycles } from 'hooks/useGrowthCycles';
import { useMemo } from 'react';
import {
  ELabelOverviewUpperCountBoundType,
  TLabelByCategory,
  TLabelStatistics,
  TLabelsByMeasurementId,
  TSelectedLabelCode,
} from 'shared/interfaces/image';
import { TMeasurementId } from 'shared/interfaces/measurement';
import { alpha } from 'shared/utils/color';
import { getGrowDay } from 'shared/utils/getters';
import { useImageFeedLabelStats } from './useImageFeedLabelStats';
type ISectionHighlights = Record<TMeasurementId, string>;

/**
 * @param {TSelectedLabelCode} selectedLabelCode Selected label code
 * @param {TLabelsByMeasurementId} labelsByMeasurementId Labels by measurement id
 * @returns {TLabelsByMeasurementId | undefined} filtered labels by measurement id by selected label category
 */
function filterLabelsByMeasurementIdBySelectedLabelCode(
  selectedLabelCode: TSelectedLabelCode,
  labelsByMeasurementId: TLabelsByMeasurementId
): TLabelsByMeasurementId | undefined {
  if (!selectedLabelCode) return;

  return Object.entries(labelsByMeasurementId).reduce<TLabelsByMeasurementId>(
    (acc, [measurementId, labels]) => {
      const filteredLabels = labels.filter(
        (label) =>
          checkIfLabelIsSelected(label, selectedLabelCode) && label.labelCount
      );

      if (!filteredLabels.length) return acc;

      return {
        ...acc,
        [measurementId]: filteredLabels,
      };
    },
    {}
  );
}

/**
 * @param {number} labelCount label count
 * @param {Optional<TMinMax>} stats of min and max
 * @returns {string} selected label color with adjusted alpha channel for intensity
 */
export const getSectionHighlightColorByLabelCodes = (
  labelCount: number,
  stats: Optional<TLabelStatistics>
) => {
  const colorAlpha = getSectionHeatOverlayIntensityFromLabelCount(
    labelCount,
    stats
  );
  return alpha('#ff7144', colorAlpha);
};

/**
 * @param {TLabelByCategory[]} labels Labels
 * @returns {number} label counts for selected category
 */
function getSelectedLabelsCount(labels: TLabelByCategory[]): number {
  return labels.reduce((acc, label) => {
    return acc + (label.labelCount ?? 0);
  }, 0);
}

/**
 * @param {TLabelsByMeasurementId} filteredLabelsByMeasurementId Labels by measurement id
 * @param {Optional<TMinMax>} stats of min and max
 * @returns {ISectionHighlights | undefined} label colors by selected by label category
 */
function getLabelHighlighColorsByMeasurementId(
  filteredLabelsByMeasurementId: TLabelsByMeasurementId,
  stats: Optional<TLabelStatistics>
): ISectionHighlights | undefined {
  return Object.entries(
    filteredLabelsByMeasurementId
  ).reduce<ISectionHighlights>((acc, [measurementId, labels]) => {
    return {
      ...acc,
      [measurementId]: getSectionHighlightColorByLabelCodes(
        getSelectedLabelsCount(labels),
        stats
      ),
    };
  }, {});
}

export const useGetSectionsHighlights = (
  selectedLabelCode: TSelectedLabelCode,
  labelsByMeasurementId: TLabelsByMeasurementId,
  measurementStartDate: Date,
  runId: number
): ISectionHighlights | undefined => {
  const { selectedCycle, currentCycle } = useGrowthCycles();
  const labelOverviewUpperCountBound =
    selectedCycle?.metadata?.label_overview_upper_count_bound ?? null;

  const filteredLabelsByMeasurementId = useMemo(
    () =>
      filterLabelsByMeasurementIdBySelectedLabelCode(
        selectedLabelCode,
        labelsByMeasurementId
      ),
    [selectedLabelCode, labelsByMeasurementId]
  );
  const filteredLabelsByMeasurementIdString = JSON.stringify(
    filteredLabelsByMeasurementId
  );
  const labelOverviewUpperCountBoundString = JSON.stringify(
    labelOverviewUpperCountBound
  );
  const currentCycleStartTimeString = currentCycle
    ? new Date(currentCycle.start_time).toISOString()
    : '';

  const { getImageFeedLabelStatsByRunId } = useImageFeedLabelStats();
  const imageFeedLabelStats = getImageFeedLabelStatsByRunId(
    runId,
    selectedLabelCode
  );
  const stats = useMemo(() => {
    const type = labelOverviewUpperCountBound?.type;
    let result: Optional<TLabelStatistics> = undefined;

    if (
      ELabelOverviewUpperCountBoundType.DEFAULT === type ||
      ELabelOverviewUpperCountBoundType.AUTOSCALE === type ||
      !type ||
      !currentCycle
    ) {
      if (imageFeedLabelStats) {
        result = imageFeedLabelStats;
      }
    } else if (
      ELabelOverviewUpperCountBoundType.CLOSEST_DAY === type &&
      labelOverviewUpperCountBound
    ) {
      const growDay = getGrowDay(measurementStartDate, currentCycle.start_time);
      result = {
        min: 1,
        max:
          getClosestLabelUpperBoundCount(
            growDay,
            labelOverviewUpperCountBound
          ) ?? 1,
      };
    }
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentCycleStartTimeString,
    labelOverviewUpperCountBoundString,
    filteredLabelsByMeasurementIdString,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    measurementStartDate.toISOString(),
    imageFeedLabelStats,
  ]);

  return useMemo(() => {
    if (!filteredLabelsByMeasurementId) return;

    return getLabelHighlighColorsByMeasurementId(
      filteredLabelsByMeasurementId,
      stats
    );
  }, [filteredLabelsByMeasurementId, stats]);
};
