import { ERoutePath } from 'shared/constants/url';
import { ESettingsTabs } from 'shared/interfaces/settings';
import { getQueryParams } from './URLQueryParams';
import { StoreArgs } from './URLStoreArgs';

export const getSettingsEditUserStore = (
  args: StoreArgs<ERoutePath.SETTINGS_EDIT_USER>
) => {
  const { routePath, searchParams, pathParams } = args;
  const queryParams = getQueryParams(searchParams, routePath);
  const actions = {};
  const state = {
    organization: queryParams.organization,
    tab: ESettingsTabs.USERS,
    userId: pathParams?.userId ?? '',
  };
  return {
    ...actions,
    ...state,
  };
};
