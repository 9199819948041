import type { SVGProps } from 'react';
import { SpyderStatus } from 'shared/interfaces/system';
import { cn } from 'shared/utils/cn';

const SpyderStatusIcon = ({
  className,
  status = 'DYNAMIC',
  ...props
}: SVGProps<SVGSVGElement> & {
  status?: SpyderStatus;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={cn('h-6 w-6', className)}
    {...props}
  >
    {['DYNAMIC', 'STATIONARY'].includes(status) && (
      <>
        <path d="M2.586 16.726C2.2109 16.351 2.00011 15.8424 2 15.312V8.688C2.00011 8.15761 2.2109 7.64899 2.586 7.274L7.274 2.586C7.64899 2.2109 8.15761 2.00011 8.688 2H15.312C15.8424 2.00011 16.351 2.2109 16.726 2.586L21.414 7.274C21.7891 7.64899 21.9999 8.15761 22 8.688V15.312C21.9999 15.8424 21.7891 16.351 21.414 16.726L16.726 21.414C16.351 21.7891 15.8424 21.9999 15.312 22H8.688C8.15761 21.9999 7.64899 21.7891 7.274 21.414L2.586 16.726Z" />
        <path d="M10 8L16 12L10 16V8Z" />
      </>
    )}
    {['HMI_HOME', 'HMI_PAUSED', 'IDLE_AFTER_AUTO_RESTART'].includes(status) && (
      <>
        <path d="M10 15V9" />
        <path d="M14 15V9" />
        <path d="M2.586 16.872C2.2109 16.497 2.00011 15.9884 2 15.458V8.542C2.00011 8.01161 2.2109 7.50299 2.586 7.128L7.128 2.586C7.50299 2.2109 8.01161 2.00011 8.542 2H15.458C15.9884 2.00011 16.497 2.2109 16.872 2.586L21.414 7.128C21.7891 7.50299 21.9999 8.01161 22 8.542V15.458C21.9999 15.9884 21.7891 16.497 21.414 16.872L16.872 21.414C16.497 21.7891 15.9884 21.9999 15.458 22H8.542C8.01161 21.9999 7.50299 21.7891 7.128 21.414L2.586 16.872Z" />
      </>
    )}
    {status === 'STATIC' && (
      <>
        <path d="M2.586 16.726C2.2109 16.351 2.00011 15.8424 2 15.312V8.688C2.00011 8.15761 2.2109 7.64899 2.586 7.274L7.274 2.586C7.64899 2.2109 8.15761 2.00011 8.688 2H15.312C15.8424 2.00011 16.351 2.2109 16.726 2.586L21.414 7.274C21.7891 7.64899 21.9999 8.15761 22 8.688V15.312C21.9999 15.8424 21.7891 16.351 21.414 16.726L16.726 21.414C16.351 21.7891 15.8424 21.9999 15.312 22H8.688C8.15761 21.9999 7.64899 21.7891 7.274 21.414L2.586 16.726Z" />
        <path d="M12 8V16" />
        <path d="M8.5 14L15.5 10" />
        <path d="M8.5 10L15.5 14" />
      </>
    )}
    {status === 'UNKNOWN' && (
      <>
        <path d="M15.312 2C15.8424 2.00011 16.351 2.2109 16.726 2.586L21.414 7.274C21.7891 7.64899 21.9999 8.15761 22 8.688V15.312C21.9999 15.8424 21.7891 16.351 21.414 16.726L16.726 21.414C16.351 21.7891 15.8424 21.9999 15.312 22H8.688C8.15761 21.9999 7.64899 21.7891 7.274 21.414L2.586 16.726C2.2109 16.351 2.00011 15.8424 2 15.312V8.688C2.00011 8.15761 2.2109 7.64899 2.586 7.274L7.274 2.586C7.64899 2.2109 8.15761 2.00011 8.688 2H15.312Z" />
        <path d="M12 17H12.01" />
        <path d="M9.1 8.99992C9.33998 8.33855 9.80477 7.78257 10.4131 7.42915C11.0215 7.07574 11.7347 6.94738 12.4281 7.06653C13.1215 7.18567 13.751 7.54472 14.2065 8.08093C14.662 8.61714 14.9145 9.29638 14.92 9.99992C14.92 11.9999 11.92 12.9999 11.92 12.9999" />
      </>
    )}
    {status === 'E_STOP_PRESSED' && (
      <>
        <path d="M12 16H12.01" stroke="black" />
        <path d="M12 8V12" />
        <path d="M15.312 2C15.8424 2.00011 16.351 2.2109 16.726 2.586L21.414 7.274C21.7891 7.64899 21.9999 8.15761 22 8.688V15.312C21.9999 15.8424 21.7891 16.351 21.414 16.726L16.726 21.414C16.351 21.7891 15.8424 21.9999 15.312 22H8.688C8.15761 21.9999 7.64899 21.7891 7.274 21.414L2.586 16.726C2.2109 16.351 2.00011 15.8424 2 15.312V8.688C2.00011 8.15761 2.2109 7.64899 2.586 7.274L7.274 2.586C7.64899 2.2109 8.15761 2.00011 8.688 2H15.312Z" />
      </>
    )}
  </svg>
);

export { SpyderStatusIcon };
