import { ISectionImage } from 'components/image_feed/types';
import { FC } from 'react';
import { Rect, Text } from 'react-konva';

const defaultWidth = 200;
const defaultHeight = 35;
const heightRatio = 0.3;
const defaultFontSize = 16;
const fontRatio = 0.1;
const offset = 15;

type TImageSectionInfoDebugProps = {
  cellX: number;
  cellY: number;
  measurementId: Nullable<number>;
  runId: Nullable<number>;
  sectionImage: Maybe<ISectionImage>;
};
const ImageSectionInfoDebug: FC<TImageSectionInfoDebugProps> = ({
  sectionImage,
  cellX,
  cellY,
  measurementId,
  runId,
}) => {
  if (!sectionImage) {
    return null;
  }
  return (
    <>
      <Rect
        key={cellY}
        height={(sectionImage.height ?? defaultHeight) * heightRatio}
        width={(sectionImage.width ?? defaultWidth) - offset}
        x={(sectionImage.x ?? 0) + offset}
        y={sectionImage.y ?? 0}
        fill={'white'}
      />
      <Text
        x={(sectionImage.x ?? 0) + offset}
        y={sectionImage.y ?? 0}
        text={`cellX: ${cellX} cellY: ${cellY}\nmeasurement id: ${measurementId}\nrun id: ${runId}`}
        fontSize={(sectionImage.height ?? defaultFontSize) * fontRatio}
        fontStyle={'bold'}
        fill={'black'}
      />
    </>
  );
};

export default ImageSectionInfoDebug;
