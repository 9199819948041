import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { mergeRefs } from 'react-merge-refs';
import { cn } from 'shared/utils/cn';
import { Button } from './Button/Button';

const COMBINED_ARROWS_WIDTH_PIXELS = 2 * (36 + 36); // = 2 * (width of arrow icon + width of gradient)

interface HorizontallyScrollableProps extends ComponentPropsWithoutRef<'div'> {}

export const HorizontallyScrollable = forwardRef<
  HTMLDivElement,
  HorizontallyScrollableProps
>(function HorizontallyScrollable({ children, className, ...props }, ref) {
  const localRef = useRef<HTMLDivElement>(null);
  const [showForwardIcon, setShowForwardIcon] = useState(false);
  const [showBackwardicon, setShowBackwardIcon] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (localRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = localRef.current;
        setShowForwardIcon(scrollLeft + clientWidth < scrollWidth - 1);
        setShowBackwardIcon(scrollLeft > 0);
      }
    };
    const handleWheel = (e: WheelEvent) => {
      if (localRef.current && Math.abs(e.deltaY) > Math.abs(e.deltaX)) {
        e.preventDefault();
        localRef.current.scrollLeft += e.deltaY;
      }
    };
    const scopeRef = localRef.current;
    if (scopeRef) {
      handleScroll();
      scopeRef.addEventListener('scroll', handleScroll);
      scopeRef.addEventListener('wheel', handleWheel);

      return () => {
        scopeRef.removeEventListener('scroll', handleScroll);
        scopeRef.removeEventListener('wheel', handleWheel);
      };
    }
  }, [children]);

  const scrollLeft = () => {
    if (!localRef.current) return;
    const amount = -localRef.current.clientWidth + COMBINED_ARROWS_WIDTH_PIXELS;
    localRef.current?.scrollBy({ left: amount, behavior: 'smooth' });
  };

  const scrollRight = () => {
    if (!localRef.current) return;
    const amount = localRef.current.clientWidth - COMBINED_ARROWS_WIDTH_PIXELS;
    localRef.current?.scrollBy({ left: amount, behavior: 'smooth' });
  };

  return (
    <div
      {...props}
      className={cn('relative min-w-0 flex flex-col justify-center', className)}
    >
      {showBackwardicon && (
        <div className="z-10 absolute top-0 left-0 h-full pointer-events-none flex justify-center items-stretch">
          <Button
            size="icon"
            variant="secondary"
            className="size-fit pointer-events-auto self-center"
            onClick={scrollLeft}
            aria-label="Scroll left"
          >
            <ChevronLeftIcon className="icon" />
          </Button>
        </div>
      )}
      <div
        ref={mergeRefs([ref, localRef])}
        className={cn(
          'w-full no-scrollbar overflow-y-auto',
          showBackwardicon &&
            '[mask-image:linear-gradient(to_left,_black_80%,_transparent_100%)]',
          showForwardIcon &&
            '[mask-image:linear-gradient(to_right,_black_80%,_transparent_100%)]'
        )}
      >
        {children}
      </div>
      {showForwardIcon && (
        <div className="z-10 absolute top-0 right-0 h-full pointer-events-none flex justify-center items-stretch">
          <Button
            size="icon"
            variant="secondary"
            className="size-fit pointer-events-auto self-center"
            onClick={scrollRight}
            aria-label="Scroll right"
          >
            <ChevronRightIcon className="icon" />
          </Button>
        </div>
      )}
    </div>
  );
});
