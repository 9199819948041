import { format } from 'date-fns';

export function formatGrowthCycleDaterange(
  startTime: number,
  endTime: number
): string {
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  const sameYear = startDate.getFullYear() === endDate.getFullYear();

  const startDateFormatted = format(
    startDate,
    `MMM d${!sameYear ? ', yyyy' : ''}`
  );
  const endDateFormatted = format(endDate, `MMM d, yyyy`);
  return `${startDateFormatted}-${endDateFormatted}`;
}
