import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * A function that allows the conditional classnames from 'clsx' or 'classnames' to be passed into 'tailwind-merge'
 *
 * Combining `clsx` with `tailwind-merge` allows us to conditionally join Tailwind CSS classes in
 * classNames together without style conflicts. Inspired by shadcn/ui.
 *
 * Copy from https://akhilaariyachandra.com/snippets/using-clsx-or-classnames-with-tailwind-merge
 */
export function cn(...args: ClassValue[]) {
  return twMerge(clsx(args));
}
