import { useUpdateGrowthCycleMetadata } from 'api/growth-cycle';
import { Button } from 'components/common/Button/Button';
import { Modal } from 'components/common/Modal/Modal';
import * as toast from 'components/common/Toast/Toast';
import { Disclosure } from 'hooks/useDisclosure';
import { useState } from 'react';
import {
  TGrowthCycle,
  TGrowthCycleEdit,
  TPlantInfo,
} from 'shared/interfaces/growthCycle';
import { EditCultivars } from './EditCultivars';

export type ManageCultivarsProps = {
  cycle: TGrowthCycle;
  disclosure: Disclosure;
};

/**
 * Add, edit, and remove cultivars.
 */
export const ManageCultivars = ({
  cycle,
  disclosure,
}: ManageCultivarsProps) => {
  const [localCycle, setLocalCycle] = useState<TGrowthCycleEdit>(
    cycle as TGrowthCycleEdit
  );
  const { update, loading } = useUpdateGrowthCycleMetadata();
  const handleChange = (cultivars: TPlantInfo[]) => {
    setLocalCycle((prev) => ({ ...prev, cultivars }));
  };
  const handleSave = async () => {
    try {
      await update(localCycle);
      disclosure.close();
    } catch (_error) {
      toast.error({
        content:
          'Something went wrong while saving the cultivars. Please try again.',
      });
    }
  };

  return disclosure.isOpen ? (
    <Modal open={disclosure.isOpen}>
      <Modal.Header closeButtonProps={{ onClick: disclosure.close }} />
      <Modal.Content>
        <EditCultivars
          cultivars={localCycle.cultivars ?? []}
          onChange={handleChange}
        />
      </Modal.Content>
      <Modal.Footer className="flex w-full flex-col justify-between gap-3 self-center sm:w-[592px] md:flex-row md:px-0">
        <Button variant="secondary" onClick={disclosure.close}>
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={loading}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  ) : null;
};
