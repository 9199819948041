import { useAuth } from 'contexts/AuthProvider';
import {
  GetUserByEmailDocument,
  GetUsersByOrganizationDocument,
  useGetNonKioskUsersWithZoneViewPermissionQuery,
  useUpdateUserMetadataMutation,
} from 'graphql/generated/react_apollo';
import isNil from 'lodash.isnil';
import { TMention } from 'shared/interfaces/discussion';
import { UserMetadata } from 'shared/interfaces/user';

export const useUpdateUserMetadata = () => {
  const [mutation, result] = useUpdateUserMetadataMutation();
  const update = (userId: number, metadata: UserMetadata) => {
    return mutation({
      variables: {
        id: userId,
        metadata,
      },
      refetchQueries: [GetUserByEmailDocument, GetUsersByOrganizationDocument],
    });
  };
  return { ...result, update };
};

const useGetTaggableUsers = (zoneUid?: string) => {
  const { isNeatleafOrganizationMember, user } = useAuth();

  const { data: taggableUsersData, ...result } =
    useGetNonKioskUsersWithZoneViewPermissionQuery({
      variables: {
        zoneUid: zoneUid!,
        customAndCondition: isNeatleafOrganizationMember
          ? {}
          : {
              _and: [
                { id: { _neq: user?.id! } },
                {
                  _or: [
                    { email: { _nlike: '%neatleaf.com' } },
                    {
                      metadata: {
                        _contains: { alwaysVisibleForTagging: true },
                      },
                    },
                  ],
                },
              ],
            },
      },
      skip: isNil(zoneUid) || isNil(user?.id),
    });
  const taggableUsers = (taggableUsersData?.user || []).filter(
    (taggableUser) => !isNil(user?.id) && taggableUser.id !== user.id
  );

  return { taggableUsers, ...result };
};

export const useAvailableMentions = (zoneUid?: string): TMention[] => {
  const { taggableUsers } = useGetTaggableUsers(zoneUid);
  return taggableUsers?.map((user) => ({
    name: user.first_name + ' ' + user.last_name,
    email: user.email,
    user_id: user.id,
  }));
};
