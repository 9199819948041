import { useGetDiscussions } from 'api/discussion';
import { Button } from 'components/common/Button/Button';
import { DiscussionBox } from 'components/discussions/DiscussionBox';
import { useAuth } from 'contexts/AuthProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { usePermissions } from 'hooks/usePermissions';
import isNil from 'lodash.isnil';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TDiscussion } from 'shared/interfaces/discussion';
import { ISectionInformation } from 'shared/interfaces/image';
import { Cluster, clusterRectangles } from 'shared/utils/geometry';
import { BoundingBoxAnnotation } from './BoundingBoxAnnotation';

export const ImageMainAnnotations = ({
  imagesGrid,
  onMouseEvent,
  sectionSize,
  onAnnotationClick,
  scale,
  position,
}: {
  imagesGrid: ISectionInformation[][];
  onMouseEvent: (e: MouseEvent | WheelEvent) => void;
  sectionSize: TSize;
  onAnnotationClick: (
    sectionInformation: ISectionInformation,
    discussion: TDiscussion
  ) => void;
  scale: TScale;
  position: TPosition;
}) => {
  const { user } = useAuth();
  const { currentZone } = useCurrentZone();
  const { insights } = usePermissions();

  const sectionsInformation = imagesGrid.flat();
  const measurementIds = sectionsInformation
    .map(({ measurementId }) => measurementId)
    .filter((element) => !isNil(element)) as number[];

  const { discussions } = useGetDiscussions({
    measurementIds,
    annotationTypes: ['single_image_annotation'],
    zone: currentZone,
    canViewInsighDraft: insights.canViewDraft,
    userId: user?.id!,
  });

  const discussionsInfo = discussions.reduce(
    (acc, discussion) => {
      if (!discussion.area?.points) return acc;
      const sectionInformation = sectionsInformation.find(
        (section) => section.measurementId === discussion.measurementId
      );
      if (!sectionInformation) return acc;
      const left =
        (sectionInformation.cellX + discussion.area.points[0].x) *
        sectionSize.width;
      const top =
        (sectionInformation.cellY + discussion.area.points[0].y) *
        sectionSize.height;
      const width =
        (discussion.area.points[1].x - discussion.area.points[0].x) *
        sectionSize.width;
      const height =
        (discussion.area.points[1].y - discussion.area.points[0].y) *
        sectionSize.height;

      const label = discussion.displayLabel;
      acc[discussion.uid] = {
        left,
        top,
        width,
        height,
        label,
        onClick: () => onAnnotationClick(sectionInformation, discussion),
        onMouseEvent,
      };
      return acc;
    },
    {} as Record<
      string,
      {
        left: number;
        top: number;
        width: number;
        height: number;
        label: string;
        onClick: () => void;
        onMouseEvent: (e: MouseEvent | WheelEvent) => void;
      }
    >
  );

  const [clusters, setClusters] = useState<Cluster[]>([]);

  const clusterDiscussions = useCallback(
    (zoomLevel: number) => {
      const rectangles = discussions
        .filter((discussion) => discussion.area?.points)
        .map((discussion) => ({
          x: discussionsInfo[discussion.uid]?.left!,
          y: discussionsInfo[discussion.uid]?.top!,
          width: discussionsInfo[discussion.uid]?.width!,
          height: discussionsInfo[discussion.uid]?.height!,
          uid: discussion.uid,
        }));

      setClusters(clusterRectangles(rectangles, zoomLevel));
    },
    [discussions, discussionsInfo]
  );

  useEffect(() => {
    if (selectedCluster) setSelectedCluster(null);
    clusterDiscussions(scale.x);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discussions, scale.x]);

  const [selectedCluster, setSelectedCluster] = useState<Cluster | null>(null);
  const selectedClusterDiscussionIds =
    selectedCluster?.rectangles.map((rectangle) => rectangle.uid) ?? [];
  const selectedClusterDiscussions = discussions.filter((discussion) =>
    selectedClusterDiscussionIds.includes(discussion.uid)
  );
  const [activeDiscussionUid, setActiveDiscussionUid] = useState<string>();
  const activeDiscussion = useMemo(
    () =>
      discussions.find((discussion) => discussion.uid === activeDiscussionUid),
    [activeDiscussionUid, discussions]
  );

  const clusterElementRef = useRef<HTMLDivElement>(null);

  return (
    <div onClick={() => selectedCluster && setSelectedCluster(null)}>
      {clusters
        .map((cluster, index) => {
          if (cluster.rectangles.length === 1) {
            const discussionUid = cluster.rectangles[0]!.uid;
            if (!discussionsInfo[discussionUid]) return null;
            return (
              <BoundingBoxAnnotation
                key={discussionUid}
                {...discussionsInfo[discussionUid]!}
                left={
                  discussionsInfo[discussionUid]!.left * scale.x + position.x
                }
                top={discussionsInfo[discussionUid]!.top * scale.y + position.y}
                width={discussionsInfo[discussionUid]!.width * scale.x}
                height={discussionsInfo[discussionUid]!.height * scale.y}
              />
            );
          }
          return (
            <>
              {selectedCluster === cluster && (
                <div
                  ref={clusterElementRef}
                  className="opacity-0 pointer-events-none absolute"
                  style={(() => {
                    const clusterRangeLeft = Math.min(
                      ...cluster.rectangles.map((rectangle) => rectangle.x)
                    );
                    const clusterRangeRight = Math.max(
                      ...cluster.rectangles.map(
                        (rectangle) => rectangle.x + rectangle.width
                      )
                    );
                    const clusterRangeTop = Math.min(
                      ...cluster.rectangles.map((rectangle) => rectangle.y)
                    );
                    const clusterRangeBottom = Math.max(
                      ...cluster.rectangles.map(
                        (rectangle) => rectangle.y + rectangle.height
                      )
                    );
                    const clusterRangeWidth =
                      clusterRangeRight - clusterRangeLeft;
                    const clusterRangeHeight =
                      clusterRangeBottom - clusterRangeTop;
                    return {
                      left: clusterRangeLeft * scale.x + position.x,
                      top: clusterRangeTop * scale.y + position.y,
                      width: clusterRangeWidth * scale.x,
                      height: clusterRangeHeight * scale.y,
                    };
                  })()}
                />
              )}
              <Button
                key={`${index}-cluster-button-${selectedCluster === cluster ? 'selected' : 'unselected'}`}
                variant={selectedCluster === cluster ? 'primary' : 'secondary'}
                className="cursor-pointer font-normal p-0 min-w-6 shadow absolute text-xs rounded-full min-h-6 h-6 flex items-center justify-center"
                style={{
                  left: cluster.centroid.x * scale.x + position.x,
                  top: cluster.centroid.y * scale.y + position.y,
                  transformOrigin: 'left bottom',
                  transform: `translate(0%, -100%)`,
                  opacity:
                    activeDiscussion && selectedCluster === cluster ? 0.5 : 1,
                }}
                onClick={() => setSelectedCluster(cluster)}
              >
                {cluster.rectangles.length}
              </Button>
            </>
          );
        })
        .filter(Boolean)}
      {activeDiscussion && (
        <BoundingBoxAnnotation
          {...discussionsInfo[activeDiscussion.uid]!}
          left={
            discussionsInfo[activeDiscussion.uid]!.left * scale.x + position.x
          }
          top={
            discussionsInfo[activeDiscussion.uid]!.top * scale.y + position.y
          }
          width={discussionsInfo[activeDiscussion.uid]!.width * scale.x}
          height={discussionsInfo[activeDiscussion.uid]!.height * scale.y}
          showBox
        />
      )}
      {selectedCluster && (
        <DiscussionBox
          referenceElement={clusterElementRef}
          onSelectDiscussion={(discussionUid) =>
            discussionUid && discussionsInfo[discussionUid]?.onClick()
          }
          onClose={() => {
            setSelectedCluster(null);
            setActiveDiscussionUid(undefined);
          }}
          discussions={selectedClusterDiscussions}
          onChangeActiveDiscussion={setActiveDiscussionUid}
          closeOnWheel
        />
      )}
    </div>
  );
};
