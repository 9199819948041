import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
  MutationMeta,
  QueryClient,
  QueryClientProvider,
  QueryMeta,
  useQueryClient,
} from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import { measurementRunIdToHighestLoadedIndex } from 'global_state/image_feed_cache';
import ky from 'ky';
import { ReactNode } from 'react';
import { AppConfig } from 'shared/config/AppConfig';

const apolloCache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        measurementRunIdToHighestLoadedIndex: {
          read() {
            return measurementRunIdToHighestLoadedIndex();
          },
        },
      },
    },
    discussion: {
      keyFields: ['uid'],
    },
    comment: {
      keyFields: ['uid'],
    },
    user_comment: {
      keyFields: ['comment_uid', 'user_id'],
    },
  },
});

const HTTP_CLIENT = ky.extend({
  hooks: {
    beforeRequest: [
      (request) => {
        // Set Content-Type as application/json by default
        request.headers.set('Content-Type', 'application/json');
      },
    ],
  },
});

interface ApiProviderProps {
  appConfig?: AppConfig;
  children?: ReactNode;
}

export const ApiProvider = ({ appConfig, children }: ApiProviderProps) => {
  const authLink = setContext(async (_, { headers }) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const httpLink = createHttpLink({
    uri: appConfig
      ? appConfig.hasura.graphqlEndpoint.toString()
      : window.location.origin,
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: apolloCache,
  });

  const apiUrl = appConfig ? appConfig.apiUrl : window.location.origin;

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        meta: { apiUrl },
        cacheTime: 0,
        retry: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
      },
      mutations: {
        meta: { apiUrl },
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </QueryClientProvider>
  );
};

interface ApiMeta extends QueryMeta, MutationMeta {
  apiUrl: URL | string;
}

export function useApi(basicAuth = true) {
  const options = useQueryClient().getDefaultOptions();
  let { apiUrl = window.location.origin } = (options.queries?.meta ??
    options.mutations?.meta ??
    {}) as ApiMeta;

  const httpClient = HTTP_CLIENT.extend({
    hooks: {
      beforeRequest: [
        async (request) => {
          const token = (await Auth.currentSession())
            .getAccessToken()
            .getJwtToken();
          basicAuth && request.headers.set('Authorization', token);
        },
      ],
    },
  });

  return { apiUrl, httpClient };
}
