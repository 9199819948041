import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const PpfdIcon = ({
  stroke = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    stroke={stroke}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={cn('h-6 w-6', className)}
    {...props}
  >
    <path d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z" />
    <path d="M2 10.7373V8.7373" />
    <path d="M6.99702 10.1023L6 8.37537" />
    <path d="M10.1983 6.50804L8.48254 5.49207" />
    <path d="M8.77737 2H10.7774" />
    <path d="M7.03094 21.7098C7.03094 19.4973 8.39533 17.7568 10.7775 17.2848C12.5622 16.9308 14.406 15.8098 15.1435 15.0723" />
    <path d="M13.6685 20.9724C12.3735 20.9763 11.1243 20.4933 10.1687 19.6193C9.21308 18.7453 8.62085 17.5441 8.50947 16.2539C8.39808 14.9637 8.77567 13.6787 9.56736 12.6538C10.359 11.629 11.507 10.9391 12.7835 10.721C16.9873 9.90978 18.0935 9.52628 19.5686 7.69727C20.3061 9.17228 21.0436 10.78 21.0436 13.5973C21.0436 17.6536 17.5183 20.9724 13.6685 20.9724Z" />
  </svg>
);

export { PpfdIcon };
