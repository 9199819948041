import {
  AppConfig,
  AwsCognitoConfig,
  AwsConfig,
  AwsLoggingConfig,
  AwsRegion,
  Environment,
  HasuraConfig,
  MixpanelConfig,
  ReleaseConfig,
  SentryConfig,
} from './AppConfig';
import {
  getBoolean,
  getEnumValue,
  getOptionalString,
  getString,
  getUrl,
} from './config-utils';

/**
 * Create an AppConfig object from an object of config values
 */
export function createAppConfig(values: Record<string, string>): AppConfig {
  const appConfig: AppConfig = {
    apiUrl: getUrl(values, 'API_URL'),
    assetsUrl: getUrl(values, 'ASSETS_URL'),
    aws: createAwsConfig(values),
    environment: getEnumValue(values, 'ENV', Environment),
    hasura: createHasuraConfig(values),
    mixpanel: createMixpanelConfig(values),
    release: createReleaseConfig(values, 'dashboard'),
    sentry: createSentryConfig(values),
  };

  return appConfig;
}

/**
 * Create an AWS config object from an object of config values
 */
function createAwsConfig(values: Record<string, string>): AwsConfig {
  const awsConfig: AwsConfig = {
    cognito: createAwsCognitoConfig(values),
    logging: createAwsLoggingConfig(values),
  };

  return awsConfig;
}

/**
 * Create an AWS Cognito config object from an object of config values
 */
function createAwsCognitoConfig(
  values: Record<string, string>
): AwsCognitoConfig {
  const awsCognitoConfig: AwsCognitoConfig = {
    identityPoolId: getString(values, 'AWS_COGNITO_IDENTITY_POOL_ID'),
    region: getEnumValue(values, 'AWS_COGNITO_REGION', AwsRegion),
    userPoolId: getString(values, 'AWS_COGNITO_USER_POOL_ID'),
    webClientId: getString(values, 'AWS_COGNITO_WEB_CLIENT_ID'),
  };
  return awsCognitoConfig;
}

/**
 * Create an AWS Logging config object from an object of config values
 */
function createAwsLoggingConfig(
  values: Record<string, string>
): AwsLoggingConfig {
  const awsLoggingConfig: AwsLoggingConfig = {
    projectRegion: getEnumValue(values, 'AWS_PROJECT_REGION', AwsRegion),
  };
  return awsLoggingConfig;
}

/**
 * Create a Mixpanel config object from an object of config values
 */
function createMixpanelConfig(values: Record<string, string>): MixpanelConfig {
  const apiToken = getString(values, 'MIXPANEL_API_TOKEN');
  const mixpanelConfig: MixpanelConfig = {
    apiToken,
    enabled: getBoolean(values, 'MIXPANEL_ENABLED'),
    debuggingEnabled: getBoolean(values, 'MIXPANEL_DEBUGGING_ENABLED'),
  };
  return mixpanelConfig;
}

/**
 * Create a Hasura config object from an object of config values
 */
function createHasuraConfig(values: Record<string, string>): HasuraConfig {
  const hasuraConfig: HasuraConfig = {
    graphqlEndpoint: getUrl(values, 'HASURA_GRAPHQL_ENDPOINT'),
  };

  return hasuraConfig;
}

/**
 * Create a Sentry config object from an object of config values
 */
function createSentryConfig(values: Record<string, string>): SentryConfig {
  const sentryConfig: SentryConfig = {
    dsn: getUrl(values, 'SENTRY_DSN'),
    release: getString(values, 'SENTRY_RELEASE'),
  };

  return sentryConfig;
}

function createReleaseConfig(
  configValues: Record<string, string>,
  applicationName: string
): ReleaseConfig {
  const branchName = getOptionalString(configValues, 'COMMIT_BRANCH');
  const tagName = getOptionalString(configValues, 'COMMIT_TAG');
  const commitShortSha = getString(configValues, 'COMMIT_SHORT_SHA');
  const commitSha = getString(configValues, 'COMMIT_SHA');
  const tagVersion = tagName && getTagVersion(tagName);
  return {
    branchName,
    commitSha,
    commitShortSha,
    releaseName: getRelease(
      applicationName,
      commitShortSha,
      branchName,
      tagName
    ),
    tagName,
    tagVersion,
  };
}

function getTagVersion(tagName: string): string {
  const match = tagName.match(/[0-9]+\.[0-9]+\.[0-9]+/);
  if (!match?.length) {
    throw new Error(`Could not get tag version from tag name ${tagName}`);
  }
  return match[0];
}

function getRelease(
  applicationName: string,
  commitHash: string,
  branchName: string | undefined,
  tagName: string | undefined
): string {
  if (tagName) {
    return `${applicationName}@${tagName}`;
  }

  // We can use max. 200 characters for a release in sentry
  // and no forward slashes are allowed
  // https://docs.sentry.io/cli/releases/
  const sanitizedBranchName = (branchName || '').replace('/', '__');
  const refName = sanitizedBranchName.substring(
    0,
    200 - (applicationName.length + commitHash.length + 2)
  );

  return `${applicationName}@${refName}-${commitHash}`;
}
