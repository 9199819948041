import { addDays } from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import {
  EnvironmentRecipe,
  EnvironmentRecipeEntry,
  EnvironmentRecipeEntryInsertInput,
  GetGrowthCyclesWithRecipesByZoneIdDocument,
  GetGrowthCyclesWithRecipesByZoneIdQuery,
  GetGrowthCyclesWithRecipesByZoneIdQueryResult,
  GetRecipesByLocationIdDocument,
  GrowthCycle,
  LightCycleRecipe,
  LightCycleRecipeEntry,
  LightCycleRecipeEntryInsertInput,
  useGetEnumerationsByTypeQuery,
  useGetGrowthCycleByDatesQuery,
  useGetGrowthCyclesWithRecipesByZoneIdQuery,
  useGetRecipesByLocationIdQuery,
  useInsertEnvironmentRecipeMutation,
  useInsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutation,
  useInsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutation,
  useInsertGrowthCycleAndUpdateRecipesMutation,
  useInsertGrowthCycleWithRecipesMutation,
  useInsertLightCycleRecipeMutation,
  useRemoveEnvironmentRecipeMutation,
  useRemoveGrowthCycleWithRecipesMutation,
  useRemoveLightCycleRecipeMutation,
  useUpdateEnvironmentRecipeMutation,
  useUpdateGrowthCycleMetadataMutation,
  useUpdateGrowthCycleWithRecipesMutation,
  useUpdateLightCycleRecipeMutation,
} from 'graphql/generated/react_apollo';
import isNil from 'lodash.isnil';
import { useCallback, useMemo } from 'react';
import { EEnumerationTypes } from 'shared/interfaces/enumeration';
import {
  EnvironmentalPreset,
  GrowthCycleMetadata,
  LightCycle,
  LightCyclesPreset,
  Parameters,
  Preset,
  Setting,
  TGrowthCycle,
  TGrowthCycleEdit,
  TPlantInfo,
} from 'shared/interfaces/growthCycle';
import {
  EMontioringParameterVersion,
  MeasurementTypeConfig,
} from 'shared/interfaces/measurement';
import { TOrganization } from 'shared/interfaces/organization';
import { TZone } from 'shared/interfaces/zone';
import { convertIf } from 'shared/utils/miscellaneous';
import { v4 as uuidv4 } from 'uuid';
import { useRecalculateHealthScoreMutation } from './health-score';

/**
 * GROWTH CYCLES
 */

export const useGrowthCyclesWithRecipes = ({
  zoneId,
  zoneTimeZone,
  presetTypes,
}: {
  zoneId?: number;
  zoneTimeZone: string;
  presetTypes: MeasurementTypeConfig[];
}): Omit<
  GetGrowthCyclesWithRecipesByZoneIdQueryResult,
  'data' | 'previousData' | 'refetch'
> & {
  growthCycles: TGrowthCycle[];
  refetch: (zoneId: number) => Promise<TGrowthCycle[]>;
} => {
  const {
    data,
    refetch: rawRefetch,
    ...result
  } = useGetGrowthCyclesWithRecipesByZoneIdQuery({
    variables: { zone_id: zoneId! },
    skip: isNil(zoneId),
  });

  const transform = useCallback(
    (rawData: GetGrowthCyclesWithRecipesByZoneIdQuery | undefined) =>
      (rawData?.growth_cycle ?? [])
        .map<TGrowthCycle>((cycle) =>
          growthCycleMapper({
            cycle: cycle as GrowthCycle,
            zoneTimeZone,
            presetTypes,
          })
        )
        .sort((a, b) => a.start_time - b.start_time),
    [presetTypes, zoneTimeZone]
  );

  const growthCycles = useMemo(() => transform(data), [data, transform]);

  const refetch = async (zoneId: number) => {
    const { data: refetchData } = await rawRefetch({ zone_id: zoneId });
    return transform(refetchData);
  };

  return { growthCycles, refetch, ...result };
};

export const useGrowthCyclesByDates = ({
  zoneId,
  zoneTimeZone,
  presetTypes,
  start,
  end,
}: {
  zoneId?: number;
  zoneTimeZone: string;
  presetTypes: MeasurementTypeConfig[];
  start: Date;
  end: Date;
}) => {
  const { data, ...result } = useGetGrowthCycleByDatesQuery({
    variables: {
      zone_id: zoneId!,
      start: fromZonedTime(start, zoneTimeZone),
      end: fromZonedTime(end, zoneTimeZone),
    },
    skip: isNil(zoneId),
  });

  const growthCycle = useMemo(
    () =>
      data && data.growth_cycle[0]
        ? growthCycleMapper({
            cycle: data.growth_cycle[0] as GrowthCycle,
            zoneTimeZone,
            presetTypes,
          })
        : undefined,
    [data, presetTypes, zoneTimeZone]
  );

  return { growthCycle, ...result };
};

export const useInsertGrowthCycleWithPresets = ({
  organization,
  zone,
  presetTypes,
}: {
  organization: TOrganization;
  zone: TZone;
  presetTypes: MeasurementTypeConfig[];
}) => {
  const [insertMutation, insertResult] =
    useInsertGrowthCycleWithRecipesMutation();
  const [updateRecipesMutation, updateRecipesResult] =
    useInsertGrowthCycleAndUpdateRecipesMutation();
  const [updateLightCycleRecipeMutation, updateLightCycleRecipeResult] =
    useInsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutation();
  const [updateEnvironmentRecipeMutation, updateEnvironmentRecipeResult] =
    useInsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutation();
  const loading =
    insertResult.loading ||
    updateRecipesResult.loading ||
    updateLightCycleRecipeResult.loading ||
    updateEnvironmentRecipeResult.loading;
  const error =
    insertResult.error ||
    updateRecipesResult.error ||
    updateLightCycleRecipeResult.error ||
    updateEnvironmentRecipeResult.error;
  const findTypeId = useFindTypeIdByCode();
  const { mutate } = useRecalculateHealthScoreMutation();
  const insert = (cycle: TGrowthCycleEdit) => {
    const startTime = fromZonedTime(cycle.start_time, zone.timeZone);
    const endTime = fromZonedTime(cycle.end_time, zone.timeZone);
    const base = {
      variables: {
        zone_id: cycle.zone_id,
        start_time: startTime,
        end_time: endTime,
        metadata: {
          ...cultivarsWriteMapper(cycle),
          use_segmentation_model: true,
        },
        location_id: zone.locationId,
        organization_id: organization.id,
        env_recipe_name: cycle.environmentalPreset?.name!,
        lc_recipe_name: cycle.lightCyclesPreset?.name!,
      },
      refetchQueries: [
        GetGrowthCyclesWithRecipesByZoneIdDocument,
        GetRecipesByLocationIdDocument,
      ],
      onCompleted: () => mutate(zone.uid),
    };

    /**
     * Inserting a new growth cycle will land on one of these 4 scenarios:
     * 1. None of the presets exist yet. None of the presets have IDs set. Will insert two new recipes.
     * 2. Both presets already exist. Both presets have IDs. Will update both recipes.
     * 3. Reusing an existing light cycle preset. Will insert a new environment recipe.
     * 4. Reusing an existing environment preset. Will insert a new light cycle recipe.
     */

    if (
      !isNil(cycle.environmentalPreset?.id) &&
      !isNil(cycle.lightCyclesPreset?.id)
    ) {
      // 2.
      return updateRecipesMutation({
        ...base,
        variables: {
          ...base.variables,
          env_recipe_id: cycle.environmentalPreset?.id,
          env_recipe_entries: environmentRecipeEntriesMapper({
            findTypeId,
            preset: cycle.environmentalPreset,
            presetTypes,
          }),
          lc_recipe_id: cycle.lightCyclesPreset?.id,
          lc_recipe_entries: lightCyclesRecipeEntriesMapper(
            cycle.lightCyclesPreset
          ),
        },
      });
    } else if (
      isNil(cycle.environmentalPreset?.id) &&
      !isNil(cycle.lightCyclesPreset?.id)
    ) {
      // 3.
      return updateLightCycleRecipeMutation({
        ...base,
        variables: {
          ...base.variables,
          env_recipe_entries: {
            data: environmentRecipeEntriesMapper({
              findTypeId,
              preset: cycle.environmentalPreset,
              presetTypes,
            }),
          },
          lc_recipe_id: cycle.lightCyclesPreset?.id,
          lc_recipe_entries: lightCyclesRecipeEntriesMapper(
            cycle.lightCyclesPreset
          ),
        },
      });
    } else if (
      !isNil(cycle.environmentalPreset?.id) &&
      isNil(cycle.lightCyclesPreset?.id)
    ) {
      // 4.
      return updateEnvironmentRecipeMutation({
        ...base,
        variables: {
          ...base.variables,
          env_recipe_id: cycle.environmentalPreset?.id,
          env_recipe_entries: environmentRecipeEntriesMapper({
            findTypeId,
            preset: cycle.environmentalPreset,
            presetTypes,
          }),
          lc_recipe_entries: {
            data: lightCyclesRecipeEntriesMapper(cycle.lightCyclesPreset),
          },
        },
      });
    }

    // 1.
    return insertMutation({
      ...base,
      variables: {
        ...base.variables,
        env_recipe_entries: {
          data: environmentRecipeEntriesMapper({
            findTypeId,
            preset: cycle.environmentalPreset,
            presetTypes,
          }),
        },
        lc_recipe_entries: {
          data: lightCyclesRecipeEntriesMapper(cycle.lightCyclesPreset),
        },
      },
    });
  };

  return { insert, loading, error };
};

export const useUpdateGrowthCycleWithPresets = ({
  zone,
  presetTypes,
}: {
  zone: TZone;
  presetTypes: MeasurementTypeConfig[];
}) => {
  const [mutation, result] = useUpdateGrowthCycleWithRecipesMutation();
  const findTypeId = useFindTypeIdByCode();
  const { mutate } = useRecalculateHealthScoreMutation();
  const update = (cycle: TGrowthCycleEdit) =>
    mutation({
      variables: {
        growth_cycle_id: cycle.id,
        start_time: fromZonedTime(cycle.start_time, zone.timeZone),
        end_time: fromZonedTime(cycle.end_time, zone.timeZone),
        metadata: cultivarsWriteMapper(cycle),
        env_recipe_id: cycle.environmentalPreset!.id!,
        env_recipe_name: cycle.environmentalPreset!.name,
        env_recipe_entries: environmentRecipeEntriesMapper({
          findTypeId,
          preset: cycle.environmentalPreset,
          presetTypes,
        }),
        lc_recipe_id: cycle.lightCyclesPreset!.id!,
        lc_recipe_name: cycle.lightCyclesPreset!.name,
        lc_recipe_entries: lightCyclesRecipeEntriesMapper(
          cycle.lightCyclesPreset
        ),
      },
      refetchQueries: [
        GetGrowthCyclesWithRecipesByZoneIdDocument,
        GetRecipesByLocationIdDocument,
      ],
      onCompleted: () => mutate(zone.uid),
    });

  return { ...result, update };
};

export const useRemoveGrowthCycleWithPresets = () => {
  const [mutation, result] = useRemoveGrowthCycleWithRecipesMutation();

  const remove = (cycle: TGrowthCycle) =>
    mutation({
      variables: {
        growth_cycle_id: cycle.id,
      },
      refetchQueries: [GetGrowthCyclesWithRecipesByZoneIdDocument],
    });

  return { ...result, remove };
};

export const useUpdateGrowthCycleMetadata = () => {
  const [mutation, result] = useUpdateGrowthCycleMetadataMutation();
  const update = (cycle: TGrowthCycleEdit) =>
    mutation({
      variables: {
        growth_cycle_id: cycle.id,
        metadata: cultivarsWriteMapper(cycle),
      },
      refetchQueries: [GetGrowthCyclesWithRecipesByZoneIdDocument],
    });

  return { ...result, update };
};

/**
 * RECIPES
 */

export const useGetPresetsByLocationId = ({
  locationId,
  presetTypes,
}: {
  locationId: number;
  presetTypes: MeasurementTypeConfig[];
}) => {
  const { data, ...result } = useGetRecipesByLocationIdQuery({
    variables: { location_id: locationId },
  });

  const environmentalPresets = (
    (data?.environment_recipe ?? []) as Array<EnvironmentRecipe>
  ).map((p) => environmentalPresetMapper(p, presetTypes));

  const lightCyclesPresets = (
    (data?.light_cycle_recipe ?? []) as Array<LightCycleRecipe>
  ).map((p) => lightCyclesPresetMapper(p));

  return { ...result, environmentalPresets, lightCyclesPresets };
};

/**
 * ENVIRONMENT RECIPE
 */
export const useInsertEnvironmentalPreset = (
  presetTypes: MeasurementTypeConfig[]
) => {
  const [mutation, result] = useInsertEnvironmentRecipeMutation();
  const findTypeId = useFindTypeIdByCode();
  const insert = async (preset: EnvironmentalPreset) => {
    const { data } = await mutation({
      variables: {
        name: preset.name,
        location_id: preset.locationId,
        organization_id: preset.organizationId,
        is_active: preset.isActive,
        entries: {
          data: environmentRecipeEntriesMapper({
            findTypeId,
            preset,
            presetTypes,
          }),
        },
      },
      refetchQueries: [GetRecipesByLocationIdDocument],
    });

    return data?.insert_environment_recipe?.returning?.[0]?.id;
  };

  return { ...result, insert };
};

export const useUpdateEnvironmentalPreset = ({
  zone,
  presetTypes,
}: {
  zone: TZone;
  presetTypes: MeasurementTypeConfig[];
}) => {
  const [mutation, result] = useUpdateEnvironmentRecipeMutation();
  const findTypeId = useFindTypeIdByCode();
  const { mutate } = useRecalculateHealthScoreMutation();
  const update = (preset: EnvironmentalPreset) =>
    mutation({
      variables: {
        id: preset.id!,
        name: preset.name,
        entries: environmentRecipeEntriesMapper({
          findTypeId,
          preset,
          presetTypes,
        }),
      },
      refetchQueries: [
        GetGrowthCyclesWithRecipesByZoneIdDocument,
        GetRecipesByLocationIdDocument,
      ],
      onCompleted: () => mutate(zone.uid),
    });
  return { ...result, update };
};

export const useRemoveEnvironmentalPreset = () => {
  const [mutation, result] = useRemoveEnvironmentRecipeMutation();
  const remove = (enviromentalPresetId: number) =>
    mutation({
      variables: {
        id: enviromentalPresetId,
      },
      refetchQueries: [
        GetGrowthCyclesWithRecipesByZoneIdDocument,
        GetRecipesByLocationIdDocument,
      ],
    });

  return { ...result, remove };
};

/**
 * LIGHT CYCLES RECIPE
 */
export const useInsertLightCyclesPreset = () => {
  const [mutation, result] = useInsertLightCycleRecipeMutation();
  const insert = async (preset: LightCyclesPreset) => {
    const { data } = await mutation({
      variables: {
        name: preset.name,
        location_id: preset.locationId,
        organization_id: preset.organizationId,
        is_active: preset.isActive,
        entries: {
          data: lightCyclesRecipeEntriesMapper(preset),
        },
      },
      refetchQueries: [GetRecipesByLocationIdDocument],
    });

    return data?.insert_light_cycle_recipe?.returning?.[0]?.id;
  };

  return { ...result, insert };
};

export const useUpdateLightCyclesPreset = (zone: TZone) => {
  const [mutation, result] = useUpdateLightCycleRecipeMutation();
  const { mutate } = useRecalculateHealthScoreMutation();
  const update = (preset: LightCyclesPreset) =>
    mutation({
      variables: {
        id: preset.id!,
        name: preset.name,
        entries: lightCyclesRecipeEntriesMapper(preset),
      },
      refetchQueries: [
        GetGrowthCyclesWithRecipesByZoneIdDocument,
        GetRecipesByLocationIdDocument,
      ],
      onCompleted: () => mutate(zone.uid),
    });
  return { ...result, update };
};

export const useRemoveLightCyclePreset = () => {
  const [mutation, result] = useRemoveLightCycleRecipeMutation();
  const remove = (lightCyclesPresetId: number) =>
    mutation({
      variables: {
        id: lightCyclesPresetId,
      },
      refetchQueries: [
        GetGrowthCyclesWithRecipesByZoneIdDocument,
        GetRecipesByLocationIdDocument,
      ],
    });

  return { ...result, remove };
};

/**
 * UTILITIES
 */

const useFindTypeIdByCode = () => {
  const { data: types } = useGetEnumerationsByTypeQuery({
    variables: {
      types: [
        EEnumerationTypes.MEASUREMENT_TYPE,
        EEnumerationTypes.COMPUTED_METRIC,
      ],
    },
  });

  return useMemo(
    () => (code: string) =>
      (types?.enumeration ?? []).find((e) => e.code === code)?.id!,
    [types]
  );
};

/** database -> UI */
export function growthCycleMapper({
  cycle,
  presetTypes,
  zoneTimeZone,
}: {
  cycle: GrowthCycle;
  zoneTimeZone: string;
  presetTypes: MeasurementTypeConfig[];
}): TGrowthCycle {
  const endTime = toZonedTime(cycle.end_time, zoneTimeZone);
  const startTime = toZonedTime(cycle.start_time, zoneTimeZone);
  const environmentalPreset = cycle.environment_recipe
    ? environmentalPresetMapper(cycle.environment_recipe, presetTypes)
    : undefined;
  const lightCyclesPreset = cycle.light_cycle_recipe
    ? lightCyclesPresetMapper(cycle.light_cycle_recipe)
    : undefined;
  const metadata = metadataMapper({
    startTime,
    originMetadata: cycle.metadata,
    lightCyclesPreset,
    environmentalPreset,
  });

  return {
    id: cycle.id,
    end_time: endTime.valueOf(),
    start_time: startTime.valueOf(),
    zone_id: cycle.zone_id,
    isActive: cycle.is_active,
    metadata,
    cultivars: cultivarsReadMapper(cycle.metadata),
    cultivarLayout: cultivarsLayoutMapper(cycle.metadata),
    environmentalPreset,
    lightCyclesPreset,
  };
}

/** database -> UI */
function metadataMapper({
  startTime,
  originMetadata,
  lightCyclesPreset,
  environmentalPreset,
}: {
  startTime: Date;
  originMetadata: any;
  lightCyclesPreset?: LightCyclesPreset;
  environmentalPreset?: EnvironmentalPreset;
}): GrowthCycleMetadata {
  const metadata: GrowthCycleMetadata = Object.assign({}, originMetadata);

  if (originMetadata) {
    metadata.show_harvest_progress = isNil(originMetadata.show_harvest_progress)
      ? true
      : originMetadata.show_harvest_progress;
    metadata.label_overview_upper_count_bound =
      originMetadata.label_overview_upper_count_bound ?? null;
  }

  metadata.monitoring_parameters = {
    version: EMontioringParameterVersion.V_2_0_0,
  };

  if (environmentalPreset) {
    metadata.monitoring_parameters = environmentalPreset.settings.reduce(
      (params, setting) => {
        if (params) {
          let type = setting.type.toLocaleLowerCase();

          // TODO: How is this avoided?
          if (type === 'infrared_matrix') {
            type = 'leaf_temperature';
          }

          const lightKey = `${type}_light_${setting.day}`;
          const darkKey = `${type}_dark_${setting.day}`;

          params[lightKey] = {
            set_point: setting.light.setPoint!,
            optimal_range: {
              min: setting.light.idealLower!,
              max: setting.light.idealUpper!,
            },
            warning_range: {
              min: setting.light.criticalLower!,
              max: setting.light.criticalUpper!,
            },
          };

          params[darkKey] = {
            set_point: setting.dark.setPoint!,
            optimal_range: {
              min: setting.dark.idealLower!,
              max: setting.dark.idealUpper!,
            },
            warning_range: {
              min: setting.dark.criticalLower!,
              max: setting.dark.criticalUpper!,
            },
          };
        }

        return params;
      },
      metadata.monitoring_parameters
    );
  }

  metadata.light_info = [];
  if (lightCyclesPreset) {
    metadata.light_info = lightCyclesPreset.lightCycles.map((lightCycle) => {
      const onAt = lightCycle.onAt.split(':');
      return {
        light_on_start_time: {
          hour: Number(onAt[0]),
          minute: Number(onAt[1]),
        },
        light_on_duration_minutes: lightCycle.duration,
        light_cycle_configuration_start_timestamp_seconds:
          addDays(startTime, lightCycle.start).valueOf() / 1000,
      };
    });
  }

  return metadata;
}

/** database -> UI */
function cultivarsReadMapper(metadata: GrowthCycle['metadata']): TPlantInfo[] {
  return metadata?.plant_info?.map(
    (cultivar: {
      strain_name: string;
      count: number;
      layout_number?: number;
    }) => ({
      id: uuidv4(),
      strainName: cultivar.strain_name,
      count: cultivar.count,
      layoutNumber: cultivar.layout_number,
    })
  );
}

/** database -> UI */
function cultivarsLayoutMapper(
  metadata: GrowthCycle['metadata']
): Maybe<Record<string, number[][]>> {
  return metadata?.cultivar_layout;
}

/** database -> UI */
function lightCyclesPresetMapper(preset: LightCycleRecipe): LightCyclesPreset {
  return Object.assign(presetMapper(preset), {
    lightCycles: lightCyclesMapper(preset.light_cycle_recipe_entrys),
  });
}

/** database -> UI */
function lightCyclesMapper(
  entries: Array<LightCycleRecipeEntry>
): LightCyclesPreset['lightCycles'] {
  return entries
    .map<LightCycle>((entry) => ({
      id: entry.id.toString(),
      duration: entry.lights_on_duration_minutes,
      onAt: entry.lights_on_time,
      start: entry.day,
    }))
    .sort((a, b) => a.start - b.start);
}

/** database -> UI */
function environmentalPresetMapper(
  preset: EnvironmentRecipe,
  presetTypes: MeasurementTypeConfig[]
): EnvironmentalPreset {
  return Object.assign(presetMapper(preset), {
    settings: settingsMapper(preset.environment_recipe_entrys, presetTypes),
  });
}

/** database -> UI */
function presetMapper(ep: EnvironmentRecipe | LightCycleRecipe): Preset {
  return {
    name: ep.name,
    isActive: ep.is_active,
    locationId: ep.location_id!,
    organizationId: ep.organization_id!,
    lastUpdated: new Date(ep.last_updated),
    id: ep.id,
    count: ep.growth_cycles_aggregate.aggregate?.count,
  };
}

/** database -> UI */
function settingsMapper(
  entries: Array<EnvironmentRecipeEntry>,
  presetTypes: MeasurementTypeConfig[]
): EnvironmentalPreset['settings'] {
  return entries
    .reduce((settings, entry) => {
      const index = settings.findIndex(
        ({ type, day }) => type === entry.enumeration.code && day === entry.day
      );

      const type = entry.enumeration
        .code as MeasurementTypeConfig['statisticsKeyV2'];
      const typeConfig = presetTypes.find(
        ({ statisticsKeyV2 }) => statisticsKeyV2 === type
      )!;
      if (index !== -1) {
        const cycleKey = entry.lights_on ? 'light' : 'dark';
        const setting = settings.at(index)!;
        setting[cycleKey] = parametersMapper(typeConfig, entry);
        settings[index] = setting;
      } else {
        settings.push({
          id: uuidv4(),
          type,
          day: entry.day,
          light: parametersMapper(typeConfig, entry),
          dark: parametersMapper(typeConfig, entry),
        });
      }

      return settings;
    }, [] as Setting[])
    .sort((a, b) => a.day - b.day);
}

/** database -> UI */
function parametersMapper(
  typeConfig: MeasurementTypeConfig,
  entry: EnvironmentRecipeEntry
): Parameters {
  const { convertFromUnit } = typeConfig;
  const setPoint = convertIf(entry.set_point, convertFromUnit, true);
  const idealUpper = convertIf(entry.ideal_upper, convertFromUnit, true);
  const idealLower = convertIf(entry.ideal_lower, convertFromUnit, true);
  const ideal = (idealUpper - idealLower) / 2;
  const criticalUpper = convertIf(entry.critical_upper, convertFromUnit, true);
  const criticalLower = convertIf(entry.critical_lower, convertFromUnit, true);
  const critical = (criticalUpper - criticalLower) / 2;

  return {
    setPoint,
    ideal,
    critical,
    idealUpper,
    idealLower,
    criticalUpper,
    criticalLower,
  };
}

/** UI -> database */
function environmentRecipeEntriesMapper({
  findTypeId,
  preset,
  presetTypes,
}: {
  findTypeId: ReturnType<typeof useFindTypeIdByCode>;
  preset?: TGrowthCycle['environmentalPreset'];
  presetTypes: MeasurementTypeConfig[];
}) {
  return (preset?.settings ?? []).reduce((entries, setting) => {
    const typeId = findTypeId(setting.type);
    const { convertToUnit } = presetTypes.find(
      ({ statisticsKeyV2 }) => statisticsKeyV2 === setting.type
    )!;

    for (const dayCycle of [setting.light, setting.dark]) {
      entries.push({
        recipe_id: preset?.id,
        type_id: typeId,
        day: setting.day,
        lights_on: dayCycle === setting.light,
        set_point: convertIf(dayCycle.setPoint, convertToUnit),
        ideal_lower: convertIf(dayCycle.idealLower, convertToUnit),
        ideal_upper: convertIf(dayCycle.idealUpper, convertToUnit),
        critical_lower: convertIf(dayCycle.criticalLower, convertToUnit),
        critical_upper: convertIf(dayCycle.criticalUpper, convertToUnit),
      });
    }
    return entries;
  }, [] as Array<EnvironmentRecipeEntryInsertInput>);
}

/** UI -> database */
function lightCyclesRecipeEntriesMapper(
  preset?: TGrowthCycle['lightCyclesPreset']
) {
  return (preset?.lightCycles ?? []).reduce((entries, lc) => {
    entries.push({
      recipe_id: preset?.id,
      day: lc.start,
      lights_on_duration_minutes: lc.duration,
      lights_on_time: lc.onAt,
    });
    return entries;
  }, [] as Array<LightCycleRecipeEntryInsertInput>);
}

/** UI -> database */
function cultivarsWriteMapper(cycle: TGrowthCycleEdit): {
  plant_info: TPlantInfo[];
  cultivar_layout: Record<string, number[][]>;
} {
  const oldToNewIndexMap =
    cycle.cultivars?.reduce(
      (previous, element, index) => ({
        ...previous,
        ...(isNil(element.layoutNumber)
          ? {}
          : { [element.layoutNumber]: index + 1 }),
      }),
      {} as Record<number, number>
    ) ?? ({} as Record<number, number>);
  return {
    ...cycle.metadata,
    plant_info: (cycle.cultivars ?? []).map((cultivar, index) => ({
      strain_name: cultivar.strainName,
      count: cultivar.count,
      layout_number: index + 1,
    })),
    cultivar_layout: Object.entries(cycle.cultivarLayout ?? {}).reduce(
      (previous, [gridId, layout]) => ({
        ...previous,
        [gridId]: layout.map((row) =>
          row.map((oldIndex) => oldToNewIndexMap[oldIndex] ?? 0)
        ),
      }),
      {}
    ),
  };
}
