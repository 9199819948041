export const Colors = {
  black: '#000000',
  dark1: '#383838',
  dark2: '#525252',
  dark7: '#808080',
  dark8: '#B4B4B4',
  dark9: '#CFCFCF',
  green1: '#56C254',
  green2: '#639513',
  grey5: '#E0E0E0',
  hb0: '#838383',
  hb1: '#82879A',
  hb2: '#9BA0B6',
  hb3: '#C3C7D9',
  hb4: '#ECEDF1',
  hb41: '#e6e6e6',
  hb5: '#F0F1F4',
  hb6: '#F8F8FC',
  hb7: '#F3F3F3',
  hb8: '#f2f2f2',
  hb9: '#FAFBE7',
  orange1: '#DD382F',
  orange2: '#E86339',
  orange3: '#F48E42',
  orange4: '#FFB94C',
  orange7: '#f7e8d0',
  white: '#ffffff',
  linearGradientedOrange: 'linear-gradient(90deg, #E86339 0%, #FFB94C 100%)',
} as const;
