import { useAuth } from 'contexts/AuthProvider';
import { useGetUsersByOrganizationQuery } from 'graphql/generated/react_apollo';
import isNil from 'lodash.isnil';
import { useMemo } from 'react';
import { NEATLEAF_ORGANIZATION_CODE } from 'shared/interfaces/organization';
import { getUserOrganizations } from 'shared/utils/user';

export const useInitializeUsers = () => {
  const { currentlySelectedOrganization, isNeatleafOrganizationMember } =
    useAuth();
  const { data } = useGetUsersByOrganizationQuery({
    fetchPolicy: 'cache-and-network',
    variables: { organizationId: currentlySelectedOrganization?.id! },
    skip: isNil(currentlySelectedOrganization),
  });

  return useMemo(() => {
    const users = data?.user ?? [];
    if (isNeatleafOrganizationMember) return { users };
    return {
      users: users.filter((user) => {
        const organizationList = getUserOrganizations(user);
        return organizationList.every(
          ({ code }) => code !== NEATLEAF_ORGANIZATION_CODE
        );
      }),
    };
  }, [data, isNeatleafOrganizationMember]);
};
