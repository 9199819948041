import { Button } from 'components/common/Button/Button';
import { Input } from 'components/common/Input/Input';
import { LockKeyholeIcon, Trash2Icon } from 'lucide-react';
import { ComponentPropsWithoutRef, forwardRef, useMemo } from 'react';
import { TPlantInfo } from 'shared/interfaces/growthCycle';
import { cn } from 'shared/utils/cn';
import { v4 as uuidv4 } from 'uuid';
import { checkCultivars } from './validation';

const getEmptyCultivar = (): TPlantInfo => ({
  id: uuidv4(),
  strainName: '',
  count: 0,
  layoutNumber: 1,
});

type ManagedFields = keyof Omit<TPlantInfo, 'id'>;

export interface EditCultivarsProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  /** The list of cultivars to display */
  cultivars: TPlantInfo[];
  /** Called whenever the cultivar list is updated */
  onChange: (cultivars: TPlantInfo[]) => void;
}

/**
 * List, add, edit, and remove cultivars.
 */
export const EditCultivars = forwardRef<HTMLDivElement, EditCultivarsProps>(
  function EditCultivars(
    { className, onChange, cultivars: _cultivars, ...props },
    ref
  ) {
    const tableTitleId = useMemo(() => uuidv4(), []);
    const cultivars = _cultivars.length > 0 ? _cultivars : [getEmptyCultivar()];
    // Disable Add button when there are entries with values
    const disableAddButton = !checkCultivars(cultivars);
    const handleAddRow = () => {
      onChange([...cultivars, getEmptyCultivar()]);
    };
    const handleDelete = (id: TPlantInfo['id']) => () => {
      onChange(cultivars.filter((cultivar) => cultivar.id !== id));
    };
    const handleClearAll = () => {
      onChange([getEmptyCultivar()]);
    };
    const handleChange = (
      id: TPlantInfo['id'],
      fieldName: ManagedFields,
      value: string | number
    ) => {
      const modifiedCultivars = cultivars.map((targetCultivar) =>
        targetCultivar.id === id
          ? { ...targetCultivar, [fieldName]: value }
          : targetCultivar
      );
      onChange(modifiedCultivars);
    };

    return (
      <div
        ref={ref}
        {...props}
        className={cn(
          'flex w-full max-w-[592px] flex-col items-center gap-2',
          className
        )}
      >
        <h3 className="text-xl font-bold">Cultivar Information</h3>

        <p className="text-center" id={tableTitleId}>
          Add the cultivar name and plant count for each variety.
        </p>

        <div className="mt-6 flex w-full flex-col items-start justify-center">
          <div
            role="table"
            className="flex w-full flex-col gap-4"
            aria-labelledby={tableTitleId}
          >
            <div className="flex w-full flex-row gap-2 font-semibold">
              <div className="flex w-full justify-start" role="columnheader">
                Name
              </div>
              <div
                className="flex w-full max-w-[64px] justify-center"
                role="columnheader"
              >
                Amount
              </div>
              <div className="min-w-9 p-0" />
            </div>
            <div className="flex flex-col gap-1">
              {cultivars.map(({ id, strainName, count }, index) => {
                const cultivarAria =
                  strainName.length > 0 ? strainName : `Cultivar #${index + 1}`;
                return (
                  <div
                    key={id}
                    role="row"
                    className="flex items-center gap-2 p-1 rounded-xs focused-row"
                    aria-label={cultivarAria}
                  >
                    <div className="w-full p-0">
                      <Input
                        autoFocus
                        placeholder="Cultivar name"
                        minLength={3}
                        value={strainName}
                        onChange={(event) =>
                          handleChange(id, 'strainName', event.target.value)
                        }
                        aria-label={`${cultivarAria} name`}
                      />
                    </div>
                    <div className="w-full max-w-[64px] p-0">
                      <Input
                        type="number"
                        min={0}
                        required
                        value={`${count}`}
                        onChange={(event) =>
                          handleChange(id, 'count', event.target.valueAsNumber)
                        }
                        aria-label={`${cultivarAria} amount`}
                      />
                    </div>
                    <div className="min-w-9 p-0">
                      {index === 0 && (
                        <Button variant="flat" size="icon" aria-hidden disabled>
                          <LockKeyholeIcon className="stroke-[1.5px] size-4 xl:size-5" />
                        </Button>
                      )}
                      {index !== 0 && (
                        <Button
                          variant="flat"
                          size="icon"
                          onClick={handleDelete(id)}
                          aria-label={`Remove ${cultivarAria}`}
                        >
                          <Trash2Icon className="stroke-[1.5px] size-4 xl:size-5" />
                        </Button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="flex w-full flex-row justify-between py-2">
            <Button
              variant="flat"
              onClick={handleAddRow}
              disabled={disableAddButton}
            >
              Add more
            </Button>

            <Button variant="flat" onClick={handleClearAll}>
              Clear all
            </Button>
          </div>
        </div>
      </div>
    );
  }
);
