import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $createTextNode,
  $getRoot,
  DOMConversionMap,
  DOMExportOutput,
  EditorConfig,
  EditorState,
  ElementNode,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  TextNode,
} from 'lexical';
import { useEffect, useRef } from 'react';

export class ReadMoreNode extends ElementNode {
  __onClick: () => void;

  constructor(onClick: () => void, key?: NodeKey) {
    super(key);
    this.__onClick = onClick;
  }

  static getType() {
    return 'readMore';
  }

  static clone(node: ReadMoreNode) {
    return new ReadMoreNode(node.__onClick, node.__key);
  }

  createDOM(_config: EditorConfig): HTMLElement {
    const container = document.createElement('span');
    const dots = document.createElement('span');
    dots.textContent = '... ';
    const link = document.createElement('a');
    link.href = '#';
    link.textContent = 'Read More';
    link.className =
      'text-orange-700 font-semibold cursor-pointer hover:underline inline';
    link.onclick = (event) => {
      event.preventDefault();
      this.__onClick();
    };

    container.appendChild(dots);
    container.appendChild(link);

    return container;
  }

  updateDOM(_prevNode: ReadMoreNode, _dom: HTMLElement): boolean {
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return null;
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const element = this.createDOM(editor._config);
    return { element };
  }

  static importJSON(_serializedNode: any): ReadMoreNode {
    return new ReadMoreNode(() => {});
  }

  exportJSON(): any {
    return {
      type: 'readMore',
      version: 1,
    };
  }
}

function $createReadMoreNode(onClick: () => void): ReadMoreNode {
  return new ReadMoreNode(onClick);
}

interface ReadMorePluginProps {
  charLimit: number;
}

export const ReadMorePlugin: React.FC<ReadMorePluginProps> = ({
  charLimit,
}) => {
  const [editor] = useLexicalComposerContext();

  const originalEditorState = useRef<EditorState | null>(null);

  const onReadMoreClick = () => {
    editor.setEditorState(originalEditorState.current!);
  };

  useEffect(() => {
    editor.update(() => {
      const root = $getRoot();
      let totalChars = 0;
      const readMoreNode = $createReadMoreNode(onReadMoreClick);

      const handleNode = (node: LexicalNode) => {
        const textContent = node.getTextContent();
        const nodeLength = textContent.length;
        if (node instanceof ElementNode) {
          const children = node.getChildren();
          for (let child of children) {
            if (totalChars > charLimit) {
              child.remove();
            } else {
              handleNode(child);
            }
          }
        } else {
          if (totalChars + nodeLength > charLimit) {
            const splitIndex = charLimit - totalChars;
            let replacementText = '';
            if (node instanceof TextNode) {
              replacementText = textContent.slice(0, splitIndex);
            }
            const replacementNode = $createTextNode(replacementText);
            node.replace(replacementNode);
            replacementNode.insertAfter(readMoreNode);
            node.remove();
          }
        }
        totalChars += nodeLength;
      };

      if (root.getTextContent().length > charLimit) {
        if (!originalEditorState.current) {
          originalEditorState.current = editor.getEditorState();
        }
        handleNode(root);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, charLimit]);

  return null;
};
