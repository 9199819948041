import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import {
  useGetDailyHealthLabelsByZoneIdTypeCodeQuery,
  useGetPlantHealthQuery,
} from 'graphql/generated/react_apollo';
import every from 'lodash.every';
import get from 'lodash.get';
import groupBy from 'lodash.groupby';
import map from 'lodash.map';
import mapValues from 'lodash.mapvalues';
import { useMemo } from 'react';
import {
  EMeasurementStatisticsTypesV2,
  GetMeasurementTypeFunction,
  MeasurementTypeConfig,
  MeasurementUnit,
  SignalMeasurements,
  SignalMeasurementsType,
} from 'shared/interfaces/measurement';
import { getPublicResourcePath } from 'shared/utils/image';
import { MeasurementRequestParams } from './measurement';

const {
  CALCULATED_DISTANCE,
  YELLOWING_GENERAL,
  NECROSIS_GENERAL,
  ABNORMAL_SHAPE_FOLDING,
  OTHER_POWDER,
  OBJECT_BUD,
} = EMeasurementStatisticsTypesV2;

export const useGetDailyHealthLabelsByZoneIdTypeCode = ({
  zoneId,
  zoneTimeZone,
  start,
  end,
  signals,
}: MeasurementRequestParams) => {
  const requestSignals = useMemo(
    () => signals.filter(({ apis }) => apis.includes('gql-labels-count')),
    [signals]
  );

  const {
    previousData: previousRawData,
    data: rawData = previousRawData,
    ...result
  } = useGetDailyHealthLabelsByZoneIdTypeCodeQuery({
    variables: {
      zoneId: zoneId,
      start: start,
      end: end,
      typeCodes: requestSignals.map(({ statisticsKeyV2 }) => statisticsKeyV2),
    },
    skip: requestSignals.length === 0,
  });

  const data = useMemo<SignalMeasurementsType>(() => {
    const extractValues = (signal: MeasurementTypeConfig) => {
      if (!rawData) {
        return [];
      }

      return rawData.computed_measurement
        .filter(({ type }) => type?.code === signal.statisticsKeyV2)
        .map<
          [number, number]
        >(({ time, data }) => [toZonedTime(time, zoneTimeZone).valueOf(), Number(data[signal.statisticsKey]) * 100]);
    };

    const allValues = new SignalMeasurements();
    for (const signal of requestSignals) {
      allValues.set(signal, extractValues(signal));
    }

    return allValues;
  }, [rawData, requestSignals, zoneTimeZone]);

  return { data, ...result };
};

export interface PlantHealthValue {
  image?: string;
  signal: MeasurementTypeConfig;
  title: string;
  trend?: {
    previousValue: number;
    value: number;
    unit: string;
    max: number;
  };
  linear?: {
    suffix?: string;
    value: number;
    unit: string;
    max: number;
  };
  colors?: string[];
}

type ComputedMetricCode =
  | 'CALCULATED_DISTANCE'
  | 'DAILY_HEALTH_LABEL_COUNT'
  | 'LABEL_EXAMPLE_IMAGE';
interface DistanceValue {
  calculated_distance: number;
  time: Date;
}
interface PercentValue {
  calculated_area_percent: number;
  calculated_counts: number;
}

export const useGetPlantHealth = ({
  zoneId,
  zoneTimeZone,
  start,
  end,
  getMeasurementType,
}: {
  zoneTimeZone: string;
  zoneId: number | undefined;
  start: Date;
  end: Date;
  getMeasurementType: GetMeasurementTypeFunction;
}) => {
  const {
    previousData: previousRawData,
    data: rawData = previousRawData,
    ...result
  } = useGetPlantHealthQuery({
    variables: {
      zoneId: Number(zoneId),
      start: fromZonedTime(start, zoneTimeZone),
      end: fromZonedTime(end, zoneTimeZone),
    },
    skip: !zoneId,
  });

  const data = useMemo(() => {
    const symptoms: PlantHealthValue[] = [];
    const growth: PlantHealthValue[] = [];

    const groupByCodeAndType = mapValues(
      groupBy(rawData?.plant_health ?? [], (item) =>
        get(item, 'computed_metric_type.code')
      ),
      (items) => {
        const allTypesNull = every(items, ['type', null]);

        if (allTypesNull) {
          return map(items, ({ data, time }) => ({
            ...data,
            time: toZonedTime(time, zoneTimeZone),
          }));
        }

        return mapValues(
          groupBy(items, (item) => get(item, 'type.code')),
          (groupedItems) =>
            map(groupedItems, ({ data, time }) => ({
              ...data,
              time: toZonedTime(time, zoneTimeZone),
            }))
        );
      }
    ) as Record<
      ComputedMetricCode,
      Record<EMeasurementStatisticsTypesV2, any[]> | Array<DistanceValue>
    >;
    function getImage(signal: MeasurementTypeConfig) {
      const symptomsImages = groupByCodeAndType.LABEL_EXAMPLE_IMAGE as
        | Record<
            EMeasurementStatisticsTypesV2,
            {
              image_bucket: string;
              label_counts: number;
              resource_path: string;
            }[]
          >
        | undefined;
      const examples =
        (symptomsImages && symptomsImages[signal.statisticsKeyV2]) ?? [];

      if (examples.length === 0) {
        return undefined;
      }

      const example = examples.reduce(
        (maxItem, currentItem) =>
          currentItem.label_counts > maxItem.label_counts
            ? currentItem
            : maxItem,
        examples[0]!
      );

      return getPublicResourcePath(
        decodeURI(example.resource_path),
        [0, 0],
        example.image_bucket,
        true
      );
    }

    if (groupByCodeAndType.DAILY_HEALTH_LABEL_COUNT) {
      const symptomsValues = (
        Object.entries(groupByCodeAndType.DAILY_HEALTH_LABEL_COUNT) as [
          EMeasurementStatisticsTypesV2,
          PercentValue[],
        ][]
      ).filter(([type]) =>
        [
          YELLOWING_GENERAL,
          ABNORMAL_SHAPE_FOLDING,
          OTHER_POWDER,
          NECROSIS_GENERAL,
        ].includes(type)
      );

      for (const [type, values] of symptomsValues) {
        if (values) {
          const signal = getMeasurementType(type);
          if (values.length >= 2) {
            const previousValue = values.at(0)
              ? values.at(0)!.calculated_area_percent * 100
              : 0;
            const value = values.at(-1)
              ? values.at(-1)!.calculated_area_percent * 100
              : 0;

            // console.log({ type, previousValue, value });

            const trend = [previousValue, value].every((v) => v === 0)
              ? undefined
              : {
                  previousValue,
                  value,
                  unit: MeasurementUnit.percent,
                  max:
                    Math.max(...values.map((v) => v.calculated_area_percent)) *
                    100,
                };

            symptoms.push({
              signal,
              title: signal.labelHealth ?? signal.label,
              image: getImage(signal),
              trend,
            });
          }
        }
      }
    }

    const distanceValues =
      groupByCodeAndType.CALCULATED_DISTANCE as Array<DistanceValue>;
    if (distanceValues) {
      const signal = getMeasurementType(CALCULATED_DISTANCE);
      const distances = distanceValues.map(
        ({ calculated_distance }) => calculated_distance
      );
      const maxDistance = Math.max(...distances);
      const value = signal.convertFromUnit(
        maxDistance - Math.min(...distances)
      );
      growth.push({
        signal,
        title: signal.labelHealth ?? signal.label,
        linear: {
          suffix: '+',
          value,
          unit: signal.unit,
          max: signal.convertFromUnit(maxDistance),
        },
      });
    }

    if (groupByCodeAndType.DAILY_HEALTH_LABEL_COUNT) {
      const budCountValues = (
        groupByCodeAndType.DAILY_HEALTH_LABEL_COUNT as Record<
          EMeasurementStatisticsTypesV2,
          PercentValue[]
        >
      )[OBJECT_BUD];

      if (budCountValues) {
        const signal = getMeasurementType(OBJECT_BUD);
        if (budCountValues.length >= 2) {
          const previous = budCountValues.at(0)
            ? budCountValues.at(0)!.calculated_area_percent
            : 0;
          const latest = budCountValues.at(-1)
            ? budCountValues.at(-1)!.calculated_area_percent
            : 0;
          let value = ((latest - previous) / previous) * 100;

          if (value < 0 || isNaN(value)) {
            value = 0;
          } else if (value === Infinity) {
            value = 100;
          }

          growth.push({
            signal,
            title: signal.labelHealth ?? signal.label,
            image: getImage(signal),
            linear: {
              suffix: value >= 0 ? '+' : '',
              value,
              unit: MeasurementUnit.percent,
              max:
                Math.max(
                  ...budCountValues.map((v) => v.calculated_area_percent)
                ) * 100,
            },
          });
        }
      }
    }

    // console.log({ groupByCodeAndType, symptoms, growth });

    return { symptoms, growth };
  }, [getMeasurementType, rawData?.plant_health, zoneTimeZone]);

  return { data, ...result };
};
