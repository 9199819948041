import { useAuth } from 'contexts/AuthProvider';
import {
  GetPermissionsByRoleIdsQuery,
  useGetPermissionsByRoleIdsQuery,
} from 'graphql/generated/react_apollo';
import isNil from 'lodash.isnil';
import { useCallback, useMemo } from 'react';
import { TUserOrganizationRole } from 'shared/interfaces/user';
import { Permission } from 'shared/models/permission';
import { PermissionContainer } from 'shared/models/permission-container';

export interface UserPermissions {
  permissionContainer: PermissionContainer | undefined;
  currentOrganizationUserPermissions:
    | GetPermissionsByRoleIdsQuery['role_permission']
    | undefined;
  hasAccessToZone: (
    organizationCode: string,
    locationId: number,
    zoneUid: string
  ) => boolean;
}

export const useUserPermissions: () => UserPermissions = () => {
  const { user, currentlySelectedOrganization } = useAuth();

  const allRoleIds = useMemo(
    () => getAllRoleIds(user?.user_organization_roles),
    [user?.user_organization_roles]
  );

  const { data: permissionsByRoleData } = useGetPermissionsByRoleIdsQuery({
    variables: {
      ids: allRoleIds,
    },
    skip: isNil(allRoleIds),
  });
  const userPermissions = permissionsByRoleData?.role_permission;

  const permissionContainer = useMemo(() => {
    return (
      user?.user_organization_roles &&
      userPermissions &&
      new PermissionContainer(user?.user_organization_roles, userPermissions)
    );
  }, [user?.user_organization_roles, userPermissions]);

  const currentOrganizationUserPermissions = useMemo(() => {
    return (
      (!isNil(currentlySelectedOrganization) || undefined) &&
      permissionContainer?.getOrganizationPermissions(
        currentlySelectedOrganization!.code
      )
    );
  }, [currentlySelectedOrganization, permissionContainer]);

  const hasAccessToZone = useCallback(
    (organizationCode: string, locationId: number, zoneUid: string) => {
      return (
        !!permissionContainer &&
        permissionContainer.hasZoneLocationOrOrganizationPermission(
          Permission.ZoneView,
          {
            locationId,
            organizationCode,
            zoneUid,
          }
        )
      );
    },
    [permissionContainer]
  );

  return {
    permissionContainer,
    hasAccessToZone,
    currentOrganizationUserPermissions,
  };
};

function getAllRoleIds(
  roles: TUserOrganizationRole[] | undefined
): number[] | undefined {
  if (!roles) {
    return undefined;
  }

  return [...new Set(roles.map((r) => r.role.id))];
}
