import { forwardRef, useCallback, useEffect, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { cn } from 'shared/utils/cn';

const CELL_HEIGHT_IN_PIXELS = 48;

type CircularInfiniteListProps = {
  ariaLabel?: string;
  className?: string;
  onClickListItem: (value: number) => void;
  nrItems: number;
  selectedIndex: number;
  renderListItem: (index: number) => React.ReactNode;
  tabbable?: boolean;
};

export const CircularInfiniteList = forwardRef<
  HTMLDivElement,
  CircularInfiniteListProps
>(function CircularInfiniteList(
  {
    ariaLabel,
    className,
    onClickListItem,
    nrItems,
    selectedIndex,
    renderListItem,
    tabbable = true,
  },
  forwardedRef
) {
  const ref = useRef<Nullable<HTMLDivElement>>(null);
  const firstRender = useRef(true);

  /*
   * Reset the scroll position to the middle of the extended list
   * when the user scrolls to the top or bottom of the list
   */
  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement, UIEvent>, itemCount: number) => {
      if (!ref.current) return;
      const { scrollTop } = event.target as HTMLElement;
      const listHeight = CELL_HEIGHT_IN_PIXELS * itemCount;
      const inMiddleList = scrollTop > listHeight && scrollTop < listHeight * 2;
      if (ref.current && !inMiddleList) {
        ref.current!.scrollTop = listHeight + (scrollTop % listHeight);
      }
    },
    []
  );

  useEffect(() => {
    if (firstRender.current) {
      ref.current!.scrollTop =
        CELL_HEIGHT_IN_PIXELS * (selectedIndex + nrItems);
      firstRender.current = false;
    } else {
      ref.current?.scrollTo?.({
        top: CELL_HEIGHT_IN_PIXELS * (selectedIndex + nrItems),
        behavior: 'smooth',
      });
    }
  }, [selectedIndex, nrItems]);

  // use up/down keys to navigate the list
  useEffect(() => {
    const localRef = ref.current;
    if (!localRef) return;
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        onClickListItem((selectedIndex - 1 + nrItems) % nrItems);
        localRef.focus();
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        onClickListItem((selectedIndex + 1) % nrItems);
        localRef.focus();
      }
    };
    localRef.addEventListener('keydown', handleKeyDown);
    return () => {
      localRef.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedIndex, nrItems, onClickListItem]);

  return (
    <div
      tabIndex={tabbable ? 0 : undefined}
      role="list"
      aria-label={ariaLabel}
      ref={mergeRefs([ref, forwardedRef])}
      className={cn(
        'group/list flex h-full flex-col overflow-y-auto outline-none no-scrollbar',
        className
      )}
      onScroll={(e) => handleScroll(e, nrItems)}
      style={{
        height: `${Math.min(CELL_HEIGHT_IN_PIXELS * nrItems, CELL_HEIGHT_IN_PIXELS * 4)}px`,
        maxHeight: `${CELL_HEIGHT_IN_PIXELS * nrItems}px`,
      }}
    >
      {Array.from({ length: nrItems * 3 }, (_, i) => {
        const index = i % nrItems;
        const selected = index === selectedIndex;
        const item = renderListItem(index);
        return (
          <div
            key={i}
            role="listitem"
            aria-label={ariaLabel ? `${ariaLabel}: ${item}` : ''}
            className={cn(
              `flex min-h-12 w-full min-w-12 items-center justify-center text-sm`,
              `rounded-sm border-4 border-white text-center`,
              `hover:cursor-pointer hover:bg-orange-300`,
              selected &&
                'bg-orange-500 group-focus/list:bg-orange-400 text-white'
            )}
            onMouseDown={(e) => {
              e.preventDefault();
              onClickListItem(index);
            }}
          >
            {item}
          </div>
        );
      })}
    </div>
  );
});
