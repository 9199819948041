import { Button } from 'components/common/Button/Button';
import {
  Dropdown,
  DropdownProps,
  Option,
} from 'components/common/Dropdown/Dropdown';
import { Radio } from 'components/common/Radio/Radio';
import { Tooltip } from 'components/common/Tooltip/Tooltip';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { useHeatMapURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { InfoIcon } from 'lucide-react';
import { FC, useCallback, useMemo } from 'react';
import { EAggregationTypes, EGradientTypes } from 'shared/interfaces/heatmap';
import {
  EMeasurementTypes,
  MeasurementTypeConfig,
} from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';
import {
  AGGREGATED,
  AirLeafTemperatureDifference,
  HEATMAP_TYPE_OPTIONS,
  HEATMAP_TYPE_OPTIONS_NO_OPTIMAL_RANGE,
  SINGLE,
  tooltips,
} from './utils';

interface IHeatMapTypeSelectionProps {
  /** */
  aggregationType: EAggregationTypes;
  /** Heat map has optimal range */
  hasOptimalRange: boolean;
  /** */
  className?: string;
  /** */
  typeConfig: MeasurementTypeConfig;
}

export const HeatMapTypeSelection: FC<IHeatMapTypeSelectionProps> = ({
  className,
  hasOptimalRange,
  aggregationType,
  typeConfig,
}) => {
  const {
    setAggregationType,
    setSignalIds,
    setGradientType,
    setShowComments,
    showComments,
    gradientType,
  } = useHeatMapURL();
  const { heatmapSignals, getMeasurementType } = useTypeConfig();
  const localTypeConfig = !typeConfig.heatMapCodes
    ? getMeasurementType(AirLeafTemperatureDifference)
    : typeConfig;
  const heatMapOptions = useMemo(() => {
    if (!hasOptimalRange || !localTypeConfig.hasOptimalRange) {
      return HEATMAP_TYPE_OPTIONS_NO_OPTIMAL_RANGE;
    }

    return HEATMAP_TYPE_OPTIONS;
  }, [hasOptimalRange, localTypeConfig.hasOptimalRange]);
  const selecteHeatMapType = heatMapOptions.find(
    (option) => option.value === gradientType
  );
  const handleChangeType: DropdownProps<EGradientTypes>['onChange'] =
    useCallback(
      (option: Option<EGradientTypes> | Option<EGradientTypes>[] | null) => {
        if (option && !Array.isArray(option)) {
          setGradientType(option.value);
        }
      },
      [setGradientType]
    );
  const measurementTypeOptions = useMemo(
    () =>
      heatmapSignals.map<Option<EMeasurementTypes>>((option) => ({
        label: option.label,
        value: option.type,
        leadingElement: option.Icon,
      })),
    [heatmapSignals]
  );
  const selectedMeasurementType = measurementTypeOptions.find(
    ({ value }) => value === localTypeConfig.type
  );
  const handleChangeMeasurementType: DropdownProps<EMeasurementTypes>['onChange'] =
    useCallback(
      (
        option: Option<EMeasurementTypes> | Option<EMeasurementTypes>[] | null
      ) => {
        if (option && !Array.isArray(option)) {
          setSignalIds([option.value]);
        }
      },
      [setSignalIds]
    );

  return (
    <div
      className={cn('flex flex-wrap items-center justify-end gap-2', className)}
    >
      <Dropdown
        variant="secondary"
        value={selectedMeasurementType}
        onChange={handleChangeMeasurementType}
        options={measurementTypeOptions}
        aria-label="selected measurement"
        className="mr-auto"
      />

      <Radio.Group
        value={aggregationType}
        orientation="horizontal"
        disabled={Boolean(localTypeConfig.hideHeatmapSingle)}
        onChange={(e) =>
          setAggregationType(e.target.value as EAggregationTypes)
        }
      >
        <Radio.Input value={AGGREGATED} label={AGGREGATED} />
        <Radio.Input value={SINGLE} label={SINGLE} />
      </Radio.Group>
      <Dropdown
        variant="secondary"
        value={selecteHeatMapType}
        onChange={handleChangeType}
        options={heatMapOptions}
        aria-label="selected heatmap type"
      />
      <Tooltip label={tooltips[gradientType]} size="lg">
        <span>
          <InfoIcon className="stroke-[1.5px] size-6" />
        </span>
      </Tooltip>
      <Button
        variant="tertiary"
        role="checkbox"
        selected={showComments}
        className="select-none"
        onClick={() => setShowComments(!showComments)}
      >
        Show comments
      </Button>
    </div>
  );
};
