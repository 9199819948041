import { differenceInDays, formatDistance } from 'date-fns';
import { useScreenSize } from 'hooks/useScreenSize';
import isNil from 'lodash.isnil';
import { LightbulbIcon, LightbulbOffIcon } from 'lucide-react';
import pluralize from 'pluralize';
import { FC, useMemo } from 'react';
import { TGrowthCycle } from 'shared/interfaces/growthCycle';
import { cn } from 'shared/utils/cn';

interface IRoomInfoProps {
  /** Allows styling of this component root element */
  className?: string;
  /** The current date */
  currentTime: Date;
  /** The latest measurement date */
  latestMeasurementDateTime: Nullable<Date>;
  /** The amount of hours with light */
  lightHours: number;
  /** The current time zone offset in hours */
  offset: number;
  /** Whether the harvest progress is shown or not */
  showHarvestProgress: boolean;
  /** The current growth cycle */
  cycle: TGrowthCycle;
}

/** Displays the basic info about the room such as the harvest progress */
export const RoomInfo: FC<IRoomInfoProps> = ({
  className,
  currentTime,
  latestMeasurementDateTime,
  lightHours,
  offset,
  showHarvestProgress,
  cycle,
}) => {
  const { isMobile } = useScreenSize();
  const isLightOn = offset < lightHours;
  const LightIcon = isLightOn ? LightbulbIcon : LightbulbOffIcon;
  const hours = isLightOn ? lightHours - offset : 24 - offset;
  const minutes = Math.round((hours - Math.floor(hours)) * 60);
  const totalDays = differenceInDays(cycle?.end_time, cycle?.start_time);
  const currentDay = differenceInDays(currentTime, cycle?.start_time) + 1;
  const daysPastHarvest = currentDay - totalDays - 1;
  const harvestPercentage = Math.min(currentDay / totalDays, 1) * 100;

  const updatedTime = useMemo(() => {
    if (!isNil(latestMeasurementDateTime)) {
      return formatDistance(
        new Date(latestMeasurementDateTime),
        new Date(currentTime),
        {
          addSuffix: true,
        }
      );
    }

    return null;
  }, [latestMeasurementDateTime, currentTime]);

  const updatedTimeText = updatedTime
    ? `Updated ${updatedTime}`
    : `Not updated in last 3 days.`;
  const harvestDaysText =
    showHarvestProgress &&
    cycle &&
    daysPastHarvest <= 0 &&
    `Harvest ${
      daysPastHarvest === 0
        ? 'today'
        : daysPastHarvest === -1
          ? 'tomorrow'
          : 'in ' + -daysPastHarvest + ' days'
    }`;
  const harvestAlertText =
    showHarvestProgress &&
    cycle &&
    daysPastHarvest > 0 &&
    `${daysPastHarvest} ${pluralize('day', daysPastHarvest)} past estimated harvest`;
  const harvestText = (
    <>
      {harvestDaysText && (
        <p className="mt-auto text-xs font-bold">{harvestDaysText}</p>
      )}

      {harvestAlertText && (
        <p className="mt-auto text-xs font-bold text-red-600">
          {harvestAlertText}
        </p>
      )}
    </>
  );

  return (
    <div
      className={cn(
        'w-full gap-3 grid grid-cols-[1fr_auto] lg:flex lg:flex-col',
        className
      )}
    >
      <p className="text-xs text-neutral-500 col-span-2">{updatedTimeText}</p>

      <div className="flex gap-2 [grid-row:2_/_span_3] [grid-column:2] col-start-2 items-start lg:items-center">
        <LightIcon className="stroke-[1.5px] size-4 xl:size-5" />

        <div className="h-full flex flex-col lg:items-center lg:flex-row lg:gap-2">
          <p className="text-xs font-bold uppercase text-neutral-600">
            {`Lights ${isLightOn ? 'on' : 'off'}`}
          </p>
          <p className="text-xs text-neutral-600">
            {`${Math.floor(hours) ? Math.floor(hours) + 'h ' : ''}${minutes ? minutes + 'm ' : ''}until ${isLightOn ? 'dark' : 'light'}`}
          </p>
          {isMobile && harvestText}
        </div>
      </div>

      {showHarvestProgress && cycle && (
        <div className="flex flex-col gap-2 [grid-row:2_/_span_3]">
          <p className="text-xl lg:text-3xl font-bold">Day {currentDay}</p>

          <div className="w-full h-7 rounded-full bg-neutral-300">
            <div
              className="h-full rounded-full bg-orange-500"
              style={{ width: `${harvestPercentage}%` }}
            />
          </div>
        </div>
      )}

      {!isMobile && harvestText}
    </div>
  );
};
