import { useGetMeasurementsByIds } from 'api/measurement';
import { getImageUrlInformation } from 'components/image_feed/hooks/utils';
import { getImageFeedResolutionInformation } from 'components/image_feed/utils';
import { useMemo } from 'react';
import { EImageTypes, ISectionCapture } from 'shared/interfaces/image';
import { TMeasurementRun } from 'shared/interfaces/measurementRun';

interface IUseSectionCaptureListProps {
  /** The measurement ids. */
  measurementIds: Nullable<number>[];
  position: TPosition;
  zoneId: string;
  /** The image type, NDVI, RGB. */
  imageType: EImageTypes;
}

export const useSectionCaptureList = ({
  measurementIds,
  position,
  imageType,
}: IUseSectionCaptureListProps): ISectionCapture[] => {
  const { measurements } = useGetMeasurementsByIds({
    measurementIds,
  });
  return useMemo(() => {
    if (!measurements) {
      return [];
    }
    return (
      measurements
        .map((measurement) => {
          const resolutionInformation = getImageFeedResolutionInformation(
            measurement.measurement_run as TMeasurementRun,
            imageType
          );
          if (!resolutionInformation) {
            return null;
          }
          const imageUrlInformation = getImageUrlInformation(
            resolutionInformation,
            measurement.data
          );

          let marked = false;
          if (
            measurement.image_annotation_trackings_aggregate.aggregate &&
            measurement.image_annotation_trackings_aggregate.aggregate?.count >
              0
          ) {
            marked = true;
          }
          return {
            measurementRunId: measurement?.measurement_run?.id,
            measurementId: measurement.id,
            cellX: position.x,
            cellY: position.y,
            time: new Date(measurement.time),
            marked,
            ...imageUrlInformation,
          };
        })
        .filter(Boolean) as ISectionCapture[]
    ).sort((a, b) => a.time.getTime() - b.time.getTime());
  }, [measurements, position.x, position.y, imageType]);
};
