import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const LeafPowderIcon = ({ className, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={cn('h-6 w-6', className)}
    {...props}
  >
    <path d="M12 17L12 22" />
    <path d="M17 12C17 16.9706 12 21 12 21C12 21 7 16.9706 7 12C7 7.02944 12 2 12 2C12 2 17 7.02944 17 12Z" />
    <path d="M14 13L13 14" />
    <path d="M12 7L14 9" />
    <path d="M10 10L10 13" />
  </svg>
);

export { LeafPowderIcon };
