import { isValidPosition } from 'components/image_feed/utils';
import { useStageProvider } from 'contexts/StageProvider';
import Konva from 'konva';
import { FC } from 'react';
import { Image, Layer, Rect, Stage } from 'react-konva';
import { Colors } from 'shared/constants/color';
import useImage from 'use-image';

export interface IImageMiniMapProps {
  /** TSize of the image to be displayed as a background image. */
  imageSize: TSize;
  /** The image url that will be used as a background of minimap. */
  miniMapImageUrl: string;
  /** The current cursor position in the Single Image View */
  initialCursorPosition?: Konva.Vector2d;
}

const getMiniMapSize = (imageSize: TSize) => {
  let { width, height } = imageSize;

  if (!width || !height) {
    return { width: 0, height: 0 };
  }

  while (width > 120 || height > 120) {
    width = width / 1.1;
    height = height / 1.1;
  }

  return { width, height };
};

export const ImageMiniMap: FC<IImageMiniMapProps> = ({
  imageSize,
  miniMapImageUrl,
  initialCursorPosition,
}) => {
  const stageProvider = useStageProvider();

  const miniMapSize = getMiniMapSize(imageSize);
  const scale = initialCursorPosition ? 1 : stageProvider?.scale.x;
  const cursorPosition = initialCursorPosition ?? stageProvider.position;

  if (!miniMapSize.width || !miniMapSize.height) {
    return null;
  }

  const viewScale = {
    x: miniMapSize.width / imageSize.width,
    y: miniMapSize.height / imageSize.height,
  };

  const viewSize = {
    width: stageProvider.size.width / scale,
    height: stageProvider.size.height / scale,
  };

  const viewPosition = {
    x: isValidPosition(cursorPosition.x) ? -cursorPosition.x / scale : 1,
    y: isValidPosition(cursorPosition.y) ? -cursorPosition.y / scale : 1,
  };

  return (
    <StaticImageMiniMap
      size={miniMapSize}
      viewSize={viewSize}
      viewScale={viewScale}
      viewPosition={viewPosition}
      imageUrl={miniMapImageUrl}
    />
  );
};

interface IStaticImageMiniMapProps {
  size: TSize;
  viewSize: TSize;
  viewScale: { x: number; y: number };
  viewPosition: Konva.Vector2d;
  imageUrl: string;
}

const StaticImageMiniMap: FC<IStaticImageMiniMapProps> = ({
  size,
  viewScale,
  viewPosition,
  viewSize,
  imageUrl,
}: IStaticImageMiniMapProps) => {
  const [miniMapImage] = useImage(imageUrl);

  return (
    <Stage
      draggable={false}
      width={size.width}
      height={size.height}
      scale={viewScale}
      style={{
        width: size.width,
        pointerEvents: 'none',
      }}
    >
      <Layer id="viewbox">
        <Image
          image={miniMapImage}
          width={size.width / viewScale.x}
          height={size.height / viewScale.y}
          x={0}
          y={0}
        />

        <Rect
          draggable={false}
          x={viewPosition.x}
          y={viewPosition.y}
          width={viewSize.width}
          height={viewSize.height}
          fill={Colors.orange2}
          opacity={0.7}
          cornerRadius={80}
        />
      </Layer>
    </Stage>
  );
};
