import { useGetEnumerationsByTypeQuery } from 'graphql/generated/react_apollo';
import groupBy from 'lodash.groupby';
import keyBy from 'lodash.keyby';
import uniq from 'lodash.uniq';
import { useMemo } from 'react';
import {
  EEnumerationTypes,
  TLabelEnumeration,
} from 'shared/interfaces/enumeration';
import { convertEnumerationToLabel } from 'shared/utils/image';

export const useGetLabelEnumerations = () => {
  const { data: labelTypes } = useGetEnumerationsByTypeQuery({
    variables: {
      types: [
        EEnumerationTypes.LABEL_SOURCE_TYPE,
        EEnumerationTypes.LABEL_STATUS,
        EEnumerationTypes.LABEL_TYPE,
        EEnumerationTypes.LABEL_NAME,
      ],
    },
  });
  const labelSourceTypeData = (labelTypes?.enumeration ?? []).filter(
    ({ type }) => type === EEnumerationTypes.LABEL_SOURCE_TYPE
  );
  const labelStatusData = (labelTypes?.enumeration ?? []).filter(
    ({ type }) => type === EEnumerationTypes.LABEL_STATUS
  );
  const labelTypeData = (labelTypes?.enumeration ?? []).filter(
    ({ type }) => type === EEnumerationTypes.LABEL_TYPE
  );
  const allLabelEnumerationsData = (labelTypes?.enumeration ?? []).filter(
    ({ type }) => type === EEnumerationTypes.LABEL_NAME
  );

  const labelEnumerations: TLabelEnumeration[] = useMemo(
    () =>
      allLabelEnumerationsData.map((enumeration) => ({
        ...convertEnumerationToLabel(enumeration),
        type: enumeration.type,
      })),
    [allLabelEnumerationsData]
  );

  const labelSourceTypesByCode = useMemo(
    () => keyBy(labelSourceTypeData, 'code'),
    [labelSourceTypeData]
  );
  const labelStatusesByCode = useMemo(
    () => keyBy(labelStatusData, 'code'),
    [labelStatusData]
  );
  const labelCategoryByCode = useMemo(
    () => keyBy(labelTypeData, 'code'),
    [labelTypeData]
  );
  const labelEnumerationsByCode = useMemo(
    () => keyBy(labelEnumerations, 'code'),
    [labelEnumerations]
  );
  const labelEnumerationsByCategory = useMemo(
    () => groupBy(labelEnumerations, 'category'),
    [labelEnumerations]
  );
  const labelEnumerationsById = useMemo(
    () => keyBy(labelEnumerations, 'enumerationId'),
    [labelEnumerations]
  );
  const labelEnumerationCategories = useMemo(
    () => uniq(labelEnumerations.map((enumeration) => enumeration.category)),
    [labelEnumerations]
  );

  return {
    labelSourceTypesByCode,
    labelStatusesByCode,
    labelCategoryByCode,
    labelEnumerationsByCode,
    labelEnumerationsByCategory,
    labelEnumerationsById,
    labelEnumerationCategories,
  };
};
