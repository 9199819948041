export enum EEventKeyCodes {
  LEFT_ARROW = 'left',
  UP_ARROW = 'up',
  RIGHT_ARROW = 'right',
  DOWN_ARROW = 'down',
  PAGE_UP = 'pageup',
  PAGE_DOWN = 'pagedown',
  SPACE = 'space',
  ESCAPE = 'escape',
}
