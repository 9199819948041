import {
  BanIcon,
  CheckIcon,
  InfoIcon,
  TriangleAlertIcon,
  XIcon,
} from 'lucide-react';
import {
  ComponentPropsWithoutRef,
  FC,
  ReactNode,
  SVGProps,
  cloneElement,
  isValidElement,
} from 'react';
import { createPortal } from 'react-dom';
import * as Toastify from 'react-toastify';
import { cn } from 'shared/utils/cn';

/** Renders the provided content according the type. */
function renderContent<T>(
  content: T,
  { closeToast, toastProps, ...props }: Toastify.ToastContentProps<T>
) {
  let Content: ReactNode = content;

  if (typeof content === 'function') {
    Content = content({ ...props, closeToast, toastProps });
  } else if (isValidElement(content)) {
    Content = cloneElement(content, props);
  }
  return <>{Content}</>;
}

const Icon: FC<ComponentPropsWithoutRef<'i'>> = ({
  children,
  className,
  ...props
}) => (
  <i
    className={cn('flex h-6 min-w-6 items-center justify-center', className)}
    {...props}
  >
    {children}
  </i>
);

const iconMap = {
  default: (
    <InfoIcon className="stroke-[1.5px] size-4 xl:size-5 text-orange-500" />
  ),
  error: <BanIcon className="stroke-[1.5px] size-4 xl:size-5 text-red-500" />,
  info: (
    <InfoIcon className="stroke-[1.5px] size-4 xl:size-5 text-orange-500" />
  ),
  warning: (
    <TriangleAlertIcon className="stroke-[1.5px] size-4 xl:size-5 text-yellow-600" />
  ),
  success: (
    <CheckIcon className="stroke-[1.5px] size-4 xl:size-5 text-green-500" />
  ),
} as {
  [key in Toastify.TypeOptions]: SVGProps<SVGSVGElement> | null;
};

/** Displays the toast content including the status icon and close button */
function getToast<T>(
  props: Toastify.ToastContentProps<T>,
  { content, extraContent, dismissable = true }: ToastFunctionParams
) {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-2">
        <Icon>{iconMap[props.toastProps.type]}</Icon>

        <div className="flex-grow">{renderContent(content, props)}</div>

        <Icon className={cn('hidden', dismissable && 'flex')}>
          <XIcon
            className="stroke-[1.5px] size-4 xl:size-5 cursor-pointer text-gray-900 outline-none"
            role="button"
            tabIndex={0}
            aria-label="Close"
            aria-hidden="false"
            onClick={() => {
              Toastify.toast.update(props.toastProps.toastId, {
                data: { ...props.toastProps.data, dismissedByUser: true },
              });
              props.closeToast?.();
            }}
          />
        </Icon>
      </div>

      {renderContent(extraContent, props)}
    </div>
  );
}

export type ToastType = Toastify.TypeOptions;

interface ToastFunctionParams {
  /** Toast main message */
  content: Toastify.ToastContent;
  /** Any extra rendered below the main message */
  extraContent?: Toastify.ToastContent;
  /** Whether the toast is dismissable or not */
  dismissable?: boolean;
}

export type ToastFunction = (
  params: ToastFunctionParams,
  options?: Toastify.ToastOptions
) => Toastify.Id;

/** Displays a toast of type `info` */
const info: ToastFunction = (params, options) =>
  Toastify.toast.info((props) => getToast(props, params), options);

/** Displays a toast of type `success` */
const success: ToastFunction = (params, options) =>
  Toastify.toast.success((props) => getToast(props, params), options);

/** Displays a toast of type `error` */
const error: ToastFunction = (params, options) =>
  Toastify.toast.error((props) => getToast(props, params), options);

/** Displays a toast of type `warning` */
const warning: ToastFunction = (params, options) =>
  Toastify.toast.warning((props) => getToast(props, params), options);

/** The toast container. Makes sure every toast displays in the proper place. */
const ToastContainer = () =>
  createPortal(
    <Toastify.ToastContainer
      className="top-0 flex w-full max-w-lg flex-col gap-2 p-4 font-medium md:m-0"
      bodyClassName="m-0 p-0 items-start w-full h-full"
      toastClassName={({ type = 'info' } = {}) =>
        cn(
          'm-0 flex cursor-default items-start gap-2 rounded-lg p-4 border bg-white text-gray-900 shadow',
          type === 'success' && 'border-green-200',
          type === 'error' && 'border-red-200 text-red-500',
          type === 'warning' && 'border-yellow-200',
          type === 'info' && 'border-orange-200',
          ['info', 'default'].includes(type) && 'border-orange-200'
        )
      }
      closeButton={false}
      icon={false}
      draggable={false}
      closeOnClick={false}
      autoClose={false}
      hideProgressBar={true}
      position="top-center"
      transition={Toastify.Slide}
    />,
    document.body
  );

const {
  toast: { dismiss, onChange },
} = Toastify;

export { ToastContainer, dismiss, error, info, onChange, success, warning };
