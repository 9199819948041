import { FC } from 'react';
import { cn } from 'shared/utils/cn';

interface IUserStatusIconProps {
  active: boolean;
}

export const UserStatusIcon: FC<IUserStatusIconProps> = ({ active }) => {
  return (
    <div
      className={cn(
        'inline-flex w-3 h-3 rounded-[12px]',
        active ? 'bg-green-500' : 'bg-red-500'
      )}
    />
  );
};
