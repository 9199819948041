import { ERoutePath } from 'shared/constants/url';
import { QueryParamsSchema, getQueryParams } from './URLQueryParams';
import { StoreArgs } from './URLStoreArgs';

export const getGrowthCycleReportURLStore = (
  args: StoreArgs<ERoutePath.GROWTH_CYCLE_REPORT>
) => {
  const { routePath, searchParams, setSearchParams } = args;

  const queryParams = getQueryParams(searchParams, routePath);
  type SchemaType = QueryParamsSchema<typeof routePath>;
  const actions = {
    setGrowthCycleId: ({
      growthCycleId,
    }: {
      growthCycleId: SchemaType['growth-cycle-id'];
    }) => {
      setSearchParams({ 'growth-cycle-id': growthCycleId }, { replace: true });
    },
  };
  const state = {
    growthCycleId: queryParams['growth-cycle-id'],
  };
  return {
    ...actions,
    ...state,
  };
};
