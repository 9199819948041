import {
  captureConsoleIntegration,
  httpClientIntegration,
} from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { AppConfig } from 'shared/config/AppConfig';

/**
 * Set up Sentry
 */
export function setupSentry(appConfig: AppConfig): void {
  // The `Sentry.isInitialized` check is only required when developing but we can keep it either way
  if (process.env.NODE_ENV === 'production' && !Sentry.isInitialized()) {
    Sentry.init({
      // This value must be identical to the release name specified during upload
      // with the `sentry-cli`.
      release: appConfig.sentry.release,
      environment: appConfig.environment,
      dsn: appConfig.sentry.dsn.toString(),
      ignoreErrors: ['ResizeObserver loop'],
      integrations: [
        // Ideally, we want this on BE
        httpClientIntegration({
          failedRequestStatusCodes: [400, 404, 405, 406, [500, 599]],
        }),
        // Capture anything in the console
        // This may produce rubbish reports
        // But from start of this integration
        // Let's see how much we actually have
        // In order to start cleaning up uncaught ones
        captureConsoleIntegration({
          levels: ['warn', 'error'],
        }),
        Sentry.browserTracingIntegration({
          // This is arguably unnecessary since we initialize Sentry only on 'production' builds
          // But I'm leaving this here in case we ever need to debug it locally ✌️
          tracePropagationTargets: [
            /^http:\/\/localhost:3000/,
            /^https:\/\/dashboard(.*)neatleaf\.com/,
          ],
        }),
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      // Capture 100% of the transactions, reduce in production!
      // Keeping sample rate to 100% since we don't have much traffic
      tracesSampleRate: 1.0,
      // Session Replay
      // This sets the sample rate at 10%.
      // You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysSessionSampleRate: 0,
      // If you're not already sampling the entire session,
      // change the sample rate to 100% when sampling sessions where errors occur.
      // Keeping sample rate to 100% since we don't have much traffic
      replaysOnErrorSampleRate: 1.0,
    });
  }
}
