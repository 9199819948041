import { CircularProgress } from './CircularProgress/CircularProgress';

export const CenteredLoader = () => {
  return (
    <div
      data-testid="centered-loader"
      className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center"
    >
      <CircularProgress />
    </div>
  );
};
