import { THeatMapScaleDataType } from 'shared/interfaces/heatmap';

export const FIXED_RANGE_HEATMAP_COLORS = {
  STEP1: '#9732FC',
  STEP2: '#FF31A0',
  STEP3: '#FFA621',
  STEP4: '#FAFF04',
};

export const MICROCLIMATES_HEATMAP_COLORS = {
  STEP1: '#0432FF',
  STEP2: '#532AA0',
  STEP3: '#A62555',
  STEP4: '#EA1F00',
};

export const OPTIMAL_RANGE_HEATMAP_COLORS = {
  STEP1: '#9732FC',
  STEP2: '#56C254',
  STEP3: '#66C254',
  STEP4: '#FAFF04',
};

export const GRADIENT_SCALE = {
  min: 0,
  max: 1,
};

export const DEFAULT_STEP_VALUES: THeatMapScaleDataType = {
  lowerBound: 0,
  upperBound: 0,
  optimalRangeLowerBound: undefined,
  optimalRangeUpperBound: undefined,
  currentRunLowerBound: 0,
  currentRunUpperBound: 0,
  setPoint: undefined,
};
