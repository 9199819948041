export enum Environment {
  DEV = 'dev',
  DEVSTABLE = 'devstable',
  SANDBOX = 'sandbox',
  SQA = 'sqa',
  STAGING = 'staging',
  PROD = 'prod',
}

export enum AwsRegion {
  US_WEST_2 = 'us-west-2',
}

export interface AwsCognitoConfig {
  identityPoolId: string;
  region: AwsRegion;
  userPoolId: string;
  webClientId: string;
}

export interface AwsLoggingConfig {
  projectRegion: AwsRegion;
}

export interface AwsConfig {
  cognito: AwsCognitoConfig;
  logging: AwsLoggingConfig;
}

export interface MixpanelConfig {
  enabled: boolean;
  debuggingEnabled: boolean;
  apiToken: string;
}

export interface HasuraConfig {
  graphqlEndpoint: URL;
}

export interface SentryConfig {
  release: string;
  dsn: URL;
}

export interface ReleaseConfig {
  branchName: string | undefined;
  commitSha: string;
  commitShortSha: string;
  releaseName: string;
  tagName: string | undefined;
  tagVersion: string | undefined;
}

export interface AppConfig {
  apiUrl: URL;
  assetsUrl: URL;
  aws: AwsConfig;
  environment: Environment;
  hasura: HasuraConfig;
  mixpanel: MixpanelConfig;
  release: ReleaseConfig;
  sentry: SentryConfig;
}
