import { CenteredLoader } from 'components/common/CenteredLoader';
import { NoDataView } from 'components/growth_cycle/NoDataView';
import { useZoneDetailsPageURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useGrowthCycles } from 'hooks/useGrowthCycles';
import { useZones } from 'hooks/useZones';
import isNil from 'lodash.isnil';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { EZoneDetailTabs } from 'shared/interfaces/zone';

export const ZoneDetailTabView: FC = () => {
  const { tab } = useZoneDetailsPageURL();
  const { latestCycle, growthCyclesLoading, growthCyclesCalled } =
    useGrowthCycles();
  const { zones, loading: zonesLoading, called: zonesCalled } = useZones();
  const { currentZone } = useCurrentZone();
  const hasGrowthCycles = !!latestCycle;
  const isGrowthCycleSettings = tab === EZoneDetailTabs.GROWTH_CYCLE_SETTINGS;

  if (
    zonesLoading ||
    !zonesCalled ||
    (zones.length > 0 &&
      (growthCyclesLoading || !growthCyclesCalled || isNil(currentZone)))
  ) {
    return <CenteredLoader />;
  }

  return (
    <div
      id="zoneDetailTabContainer"
      className="flex flex-col h-full gap-2"
      role="tabpanel"
      aria-labelledby={tab}
    >
      {!hasGrowthCycles && !isGrowthCycleSettings && (
        <NoDataView
          message={
            !zones.length
              ? 'There is no zone in this organization.'
              : 'There is no data available for this zone. Please, select a different one to continue.'
          }
        />
      )}

      {(hasGrowthCycles || isGrowthCycleSettings) && <Outlet />}
    </div>
  );
};
