/**
 * Retrieves the value from an object at a given path.
 * @param obj - The object from which to extract the value.
 * @param path - The path to the value within the object.
 * @returns The value at the specified path in the object.
 */
function getValue<T>(obj: T, path: string): number | undefined {
  return path
    .split('.')
    .reduce((acc: any, part: string) => acc && acc[part], obj);
}

/**
 * Checks if the array is sorted based on the specified path and optional transformation.
 * @param arr - The array to check.
 * @param path - The path to the field in the objects used for comparison.
 * @param transform - Optional transformation function to apply to the field value.
 * @returns True if the array is sorted, false otherwise.
 */
function isSorted<T, U>(
  arr: T[],
  path?: string,
  transform?: (value: U | any) => number
): boolean {
  for (let i = 0; i < arr.length - 1; i++) {
    let currentValue = path ? getValue(arr[i], path) : arr[i]!;
    if (transform) currentValue = transform(currentValue);
    let nextValue = path ? getValue(arr[i + 1], path) : arr[i + 1]!;
    if (transform) nextValue = transform(nextValue);
    if (currentValue === undefined || nextValue === undefined) {
      throw new Error(
        `Invalid path: '${path}' or transform function for object at index ${i}`
      );
    }
    if (currentValue > nextValue) {
      return false;
    }
  }
  return true;
}

/**
 * Finds the object in an array whose specified field, optionally transformed, is closest to the given target.
 * The function assumes that the array is sorted based on the specified field.
 * If the array is not sorted, an error is thrown.
 * @param arr - The array of objects to search.
 * @param target - The target value to compare to, which will be transformed by the same function as the field values.
 * @param path - The path to the field in the objects used for comparison.
 * @param transform - Optional transformation function to apply to the field value for comparison.
 * @returns A tuple containing the object from the array whose specified field is closest to the
 * transformed target and its index.
 *
 * @example
 * // Example with Date strings
 * const arr = [
 *     { id: 1, date: '2021-01-01' },
 *     { id: 2, date: '2021-07-01' },
 *     { id: 3, date: '2021-12-31' }
 * ];
 * const target = new Date('2021-06-01');
 * const path = 'date';
 * const transform = (dateStr: string) => new Date(dateStr).getTime();
 * console.log(findClosest(arr, target, path, transform));
 * // Output will be the object with the date closest to 2021-06-01 and
 * its index: { closest: { id: 2, date: '2021-07-01' }, closestIndex: 1]}
 */
export function findClosest<T, U>(
  arr: T[],
  target: U,
  path?: string,
  transform?: (value: U | any) => number
): { closest: T | undefined; closestIndex: number | undefined } {
  if (arr.length === 0) {
    return { closest: undefined, closestIndex: undefined };
  }

  // we do not check on prod to keep log(n) complexity
  if (!import.meta.env.PROD && !isSorted(arr, path, transform)) {
    throw new Error(
      'The array must be sorted based on the specified field and transformation.'
    );
  }

  const transformValue = (value: U | any) =>
    transform ? transform(value) : value;
  const targetTransformed = transformValue(target);

  let closest: T | undefined;
  let closestIndex: number | undefined;
  let closestTransformed = transformValue(
    path ? getValue(arr[0], path) : arr[0]!
  );
  let start = 0;
  let end = arr.length - 1;

  while (start <= end) {
    const mid = Math.floor((start + end) / 2);
    const midValue = transformValue(
      path ? getValue(arr[mid], path) : arr[mid]!
    );

    if (midValue === undefined) {
      throw new Error(
        `Invalid path: '${path}' or transform function for object at index ${mid}`
      );
    }

    if (
      Math.abs(midValue - targetTransformed) <=
      Math.abs(closestTransformed - targetTransformed)
    ) {
      closest = arr[mid]!;
      closestIndex = mid;
      closestTransformed = midValue;
    }

    if (midValue < targetTransformed) {
      start = mid + 1;
    } else if (midValue > targetTransformed) {
      end = mid - 1;
    } else {
      break;
    }
  }

  return { closest, closestIndex };
}
