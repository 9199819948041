import { useReactiveVar } from '@apollo/client';
import { imageFeedLabelStats } from 'global_state/image_feed_label_stats';
import {
  TImageFeedLabelStats,
  TLabelByCategory,
  TLabelStatistics,
  TRunIdToStats,
} from 'shared/interfaces/image';

type TImageFeedLabelStatsReturn = {
  getImageFeedLabelStatsByRunId: (
    runId: number,
    labelCode: string
  ) => Optional<TLabelStatistics>;
  updateImageFeedLabelStats: (labels: TLabelByCategory[]) => void;
};

export const useImageFeedLabelStats = (): TImageFeedLabelStatsReturn => {
  const imageFeedLabelStatsVar = useReactiveVar(imageFeedLabelStats);

  /**
   * Updates the image feed label statistics based on the provided array of labels.
   *
   * @param {TLabelByCategory[]} labels - An array of labels categorized by category.
   */
  function updateImageFeedLabelStats(labels: TLabelByCategory[]) {
    const runId = labels[0]?.measurementRunId;
    if (runId === undefined || imageFeedLabelStatsVar.has(runId)) {
      return;
    }

    const newImageFeedLabelStats: TImageFeedLabelStats = new Map();

    labels.forEach(({ category, subcategory, labelCount }) => {
      if (labelCount === undefined) {
        return;
      }

      const categoryStats = newImageFeedLabelStats.get(category) || {
        min: Infinity,
        max: -Infinity,
      };

      const subcategoryKey = `${category}:${subcategory}`;
      const subcategoryStats = newImageFeedLabelStats.get(subcategoryKey) || {
        min: Infinity,
        max: -Infinity,
      };

      categoryStats.min = Math.min(labelCount, categoryStats.min);
      categoryStats.max = Math.max(labelCount, categoryStats.max);

      subcategoryStats.min = Math.min(labelCount, subcategoryStats.min);
      subcategoryStats.max = Math.max(labelCount, subcategoryStats.max);

      newImageFeedLabelStats.set(category, categoryStats);
      newImageFeedLabelStats.set(subcategoryKey, subcategoryStats);
    });

    const newRunIdToStats: TRunIdToStats = new Map([
      [runId, newImageFeedLabelStats],
    ]);

    imageFeedLabelStats(
      new Map([...newRunIdToStats, ...imageFeedLabelStatsVar])
    );
  }

  /**
   * Retrieves the statistics of a specific label in the image feed for a given run ID.
   *
   * @param {number} runId - The ID of the run.
   * @param {string} labelCode - The code of the label.
   * @return {Optional<TLabelStatistics>} The statistics of the label in the image feed, or undefined if not found.
   */
  function getImageFeedLabelStatsByRunId(runId: number, labelCode: string) {
    const imageFeedStatsX = imageFeedLabelStatsVar.get(runId);
    return imageFeedStatsX?.get(labelCode);
  }

  return {
    updateImageFeedLabelStats,
    getImageFeedLabelStatsByRunId,
  };
};
