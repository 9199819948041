import { CenteredLoader } from 'components/common/CenteredLoader';
import { Header, HeaderProps, THeaderLink } from 'components/header/Header';
import { NotificationSideBar } from 'components/notifications/NotificationSideBar';
import { useAuth } from 'contexts/AuthProvider';
import {
  useGlobalInsightsURL,
  useURL,
  useZoneDetailsPageURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useLogOut } from 'hooks/useLogOut';
import { usePermissions } from 'hooks/usePermissions';
import { useZones } from 'hooks/useZones';
import isNil from 'lodash.isnil';
import { DoorOpenIcon, UserIcon } from 'lucide-react';
import { OrganizationNotFoundBoundary } from 'pages/not_found/OrganizationNotFoundBoundary';
import { Suspense, useEffect, useMemo } from 'react';
import { PSEUDO_ZONE, ZONE_TAB_DETAILS } from 'shared/constants/zone';
import {
  EHeaderMenuLinks,
  EZoneDetailTabs,
  HLink,
} from 'shared/interfaces/zone';
import { getDefaultFeedStartTime } from 'shared/utils/discussion';
import { InsightsFeed, InsightsFeedProps } from './InsightsFeed';

const { INSIGHTS } = EZoneDetailTabs;

const { LOGOUT, SETTINGS } = EHeaderMenuLinks;

export const GlobalInsightsPage = () => {
  const { organizations, onChangeOrganization, currentlySelectedOrganization } =
    useAuth();
  const { logOut } = useLogOut();
  const { canAccessUserSettings } = usePermissions();
  const { zoneTimeZone, currentTimeInCurrentZone, currentZone } =
    useCurrentZone();
  const {
    organizationCode,
    setOrganization,
    getFeedStartTime,
    setFeedStartTime,
  } = useGlobalInsightsURL();
  const feedStartTime = getFeedStartTime(zoneTimeZone);
  const { navigateToHome, navigateToSettings } = useURL();
  const { navigateTo } = useZoneDetailsPageURL();
  const { zones } = useZones();

  const headerZones = useMemo(() => {
    return [PSEUDO_ZONE].concat(zones);
  }, [zones]);
  const headerLinks: THeaderLink<HLink>[] = useMemo(
    () => [
      {
        id: INSIGHTS,
        label: ZONE_TAB_DETAILS[INSIGHTS].label,
        location: 'zone-detail-tabs',
      },
      {
        id: SETTINGS,
        label: 'User settings',
        location: 'settings-menu',
        enabled: canAccessUserSettings,
        onClick: navigateToSettings,
        leadingIcon: <UserIcon className="stroke-[1.5px] size-4 xl:size-5" />,
      },
      {
        id: LOGOUT,
        label: 'Logout',
        location: 'settings-menu',
        onClick: logOut,
        leadingIcon: (
          <DoorOpenIcon className="stroke-[1.5px] size-4 xl:size-5" />
        ),
      },
    ],
    [canAccessUserSettings, logOut, navigateToSettings]
  );

  const selectedLink = headerLinks.at(0)!;

  const extraContent = useMemo(() => {
    return currentZone && zones.length > 0 ? (
      <NotificationSideBar zones={zones} currentZone={currentZone} />
    ) : null;
  }, [currentZone, zones]);

  const handleChangeOrg: HeaderProps<HLink>['onChangeOrg'] = (org) => {
    setOrganization(org.code);
  };

  const handleChangeZone: HeaderProps<HLink>['onChangeZone'] = (zone) => {
    if (isNil(currentlySelectedOrganization))
      throw new Error('No organization selected');
    if (zone.code !== PSEUDO_ZONE.code) {
      navigateTo({
        organizationCode: currentlySelectedOrganization.code,
        zoneId: zone.id,
        tab: INSIGHTS,
      });
    }
  };

  const handleChangeStartTime: InsightsFeedProps['onChangeStartTime'] = (
    startTime
  ) => {
    setFeedStartTime({
      zonedDate: startTime.valueOf(),
      timeZone: zoneTimeZone,
    });
  };

  useEffect(() => {
    if (
      organizations.length &&
      !organizations.find((org) => org.code === organizationCode)
    ) {
      if (!isNil(currentlySelectedOrganization)) {
        setOrganization(currentlySelectedOrganization.code, {
          replace: true,
        });
      }
    } else {
      const selectedOrganization = organizations.find(
        (org) => org.code === organizationCode
      );
      if (selectedOrganization) {
        onChangeOrganization(selectedOrganization);
      }
    }
  }, [
    organizationCode,
    onChangeOrganization,
    organizations,
    currentlySelectedOrganization,
    setOrganization,
  ]);

  useEffect(() => {
    if (!feedStartTime) {
      setFeedStartTime({
        zonedDate: getDefaultFeedStartTime(currentTimeInCurrentZone).valueOf(),
        timeZone: zoneTimeZone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimeInCurrentZone, feedStartTime, zoneTimeZone]);

  return (
    <OrganizationNotFoundBoundary organization={currentlySelectedOrganization}>
      <Header
        orgs={organizations}
        zones={headerZones}
        links={headerLinks}
        selectedOrg={currentlySelectedOrganization}
        selectedZone={PSEUDO_ZONE}
        selectedLink={selectedLink}
        onChangeOrg={handleChangeOrg}
        onChangeZone={handleChangeZone}
        onClickHome={navigateToHome}
        extraContent={extraContent}
      />

      <main
        id="main"
        className="relative h-full px-4 lg:px-8 box-border overflow-x-hidden overflow-y-auto"
      >
        <Suspense fallback={<CenteredLoader />}>
          {feedStartTime && (
            <InsightsFeed
              zones={zones}
              startTime={new Date(feedStartTime)}
              onChangeStartTime={handleChangeStartTime}
            />
          )}
        </Suspense>
      </main>

      <footer id="footer" className="pb-4 px-4 lg:px-8" />
    </OrganizationNotFoundBoundary>
  );
};
