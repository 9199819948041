import { useGetHeatMapById } from 'api/heatmap';
import { wrapAround } from 'components/heat_map/hooks/utils';
import { EAggregationTypes } from 'shared/interfaces/heatmap';
import { MeasurementTypeConfig } from 'shared/interfaces/measurement';

interface IHeatMapDataPrefetchProps {
  /** Heat map id list */
  heatMapIdList: (string | number)[];
  /** The type of measurement */
  typeConfig: MeasurementTypeConfig;
  /** The index to offset from */
  index: number;
  aggregationType: EAggregationTypes;
}

/**
 * Prefetches heat maps surrounding the current heat map for faster loading.
 * @param {IHeatMapDataPrefetchProps} IHeatMapDataPrefetchProps - The props object containing the heat map ID list, measurement statistics type, and index.
 * @returns {void}
 */
export const useHeatMapPrefetch = ({
  heatMapIdList,
  typeConfig,
  index,
  aggregationType,
}: IHeatMapDataPrefetchProps): void => {
  // Offset index by -1 to prefetch the heat map.
  let offset = -1;
  const previousIndex1 = wrapAround(index, offset, heatMapIdList.length);

  useGetHeatMapById({
    id: heatMapIdList[previousIndex1],
    typeConfig,
    aggregationType,
  });

  // Offset index by -2 to prefetch the heat map.
  offset = -2;
  const previousIndex2 = wrapAround(index, offset, heatMapIdList.length);
  useGetHeatMapById({
    id: heatMapIdList[previousIndex2],
    typeConfig,
    aggregationType,
  });
  // Offset index by 1 to prefetch the heat map.
  offset = 1;
  const nextIndex1 = wrapAround(index, offset, heatMapIdList.length);

  useGetHeatMapById({
    id: heatMapIdList[nextIndex1],
    typeConfig,
    aggregationType,
  });

  // Offset index by 2 to prefetch the heat map.
  offset = 2;
  const nextIndex2 = wrapAround(index, offset, heatMapIdList.length);
  useGetHeatMapById({
    id: heatMapIdList[nextIndex2],
    typeConfig,
    aggregationType,
  });
};
