import Plotly from 'plotly.js';
import { useMemo } from 'react';
import { Colors } from 'shared/constants/color';
import { IExtendedLayout } from 'shared/interfaces/general';
import { IHeatMapData, TScaleColorizedValue } from 'shared/interfaces/heatmap';
import { MeasurementTypeConfig } from 'shared/interfaces/measurement';

export interface IHeatMapPlotInformation {
  data: Partial<Plotly.PlotData>;
  layout: Partial<IExtendedLayout>;
}

export interface IHeatMapPlotData {
  /** */
  typeConfig: MeasurementTypeConfig;
  /** Color scale to be used as a heatmap gradient base. */
  colorScale: TScaleColorizedValue[];
  /** Shows if the heatmap is displayed in mobile or desktop. */
  isMobile: boolean;
  /** Image to be used as a layout of Plotly. */
  layoutImageUrl: string;
  /** PlotData generated by HeatMap. */
  plotData: Nullable<IHeatMapData>;
  /** Unit */
  unit: string;
  /** Heat map width */
  width: number;
  /** Heat map height */
  height: number;
  /** Heat map range */
  range?: number[];
}

export interface IHeatMapPlotDataResult {
  heatMapPlotInformation: IHeatMapPlotInformation | null;
}

/**
 * Manipulates and organizes and combines the given data to the information.
 *
 * @param {IHeatMapPlotData} props The hook props.
 * @returns {IHeatMapPlotDataResult} The heatMapPlot Result.
 */
export const useHeatMapPlotData = ({
  typeConfig,
  colorScale,
  plotData,
  isMobile,
  layoutImageUrl,
  unit,
  width,
  height,
  range,
}: IHeatMapPlotData): IHeatMapPlotDataResult => {
  const heatMapPlotInformation: IHeatMapPlotInformation | null = useMemo(() => {
    if (!plotData) {
      return null;
    }

    const zFontSize = isMobile ? 14 : 20;
    const normalFontSize = isMobile ? 10 : 14;

    const zStyle = `color: ${Colors.orange2}; font-size: ${zFontSize}px; font-weight: 600; text-align: center;`;
    const valueStyle = `color: ${Colors.black}; font-size: ${normalFontSize};`;
    const labelStyle = `color: ${Colors.orange2}; font-size: ${normalFontSize};`;

    const desktopHoverTemplate =
      '<span> </span><br>' +
      `<span style="${zStyle}">      %{z:.01f} ${unit}        </span><br>` +
      `<span style="${valueStyle}">      %{x:.01f} </span>` +
      `<span style="${labelStyle}">${typeConfig.unitHeatmapXY} X</span>   ` +
      `<span style="${valueStyle}">%{y:.01f} ` +
      `<span style="${labelStyle}">${typeConfig.unitHeatmapXY} Y</span></span>      ` +
      '<br><span> </span>' +
      '<extra></extra>';

    const mobileHoverTemplate =
      `<span style="${zStyle}">   %{z:.01f} ${unit}     </span><br>` +
      `<span style="${valueStyle}">  %{x:.01f} </span>` +
      `<span style="${labelStyle}">${typeConfig.unitHeatmapXY} X</span>   ` +
      `<span style="${valueStyle}">%{y:.01f} ` +
      `<span style="${labelStyle}">${typeConfig.unitHeatmapXY} Y</span></span> ` +
      '<extra></extra>';

    const heatMapPlotData: Partial<Plotly.PlotData> = {
      ...plotData,
      type: 'heatmap',
      zsmooth: 'best',
      opacity: 0.6,
      marker: { color: '#dd382f' },
      showlegend: false,
      colorscale: colorScale,
      showscale: false,
      hovertemplate: isMobile ? mobileHoverTemplate : desktopHoverTemplate,
      hoverlabel: {
        bgcolor: 'white',
        font: { family: 'Poppins' },
        bordercolor: 'white',
      },
    };

    // Calculate the max and min values of the plot
    const plotXData = plotData.x as number[];
    const maxXPlot = Math.max(...plotXData);
    const minXPlot = Math.min(...plotXData);

    const plotYData = plotData.y as number[];
    const maxYPlot = Math.max(...plotYData);
    const minYPlot = Math.min(...plotYData);

    let scaledWidth = Math.round((maxXPlot / maxYPlot) * height);
    let scaledHeight = Math.round((maxYPlot / maxXPlot) * width);
    if (scaledHeight < height) {
      scaledWidth = width;
    } else {
      scaledHeight = height;
    }

    const layout: Partial<IExtendedLayout> = {
      autosize: true,
      images: [
        {
          source: layoutImageUrl,
          xref: 'x',
          yref: 'y',
          x: minXPlot,
          y: maxYPlot,
          sizex: maxXPlot - minXPlot,
          sizey: maxYPlot - minYPlot,
          sizing: 'stretch',
          opacity: 0.5,
          layer: 'below',
        },
      ],
      xaxis: {
        // Changing the xAxis doesn't update the plotly.
        range: range ? [range[0], range[1]] : [minXPlot, maxXPlot],
        visible: false,
        automargin: true,
        fixedrange: true,
      },
      yaxis: {
        // Changing the yAxis doesn't update the plotly.
        range: range ? [range[2], range[3]] : [minYPlot, maxYPlot],
        visible: false,
        automargin: true,
        fixedrange: true,
      },
      modebar: {
        // vertical modebar button layout
        orientation: 'v',
      },
      margin: {
        l: 0,
        r: 0,
        b: 0,
        t: 0,
      },
      height: scaledHeight,
      width: scaledWidth,
    };

    return {
      data: heatMapPlotData,
      layout,
    };
  }, [
    colorScale,
    height,
    isMobile,
    layoutImageUrl,
    plotData,
    range,
    typeConfig.unitHeatmapXY,
    unit,
    width,
  ]);

  return {
    heatMapPlotInformation,
  };
};
