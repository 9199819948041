export enum ERoutePath {
  ZONE_DETAILS_PAGE = 'ZONE_DETAILS_PAGE',
  HOMEPAGE = 'HOMEPAGE',
  HOMEPAGEV2 = 'HOMEPAGEV2',
  HEAT_MAP = 'HEAT_MAP',
  IMAGE_FEED = 'IMAGE_FEED',
  LINE_CHART = 'LINE_CHART',
  GROWTH_CYCLE_SETTINGS = 'GROWTH_CYCLE_SETTINGS',
  SETTINGS = 'SETTINGS',
  ROOT = 'ROOT',
  RESET_PASSWORD = 'RESET_PASSWORD',
  SETTINGS_EDIT_PROFILE = 'SETTINGS_EDIT_PROFILE',
  LOGIN = 'LOGIN',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  VERIFY_PASSWORD = 'VERIFY_PASSWORD',
  SETTINGS_TAB = 'SETTINGS_TAB',
  SETTINGS_USERS = 'SETTINGS_USERS',
  SETTINGS_ADD_USER = 'SETTINGS_ADD_USER',
  SETTINGS_EDIT_USER = 'SETTINGS_EDIT_USER',
  SETTINGS_NOTIFICATIONS = 'NOTIFICATIONS',
  SETTINGS_CRITICAL_ENV_SETTINGS = 'CRITICAL_ENV_SETTINGS',
  TERMS = 'TERMS',
  GLOBAL_INSIGHTS = 'GLOBAL_INSIGHTS',
  ZONE_INSIGHTS = 'ZONE_INSIGHTS',
  GROWTH_CYCLE_REPORT = 'GROWTH_CYCLE_REPORT',
}

export const PATH_PATTERNS = {
  [ERoutePath.ZONE_DETAILS_PAGE]:
    '/:organizationCode/zone-details/:zoneId?/:tab?',
  [ERoutePath.HOMEPAGE]:
    '/:organizationCode/zone-details/:zoneId?/homepage-tab',
  [ERoutePath.HOMEPAGEV2]:
    '/:organizationCode/zone-details/:zoneId?/homepage-tab-v2',
  [ERoutePath.HEAT_MAP]:
    '/:organizationCode/zone-details/:zoneId?/heat-map-tab',
  [ERoutePath.IMAGE_FEED]:
    '/:organizationCode/zone-details/:zoneId?/image-feed-tab',
  [ERoutePath.LINE_CHART]:
    '/:organizationCode/zone-details/:zoneId?/line-chart-tab',
  [ERoutePath.GROWTH_CYCLE_SETTINGS]:
    '/:organizationCode/zone-details/:zoneId?/growth-cycle-settings',
  [ERoutePath.GROWTH_CYCLE_REPORT]:
    '/:organizationCode/zone-details/:zoneId?/growth-cycle-report',
  [ERoutePath.ZONE_INSIGHTS]:
    '/:organizationCode/zone-details/:zoneId?/insights',
  [ERoutePath.GLOBAL_INSIGHTS]: '/:organizationCode/insights',
  [ERoutePath.SETTINGS]: '/settings',
  [ERoutePath.SETTINGS_TAB]: '/settings/:tab/:method?/:id?',
  [ERoutePath.SETTINGS_USERS]: '/settings/users',
  [ERoutePath.SETTINGS_ADD_USER]: '/settings/users/add',
  [ERoutePath.ROOT]: '/',
  [ERoutePath.RESET_PASSWORD]: '/reset',
  [ERoutePath.LOGIN]: '/login',
  [ERoutePath.FORGOT_PASSWORD]: '/forgot',
  [ERoutePath.VERIFY_PASSWORD]: '/verify',
  [ERoutePath.SETTINGS_EDIT_USER]: '/settings/users/edit/:userId',
  [ERoutePath.SETTINGS_EDIT_PROFILE]: '/settings/profile',
  [ERoutePath.SETTINGS_NOTIFICATIONS]: '/settings/notifications',
  [ERoutePath.SETTINGS_CRITICAL_ENV_SETTINGS]:
    '/settings/critical-env-settings',
  [ERoutePath.TERMS]: '/termsandconditions',
} as const;

export const NO_URL = 'NO_URL';
export const NO_THUMBNAIL_BUCKET = 'NO_THUMBNAIL_BUCKET';
export const NO_IMAGE_BUCKET = 'NO_IMAGE_BUCKET';
