/**
 * Apply unshift function to immutable array.
 * @param {Array<T>} immutableObj - immutable object
 * @param {T} value - parameter for unshift function
 * @returns {Array<T>} mutated array
 */
export function unshiftImmutableArray<T>(
  immutableObj: Array<T>,
  value: T
): Array<T> {
  const mutableObj = [...immutableObj];
  mutableObj.unshift(value);
  return mutableObj;
}

/**
 * Generate the array with the values of 0 ~ [length - 1]
 * @param {number} length - the array length
 * @returns {Array<number>} - sequential array
 */
export function generateSequentialArray(length: number): Array<number> {
  return new Array(length).fill(0).map((_value, index) => index);
}

/**
 * Initialize a grid with a given number of rows and columns.
 * @param numberOfRows The number of rows.
 * @param numberOfColumns The number of columns.
 * @param value The value to fill the grid with.
 * @returns The initialized grid.
 */
export function initializeGrid<T>(
  numberOfRows: number,
  numberOfColumns: number,
  value: T
) {
  return Array.from({ length: numberOfRows }, () =>
    Array(numberOfColumns).fill(value)
  );
}

/**
 * Assign a value to a subgrid of a grid.
 * @param grid The grid to assign the value to.
 * @param fromRow The starting row index of the subgrid.
 * @param toRow The ending row index of the subgrid.
 * @param fromColumn The starting column index of the subgrid.
 * @param toColumn The ending column index of the subgrid.
 * @param value The value to assign to the subgrid.
 */
export function assignValueToSubGrid<T>(
  grid: Array<Array<T>>,
  fromRow: number,
  toRow: number,
  fromColumn: number,
  toColumn: number,
  value: T
) {
  return grid.map((row, i) =>
    row.map((cell, j) => {
      if (
        (i >= fromRow && i <= toRow && j >= fromColumn && j <= toColumn) ||
        (i <= fromRow && i >= toRow && j <= fromColumn && j >= toColumn) ||
        (i <= fromRow && i >= toRow && j >= fromColumn && j <= toColumn) ||
        (i >= fromRow && i <= toRow && j <= fromColumn && j >= toColumn)
      )
        return value;
      return cell;
    })
  );
}
