export enum Permission {
  ImageLabelView = 'IMAGE_LABEL:VIEW',
  GrowthCycleUpdate = 'GROWTH_CYCLE:UPDATE',
  LocationUpdate = 'LOCATION:UPDATE',
  HeatMapView = 'HEAT_MAP:VIEW',
  HomepageView = 'HOMEPAGE:VIEW',
  LineChartView = 'LINE_CHART:VIEW',
  UserUpdate = 'USER:UPDATE',
  UserSettingsView = 'USER_SETTINGS:VIEW',
  ZoneView = 'ZONE:VIEW',
  DiscussionView = 'DISCUSSION:VIEW',
  DiscussionCreate = 'DISCUSSION:CREATE',
  DiscussionUpdate = 'DISCUSSION:UPDATE',
  DiscussionDelete = 'DISCUSSION:DELETE',
  InsightCreate = 'INSIGHT:CREATE',
  InsightUpdate = 'INSIGHT:UPDATE',
  InsightDelete = 'INSIGHT:DELETE',
  InsightPublish = 'INSIGHT:PUBLISH',
  InsightDraftView = 'INSIGHT_DRAFT:VIEW',
  CommentThreadView = 'COMMENT_THREAD:VIEW',
  CommentThreadCreate = 'COMMENT_THREAD:CREATE',
  CommentThreadUpdate = 'COMMENT_THREAD:UPDATE',
  CommentThreadDelete = 'COMMENT_THREAD:DELETE',
  GrowthCycleReportView = 'GROWTH_CYCLE_REPORT:VIEW',
}
