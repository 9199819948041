import {
  DiscussionBoxContextProvider,
  DiscussionBoxContextProviderProps,
} from './DiscussionBoxContext';
import {
  DiscussionBoxPopover,
  DiscussionBoxPopoverProps,
} from './DiscussionBoxPopover';

export type DiscussionBoxProps = DiscussionBoxPopoverProps &
  Omit<DiscussionBoxContextProviderProps, 'children'>;

export const DiscussionBox = ({
  referenceElement,
  discussions,
  selectedDiscussion,
  floatProps,
  timeRange,
  onSaveDiscussion,
  onSelectDiscussion,
  onChangeActiveDiscussion,
  onClose,
  onDelete,
  closeOnWheel,
}: DiscussionBoxProps) => {
  return (
    <DiscussionBoxContextProvider
      discussions={discussions}
      selectedDiscussion={selectedDiscussion}
      timeRange={timeRange}
      onClose={onClose}
      onDelete={onDelete}
      onSaveDiscussion={onSaveDiscussion}
      onSelectDiscussion={onSelectDiscussion}
      onChangeActiveDiscussion={onChangeActiveDiscussion}
    >
      <DiscussionBoxPopover
        referenceElement={referenceElement}
        floatProps={floatProps}
        closeOnWheel={closeOnWheel}
      />
    </DiscussionBoxContextProvider>
  );
};
